import React from "react";
import { Flex, useMantineTheme } from "@mantine/core";
import ContactFooter from "./ContactFooter";
import SocialsFooter from "./SocialsFooter";
import NavlinksFooter from "./NavlinksFooter";
import AfterFooter from "./AfterFooter";

const Footer: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Flex
      direction={{ base: "column" }}
      bg={theme.colors.primary[0]}
      align="center"
      px={{ base: 24, 630: 60 }}
    >
      <Flex
        p={{ base: "48px 0 86px", 630: "48px 0" }}
        w="100%"
        justify="center"
      >
        <Flex
          direction={{ base: "column-reverse", 796: "row" }}
          gap={{ base: 48, 670: 24, 1120: 48 }}
          align="start"
          justify="space-between"
          maw={1680}
          w="100%"
        >
          <ContactFooter />
          <NavlinksFooter />
          <SocialsFooter />
        </Flex>
      </Flex>

      <AfterFooter />
    </Flex>
  );
};

export default Footer;

import { Avatar, Flex, FlexProps, Text, Title } from "@mantine/core";
import { Profile } from "iconsax-react";
import { IProductItem } from "../../../../interfaces/IListProducts";

interface IProps extends FlexProps {
  item?: IProductItem;
}

const HeaderHighlightCard: React.FC<IProps> = ({ item, ...props }) => {
  return (
    <Flex {...props} gap="16px" align="center" className="" h="54px" mih="54px">
      <Avatar
        component="span"
        variant="outline"
        size={48}
        color="basic.2"
        radius={8}
        src={
          item?.organization?.icon?.Location
            ? item.organization.icon.Location
            : null
        }
        alt={item?.product.title ? item?.product.title : "não existe imagem"}
        style={{ transition: "all 0.5s linear" }}
        styles={{
          root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #D7D7D7",
          },
          image: {
            height: "38px",
            width: "38px",
          },
        }}
      >
        <Profile color="#2F2F2F" />
      </Avatar>
      <Flex gap="4px" justify="space-between" direction="column" h="100%">
        <Text
          tt="uppercase"
          fw={500}
          size={14}
          lh="21.7px"
          color="basic.4"
          ff="Barlow"
          lineClamp={1}
          style={{
            wordBreak: "break-all",
          }}
        >
          {!!item?.organization?.name
            ? item.organization.name
            : "nome do parceiro ausente na api"}
        </Text>
        <Title
          tt="uppercase"
          fw={700}
          size={18}
          lh={"27.9px"}
          color="basic.5"
          lineClamp={1}
          style={{
            wordBreak: "break-all",
          }}
        >
          {!!item?.product.title
            ? item.product?.title
            : "nome do produto ausente na api"}
        </Title>
      </Flex>
    </Flex>
  );
};

export default HeaderHighlightCard;

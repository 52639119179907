import { Box, Divider, Flex } from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import {
  ComponentHighlightCard,
  ImageHighlightCard,
  HeaderHighlightCard,
  FooterCard,
  ContentCampCard,
} from "../../ComponentsCards";
import { CardCampHighlightProps } from "../../ComponentsCards/typesCards";
import { useNavigate } from "react-router-dom";

interface IProps {
  item: CardCampHighlightProps;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
}

const CardHighlightCamp: React.FC<IProps> = (props) => {
  const { hovered, ref } = useHover();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const navigate = useNavigate();

  return (
    <Box ref={ref} sx={{ width: "100%", maxWidth: "539px" }}>
      <ComponentHighlightCard
        onClick={() =>
          navigate(
            !!props?.preSlug
              ? `${props?.preSlug}${props?.item.slug}`
              : props?.item.slug
          )
        }
      >
        <ImageHighlightCard
          image={props?.item.image}
          title={props?.item.title}
          badgetext={props?.item.badgetext}
          radius={0}
          height={isLargerThan991 ? (hovered ? 215 : 336) : 215}
        />
        <Flex
          w="100%"
          direction="column"
          h={isLargerThan991 ? 309 : "fit-content"}
          p={32}
          bg="white"
          pos={isLargerThan991 ? "absolute" : "unset"}
        >
          <HeaderHighlightCard
            mb={4}
            // item={props.item}
          />

          <ContentCampCard
            initialDate={props?.item.initialDate}
            finalDate={props?.item.finalDate}
            awarded={props?.item.awarded}
            participant={props?.item.participant}
            participantTotal={props?.item.participantTotal}
            contract={props?.item.contract}
            award={props?.item.award}
            price={props?.item.price}
          />

          <Divider my={20} />

          <FooterCard
            price={props?.item.price}
            activeInit={props?.item.activeInit}
            activeFinal={props?.item.activeFinal}
          />
        </Flex>
      </ComponentHighlightCard>
    </Box>
  );
};

export default CardHighlightCamp;

import { Image, ImageProps } from "@mantine/core";
import { Link } from "react-router-dom";
import LogoTraderEvolutionOn from "../../assets/LogosTrader/LogoTraderEvolutionOn.svg";
import { useScrollIntoView } from "@mantine/hooks";

interface IProps extends ImageProps {
  noText?: boolean;
}

const Logo: React.FC<IProps> = ({ noText, ...props }) => {
  const { scrollIntoView } = useScrollIntoView({ duration: 0 });

  return (
    <Link
      onClick={() => {
        scrollIntoView({ alignment: "start" });
      }}
      to={"/"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image
        {...props}
        src={noText ? LogoTraderEvolutionOn : LogoTraderEvolutionOn}
        alt="Logo"
        fit="contain"
        styles={{
          root: {
            cursor: "pointer",
            transition: "0.3s",
            ":hover": {
              opacity: "0.9",
              transition: "0.3s",
            },
          },
        }}
      />
    </Link>
  );
};

export default Logo;

import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: "#ECF3FE",
    border: "unset",
    maxWidth: "454px",
    width: "100%",
    padding: "16px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    "&::before": {
      backgroundColor: "#1B4FDF",
      margin: "16px 10px 16px 0",
      left: "8px",
    },
  },

  icon: {
    margin: "0",
  },

  loader: {
    margin: "0",
  },

  title: {
    color: "#2F2F2F",
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "12px",
  },

  description: {
    color: "#2F2F2F",
    opacity: 0.5,
    fontWeight: 400,
    fontSize: "16px",
    marginLeft: "12px",
  },

  closeButton: {
    width: "28px",
    height: "28px",
    color: "#6B6B6B",
    "&:hover": { backgroundColor: "unset", color: "#2F2F2F", scale: "1.1" },
    svg: {
      width: "28px",
      height: "28px",
    },
  },
}));

export default useStyles;

import {
  ActionIcon,
  Box,
  Card,
  Flex,
  Image,
  useMantineTheme,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { ArrowRight2 } from "iconsax-react";
import { Link } from "react-router-dom";
import {
  FotoNossosParceiros,
  FotoTraderEvolution,
  LogoTraderEvolution,
  NossosParceiros,
} from "../../../assets";

interface IProps {
  type: "partners" | "trader";
}

const CardLeague: React.FC<IProps> = (props) => {
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();

  return (
    <Box ref={ref} maw={539} w="100%" h={195}>
      <Card
        p={0}
        radius="12px"
        component={Link}
        to={props.type === "partners" ? "/plataformas" : "/robos"}
        sx={{
          width: "100%",
          height: "100%",
          textDecoration: "none",
          cursor: "pointer",
          transition: "all 0.5s ease-in-out",
          position: "relative",
          background: "transparent",
          overflow: "hidden",
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",

          button: {
            border: "unset",
            transition: "0.5s",
            opacity: "0",
            scale: ".01",
            zIndex: 3,
          },

          ":hover": {
            boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.1)",
            transition: "0.5s",

            button: {
              transition: "0.5s",
              opacity: "1",
              scale: "1",
            },
          },
        }}
      >
        <Flex
          align="center"
          h="100%"
          w="50%"
          pos="absolute"
          style={{
            zIndex: 1,
            background: theme.colors.basic[0],
          }}
        >
          <Image
            height={52}
            maw={232}
            w="100%"
            m="0 20px 0 32px"
            fit="contain"
            src={
              props.type === "partners" ? NossosParceiros : LogoTraderEvolution
            }
            alt={
              props.type === "partners"
                ? "NossosParceiros"
                : "LogoTraderEvolution"
            }
          />
        </Flex>
        <ActionIcon
          size={32}
          radius="xl"
          variant="default"
          color="basic.5"
          bg="primaryOpacity.0"
          style={{ position: "absolute", right: "32px", top: "82px" }}
        >
          <ArrowRight2 size={24} />
        </ActionIcon>
        <Box
          w={hovered ? "5%" : "0%"}
          h={194}
          left="50%"
          bottom="0"
          style={{
            position: "absolute",
            zIndex: 2,
            background: "white",
            transition: "all 0.5s ease-in-out",
          }}
        />
        <Box
          w={hovered ? "45%" : "50%"}
          h={194}
          right="0"
          bottom="0"
          style={{
            position: "absolute",
            zIndex: 1,
            border: "unset",
            backgroundRepeat: "no-repeat",
            backgroundImage:
              props.type === "partners"
                ? `url(${FotoNossosParceiros})`
                : `url(${FotoTraderEvolution})`,
            backgroundSize: "auto 105%",
            backgroundPosition: "right bottom",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <Box
            w="100%"
            h={194}
            style={{
              position: "absolute",
              zIndex: 2,
              backgroundImage: "primary.0",
              backgroundSize: hovered ? "30% 100%" : "25% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left, right",
              transition: "all 0.5s ease-in-out",
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default CardLeague;

import * as Yup from "yup";
import {
  cpf,
  email,
  name,
  password,
  phone,
  secondPassword,
  term_of_contract,
} from "../YupSchema";

const validationSignUp = Yup.object().shape({
  name: name,
  document: cpf,
  email: email,
  phone: phone,
  password: password,
  secondPassword: secondPassword,
  term_of_contract: term_of_contract,
});

export { validationSignUp };

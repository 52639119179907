import React from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  Marketplace,
  PageNotFound,
  Term,
  Policy,
  CopyOrder,
  CopyOrderProduct,
  Authentication,
  ScreenComponents,
  Profile,
  MyShopp,
  ProductDetails,
  SmartCopy,
  SmartCopyProduct,
  Purchase,
} from "../pages";

import Root from "./Root";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Genial from "../pages/Marketplace/CopyOrder/Genial";

const Router: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route element={<Root />}>
        <Route
          index
          element={
            <Navigate to="/marketplace" state={{ from: location }} replace />
          }
        />
        <Route path="/marketplace" element={<Marketplace />} />

        <Route path="marketplace/copyorder" element={<CopyOrder />} />
        <Route
          path="marketplace/copyorder/:slug"
          element={<CopyOrderProduct />}
        />
        <Route path="marketplace/copyorder/genial" element={<Genial />} />
        <Route
          path="marketplace/copyorder/genial/:slug"
          element={<CopyOrderProduct />}
        />
        <Route path="marketplace/smartcopy" element={<SmartCopy />} />
        <Route
          path="marketplace/smartcopy/:slug"
          element={<SmartCopyProduct />}
        />
        <Route
          path="/comprar-produtos"
          element={
            <PrivateRoute>
              <Purchase />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
        <Route path="/politica-de-privacidade" element={<Policy />} />
        <Route path="/termo-de-uso" element={<Term />} />

        <Route
          path="/meu-perfil/configuracoes"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/meu-perfil/financeiro"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/meu-perfil/senha"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/minhas-compras"
          element={
            <PrivateRoute>
              <MyShopp />
            </PrivateRoute>
          }
        />

        <Route
          path="/minhas-compras/detalhes/:slug"
          element={
            <PrivateRoute>
              <ProductDetails />
            </PrivateRoute>
          }
        />
        <Route path="autenticacao">
          <Route
            path="acessar-conta"
            element={
              <PublicRoute>
                <Authentication />
              </PublicRoute>
            }
          />
          <Route
            path="criar-conta"
            element={
              <PublicRoute>
                <Authentication />
              </PublicRoute>
            }
          />
        </Route>

        {/* <Route path="marketplace/campeonatos" element={<Campeonatos />} /> */}
        {/* <Route
          path="marketplace/algoritmos-indicadores"
          element={<AlgoritmosIndicadoresMarketplace />}
        /> */}

        {/* <Route path="marketplace/cursos" element={<Cursos />} /> */}
        {/* <Route
          path="marketplace/plataformas"
          element={<PlataformasMarketplace />}
        /> */}

        {/*  <Route
          path="marketplace/workspaces"
          element={<WorkspacesMarketplace />}
        /> */}

        {/* 
        <Route path="/solucoes/trader-evolution" element={<Solucoes />} />
        <Route
          path="/solucoes/trader-evolution/algoritmos-indicadores"
          element={<AlgoritmosIndicadores />}
        />
        <Route
          path="/solucoes/trader-evolution/algoritmos-indicadores/slug"
          element={<Algoritmo />}
        />
        <Route
          path="/solucoes/trader-evolution/plataformas"
          element={<PlataformasSolucoes />}
        />
        <Route
          path="/solucoes/trader-evolution/workspaces"
          element={<Workspaces />}
        />
        <Route path="/solucoes/produtos/mac" element={<MacProducts />} />
        <Route path="/solucoes/produtos/mobile" element={<MobileProducts />} />
        <Route path="/solucoes/produtos/desktop" element={<ProProducts />} />
        <Route
          path="/solucoes/produtos/simulador"
          element={<SimulatorProducts />}
        />
        <Route path="/solucoes/produtos/web" element={<WebProducts />} />
        <Route path="/solucoes/produtos/ideas" element={<IdeasProducts />} />
        <Route
          path="/solucoes/corretoras/homologadas"
          element={<ApprovedBrokers />}
        />
        <Route
          path="/solucoes/corretoras/seja-parceira"
          element={<PartnerBrokers />}
        /> */}

        {/* Components */}
        <Route path="c0mp0n&nt$" element={<ScreenComponents />} />
      </Route>
    </Routes>
  );
};

export default Router;

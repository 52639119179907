import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Modal as ModalMantine,
  Button,
  useMantineTheme,
} from "@mantine/core";
import { TickCircle, CloseCircle, InfoCircle } from "iconsax-react";
import TextHTML from "../../TextHTML";

interface IProps {
  type: "success" | "danger" | "warning" | "info" | "successSpecial";
  description: string;
  open: boolean;
  btnclose?: boolean;
  firstClick?: () => void;
  secondClick?: () => void;
  setClose: () => void;
  titleFirstButton?: string;
  titleSeccondButton?: string;
  contentEspecial?: React.ReactNode;
}

const Modal: React.FC<IProps> = ({
  description,
  open,
  type,
  setClose,
  btnclose,
  firstClick,
  secondClick,
  titleFirstButton,
  titleSeccondButton,
  contentEspecial,
}) => {
  const theme = useMantineTheme();
  const [content, setContent] = useState<string | any>({
    icon: "",
  });

  useEffect(() => {
    switch (type) {
      case "danger":
        return setContent({
          backgroundColor: theme.colors.alertsOpacity[0],
          icon: (
            <CloseCircle
              size="60"
              color={theme.colors.alerts[0]}
              variant="Bold"
            />
          ),
          textModal: "Erro!",
          varinantFirstButton: "danger",
        });
      case "info":
        return setContent({
          backgroundColor: theme.colors.primaryOpacity[1],
          icon: (
            <InfoCircle
              size="60"
              color={theme.colors.primary[1]}
              variant="Bold"
            />
          ),
          textModal: "Informação!",
          varinantFirstButton: "primary",
        });
      case "success":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[0],
          icon: (
            <TickCircle
              size="60"
              color={theme.colors.alerts[2]}
              variant="Bold"
            />
          ),
          textModal: "Sucesso!",
          varinantFirstButton: "primary",
        });
      case "warning":
        return setContent({
          backgroundColor: theme.colors.alertsOpacity[1],
          icon: (
            <InfoCircle
              size="60"
              color={theme.colors.alerts[1]}
              variant="Bold"
            />
          ),
          textModal: "Atenção!",
          varinantFirstButton: "danger",
        });
      case "successSpecial":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[0],
          icon: (
            <TickCircle
              size="60"
              color={theme.colors.alerts[2]}
              variant="Bold"
            />
          ),
          textModal: "Pedido realizado com sucesso!",
          fz: "22px",
          varinantFirstButton: "primary",
        });
    }
  }, [type]);

  return (
    <ModalMantine
      size="auto"
      opened={open}
      onClose={setClose}
      closeOnClickOutside={false}
      transitionProps={{
        exitDuration: 600,
        transition: "slide-up",
        duration: 600,
        timingFunction: "ease",
      }}
      centered
      styles={{
        content: {
          background: content.backgroundColor,
          borderRadius: "12px",
        },
        inner: { zIndex: 1000 },
        overlay: { zIndex: 999 },
        header: {
          marginBottom: "0",
          background: "transparent",
          height: "75px",
        },
        close: {
          display: !!btnclose ? "block" : "none",
          position: "absolute",
          top: "12px",
          right: "12px",
          svg: {
            height: "24px",
            width: "24px",
          },
        },
        body: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "50px 50px 12px 12px",
          padding: "0 40px 40px 40px",
        },
      }}
    >
      <Box mt="-32px">{content.icon}</Box>
      <Text fw={600} fz={content.fz ? content.fz : "32px"} lh="32px">
        {content.textModal}
      </Text>
      <TextHTML
        maw={420}
        ta="center"
        fw={600}
        fz="16px"
        lh="24px"
        color="basic.4"
        mt="32px"
        mb="32px"
        textHTML={description}
      />
      {contentEspecial}
      {!!titleFirstButton && (
        <Button
          variant={content.varinantFirstButton}
          fullWidth
          mb={16}
          onClick={firstClick}
        >
          {titleFirstButton}
        </Button>
      )}
      {!!titleSeccondButton && (
        <Button variant="primaryOutline" fullWidth onClick={secondClick}>
          {titleSeccondButton}
        </Button>
      )}
    </ModalMantine>
  );
};

export default Modal;

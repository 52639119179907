import { Center, Flex, Tooltip } from "@mantine/core";
import { MdAndroid } from "react-icons/md";
import { DiChrome, DiApple, DiWindows } from "react-icons/di";
import { RiSafariFill } from "react-icons/ri";
import { FaOpera } from "react-icons/fa";
import { BsBrowserEdge } from "react-icons/bs";
import { SiFirefoxbrowser } from "react-icons/si";
import { IoIosAppstore } from "react-icons/io";
import TextHTML from "../../../TextHTML";
import DeskMobWeb from "../../../DeskMobWeb";
import { Product } from "../../../../interfaces/IProduct";
import { useMediaQuery } from "@mantine/hooks";

interface IProps {
  item: Product;
  select?: boolean;
}

const ContentBrokerCard: React.FC<IProps> = (props) => {
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <Flex
      mt={{ base: 12, 991: props.select ? 18 : 16 }}
      gap={{ base: 16, 991: props.select ? 18 : 16 }}
      direction="column"
    >
      {!!props?.item?.desktop || !!props?.item?.mobile || !!props?.item?.web ? (
        <DeskMobWeb
          desktop={props?.item?.desktop}
          mobile={props?.item?.mobile}
          web={props?.item?.web}
          select={props.select}
        />
      ) : null}

      <TextHTML
        textHTML={props?.item?.short_description}
        ff="Barlow"
        fw={500}
        fz={{ base: 14, 991: props.select ? 15.87 : 14 }}
        lh={{ base: "24px", 991: props.select ? "27.21px" : "24px" }}
        h={{ base: "72px", 991: props.select ? "55px" : "48px" }}
        lineClamp={isLargerThan991 ? 2 : 3}
      />
      <Flex gap={props.select ? 9 : 8} align="center" h="fit-content">
        <Tooltip label="windows" withArrow>
          <Center>
            <DiWindows
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.windows ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Apple" withArrow>
          <Center>
            <DiApple
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.mac ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Android" withArrow>
          <Center>
            <MdAndroid
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.android ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="ios store" withArrow>
          <Center>
            <IoIosAppstore
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.ios ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Chrome" withArrow>
          <Center>
            <DiChrome
              size={!props.select ? 27 : 24}
              color={props.item.platforms?.chrome ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Safari" withArrow>
          <Center>
            <RiSafariFill
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.safari ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Opera" withArrow>
          <Center>
            <FaOpera
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.opera ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Edge" withArrow>
          <Center>
            <BsBrowserEdge
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.edge ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
        <Tooltip label="Firefox" withArrow>
          <Center>
            <SiFirefoxbrowser
              size={!props.select ? 27 : 24}
              color={!!props?.item?.platforms?.firefox ? "#6B6B6B" : "#B9B9B9"}
            />
          </Center>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default ContentBrokerCard;

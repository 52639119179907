import { Modal } from "@mantine/core";
import InfoUserDashboard, { IProps } from "../InfoUserDashboard";
import InfoUserDashboardSectionTwo from "../InfoUserDashboardSectionTwo";
import TableDashboard from "../TablesDashboard";

const ModalExpand: React.FC<IProps> = ({ openModal, setOpenModal }) => {
  return (
    <>
      <Modal
        withCloseButton={false}
        opened={openModal}
        onClose={setOpenModal}
        size="100%"
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <InfoUserDashboard
          openModal={openModal}
          setOpenModal={() => setOpenModal()}
        />

        <InfoUserDashboardSectionTwo />

        <TableDashboard />
      </Modal>
    </>
  );
};
export default ModalExpand;

import React from "react";
import { Select as SelectMantine, SelectProps } from "@mantine/core";
import { ArrowDown2 } from "iconsax-react";

const Select: React.FC<SelectProps> = (props) => {
  return (
    <SelectMantine
      mt={-8}
      styles={{ rightSection: { pointerEvents: "none" } }}
      aria-label="select"
      dropdownPosition="bottom"
      rightSection={<ArrowDown2 size="24" color="#2F2F2F" />}
      {...props}
    />
  );
};

export default Select;

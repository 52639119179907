import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();
  const from = location.state?.from;

  let { userMVP, isLoading } = useAuth();
  if (isLoading) {
    return <p>carregando...</p>;
  }

  if (!!!userMVP.username) {
    return (
      <Navigate
        to="/autenticacao/acessar-conta"
        state={{ from: from }}
        replace
      />
    );
  }

  return children;
};

export default PrivateRoute;

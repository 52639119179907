import {
  ActionIcon,
  Avatar,
  Flex,
  FlexProps,
  Text,
  Title,
} from "@mantine/core";
import { ArrowRight, Profile } from "iconsax-react";

interface IProps extends FlexProps {
  avatar?: string;
  name: string;
  title: string;
}

const HeaderMainCard: React.FC<IProps> = (props) => {
  return (
    <Flex
      {...props}
      gap="16px"
      align="center"
      h="54px"
      mih="54px"
      justify="space-between"
    >
      <Flex gap="16px" align="center">
        <Avatar
          component="span"
          variant="outline"
          size={48}
          color="basic.2"
          radius={8}
          src={props.avatar}
          alt="Nome do parceiro"
          styles={{
            image: { height: "38px", width: "38px" },
          }}
        >
          <Profile color="#2F2F2F" />
        </Avatar>
        <Flex gap="4px" justify="space-between" direction="column" h="100%">
          <Text
            tt="uppercase"
            fw={500}
            size={14}
            lh="21.7px"
            color="basic.4"
            ff="Barlow"
            lineClamp={1}
            style={{
              wordBreak: "break-all",
            }}
          >
            {props.name}
          </Text>
          <Title
            tt="uppercase"
            fw={700}
            size={18}
            lh={"27.9px"}
            color="basic.5"
            lineClamp={1}
            style={{
              wordBreak: "break-all",
            }}
          >
            {props.title}
          </Title>
        </Flex>
      </Flex>
      <ActionIcon size={44} radius="xl" variant="outline">
        <ArrowRight size={28} />
      </ActionIcon>
    </Flex>
  );
};

export default HeaderMainCard;

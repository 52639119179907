import { useState } from "react";
import { Box, Button, Flex, Popover, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Calendar } from "iconsax-react";
import "dayjs/locale/pt-br";
import moment from "moment";

const DatePopover: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);

  var daysSelections = moment(date[1]).diff(moment(date[0]), "days");

  return (
    <Popover opened={opened} onChange={setOpened} position="bottom-end">
      <Popover.Target>
        <Button
          w={56}
          onClick={() => {
            setOpened(!opened);
          }}
          variant="transparentEspecial"
          styles={{
            root: {
              background: "rgba(63, 135, 244, 0.2)",
              borderRadius: "0 50px 50px 0",
              ":hover": {
                background: "rgba(63, 135, 244, 0.2)",
              },
            },
          }}
        >
          <Calendar size="18" color="#122447" />
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Box>
          <Flex>
            <DatePicker
              firstDayOfWeek={0}
              locale="pt-br"
              mb={15}
              type="range"
              value={date}
              onChange={setDate}
              px={20}
              sx={{ borderRight: "1.5px solid  #D7D7D7" }}
            />
            <Flex
              p={20}
              direction={"column"}
              align="start"
              justify="start"
              h="auto"
              gap={8}
            >
              <Text>Datas predefinidas</Text>
              <Box pl={10}>
                <Text>Últimos 7 dias</Text>
                <Text>Últimos 30 dias</Text>
                <Text>Últimos 90 dias</Text>
                <Text>Últimos 180 dias</Text>
                <Text>Últimos 365 dias</Text>
              </Box>
            </Flex>
          </Flex>

          <Flex
            p={20}
            justify={"space-between"}
            align={"center"}
            sx={{ borderTop: "1.5px solid  #D7D7D7" }}
          >
            <Button
              variant="primary"
              h={38}
              onClick={() => {
                setOpened(!opened);
                setDate([null, null]);
              }}
            >
              Cancelar
            </Button>
            <Flex gap={10} align={"center"}>
              {date[0] !== null ? (
                <Text>
                  Selecionado:{" "}
                  {date[1] !== null
                    ? `${daysSelections} ${
                        daysSelections === 1 ? "dia" : "dias"
                      }`
                    : "Somente o dia"}
                </Text>
              ) : (
                "Escolha uma ou mais datas"
              )}
              <Button
                variant="primary"
                h={38}
                onClick={() => {
                  setOpened(!opened);
                }}
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DatePopover;

import { useState } from "react";
import { Badge, Box, Center, Flex, Tabs, Text } from "@mantine/core";
import { DATA_SHOPPING } from "../../constants/DATA_SHOPPINGS";
import CardShopping from "./CardProduct";
import { convertDayWeek, convertToDate } from "../../utils/date";
import SearchDrawer from "../../components/Navbar/components/SubNavLink/Search";
import FilterDrawer from "../../components/Navbar/components/SubNavLink/Filter";

const contentTabs = [
  {
    id: 1,
    text: "Todas as compras",
    badgeNumber: 4,
    color: "primary.1",
  },
  {
    id: 2,
    text: "Aprovadas",
    badgeNumber: 2,
    color: "alerts.2",
  },
  {
    id: 3,
    text: "Em processamento",
    badgeNumber: 1,
    color: "alerts.1",
  },
  {
    id: 4,
    text: "Reprovadas",
    badgeNumber: 1,
    color: "alerts.0",
  },
  {
    id: 5,
    text: "Solicitações de cancelamento",
    badgeNumber: 0,
    color: "basic.4",
  },
  {
    id: 6,
    text: "Canceladas",
    badgeNumber: 0,
    color: "basic.4",
  },
];

const MyPurchases: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string | null>("Todas as compras");
  return (
    <Center bg="other.0">
      <Flex
        direction="column"
        w="100%"
        maw={1680}
        px={{ base: 24, 768: 60 }}
        pb={96}
      >
        <Flex justify={"space-between"} align="center" mt={32} mb={20}>
          <Text
            fz={{ base: 32 }}
            fw={{ base: 700 }}
            c="basic.5"
            lh={{ base: "155%" }}
          >
            Minhas compras
          </Text>
          <Flex
            gap={16}
            sx={{
              button: {
                color: "#2F2F2F",
              },
            }}
          >
            <SearchDrawer />
            <FilterDrawer />
          </Flex>
        </Flex>

        <Tabs
          value={activeTab}
          onTabChange={setActiveTab}
          radius="xs"
          defaultValue="first"
          styles={{
            root: {
              zIndex: 1,
              overflowY: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            },
            tabsList: {
              borderBottom: "1px solid #D7D7D7",
              flexWrap: "nowrap",
            },
          }}
        >
          <Tabs.List>
            {contentTabs.map((tabItem: any, index: number) => (
              <Tabs.Tab
                key={index}
                disabled={tabItem?.badgeNumber === 0}
                rightSection={
                  <Badge
                    color={tabItem?.color}
                    h={22}
                    styles={{
                      root: {
                        fontWeight: 600,
                        fontSize: "14px",
                      },
                    }}
                    variant="filled"
                    px={8}
                  >
                    {tabItem?.badgeNumber >= 10
                      ? tabItem?.badgeNumber
                      : "0" + tabItem?.badgeNumber}
                  </Badge>
                }
                value={tabItem?.text}
              >
                {tabItem?.text}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>

        {DATA_SHOPPING.map((shop: any, index: number) => (
          <Box key={index}>
            <Text
              mt={46}
              mb={32}
              c="basic.4"
              fw={{ base: 600 }}
              fz={{ base: 16 }}
              lh={{ base: "24.8px" }}
            >
              {convertToDate(shop?.products[0]?.date)}
              <Text ml={6} component="span" fw={{ base: 400 }}>
                ({convertDayWeek(shop?.products[0]?.date)})
              </Text>
            </Text>
            <Flex direction="column" gap={16}>
              <CardShopping product={shop?.products} />
            </Flex>
          </Box>
        ))}
      </Flex>
    </Center>
  );
};

export default MyPurchases;

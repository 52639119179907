import { IFormModalBrokers } from "./type";
import { convertToDate, convertToHour } from "../../../utils/date";
import { IProductItem } from "../../../interfaces/IListProducts";
import { getPublicIPAdrress } from "../../../hooks/usePublicUPAddress";
import { PostEmailProps } from "../../../hooks/useEmail";

type IProps = {
  dataForm: IFormModalBrokers;
  dataProduct: IProductItem;
};

const CreateMessage = async ({ dataForm, dataProduct }: IProps) => {
  const dateFormated = convertToDate(new Date());
  const hourFormated = convertToHour(new Date());
  const urlPage = window.location.href;
  const userAgent = window.navigator.userAgent;
  const getIP = await getPublicIPAdrress();
  const nameActive = dataForm.name
    ? `<p><b>nome: </b>${dataForm.name}</p>`
    : "";
  const phoneActive = dataForm.phone
    ? `<p><b>numero: </b>${dataForm.phone}</p>`
    : "";
  const emailActive = dataForm.email
    ? `<p><b>email: </b>${dataForm.email}</p>`
    : "";
  const cpfActive = dataForm.cpf ? `<p><b>CPF: </b>${dataForm.cpf}</p>` : "";
  const AccountActive = dataForm.account
    ? `<p><b>Conta: </b>${dataForm.account}</p>`
    : "";
  const htmlText = !!dataProduct.product.email.html
    ? `${dataProduct?.product?.email?.html}`
    : "";
  const brokerListActive = dataForm.broker_list
    ? `<p><b>Corretora: </b>${dataForm.broker_list}</p>`
    : "";
  const termOfContractActive = dataForm.term_of_contract
    ? `<p><b>Leu e aceitou os termos de contrato: </b>Sim</p>`
    : "";
  const commercialConditionsActive = dataForm.commercial_conditions
    ? `<p><b>Está ciente das condições comercias: </b>Estou ciente</p>`
    : "";
  const habilitAccountActive = dataForm.habilit_account
    ? `<p><b>Está ciente que precisa da versão desktop: </b>Estou ciente</p>`
    : "";

  const htmlBodyEmail = `<div>
          ${nameActive}
          ${phoneActive}
          ${emailActive}
          ${cpfActive}
          ${AccountActive}
          ${brokerListActive}
          ${termOfContractActive}
          ${commercialConditionsActive}
          ${habilitAccountActive}
          <br />  
          ${htmlText}
          <br />
          <p><b>Data: </b>${dateFormated}</p>
          <p><b>Horário: </b>${hourFormated}</p>
          <p><b>URL da página: </b>${urlPage}</p>
          <p><b>Agente de usuário: </b>${userAgent}</p>
          <p><b>IP remoto: </b>${getIP}</p>
        </div> `;

  const dataMensage: PostEmailProps = {
    email_to: !!dataProduct?.product?.email?.email_to
      ? dataProduct.product.email.email_to
      : "atendimento@traderevolution.movidesk.com",
    email_from: !!dataProduct?.product?.email?.email_from
      ? dataProduct.product.email.email_from
      : "atendimento@traderevolution.com.br",
    text: "TraderEvolution",
    subject: `${dataProduct.product.email.subject} - Email: ${dataForm.email}`,
    html: htmlBodyEmail,
    reply_to: !!dataProduct?.product?.email?.reply_to
      ? dataProduct.product.email.reply_to
      : dataForm.email,
  };

  return dataMensage;
};

export default CreateMessage;

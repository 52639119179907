import { Flex, Text, Title } from "@mantine/core";
import IconColor from "../../IconColor";

interface IProps {
  title: string;
  value1: string;
  value2?: string;
  full: boolean;
  icon: React.ReactNode;
  colorIcon?:
    | "green"
    | "orange"
    | "violet"
    | "red"
    | "blue"
    | "pink"
    | "purple"
    | "babyBlue";
}

const ParametersCard: React.FC<IProps> = (props) => {
  return (
    <Flex
      w="100%"
      justify="space-between"
      direction={props.full ? "row-reverse" : "column"}
      p={props.full ? "32px" : "24px"}
      gap={props.full ? "8px" : "16px"}
      style={{
        maxHeight: "183px",
        height: "100%",
        flex: "1",
        borderRadius: "12px",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
      }}
    >
      <IconColor colorIcon={props.colorIcon}>{props.icon}</IconColor>
      <Flex
        justify="space-between"
        direction="column"
        h="100%"
        w="fit-content"
        gap="4px"
      >
        <Text
          fw={700}
          size={16}
          lh="25px"
          color="basic.5"
          style={{
            width: "fit-content",
          }}
        >
          {props.value1 && props.value2
            ? `${props.value1} / ${props.value2}`
            : !!props.value1
            ? props.value1
            : "-"}
        </Text>
        <Flex h="100%">
          <Title
            fw={500}
            size={14}
            lh="22px"
            color="basic.3"
            lineClamp={2}
            style={{
              width: "fit-content",
              wordBreak: "break-word",
            }}
          >
            {props.title}
          </Title>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ParametersCard;

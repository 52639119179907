import { Card, Divider, Text } from "@mantine/core";
import {
  ComponentCommonCard,
  ImageCommonCard,
  HeaderCommonCard,
  FooterCard,
} from "../../ComponentsCards";
import { useMediaQuery } from "@mantine/hooks";
import { IProductItem } from "../../../../interfaces/IListProducts";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalRegister from "../../../Modals/ModalRegister";

type IProps = {
  item: IProductItem;
  select?: boolean;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardCommonAlgorithm: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [modalRegister, setModalRegister] = useState<boolean>(false);
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <>
      <ModalRegister
        item={props.item}
        open={modalRegister}
        setClose={() => setModalRegister(!modalRegister)}
      />

      <ComponentCommonCard
        onClick={() =>
          props.slug
            ? navigate(
                !!props?.preSlug
                  ? `${props?.preSlug}${props?.item?.product?.title}`
                  : props?.item?.product?.title
              )
            : setModalRegister(!modalRegister)
        }
        maw={{ base: 539, 991: props?.select ? 460 : 396 }}
        miw={{ base: 331, 991: props?.select ? 460 : 396 }}
        p={{ base: 16, 991: props?.select ? 29.8 : 24 }}
      >
        <Card.Section
          pos={"relative"}
          style={{
            marginInline: props?.select ? "-29.8px" : "-24px",
            marginTop: props?.select ? "-29.8px" : "-24px",
          }}
        >
          <ImageCommonCard
            image={props?.item?.product?.photo?.Location}
            title={props?.item?.product?.title}
            height={isLargerThan991 ? (props?.select ? 224 : 193) : 122}
            full={true}
            select={props?.select}
            radius={12}
            badgeName={props?.item?.product?.subcategory}
            badgeColor={"green"}
          />
        </Card.Section>
        <HeaderCommonCard
          item={props.item}
          select={props?.select}
          mt={props?.select ? 25 : 20}
          mb={props?.select ? 25 : 20}
        />
        <Text
          lineClamp={2}
          fw={400}
          size={props?.select ? 16.26 : 14}
          lh={props?.select ? "25.2px" : "21.7px"}
          color="basic.4"
          ff="Barlow"
        >
          {props?.item?.product?.short_description}
        </Text>
        <Divider my={props?.select ? 25 : 20} />
        <FooterCard
          price={props?.item?.plan?.price}
          // points={item.product.points}
          select={props?.select}
        />
      </ComponentCommonCard>
    </>
  );
};

export default CardCommonAlgorithm;

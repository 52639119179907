import { Flex, Image } from "@mantine/core";
import DataPopover from "../DatePopover";
import { SegmentControlRounded } from "../../../../components";
import { GraphicDahsboard } from "../../../../assets";

const optionsDay = [
  { label: "1D", value: "1" },
  { label: "7D", value: "7" },
  { label: "1M", value: "30" },
  { label: "3M", value: "90" },
  { label: "6M", value: "180" },
  { label: "1A", value: "365" },
  { label: "TUDO", value: "all" },
];

const GraphicWaveDashboard: React.FC = () => {
  return (
    <Flex
      justify="end"
      bg="basic.0"
      pos={"relative"}
      sx={{ borderRadius: "10px" }}
      direction={"column"}
      h={440}
    >
      <Image radius={10} src={GraphicDahsboard} />
      <Flex
        pos="absolute"
        top={32}
        right={32}
        //   bg="#E7E9EC"
        sx={{ borderRadius: "50px" }}
      >
        <SegmentControlRounded radius="50px 0 0 50px" data={optionsDay} />

        <DataPopover />
      </Flex>
    </Flex>
  );
};

export default GraphicWaveDashboard;

import { Flex, Text, TextProps, useMantineTheme } from "@mantine/core";

interface IProps extends TextProps {
  actives?: string[];
  gap?: string;
  colorWhite?: boolean | false;
  radius?: string;
}

const TagActive: React.FC<IProps> = ({
  radius,
  colorWhite,
  actives,
  gap,
  ...props
}) => {
  const theme = useMantineTheme();

  return (
    <Flex
      justify="start"
      h="fit-content"
      gap={!!gap ? gap : "24px"}
      wrap={{
        base: "wrap",
        991: !!actives?.length && actives?.length > 6 ? "wrap" : "nowrap",
      }}
      mb="26px"
    >
      {actives?.map((active, index: number) => (
        <Text
          key={index}
          tt="uppercase"
          fw={600}
          size={16}
          lh="32px"
          color={colorWhite ? theme.colors.basic[0] : theme.colors.primary[1]}
          ff="Barlow"
          p="8px 12px"
          w="fit-content"
          style={{
            borderRadius: radius ? radius : 0,
            border: "1px solid",
            borderColor: colorWhite
              ? theme.colors.basic[0]
              : theme.colors.primary[1],
          }}
          {...props}
        >
          {active}
        </Text>
      ))}
    </Flex>
  );
};

export default TagActive;

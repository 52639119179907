const DATA_ACCORDION = [
  {
    id: "1",
    title: "BIG IDEIA",
    content:
      "Apresentando o Spectre: seu aliado nos mercados futuros da B3. Este copy inteligente foi meticulosamente desenvolvido para operar com precisão no índice e dólar ao mesmo tempo, para isso foi criado um ativo sintético (juntando os dois ativos em um único) para a tomada de decisão e utilizando análise gráfica e indicadores de tendência para identificar e seguir a tendência predominante no daytrade.Com uma abordagem inovadora, o Spectre filtra correções nos movimentos de preços, garantindo que os traders aproveitem as tendências no daytrade de maior prazo sem serem afetados por volatilidades momentâneas. Realizando apenas operações buscando uma maior assertividade no daytrade, este copy maximiza os ganhos enquanto minimiza as perdas, proporcionando um gerenciamento de risco sofisticado para uma abordagem prudente e sustentável. Além disso, o Spectre oferece benefícios adicionais, incluindo aprimoramento emocional e comportamental, tornando-o ideal para o trader focar nos seus ganhos. Com o Spectre ao seu lado, você opera com confiança, sabendo que está utilizando uma ferramenta poderosa para otimizar seus resultados nos mercados futuros da B3",
  },
  {
    id: "2",
    title: "Como contratar?",
    content:
      "Clique no botão acima para ver os contratos disponíveis, e escolha o que melhor se adequa ao seu perfil de investidor",
  },
];

export { DATA_ACCORDION };

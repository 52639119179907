import { Divider } from "@mantine/core";
import { HeaderCommonCard, FooterCard } from "../../ComponentsCards";
import ContentBrokerCard from "../../ComponentsCards/ContentBrokerCard";
import ComponentBrokerCard from "./ComponentBrokerCard";
import ModalRegister from "../../../Modals/ModalRegister";
import { useState } from "react";
import { IProductItem } from "../../../../interfaces/IListProducts";
import { useNavigate } from "react-router-dom";

type IProps = {
  item: IProductItem;
  select?: boolean;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardCommonBroker: React.FC<IProps> = (props) => {
  const [modalRegister, setModalRegister] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <ModalRegister
        item={props.item}
        open={modalRegister}
        setClose={() => setModalRegister(!modalRegister)}
      />
      <ComponentBrokerCard
        onClick={() =>
          props.slug
            ? navigate(props.item.product.title)
            : setModalRegister(!modalRegister)
        }
        maw={{
          base: props.carrousel ? 313 : 340,
          991: 540,
          1100: props.select ? 612 : 540,
        }}
        ml={{ base: 0, 991: props.select ? 16 : 0 }}
        p={{ base: 16, 991: props.select ? 36 : 32 }}
      >
        <HeaderCommonCard badge={true} select={props.select} />

        <ContentBrokerCard select={props.select} item={props.item.product} />

        <Divider my={{ base: "16px", 991: 16 }} />

        <FooterCard price={props.item?.plan?.price} select={props.select} />
      </ComponentBrokerCard>
    </>
  );
};

export default CardCommonBroker;

import { Flex, Text } from "@mantine/core";
import { Global, Mobile, Monitor } from "iconsax-react";

interface IProps {
  desktop?: boolean;
  mobile?: boolean;
  web?: boolean;
  select?: boolean;
}

const DeskMobWeb: React.FC<IProps> = (props) => {
  return (
    <>
      <Flex gap={props.select ? 14 : 12}>
        <Flex gap={props.select ? 9 : 8}>
          {!!props?.desktop ? (
            <Monitor size={props.select ? 27 : 24} color="#3F87F4" />
          ) : null}

          {!!props?.mobile ? (
            <Mobile
              variant="Outline"
              size={props.select ? 27 : 24}
              color="#3F87F4"
            />
          ) : null}

          {!!props?.web ? <Global color="#3F87F4" /> : null}
        </Flex>
        <Text
          fw={500}
          fz={{ base: 12, 991: props.select ? 13.6 : 12 }}
          lh={{ base: "24px", 991: props.select ? "27px" : "24px" }}
          color="basic.4"
        >
          {!!props?.desktop ? "Desktop" : null}
          {(!!props?.desktop && !!props?.mobile) ||
          (!!props?.desktop && !!props?.web)
            ? " + "
            : null}
          {!!props?.mobile ? "Mobile" : null}
          {!!props?.mobile && !!props?.web ? " + " : null}
          {!!props?.web ? "Web" : null}
        </Text>
      </Flex>
    </>
  );
};

export default DeskMobWeb;

import { Flex } from "@mantine/core";

import { navbarLinks } from "../../constants/links";
import { useAuth } from "../../context/auth";
import NavLink from "./NavLink";

const NavLinks = () => {
  const { role } = useAuth();

  return (
    <Flex
      gap={{ base: "16px", xl: "20px" }}
      align="center"
      justify="space-between"
    >
      {navbarLinks(role).map((navLink, index) => {
        return (
          <NavLink key={`navlinkNavbarDesktop ${index}`} navLink={navLink} />
        );
      })}
    </Flex>
  );
};

export default NavLinks;

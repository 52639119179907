import {
  ActionIcon,
  Flex,
  Indicator,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { MessageText, Notification } from "iconsax-react";
import { useAuth } from "../../context/auth";

const NotificationsNavbar = () => {
  const theme = useMantineTheme();
  const { userMVP } = useAuth();

  return (
    <Flex gap={{ base: "16px", 1700: "24px" }} align="center">
      {/* <Tooltip
        label="Mensagens"
        color={theme.colors.basic[5]}
        position="bottom"
        withArrow
      >
        <ActionIcon
          size={44}
          variant="transparent"
          sx={{
            transition: "0.3s",
            borderRadius: "8px !important",
            border: "unset",
            ":hover": {
              background: theme.colors.basic[6],
            },
          }}
        >
          <Indicator
            disabled={!userMVP.message}
            color="red"
            withBorder
            offset={5}
            styles={{
              common: {
                borderWidth: "1px",
              },
            }}
          >
            <MessageText
              size={24}
              variant="Outline"
              color={theme.colors.basic[0]}
            />
          </Indicator>
        </ActionIcon>
      </Tooltip> */}
      <Tooltip
        label="Notificações"
        color={theme.colors.basic[5]}
        position="bottom"
        withArrow
      >
        <ActionIcon
          size={44}
          variant="transparent"
          sx={{
            transition: "0.3s",
            borderRadius: "8px !important",
            ":hover": {
              background: theme.colors.basic[6],
            },
          }}
        >
          <Indicator
            disabled={!userMVP.notification}
            color="red"
            withBorder
            offset={5}
            styles={{
              common: {
                borderWidth: "1px",
              },
            }}
          >
            <Notification
              size={24}
              variant="Outline"
              color={theme.colors.basic[0]}
            />
          </Indicator>
        </ActionIcon>
      </Tooltip>
    </Flex>
  );
};

export default NotificationsNavbar;

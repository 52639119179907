import React from "react";
import { IMaskInput } from "react-imask";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Flex, Input, InputBase, Text } from "@mantine/core";
import { InputWrapper } from "../../../../components";
import { IFormSendRecover } from "./type";
import {
  ValidationSendRecoverEmail,
  ValidationSendRecoverPhone,
} from "./schema-validation";

interface IPropsSendRecover {
  nextStep: () => void;
  type: string;
  setAlreadyPassword: Function;
}

const SendRecover: React.FC<IPropsSendRecover> = ({
  nextStep,
  type,
  setAlreadyPassword,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<IFormSendRecover>({
    mode: "onBlur",
    resolver: yupResolver(
      type === "phone" ? ValidationSendRecoverPhone : ValidationSendRecoverEmail
    ),
  });

  const onSubmit = (data: IFormSendRecover) => {
    setAlreadyPassword(true);
    nextStep();
  };

  return (
    <>
      <Text size={16} fw={400} mb={24} maw={602} ta="center">
        {`Vamos enviar para o seu ${
          type === "phone" ? "telefone um sms com o" : "e-mail um"
        }  código para recuperação da sua senha. Preencha com o mesmo ${
          type === "phone" ? "telefone" : "e-mail"
        } do seu cadastro.`}
      </Text>
      {type === "phone" ? (
        <InputWrapper label="Telefone" withAsterisk>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input<any>
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                component={IMaskInput}
                mask="(00) 00000-0000"
                placeholder="(00) 00000-0000"
              />
            )}
          />
        </InputWrapper>
      ) : (
        <Controller
          name="email"
          control={control}
          render={({ field: { onBlur, onChange, value } }) => (
            <InputWrapper label="E-mail" withAsterisk>
              <InputBase
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                placeholder="Digite seu email"
              />
            </InputWrapper>
          )}
        />
      )}
      <Button
        variant="primary"
        fullWidth
        mt={26}
        onClick={handleSubmit(onSubmit)}
        disabled={!isDirty || !isValid}
      >
        Enviar
      </Button>
      <Flex
        w="100%"
        justify="space-between"
        mt={24}
        pt={24}
        style={{ borderTop: " 1px solid #B3B1B1" }}
      >
        <Text>Já possui o código de recuperação?</Text>
        <Button
          h="auto"
          variant="link"
          onClick={() => {
            setAlreadyPassword(false);
            nextStep();
          }}
        >
          Clique aqui
        </Button>
      </Flex>
    </>
  );
};

export default SendRecover;

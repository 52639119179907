import { Avatar, Divider, Flex, Text, Title } from "@mantine/core";
import {
  ComponentCommonCard,
  ImageCommonCard,
  FooterCard,
} from "../../ComponentsCards";
import { CardGraficProps } from "../../ComponentsCards/typesCards";
import { useNavigate } from "react-router-dom";
import HeaderCommonCardMVP from "./HeaderCommonCardMVP";
import { Profile } from "iconsax-react";

type IProps = {
  item: CardGraficProps;
  select?: boolean;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardCommonGraficMVP: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ComponentCommonCard
      onClick={() =>
        navigate(
          !!props?.preSlug
            ? `${props?.preSlug}${props?.item?.slug}`
            : props?.item?.slug,
          { preventScrollReset: false }
        )
      }
      maw={props?.select ? 460 : 396}
      h={props?.select ? 520 : 448}
      p={props?.select ? 27.8 : 22}
      select={props?.select}
      withBorder
    >
      <ImageCommonCard
        image={props?.item.image}
        title={props?.item.title}
        height={props?.select ? 182 : 157}
        radius={12}
        select={props?.select}
        badgeName={props?.item.badgetext}
      />

      <HeaderCommonCardMVP
        item={props.item}
        select={props?.select}
        mt={props?.select ? 25 : 20}
        mb={props?.select ? 25 : 20}
      />

      {/* <Flex direction={"column"} gap={"10px"}>
        <Text fw={500} size={12} lh={"16px"} color="#6B6B6B">
          corretoras
        </Text>
        <Flex gap="10px" justify="center">
          {props?.item.brokers.map((broker, index: number) => {
            return (
              <Avatar
                variant="outline"
                size={props?.select ? 55.8 : 48}
                color="basic.2"
                radius={8}
                src={broker?.icon.Location}
                alt={broker?.name}
                style={{ transition: "all 0.5s linear" }}
                styles={{
                  root: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D7D7D7",
                  },
                  image: {
                    height: props?.select ? "45.8px" : "38px",
                    width: props?.select ? "45.8px" : "38px",
                  },
                }}
              >
                <Profile />
              </Avatar>
            );
          })}
        </Flex>
      </Flex> */}
      <Flex
        gap={props?.select ? "19.8px" : "17px"}
        h={props?.select ? "72.6px" : "63px"}
        style={{
          transition: "all 0.5s linear",
        }}
      >
        <Flex justify="center" direction="column" w="144px">
          <Text
            fw={700}
            size={props?.select ? 18.59 : 16}
            lh={props?.select ? "28.8px" : "24.8px"}
            style={{
              transition: "all 0.5s linear",
            }}
            color="basic.4"
          >
            {props?.item.return}
          </Text>
          <Title
            ff="Barlow"
            fw={500}
            size={props?.select ? 16.26 : 14}
            lh={props?.select ? "25.2px" : "21.7px"}
            style={{
              transition: "all 0.5s linear",
            }}
            color="basic.3"
          >
            Retorno no período
          </Title>
        </Flex>
        <Flex justify="center" direction="column" w="144px">
          <Text
            fw={700}
            size={props?.select ? 18.59 : 16}
            lh={props?.select ? "28.8px" : "24.8px"}
            style={{
              transition: "all 0.5s linear",
            }}
            color="basic.4"
          >
            {props?.item.market}
          </Text>
          <Title
            ff="Barlow"
            fw={500}
            size={props?.select ? 16.26 : 14}
            lh={props?.select ? "25.2px" : "21.7px"}
            style={{
              transition: "opacity 0.5s linear",
            }}
            color="basic.3"
          >
            Mercado
          </Title>
        </Flex>
      </Flex>

      <Divider
        my={props?.select ? 25 : 20}
        style={{
          transition: "all 0.5s linear",
        }}
      />

      <FooterCard
        price={props?.item.plan[0].price}
        points={props?.item.points}
        select={props?.select}
      />
    </ComponentCommonCard>
  );
};

export default CardCommonGraficMVP;

import { Badge, BadgeProps } from "@mantine/core";
import React, { useEffect, useState } from "react";
interface IProps extends BadgeProps {
  badgeName?: string;
  badgeColor?:
    | string
    | "green"
    | "orange"
    | "violet"
    | "red"
    | "blue"
    | "pink"
    | "purple"
    | "babyBlue";
  select?: boolean;
}

const CategorieBadge: React.FC<IProps> = ({
  badgeColor,
  select,
  badgeName,
  ...props
}) => {
  const [content, setContent] = useState<string | any>({});

  useEffect(() => {
    switch (badgeColor) {
      case "green":
        return setContent({
          backgroundColor: "#E9F8EB",
          colorText: "#23BD32",
        });
      case "orange":
        return setContent({
          backgroundColor: "#FFEEDE",
          colorText: "#FB6815",
        });
      case "violet":
        return setContent({
          backgroundColor: "#EAE8F1",
          colorText: "#2C1B73",
        });
      case "red":
        return setContent({
          backgroundColor: "#FCE8EB",
          colorText: "#E3183B",
        });
      case "blue":
        return setContent({
          backgroundColor: "#ECF3FE",
          colorText: "#1B4FDF",
        });
      case "pink":
        return setContent({
          backgroundColor: "#FDEDFD",
          colorText: "#EB49EE",
        });
      case "purple":
        return setContent({
          backgroundColor: "#F2EAFC",
          colorText: "#8131E6",
        });
      case "babyBlue":
        return setContent({
          backgroundColor: "#EAF5FC",
          colorText: "#319CE6",
        });
    }
  }, [badgeColor]);

  return (
    <Badge
      {...props}
      pos={"absolute"}
      right="24px"
      bottom="0"
      radius={0}
      style={{
        height: select ? "31.3px" : "27px",
        padding: "4px 8px",
        fontWeight: 500,
        fontSize: select ? "13.9px" : "12px",
        lineHeight: select ? "21.6px" : "18.6px",
        color: content.colorText,
        backgroundColor: content.backgroundColor,
        borderRadius: "8px 8px 0 0",
        transition: "all 0.5s ease-in-out",
      }}
    >
      {badgeName}
    </Badge>
  );
};

export default CategorieBadge;

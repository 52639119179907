import { Box, Divider, Flex, Text } from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import {
  ComponentHighlightCard,
  ImageHighlightCard,
  FooterCard,
  HeaderHighlightCard,
} from "../../ComponentsCards";
import { IProductItem } from "../../../../interfaces/IListProducts";
import ModalRegister from "../../../Modals/ModalRegister";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  item: IProductItem;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
}

const CardHighlightAlgorithm: React.FC<IProps> = (props) => {
  const [modalRegister, setModalRegister] = useState<boolean>(false);
  const navigate = useNavigate();

  const { hovered, ref } = useHover();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <>
      <ModalRegister
        item={props.item}
        open={modalRegister}
        setClose={() => setModalRegister(!modalRegister)}
      />
      <Box ref={ref} sx={{ width: "100%", maxWidth: "539px" }}>
        <ComponentHighlightCard
          onClick={() =>
            props.slug
              ? navigate(
                  !!props?.preSlug
                    ? `${props?.preSlug}${props?.item?.product?.title}`
                    : props?.item?.product?.title
                )
              : setModalRegister(!modalRegister)
          }
          maw={{ base: 539, 991: 539 }}
          miw={{ base: 313, 991: 396 }}
        >
          <ImageHighlightCard
            image={props?.item?.product?.photo?.Location}
            title={props?.item?.product?.title}
            badgetext={props?.item?.product?.subcategory}
            radius={0}
            height={isLargerThan991 ? (hovered ? 224 : 406) : 215}
          />
          <Flex
            w="100%"
            direction="column"
            h={isLargerThan991 ? 300 : "fit-content"}
            p={{ base: 16, 991: 32 }}
            bg="white"
            pos={isLargerThan991 ? "absolute" : "unset"}
          >
            <HeaderHighlightCard
              item={props.item}
              mb={isLargerThan991 ? (hovered ? 12 : 32) : 12}
              style={{
                transition: "0.5s",
              }}
            />

            <Text
              lineClamp={3}
              h={isLargerThan991 ? 66 : "unset"}
              fw={400}
              fs="14px"
              lh={"21.7px"}
              color="basic.4"
              ff="Barlow"
            >
              {props?.item?.product?.short_description}
            </Text>

            <Divider my={24} />

            <FooterCard price={props?.item?.plan?.price} />
          </Flex>
        </ComponentHighlightCard>
      </Box>
    </>
  );
};

export default CardHighlightAlgorithm;

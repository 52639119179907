import { Badge } from "@mantine/core";

type IProps = {
  badgetext: string;
};

const CategorieBadge: React.FC<IProps> = (props) => {
  return (
    <Badge
      pos={"absolute"}
      right="0"
      top="32px"
      radius={0}
      style={{
        width: "fit-content",
        height: "42px",
        padding: "10px",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1B4FDF",
        backgroundColor: "white",
        borderRadius: "12px 0 0 12px",
      }}
    >
      {props.badgetext}
    </Badge>
  );
};

export default CategorieBadge;

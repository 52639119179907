import React from "react";
import {
  Flex,
  Text,
  SimpleGrid,
  Input,
  InputBase,
  Checkbox,
  Anchor,
  Button,
  TextInput,
  Center,
} from "@mantine/core";
import {
  InputWrapper,
  PasswordInput,
  PasswordRequirements,
} from "../../../components";
import { IMaskInput } from "react-imask";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiCheck } from "react-icons/hi";
import { Validation } from "./schema-validation";
import { IUserMVP, MOCK_USER_MVP, useAuth } from "../../../context/auth";

const SignUp: React.FC = () => {
  const { loginMVP } = useAuth();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm<IUserMVP>({
    mode: "onSubmit",
    resolver: yupResolver(Validation),
    defaultValues: MOCK_USER_MVP,
  });

  const acceptTerm = watch("term_of_contract");
  const handleAcceptTerm = () => {
    setValue("term_of_contract", !acceptTerm);
  };

  const onSubmit = (data: IUserMVP) => {
    console.log(data);
    loginMVP();
  };

  return (
    <Center w="100%" h="100%">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        style={{ width: "100%", height: "100%" }}
      >
        <Flex
          h="100%"
          w="100%"
          p={{ base: "50px", 1600: "77px" }}
          direction="column"
          justify="center"
          gap="8px"
        >
          <Flex
            w="100%"
            direction="column"
            gap={24}
            bg="basic.0"
            p={"24px 48px"}
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
            }}
          >
            <Text fw={600} size={24} color="#2f2f2f">
              Cadastrar conta
            </Text>
            <SimpleGrid
              cols={2}
              breakpoints={[{ maxWidth: "97rem", cols: 1, spacing: "md" }]}
              spacing="32px"
              verticalSpacing="24px"
            >
              <Controller
                name="username"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    label="Nome"
                    withAsterisk
                    placeholder="Digite seu nome"
                    error={errors?.username?.message}
                  />
                )}
              />

              <Controller
                name="document"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper
                    label="CPF"
                    withAsterisk
                    error={errors?.document?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    <Input<any>
                      value={value}
                      component={IMaskInput}
                      mask="000.000.000-00"
                      placeholder="000.000.000-00"
                      error={errors?.document?.message}
                    />
                  </InputWrapper>
                )}
              />
            </SimpleGrid>
          </Flex>
          <Flex
            direction="column"
            gap="24px"
            bg="basic.0"
            p={"24px 48px"}
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
            }}
          >
            <SimpleGrid
              cols={2}
              breakpoints={[{ maxWidth: "97rem", cols: 1, spacing: "md" }]}
              spacing="32px"
              verticalSpacing="24px"
            >
              <Controller
                name="email"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper label="E-mail" withAsterisk>
                    <InputBase
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Digite seu email"
                      error={errors.email?.message}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper
                    label="Telefone"
                    withAsterisk
                    error={errors.phone?.message}
                  >
                    <Input<any>
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      component={IMaskInput}
                      mask="(00) 00000-0000"
                      placeholder="(00) 00000-0000"
                      error={errors.phone?.message}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <PasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    label="Senha"
                    placeholder="Digite sua senha"
                    error={errors.password?.message}
                  />
                )}
              />
              <Controller
                name="secondPassword"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <PasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    label="Confirmar senha"
                    placeholder="Confirme sua senha"
                    error={errors.secondPassword?.message}
                  />
                )}
              />
            </SimpleGrid>
            <PasswordRequirements
              passwordOne={watch("password")}
              passwordTwo={watch("secondPassword")}
            />
            <Checkbox
              color="primary.1"
              size="md"
              icon={HiCheck}
              onChange={handleAcceptTerm}
              checked={acceptTerm}
              label={
                <>
                  Li e aceito todos os
                  <Anchor href="https://cria.digital" target="_blank">
                    termos de uso
                  </Anchor>
                </>
              }
            />
            <Button
              type="submit"
              variant="primary"
              fullWidth
              mt={26}
              disabled={!acceptTerm}
            >
              Cadastra conta
            </Button>
          </Flex>
        </Flex>
      </form>
    </Center>
  );
};

export default SignUp;

import { Button, Center, Flex, Modal, Text } from "@mantine/core";
import React from "react";
import { useFormContext } from "react-hook-form";

type IProps = {
  setCancelRegister: () => void;
  title: string;
  isLoading: boolean;
};

const HeaderModal = ({ setCancelRegister, title, isLoading }: IProps) => {
  return (
    <Modal.Header
      p="0"
      style={{
        gap: "24px",
        alignItems: "center",
        justifyContent: "start",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
        background: "white",
      }}
    >
      <Center w="100%">
        <Flex
          justify="space-between"
          align={"center"}
          maw={1920}
          p={{ base: "28px 120px", 991: "28px 120px" }}
          w="100%"
        >
          <Text fw={600} c="basic.5" fz={24} lh="32px">
            {title}
          </Text>
          <Flex gap="24px" maw="424px" w="100%">
            <Button
              fullWidth
              maw={{ base: 200 }}
              variant="dangerOutline"
              onClick={() => setCancelRegister()}
            >
              Cancelar
            </Button>

            <Button
              fullWidth
              maw={{ base: 200 }}
              variant="primary"
              type="submit"
              loading={isLoading}
            >
              Finalizar
            </Button>
          </Flex>
        </Flex>
      </Center>
    </Modal.Header>
  );
};

export default HeaderModal;

/* export function verifyIfStringHaveSpace(string: string) {
    return string.indexOf(" ") >= 0 && string[string.indexOf(" ") + 1];
  }; */

export const nameInitials = (name?: string) => {
  if (!!name && name.length > 0) {
    let n1,
      n2 = "";
    let nome_completo = name.split(" ");
    n1 = nome_completo[0][0];
    let regex = /\s/;
    if (
      regex.test(name) &&
      nome_completo[nome_completo.length - 1].length > 0
    ) {
      n2 = nome_completo[nome_completo.length - 1][0];
    }
    return `${n1}${n2}`.toLocaleUpperCase();
  } else {
    return "";
  }
};

import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();
  const from = location.state?.from;

  let auth = useAuth();
  if (auth.isLoading) {
    return <p>carregando...</p>;
  }

  if (!!auth.signed && location.pathname.includes("/autenticacao")) {
    return <Navigate to="/marketplace" state={{ from: from }} replace />;
  }

  return children;
};

export default PrivateRoute;

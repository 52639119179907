import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Flex, PinInput, Text } from "@mantine/core";
import { Modal } from "../../../../components";
import { IFormPinCode } from "./type";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationPin } from "./schema-validation";

interface IPropsPinCode {
  nextStep: () => void;
  type?: string;
  alreadyPassword: boolean;
}

const PinCode: React.FC<IPropsPinCode> = ({
  nextStep,
  type,
  alreadyPassword,
}) => {
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<IFormPinCode>({
    mode: "onBlur",
    resolver: yupResolver(ValidationPin),
  });

  const onSubmit = (data: IFormPinCode) => {
    setModalSuccess(!modalSuccess);
  };

  return (
    <>
      <Modal
        type="success"
        titleSeccondButton="Alterar senha"
        description="Código validado com sucesso!"
        open={modalSuccess}
        setClose={() => setModalSuccess(!modalSuccess)}
        firstClick={() => {}}
        secondClick={() => {
          nextStep();
          setModalSuccess(!modalSuccess);
        }}
      />
      {alreadyPassword ? (
        <>
          <Text fw={600} size={16} lh={"28px"} mb={6} ta="center">
            {`${type === "phone" ? "Sms" : "E-mail"} enviado com sucesso!`}
          </Text>
          <Text size={16} fw={400} mb={24} maw={602} ta="center">
            {`Confira ${
              type === "phone" ? "suas mensagens" : "sua caixa postal"
            } e digite o código recebido no campo abaixo:`}
          </Text>
        </>
      ) : (
        <Text size={16} fw={400} mb={24} maw={602} ta="center">
          Digite o código recebido no campo abaixo
        </Text>
      )}
      <Controller
        control={control}
        name="pin"
        render={({ field: { onBlur, onChange } }) => (
          <PinInput
            onBlur={onBlur}
            onChange={onChange}
            length={6}
            type="number"
            placeholder="0"
          />
        )}
      />
      {alreadyPassword && (
        <Flex w="100%" justify="space-between" mt={24}>
          <Text>Não recebeu o código?</Text>
          <Button h="auto" variant="link">
            Reenviar o código
          </Button>
        </Flex>
      )}
      <Button
        onClick={handleSubmit(onSubmit)}
        variant="primary"
        fullWidth
        mt={26}
        disabled={!isDirty || !isValid}
      >
        Validar código
      </Button>
    </>
  );
};

export default PinCode;

import { Box, Text, Divider, Group, Flex, Button } from "@mantine/core";
import { useAuth } from "../../context/auth";

interface IProps {
  isLoading: boolean;
}

export const PaymentSummary = ({ isLoading }: IProps) => {
  const { interest } = useAuth();

  // Filtra os itens que possuem um plano selecionado e soma os valores
  const totalPrice = interest.reduce((acc, item) => {
    const selectedPlan = item.plan.find((p) => p.isSelected);
    return selectedPlan ? acc + selectedPlan.price : acc;
  }, 0);

  return (
    <Flex
      w="100%"
      maw={"396px"}
      gap="32px"
      p="24px"
      bg="basic.0"
      direction="column"
      style={{
        borderRadius: "12px",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Text
          sx={{
            color: "#2f2f2f",
            textAlign: "left",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "16px",
            lineHeight: "155%",
            fontWeight: 600,
          }}
        >
          Resumo
        </Text>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexShrink: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexShrink: 0,
            }}
          >
            <Group
              sx={{
                gap: "0px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexShrink: 0,
                width: "348px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flex: 1,
                }}
              >
                <Text
                  sx={{
                    color: "#2f2f2f",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "16px",
                    lineHeight: "155%",
                    fontWeight: 500,
                    width: "155px",
                  }}
                >
                  Compra de {interest.length} produto
                  {interest.length > 1 && "s"}
                </Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  flexShrink: 0,
                  width: "193px",
                }}
              >
                <Text
                  sx={{
                    color: "#2f2f2f",
                    textAlign: "right",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "16px",
                    lineHeight: "155%",
                    fontWeight: 500,
                  }}
                >
                  R${totalPrice.toFixed(2)}
                </Text>
              </Box>
            </Group>
          </Box>
          <Divider
            sx={{
              borderColor: "#d7d7d7",
              flexShrink: 0,
              width: "348px",
              height: "0px",
              position: "relative",
              marginTop: "-1px",
            }}
          />
        </Box>
      </Box>
      <Button fullWidth variant="primary" type="submit" loading={isLoading}>
        Finalizar pagamento
      </Button>
    </Flex>
  );
};

import { Center, Flex } from "@mantine/core";
import { Breadcrumb } from "../../../components";
import InfosDashboard from "./InfosDashboard";
import PermittedSystems from "./PermittedSystems";
import CardsDashboard from "./CardsDashboard";
import GraphicDashboard from "./GraphicWaveDashboard";
import {
  ArrowDown,
  DollarSquare,
  Graph,
  PercentageSquare,
  TrendUp,
} from "iconsax-react";
import InfoUserDashboard from "./InfoUserDashboard";
import { useState } from "react";
import ModalExpand from "./ModalExpand";
import InfoUserDashboardSectionTwo from "./InfoUserDashboardSectionTwo";
import TableDashboard from "./TablesDashboard";
import GraphicDonutsDahsboardLeft from "./GraphicDonutsDahsboardLeft";
import GraphicDonutsDahsboardRigth from "./GraphicDonutsDahsboardRigth";

const breacrumbs = [
  { title: "Todas as compras", href: "/minhas-compras" },
  { title: "Detalhes", href: "minhas-compras/detalhes" },
];

const DATA_CARDS_ONE = [
  {
    info: "137%",
    text: "Retorno no período",
    bgColor: "complementaryOpacity.0",
    icon: <DollarSquare size="26" color="#169C3C" />,
  },
  {
    info: "-",
    text: "Fator de lucro",
    bgColor: "complementaryOpacity.3",
    icon: <PercentageSquare size="26" color="#8131E6" />,
  },
  {
    info: "0%",
    text: "Perda máxima",
    bgColor: "complementaryOpacity.2",
    icon: <ArrowDown size="26" color="#E3183B" />,
  },
  {
    info: "76 / 100",
    text: "Posições / Ordens",
    bgColor: "complementaryOpacity.5",
    icon: <TrendUp size="26" color="#EB49EE" />,
  },
  {
    info: "100%",
    text: "Trades com lucro",
    bgColor: "primaryOpacity.1",
    icon: <Graph size="26" color="#3F87F4" />,
  },
];

const DATA_CARDS_TWO = [
  {
    info: "Rentabilidade do mês",
    // text: "Rentabilidade do mês",
    bgColor: "primaryOpacity.1",
    icon: <DollarSquare size="26" color="#3F87F4" />,
    incomes: 10.4,
    // textFooter: "Rentabilidade do mês",
  },
  {
    info: "Perda máxima no período",
    // text: "Perda máxima no período",
    bgColor: "complementaryOpacity.3",
    icon: <DollarSquare size="26" color="#8131E6" />,
    incomes: -2.18,
    // textFooter: "Perda máxima no período",
  },
  {
    info: "Rentabilidade no ano",
    // text: "Rentabilidade no ano",
    bgColor: "complementaryOpacity.4",
    icon: <DollarSquare size="26" color="#FB6815" />,
    incomes: 13.5,
    // textFooter: "Rentabilidade no ano",
  },
  {
    info: "Ganho máximo em um dia",
    // text: "Ganho máximo em um dia",
    bgColor: "complementaryOpacity.1",
    icon: <DollarSquare size="26" color="#2C1B73" />,
    incomes: 1.8,
    // textFooter: "Ganho máximo em um dia",
  },
];

const Dashboard: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Center bg="primary.0">
        <Flex
          direction="column"
          w="100%"
          maw={1680}
          mx={{ base: 24, 768: 60 }}
          pb={196}
        >
          <Breadcrumb navlinks={breacrumbs} />

          <InfosDashboard />

          <PermittedSystems />

          <CardsDashboard data={DATA_CARDS_ONE} />
        </Flex>
      </Center>
      <Center>
        <Flex
          mt={-148}
          direction="column"
          w="100%"
          maw={1680}
          mx={{ base: 24, 768: 60 }}
        >
          <GraphicDashboard />

          <CardsDashboard data={DATA_CARDS_TWO} />
        </Flex>
      </Center>

      <Center>
        <Flex
          mt={48}
          direction="column"
          w="100%"
          maw={1680}
          mx={{ base: 24, 768: 60 }}
          sx={{ filter: " drop-shadow(0px 25px 35px rgba(0, 0, 0, 0.03))" }}
        >
          <InfoUserDashboard
            openModal={openModal}
            setOpenModal={() => setOpenModal(!openModal)}
          />
          <ModalExpand
            openModal={openModal}
            setOpenModal={() => setOpenModal(!openModal)}
          />

          <InfoUserDashboardSectionTwo />

          <TableDashboard />
        </Flex>
      </Center>

      <Center>
        <Flex
          id="29644"
          mt={48}
          mb={45}
          w="100%"
          gap={{ base: 33 }}
          maw={1680}
          mx={{ base: 24, 768: 60 }}
        >
          <GraphicDonutsDahsboardLeft />

          <GraphicDonutsDahsboardRigth />
        </Flex>
      </Center>
    </>
  );
};

export default Dashboard;

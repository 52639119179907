import { Center, Flex, Text, useMantineTheme } from "@mantine/core";

import { MdAndroid } from "react-icons/md";
import { DiChrome, DiApple, DiWindows } from "react-icons/di";
import { RiSafariFill } from "react-icons/ri";
import { FaOpera } from "react-icons/fa";
import { BsBrowserEdge } from "react-icons/bs";
import { SiFirefoxbrowser } from "react-icons/si";
import { IoIosAppstore } from "react-icons/io";
import { DATA_DASHBOARD } from "../../../../constants/DATA_DASHBOARD";
import { Global, Mobile, Monitor } from "iconsax-react";
import { TagStatus } from "../../../../components";

const PermittedSystems: React.FC = () => {
  const data = DATA_DASHBOARD;
  const theme = useMantineTheme();
  return (
    <Flex w={"100%"} mt={{ base: 48 }} justify="space-between">
      <Flex gap={10} align="center" h="fit-content">
        <Monitor
          size={27}
          color={
            data?.desktop ? theme.colors.primary[1] : theme.colors.other[1]
          }
        />

        <Mobile
          variant="Outline"
          size={27}
          color={data?.mobile ? theme.colors.primary[1] : theme.colors.other[1]}
        />

        <Global
          color={data?.web ? theme.colors.primary[1] : theme.colors.other[1]}
        />
        <Text
          ml={{ base: 2 }}
          mr={{ base: 22 }}
          fw={500}
          fz={{ base: 12, 991: 13 }}
          lh={{ base: "24px", 991: "27px" }}
          color="basic.0"
        >
          {!!data?.desktop ? "Desktop" : null}
          {(!!data?.desktop && !!data?.mobile) ||
          (!!data?.desktop && !!data?.web)
            ? " + "
            : null}
          {!!data?.mobile ? "Mobile" : null}
          {!!data?.mobile && !!data?.web ? " + " : null}
          {!!data?.web ? "Web" : null}
        </Text>
        <Center>
          <DiWindows
            size={27}
            color={
              data?.windows ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <DiApple
            size={27}
            color={
              data?.apple ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <MdAndroid
            size={27}
            color={
              data?.android ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <IoIosAppstore
            size={27}
            color={
              data?.appleStore ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <DiChrome
            size={27}
            color={
              data?.chrome ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <RiSafariFill
            size={27}
            color={
              data?.safari ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <FaOpera
            size={27}
            color={
              data?.opera ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Center>
          <BsBrowserEdge
            size={27}
            color={data?.edge ? theme.colors.primary[1] : theme.colors.other[1]}
          />
        </Center>
        <Center>
          <SiFirefoxbrowser
            size={27}
            color={
              data?.firefox ? theme.colors.primary[1] : theme.colors.other[1]
            }
          />
        </Center>
        <Text
          ml={{ base: 2 }}
          mr={{ base: 22 }}
          fw={500}
          fz={{ base: 12, 991: 13 }}
          lh={{ base: "24px", 991: "27px" }}
          color="basic.0"
        >
          Sistemas permitidos
        </Text>
      </Flex>
      <TagStatus status="orange" border title="versão demo" />
    </Flex>
  );
};
export default PermittedSystems;

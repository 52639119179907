import { Checkbox, Flex } from "@mantine/core";
import { Search, Select } from "../../../../components";
import { useState } from "react";

const options = [
  { value: "Hoje", label: "Hoje" },
  { value: "Amanhã", label: "amanha" },
];

const InfoUserDashboardSectionTwo: React.FC = () => {
  const [checked, setChecked] = useState(false);
  return (
    <Flex
      justify="space-between"
      align="center"
      bg="basic.0"
      p="0 32px 12px"
      sx={{ borderRadius: "12px 12px 0 0" }}
    >
      <Checkbox
        checked={checked}
        onChange={(e) => setChecked(e.currentTarget.checked)}
        radius="lg"
        label="Mostrar somente registros copiados"
      />
      <Flex align="center" gap={24}>
        <Search />
        <Select placeholder="Escolha sua corretora" data={options} />
      </Flex>
    </Flex>
  );
};
export default InfoUserDashboardSectionTwo;

import { Flex, Text, Title, SimpleGrid } from "@mantine/core";
import { Cup, Note, People, Rank } from "iconsax-react";
import { convertToDateAndHour } from "../../../../utils/date";
import { useMediaQuery } from "@mantine/hooks";

interface IProps {
  initialDate: string;
  finalDate: string;
  awarded: string;
  participant: number;
  participantTotal: number;
  contract: number;
  award: string;
  price: number;
}

type InfosProps = {
  children: React.ReactNode;
  title: string;
  result: string;
};

const Infos = (props: InfosProps) => {
  return (
    <Flex gap="4px" justify="space-between" direction="column" miw="84px">
      <Text
        fw={500}
        size={13}
        lh="20.15px"
        color="basic.3"
        ff="Barlow"
        lineClamp={1}
      >
        {props.title}
      </Text>
      <Flex gap="4px" align="center">
        {props.children}
        <Title
          fw={500}
          size={14}
          lh="21.7px"
          color="basic.4"
          style={{ wordBreak: "keep-all" }}
        >
          {props.result}
        </Title>
      </Flex>
    </Flex>
  );
};

const ContentCampCard: React.FC<IProps> = (props) => {
  const isLargerThan500 = useMediaQuery("(min-width: 500px)");

  return (
    <Flex
      gap="12px"
      direction="column"
      sx={{
        div: {
          borderRadius: "unset !important",
        },
      }}
    >
      <Text color="basic.4" fw={500} size={14} lh="20px">
        {`${convertToDateAndHour(props.initialDate)} até ${convertToDateAndHour(
          props.finalDate
        )}`}
      </Text>
      <SimpleGrid cols={isLargerThan500 ? 4 : 2} verticalSpacing={20}>
        <Infos result={props.awarded} title="Premiados">
          <Rank size="24" color="#3F87F4" />
        </Infos>

        <Infos
          result={`${props.participant}/${props.participantTotal}`}
          title="Participantes"
        >
          <People size="24" color="#3F87F4" />
        </Infos>

        <Infos result={props.contract.toString()} title="Contratos">
          <Note size="24" color="#3F87F4" />
        </Infos>

        <Infos result={props.award} title="Prêmio">
          <Cup size="24" color="#3F87F4" />
        </Infos>
      </SimpleGrid>
    </Flex>
  );
};

export default ContentCampCard;

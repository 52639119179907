import { Flex, Text, useMantineTheme } from "@mantine/core";
import TableOrderAnalist from "./TableOrderAnalist";
import { TableCopy } from "./TableCopy";
import { useState } from "react";
import { Pagination } from "../../../../components";

interface IProps {
  activ?: boolean;
}

const TableDashboard: React.FC<IProps> = ({ activ }) => {
  const [activePage, setPage] = useState<number>(1);
  const theme = useMantineTheme();
  const qtd = 15;
  const total = 120221;
  return (
    <>
      <Flex id="29712" gap={16} w="100%">
        {!activ ? (
          <Flex direction="column" bg="primaryOpacity.1" w="160%">
            <Text
              bg="primaryOpacity.1"
              p="12px 16px"
              fz={{ base: 14 }}
              fw={{ base: 600 }}
              lh={{ base: "32px" }}
              color="primary.0"
            >
              Ordens do analista
            </Text>
            <TableOrderAnalist />
          </Flex>
        ) : null}
        <Flex direction="column" w="100%" bg="primaryOpacity.1">
          <Flex justify="space-between" bg="primaryOpacity.1">
            <Text
              p="12px 16px"
              fz={{ base: 14 }}
              fw={{ base: 600 }}
              lh={{ base: "32px" }}
              color="primary.0"
            >
              Informações copiadas
            </Text>
            <Text
              p="12px 16px"
              fz={{ base: 14 }}
              lh={{ base: "32px" }}
              color="basic.4"
            >
              8.0000 de 10.000
            </Text>
          </Flex>
          <TableCopy />
        </Flex>
      </Flex>
      <Flex
        bg={theme.colors.basic[0]}
        justify="space-between"
        align="center"
        py={{ base: 24 }}
        px={{ base: 32 }}
        sx={{
          isolation: "isolate",
          borderRadius: "0 0 12px 12px",
          border: "1.5px solid",
          borderTop: "none",
          borderColor: theme.colors.basic[2],
        }}
      >
        <Text>
          Mostrando
          <Text component="b">
            {" "}
            {!!qtd ? qtd : "0"} {!total ? "" : "de"}{" "}
            {total?.toLocaleString("pt-BR")}
          </Text>{" "}
          registros
        </Text>
        <Pagination
          total={5}
          value={activePage}
          onChange={setPage}
          siblings={0}
        />
      </Flex>
    </>
  );
};
export default TableDashboard;

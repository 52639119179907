import React from "react";
import { Badge as BadgeMantine, BadgeProps } from "@mantine/core";

interface IPropsTagPay extends BadgeProps {
  paysucces: boolean;
  border?: boolean;
  value?: string;
}

const TagPay: React.FC<IPropsTagPay> = ({
  value,
  paysucces,
  border,
  ...props
}) => {
  return (
    <BadgeMantine
      {...props}
      sx={{
        color: paysucces ? "#23BD32" : "#6B6B6B",
        background: paysucces ? "#E9F8EB" : "#F0F0F0",
        padding: "4px 8px",
        height: "30px",
        fontSize: "14px",
        border: "1px solid",
        borderColor: !!border
          ? paysucces
            ? "#23BD32"
            : "#6B6B6B"
          : "transparent",
      }}
      radius="sm"
    >
      {!!value ? value : paysucces ? "Pago" : "Pendente"}
    </BadgeMantine>
  );
};

export default TagPay;

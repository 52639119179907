import { Card, CardProps } from "@mantine/core";

interface IProps extends CardProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const ComponentMainCard: React.FC<IProps> = ({
  children,
  onClick,
  ...props
}) => {
  return (
    <Card
      {...props}
      onClick={onClick}
      miw={{ base: 313, 991: 335 }}
      h="100%"
      mah={"320px"}
      sx={(theme) => ({
        width: "100%",
        textDecoration: "none",
        cursor: "pointer",
        transition: "all 0.5s ease-in-out",
        borderRadius: "12px",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",

        button: {
          borderColor: theme.colors.basic[2],
          color: theme.colors.basic[4],
          background: theme.colors.basic[0],
          transition: "all 0.3s ease-in-out",
        },

        ":hover": {
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.1)",
          transition: "0.5s",

          button: {
            color: theme.colors.basic[0],
            borderColor: theme.colors.primary[1],
            background: theme.colors.primary[1],
          },
        },
      })}
    >
      {children}
    </Card>
  );
};

export default ComponentMainCard;

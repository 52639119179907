import { api } from "../api/api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IProductItem } from "../interfaces/IListProducts";

const getPlatforms = async (
  is_marketplace?: boolean,
  organization?: string,
  type_of_organization?: string
): Promise<IProductItem[]> => {
  let params = new URLSearchParams();

  if (organization !== undefined) params.append("organization", organization);

  if (type_of_organization !== undefined)
    params.append("type_of_organization", type_of_organization);

  params.append("is_marketplace", `${is_marketplace}`);

  const resp = await api.get<IProductItem[]>(
    "/list-products?category=Platforms",
    {
      params: params,
    }
  );

  return resp.data;
};

export const usePlatforms = (
  is_marketplace?: boolean,
  organization?: string,
  type_of_organization?: string
): UseQueryResult<IProductItem[]> => {
  return useQuery(
    ["Platforms", is_marketplace, organization, type_of_organization],
    () => getPlatforms(is_marketplace, organization, type_of_organization)
  );
};

import { IProductItem } from "../interfaces/IListProducts";

const DATA_CARD_ALGORITHMS: IProductItem[] = [
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
];

const DATA_CARD_ALGORITHMS_HIGHLIGHTS: IProductItem[] = [
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
];

const DATA_CARD_PLATFORM: IProductItem[] = [
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: true,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: true,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: true,
        chrome: false,
        edge: true,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: true,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: true,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 100,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: true,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: true,
        chrome: true,
        edge: true,
        firefox: true,
        ios: true,
        mac: true,
        opera: true,
        safari: true,
        windows: true,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: true,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: true,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: true,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: true,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: true,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: true,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: true,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: true,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: true,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "TraderEvolution",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      price: 0,
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      active: true,
      category: "AlgorithmsAndIndicators",
      dashboard_analyst: true,
      dashboard_client: true,
      desktop: false,
      detailed_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      is_real: true,
      is_demo: false,
      mobile: false,
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      platforms: {
        android: false,
        chrome: false,
        edge: false,
        firefox: false,
        ios: false,
        mac: false,
        opera: false,
        safari: false,
        windows: false,
      },

      short_description:
        "A média móvel de Hull faz uso da Média Ponderada e da raiz quadrada do período analisado.",
      subcategory: "Reversão",
      term_of_contract: "http://teste",
      title: "Hull Moving Average",
      web: false,
      _id: "64356c9aa4c39253175353bb",
    },
  },
];

const DATA_CARD_BROKERS: IProductItem[] = [
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "XP investimentos",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      price: 150,
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      title: "pro desktop",

      active: true,
      category: "BrokersApproved",
      dashboard_analyst: true,
      dashboard_client: true,
      is_real: true,
      is_demo: false,
      desktop: true,
      mobile: true,
      web: true,
      platforms: {
        windows: true,
        mac: true,
        android: true,
        ios: true,
        chrome: false,
        safari: false,
        opera: false,
        edge: false,
        firefox: false,
      },
      short_description:
        "Desenvolvida com foco operacional. Ferramentas avançadas para Análise Técnica e Tape Reading.",
      detailed_description:
        "Desenvolvida com foco operacional. Ferramentas avançadas para Análise Técnica e Tape Reading.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },

      subcategory: "Corretoras",
      term_of_contract: "http://teste",

      _id: "64356c9aa4c39253175353bb",
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "Clear Corretora",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      price: 160,
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      title: "pro desktop",
      is_real: true,
      is_demo: false,
      desktop: true,
      mobile: true,
      web: true,
      short_description:
        "Tecnologia leve, moderna e com ferramentas avançadas para suas operações.",
      platforms: {
        windows: true,
        mac: true,
        android: true,
        ios: true,
        chrome: false,
        safari: false,
        opera: false,
        edge: false,
        firefox: false,
      },
      detailed_description:
        "Desenvolvida com foco operacional. Ferramentas avançadas para Análise Técnica e Tape Reading.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      subcategory: "Corretoras",
      term_of_contract: "http://www.pudim.com.br/",
      _id: "64356c9aa4c39253175353bb",
      active: true,
      category: "BrokersApproved",
      dashboard_analyst: true,
      dashboard_client: true,
    },
  },
  {
    analyst: "",

    instruments: [
      {
        instrument: {
          _id: "",
          plan_id: "",
          setup_type: "",
          amount_transaction: 0,
          instrument: "",
        },
      },
    ],

    organization: {
      _id: "TraderEvolution",
      name: "Clear Corretora",
      logo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
    },
    plan: {
      price: 0,
      active: true,
      amount_limits: 0,
      createdAt: "2024-04-11T14:10:55.637Z",
      detailed_plain_description: "string",
      id_product_vindi: 0,
      papers_entries: 0,
      payment_page: "string",
      updatedAt: "2024-04-11T14:10:55.637Z",
      _id: "64356d8fa4c39253175353c7",
    },

    product: {
      emphasis: true,
      modal: {
        name: true,
        phone: true,
        email: true,
        cpf: true,
        broker_list: ["XP"],
        commercial_conditions: false,
        habilit_account: false,
        term_of_contract: "/termos-de-contratação",
        account: false,
      },
      is_marketplace: true,
      email: {
        redirect_url: "https://google.com",
        message:
          "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
        email_to: "atendimento1@traderevolution.com.br",
        subject: "adicione um assunto aqui",
        email_from: "atendimento1@traderevolution.com.br",
        text: "",
        html: "<p>HTML que vem da API - cadastrada pelo cliente - product/email/html</p>",
      },
      title: "MOBILE + WEB",
      is_real: true,
      is_demo: true,
      desktop: false,
      mobile: true,
      web: true,
      short_description:
        "Web: Acesse de qualquer navegador.Mobile: Opere de onde e quando quiser. Envie ordens no gráfico.",
      platforms: {
        windows: false,
        mac: false,
        android: true,
        ios: true,
        chrome: true,
        safari: false,
        opera: true,
        edge: true,
        firefox: true,
      },
      detailed_description:
        "Desenvolvida com foco operacional. Ferramentas avançadas para Análise Técnica e Tape Reading.",
      icon: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/Empresas.png",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      photo: {
        _id: "6436b79f0273857ccf18f996",
        fieldname: "file",
        originalname: "Empresas.png",
        encoding: "7bit",
        mimetype: "image/png",
        ACL: "public-read",
        ServerSideEncryption: "AES256",
        ETag: '"c1525ab80c3b09f052a29c06d7089566"',
        Location:
          "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
        key: "img/Empresas.png",
        Key: "img/Empresas.png",
        Bucket: "trader-evolution-app",
        width: 48,
        height: 48,
        premultiplied: false,
        size: 1365,
        ContentType: "png",
        createdAt: "2024-04-12T13:52:31.506Z",
        updatedAt: "2024-04-12T13:52:31.506Z",
      },
      subcategory: "Corretoras",
      term_of_contract: "http://www.pudim.com.br/",
      _id: "64356c9aa4c39253175353bb",
      active: true,
      category: "BrokersApproved",
      dashboard_analyst: true,
      dashboard_client: true,
    },
  },
];
const DATA_MODAL_SIMULATOR: IProductItem[] = [
  {
    product: {
      _id: "64513b798c0ad956b2147512",
      short_description:
        "Simulador - Teste suas estratégias em um ambiente simulado com dados em tempo Real.",
      detailed_description: " ",
      photo: undefined,
      icon: undefined,
      dashboard_analyst: true,
      dashboard_client: true,
      active: true,
      category: "Platforms",
      subcategory: "Simulador",
      is_marketplace: false,
      title: "SIMULADOR PRO DESKTOP",
      desktop: true,
      mobile: true,
      web: true,
      is_demo: true,
      platforms: {
        windows: true,
        mac: true,
        android: true,
        chrome: true,
        firefox: true,
        opera: true,
        safari: true,
        edge: true,
        ios: true,
      },
      emphasis: true,
      email: {
        redirect_url:
          "https://app.vindi.com.br/customer/pages/ad0e7c44-8164-45f2-aa4f-9517e726d9dd/subscriptions/new",
        message: " ",
        email_to: "atendimento@traderevolution.movidesk.com",
        subject: "PEDIDO CONCLUÍDO COM SUCESSO - CRIAÇÃO DE CONTA DEMO - ",
        email_from: "atendimento@traderevolution.com.br",
        text: " ",
        html: "<<br>PEDIDO FINALIZADO COM SUCESSO!<br><br>Seja bem-vindo à comunidade TraderEvolution! <br>Agora você faz parte de um time de mais de 1.000.000 de Trader's ao redor do Mundo!<br><br>Trabalhamos incansavelmente para lhe entregar a melhor experiência em plataformas profissionais do mercado.<br><br>Desejamos a você SUCESSO na sua jornada de aprendizado e, caso necessite de algum apoio, não hesite em nos contatar em nos nossos canais de atendimento e suporte.<br><br>As credenciais de acesso ao sistema serão enviadas por email em até 24horas! <br>*** Divergências nos dados informados poderão inviabilizar a criação da conta. <br><br> Dados fornecidos no processo de contratação: <br><br> [all-fields] <br>Atenciosamente,Equipe TraderEvolution",
      },
      term_of_contract: " ",
      is_real: true,
      modal: undefined,
    },
    plan: {
      _id: "6447d9d5c2d63956fa552874",
      detailed_plain_description: " ",
      price: 0,
      id_product_vindi: 0,
      payment_page: "string",
      papers_entries: 0,
      amount_limits: 0,
      active: true,
      createdAt: "2024-04-24T17:41:46.110Z",
      updatedAt: "2024-04-24T17:41:46.110Z",
    },
    instruments: [],
    organization: {
      _id: "TRADEREVOLUTION",
      name: "TRADEREVOLUTION",
      logo: {
        _id: "6446e75fc2d63956fa551bec",
        fieldname: "",
        originalname: "",
        encoding: "",
        mimetype: "",
        ServerSideEncryption: "",
        ETag: "",
        width: 0,
        height: 0,
        premultiplied: false,
        size: 0,
        ContentType: "",
        Bucket: "trader-evolution-app",
        ACL: "public-read",
        key: "img/2024-04-24T20:32:30.996Z_tebr-logo-grande.png",
        Key: "img/2024-04-24T20:32:30.996Z_tebr-logo-grande.png",
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/2024-04-24T20:32:30.996Z_tebr-logo-grande.png",
        createdAt: "2024-04-24T20:32:31.162Z",
        updatedAt: "2024-04-24T20:32:31.162Z",
      },
      icon: {
        _id: "6446e479c2d63956fa551b72",
        fieldname: "",
        originalname: "",
        encoding: "",
        mimetype: "",
        ServerSideEncryption: "",
        ETag: "",
        width: 0,
        height: 0,
        premultiplied: false,
        size: 0,
        ContentType: "",
        Bucket: "trader-evolution-app",
        ACL: "public-read",
        key: "img/2024-04-24T20:20:09.474Z_tebr-logo-pequeno.png",
        Key: "img/2024-04-24T20:20:09.474Z_tebr-logo-pequeno.png",
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/2024-04-24T20:20:09.474Z_tebr-logo-pequeno.png",
        createdAt: "2024-04-24T20:20:09.589Z",
        updatedAt: "2024-04-24T20:20:09.589Z",
      },
    },
    analyst: "",
  },
  {
    product: {
      modal: undefined,
      is_real: false,
      _id: "6447d9bec2d63956fa552870",
      short_description:
        "Nossos clieente podem utilizar o Simulador gratuitamente por 30 dias!",
      detailed_description: " ",
      photo: undefined,
      icon: undefined,
      term_of_contract: " ",
      dashboard_analyst: true,
      dashboard_client: true,
      active: true,
      category: "Platforms",
      subcategory: "",
      is_marketplace: false,
      title: "SIMULADOR PRO DESKTOP",
      desktop: true,
      mobile: true,
      web: true,
      is_demo: true,
      platforms: {
        windows: true,
        mac: true,
        android: true,
        chrome: true,
        firefox: true,
        opera: true,
        safari: true,
        edge: true,
        ios: true,
      },
      emphasis: true,
      email: {
        redirect_url: " ",
        message: " ",
        email_to: "atendimento@traderevolution.movidesk.com",
        subject: "PEDIDO CONCLUÍDO COM SUCESSO - CRIAÇÃO DE CONTA DEMO",
        email_from: "atendimento@traderevolution.movidesk.com",
        text: " ",
        html: "<br>PEDIDO FINALIZADO COM SUCESSO!<br><br>Seja bem-vindo à comunidade TraderEvolution!<br>Agora você faz parte de um time de mais de 1.000.000 de Trader's ao redor do Mundo!<br><br>Trabalhamos incansavelmente para lhe entregar a melhor experiência em plataformas profissionais do mercado.<br><br>Desejamos a você SUCESSO na sua jornada de aprendizado e, caso necessite de algum apoio, não hesite em nos contatar em nos nossos canais de atendimento e suporte.<br><br>As credenciais de acesso ao sistema serão enviadas por email em até 24horas! <br>*** Divergências nos dados informados poderão inviabilizar a criação da conta. <br><br> Dados fornecidos no processo de contratação: <br><br> [all-fields] <br>Atenciosamente,Equipe TraderEvolution",
      },
    },
    plan: {
      _id: "64513b8e8c0ad956b2147516",
      detailed_plain_description: " ",
      price: 19.9,
      id_product_vindi: 0,
      payment_page: "string",
      papers_entries: 0,
      amount_limits: 0,
      active: true,
      createdAt: "2024-05-02T15:20:14.659Z",
      updatedAt: "2024-05-02T15:20:14.659Z",
    },
    instruments: [],
    organization: {
      _id: "TRADEREVOLUTION",
      name: "TRADEREVOLUTION",
      logo: {
        _id: "6446e75fc2d63956fa551bec",
        fieldname: "",
        originalname: "",
        encoding: "",
        mimetype: "",
        ServerSideEncryption: "",
        ETag: "",
        width: 0,
        height: 0,
        premultiplied: false,
        size: 0,
        ContentType: "",
        Bucket: "trader-evolution-app",
        ACL: "public-read",
        key: "img/2024-04-24T20:32:30.996Z_tebr-logo-grande.png",
        Key: "img/2024-04-24T20:32:30.996Z_tebr-logo-grande.png",
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/2024-04-24T20:32:30.996Z_tebr-logo-grande.png",
        createdAt: "2024-04-24T20:32:31.162Z",
        updatedAt: "2024-04-24T20:32:31.162Z",
      },
      icon: {
        _id: "6446e479c2d63956fa551b72",
        fieldname: "",
        originalname: "",
        encoding: "",
        mimetype: "",
        ServerSideEncryption: "",
        ETag: "",
        width: 0,
        height: 0,
        premultiplied: false,
        size: 0,
        ContentType: "",
        Bucket: "trader-evolution-app",
        ACL: "public-read",
        key: "img/2024-04-24T20:20:09.474Z_tebr-logo-pequeno.png",
        Key: "img/2024-04-24T20:20:09.474Z_tebr-logo-pequeno.png",
        Location:
          "https://trader-evolution-app.s3.us-west-2.amazonaws.com/img/2024-04-24T20:20:09.474Z_tebr-logo-pequeno.png",
        createdAt: "2024-04-24T20:20:09.589Z",
        updatedAt: "2024-04-24T20:20:09.589Z",
      },
    },
    analyst: "",
  },
];
export {
  DATA_CARD_ALGORITHMS,
  DATA_CARD_ALGORITHMS_HIGHLIGHTS,
  DATA_CARD_PLATFORM,
  DATA_CARD_BROKERS,
  DATA_MODAL_SIMULATOR,
};

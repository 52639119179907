import { Breadcrumbs, Flex, NavLink, useMantineTheme } from "@mantine/core";
import { ArrowRight2 } from "iconsax-react";
import { Link } from "react-router-dom";

interface IProps {
  navlinks: any;
}

const Breadcrumb: React.FC<IProps> = ({ navlinks }) => {
  const theme = useMantineTheme();

  return (
    <Flex w="fit-content" gap="16px" align="center" sx={{ color: "white" }}>
      <Breadcrumbs
        separator={<ArrowRight2 size="24" color="white" variant="Outline" />}
        styles={{ separator: { marginRight: "12px", marginLeft: "12px" } }}
      >
        {navlinks.map((item: any, index: number) => (
          <NavLink
            key={index}
            label={item.title}
            component={Link}
            to={item.href}
            disabled={navlinks.length === index + 1}
            active={navlinks.length === index + 1}
            styles={{
              root: {
                width: "fit-content",
                height: "22px",
                whiteSpace: "nowrap",
                backgroundColor: "unset",
                padding: "0",
                color: theme.colors.other[0],
                transition: "0.5s",
                fontWeight: 400,
                textDecoration: "underline",

                span: {
                  fontSize: "14px",
                  lineHeight: "21.7px",

                  "@media (max-width: 1400px)": {
                    fontSize: "13px",
                    lineHeight: "20.20px",
                  },
                },

                "&:hover": {
                  backgroundColor: "unset",
                  color: theme.colors.other[0],
                  opacity: 0.95,
                  fontWeight: 600,
                  overflow: "hidden",
                },
                "&[data-active]": {
                  backgroundColor: "unset",
                  color: theme.colors.other[0],
                  transition: "0.5s",
                  fontWeight: 600,
                  opacity: "1",
                  textDecoration: "unset",

                  "&:hover": {
                    backgroundColor: "unset",
                    color: theme.colors.other[0],
                    cursor: "unset",
                  },
                },
              },
            }}
          />
        ))}
      </Breadcrumbs>
    </Flex>
  );
};

export default Breadcrumb;

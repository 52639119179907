import { Button, Flex } from "@mantine/core";
import { HeaderItem, TagActive } from "../../../../components";
import { SPECTRE_LOGO } from "../../../../assets";
import { ReceiveSquare } from "iconsax-react";

const tags = ["PETR4", "WING23"];

const InfosDashboard: React.FC = () => {
  return (
    <Flex w={"100%"} mt={{ base: 48 }}>
      <Flex gap={{ base: 24 }} align={"end"}>
        <HeaderItem
          avatar={SPECTRE_LOGO}
          name={"Spider"}
          title="Spectre"
          backgroundName="basic.1"
          colorWhite
          subtitle="CNPI 1234"
        />
        <TagActive radius="8px" colorWhite actives={tags} />
      </Flex>
      <Flex ml="auto" gap={{ base: 24 }}>
        <Button fullWidth w={{ base: 300 }} variant="primary">
          Detalhes da compra
        </Button>
        <Button
          fullWidth
          w={{ base: 300 }}
          variant="secondary"
          leftIcon={<ReceiveSquare size="20" />}
        >
          Fazer download
        </Button>
      </Flex>
    </Flex>
  );
};
export default InfosDashboard;

import { useCallback, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Input,
  InputBase,
  Modal as MantineModal,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { IUserMVP, MOCK_USER_MVP, useAuth } from "../../context/auth";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  InputWrapper,
  Modal,
  ModalLoading,
  Select,
  TagCapsules,
} from "../../components";
import HeaderModal from "../../components/HeaderModal";
import SectionsForm from "../../components/SectionForm";
import ProductPlans from "./ProductPlans";
import { useCity, useUF } from "../../hooks";
import { IState } from "../../interfaces/IState";
import { IMaskInput } from "react-imask";
import { PaymentSummary } from "./PaymentSummary";

const Purchase = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { returnPath, interest, setInterest } = useAuth();
  const [opened, { open, close }] = useDisclosure(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const methods = useForm<IUserMVP>({
    mode: "all",
    delayError: 500,
    defaultValues: MOCK_USER_MVP,
  });
  const {
    reset,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = methods;

  const { data: state } = useUF(opened);
  const watchUF = watch("adress.state");
  const { data: listCity } = useCity(watchUF);

  const ufsOptions = state?.map((uf: IState, index: number) => {
    return {
      key: `${index}${uf?.sigla}`,
      value: uf?.sigla,
      label: uf?.nome,
    };
  });

  const cityOptions = listCity?.map((uf: any, index: number) => {
    return {
      key: `${index}${uf?.sigla}`,
      label: uf?.nome,
      value: uf?.nome,
    };
  });

  function onSubmit(data: any) {
    setIsLoading(true);
    setTimeout(() => {
      setIsSuccess(true);
      setOpenModalConfirmation(true);
      setIsLoading(false);
    }, 2000);
    console.log(data);
  }

  const handleRedirect = useCallback(() => {
    open();
    const from = location.pathname;
    navigate("/minhas-compras", { state: { from } });
  }, [location.pathname, navigate, open]);

  const handleOnClose = () => {
    close();
    returnPath("/comprar-produtos");
    setIsLoading(false);
    setOpenModalConfirmation(false);
    reset();
  };

  const handleCloseConfirmation = () => {
    setOpenModalConfirmation(!openModalConfirmation);
  };
  const handleSucessConfirmationClose = () => {
    returnPath("/comprar-produtos");
    setIsLoading(false);
    setOpenModalConfirmation(!openModalConfirmation);
    close();
  };

  const hasDirtyFirst = true;
  const hasDirtySecond = true;

  const handleDelete = (index: number) => {
    setInterest((prev) => prev.filter((_, i) => i !== index));
    if (interest.length === 0) close();
  };

  return (
    <>
      {isLoading ? (
        <ModalLoading open={isLoading} />
      ) : (
        <Modal
          type={isSuccess ? "success" : "warning"}
          titleFirstButton={
            isSuccess ? "Acessar minhas compras" : "Sim, cancelar compra"
          }
          titleSeccondButton={isSuccess ? "Fechar" : "Não, continuar compra"}
          description={
            isSuccess
              ? "Compra aprovada com sucesso! Acesse as informações do produto na área “Minhas compras”."
              : "Deseja mesmo cancelar o cadastro deste campeonato?"
          }
          open={openModalConfirmation}
          setClose={handleCloseConfirmation}
          secondClick={
            isSuccess ? handleSucessConfirmationClose : handleCloseConfirmation
          }
          firstClick={isSuccess ? handleRedirect : handleOnClose}
        />
      )}

      <MantineModal
        yOffset={0}
        withCloseButton={false}
        opened={opened}
        onClose={handleOnClose}
        fullScreen
        transitionProps={{ transition: "slide-up", duration: 200 }}
        styles={{
          body: { padding: 0 },
          content: {
            background: "other.0",
          },
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <HeaderModal
              title="Pagamento"
              isLoading={isLoading}
              setCancelRegister={() =>
                setOpenModalConfirmation(!openModalConfirmation)
              }
            />
            <Center bg="other.0">
              <Flex
                justify="center"
                direction="column"
                maw={1920}
                p={{ base: "48px 120px", 991: "48px 120px" }}
                w="100%"
                gap="80px"
              >
                <SectionsForm title={"Dados pessoais"} number={"01"}>
                  <Flex direction="column" gap={24}>
                    <Text
                      fz={{ base: 24 }}
                      fw={{ base: 600 }}
                      lh={{ base: "37.2px" }}
                      c={"basic.5"}
                    >
                      Verificar Perfil
                    </Text>

                    <SimpleGrid
                      cols={2}
                      /*   breakpoints={[
              { maxWidth: "97rem", cols: 2, spacing: "md" },
              { maxWidth: "86rem", cols: 1, spacing: "md" },
            ]} */
                      spacing="32px"
                      verticalSpacing="24px"
                    >
                      <Controller
                        name="username"
                        control={control}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <TextInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            label="Nome"
                            withAsterisk
                            placeholder="Digite seu nome"
                            error={errors?.username?.message}
                          />
                        )}
                      />

                      <Controller
                        name="document"
                        control={control}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <InputWrapper
                            label="CPF"
                            withAsterisk
                            error={errors?.document?.message}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            <Input<any>
                              value={value}
                              component={IMaskInput}
                              mask="000.000.000-00"
                              placeholder="000.000.000-00"
                              error={errors?.document?.message}
                            />
                          </InputWrapper>
                        )}
                      />

                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <InputWrapper label="E-mail" withAsterisk>
                            <InputBase
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              placeholder="Digite seu email"
                              error={errors.email?.message}
                            />
                          </InputWrapper>
                        )}
                      />

                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <InputWrapper
                            label="Celular"
                            withAsterisk
                            error={errors.phone?.message}
                          >
                            <Input<any>
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              component={IMaskInput}
                              mask="(00) 00000-0000"
                              placeholder="(00) 00000-0000"
                              error={errors.phone?.message}
                            />
                          </InputWrapper>
                        )}
                      />
                    </SimpleGrid>

                    <Text fw={600} size={24} color="#2f2f2f">
                      Verificar Endereço
                    </Text>

                    <SimpleGrid
                      cols={2}
                      id="gridAdressOne"
                      spacing="32px"
                      verticalSpacing="24px"
                    >
                      <Flex gap={{ base: 32 }}>
                        <TextInput
                          maw={{ base: 150 }}
                          label="CEP"
                          withAsterisk
                          placeholder="CEP"
                        />
                        <TextInput
                          label="Endereço"
                          withAsterisk
                          placeholder="Digite seu logradouro"
                        />
                        <TextInput
                          maw={{ base: 90 }}
                          label="Número"
                          withAsterisk
                          placeholder="N°"
                        />
                      </Flex>
                      <Flex gap={{ base: 32 }}>
                        <Controller
                          name="adress.state"
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Select
                              searchable
                              nothingFound="Estado inválido"
                              value={value}
                              name={name}
                              onChange={onChange}
                              withAsterisk
                              label={"Estado"}
                              placeholder="Selecione um estado"
                              data={
                                ufsOptions !== undefined
                                  ? ufsOptions
                                  : [{ value: "", label: "" }]
                              }
                            />
                          )}
                        />

                        <Controller
                          name="adress.city"
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Select
                              searchable
                              nothingFound="Cidade inválida"
                              value={value}
                              name={name}
                              onChange={onChange}
                              withAsterisk
                              label={"Cidade"}
                              placeholder="Selecione uma cidade"
                              data={
                                cityOptions !== undefined
                                  ? cityOptions
                                  : [{ value: "", label: "" }]
                              }
                            />
                          )}
                        />
                      </Flex>
                    </SimpleGrid>

                    <SimpleGrid
                      cols={2}
                      id="gridAdressTwo"
                      spacing="32px"
                      verticalSpacing="24px"
                    >
                      <TextInput
                        label="Bairro"
                        withAsterisk
                        placeholder="Digite seu bairro"
                      />

                      <TextInput
                        label="Complemento"
                        placeholder="Digite o complemento"
                      />
                    </SimpleGrid>
                  </Flex>
                </SectionsForm>

                <SectionsForm title={"Produtos"} number={"02"}>
                  {hasDirtyFirst ? (
                    interest.map((item, index) => (
                      <Flex
                        key={index}
                        gap="32px"
                        w="100%"
                        justify="space-between"
                        direction={"column"}
                      >
                        {index !== 0 && <Divider variant="dashed" />}
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "100%",
                          })}
                        >
                          {/* Cabeçalho */}
                          <Group
                            sx={(theme) => ({
                              backgroundColor: theme.colors.gray[1],
                              borderRadius: "12px 12px 0px 0px",
                              padding: "8px 16px",
                              justifyContent: "space-between",
                              width: "100%",
                            })}
                          >
                            <Text weight={600} size="lg" color="dark">
                              {item.slug}
                            </Text>
                            <Button
                              variant="dangerTransparent"
                              onClick={() => handleDelete(index)}
                              w="fit-content"
                              disabled={interest.length === 1}
                            >
                              Remover
                            </Button>
                          </Group>

                          {/* Conteúdo do Produto */}
                          <Box
                            sx={{
                              padding: "16px 16px 0",
                              display: "flex",
                              flexDirection: "column",
                              gap: "32px",
                              width: "100%",
                            }}
                          >
                            <Flex justify={"space-between"} gap="md">
                              <Flex align="center" gap="md">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "16px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flex
                                    w="60px"
                                    h="60px"
                                    sx={{
                                      backgroundImage: `url(${item.image})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      borderRadius: "12px",
                                    }}
                                  />

                                  <Stack spacing={4}>
                                    <TagCapsules
                                      colorTag="orange"
                                      border={true}
                                    >
                                      COPYTRADING
                                    </TagCapsules>
                                    <Text weight={700} size="xl" color="dark">
                                      {item.title}
                                    </Text>
                                  </Stack>
                                </Box>

                                <Stack spacing={0}>
                                  <Text color="gray" size="sm">
                                    Vendido por:
                                  </Text>
                                  <Text color="dark" weight={600} size="sm">
                                    {item.name}
                                  </Text>
                                </Stack>
                                {/* Periodicidade */}
                                <Stack spacing={0}>
                                  <Text color="gray" size="sm">
                                    Periodicidade
                                  </Text>
                                  <Text color="dark" weight={600} size="sm">
                                    Pagamento recorrente (mensal)
                                  </Text>
                                </Stack>
                              </Flex>

                              {/* Método de Pagamento*/}
                              <Select
                                label="Forma de Pagamento"
                                miw="356px"
                                placeholder="Selecione forma de pagamento"
                                data={[
                                  {
                                    value: "Cartão de crédito",
                                    label: "Cartão de crédito",
                                  },
                                  {
                                    value: "PIX",
                                    label: "PIX",
                                    disabled: true,
                                  },
                                  {
                                    value: "Pontos",
                                    label: "Pontos",
                                    disabled: true,
                                  },
                                  {
                                    value: "Boleto",
                                    label: "Boleto",
                                    disabled: true,
                                  },
                                  {
                                    value: "Ted",
                                    label: "Ted",
                                    disabled: true,
                                  },
                                ]}
                              />
                            </Flex>

                            <ProductPlans product={item} />
                          </Box>
                        </Box>
                      </Flex>
                    ))
                  ) : (
                    <Center>Finalize a etapa anterior</Center>
                  )}
                </SectionsForm>

                <Flex gap="32px" w="100%">
                  <SectionsForm title={"Pagamento"} number={"03"}>
                    {hasDirtySecond ? (
                      <Flex direction={"column"} gap={24}>
                        <Text
                          fz={{ base: 24 }}
                          fw={{ base: 600 }}
                          lh={{ base: "37.2px" }}
                          c={"basic.5"}
                        >
                          Cartão de Crédito
                        </Text>

                        <SimpleGrid
                          cols={1}
                          spacing="32px"
                          verticalSpacing="24px"
                        >
                          <Select
                            data={["Visa", "Mastercard", "American Express"]}
                            label="Selecione a Bandeira"
                            withAsterisk
                            placeholder="Bandeira do Cartão"
                          />

                          <TextInput
                            label="Numero do cartão"
                            withAsterisk
                            placeholder="digite o numero do cartão"
                          />
                        </SimpleGrid>
                        <TextInput
                          label="Nome do cartão"
                          withAsterisk
                          placeholder="digite o Nome no cartão"
                        />
                        <SimpleGrid
                          cols={2}
                          spacing="32px"
                          verticalSpacing="24px"
                        >
                          <TextInput
                            label="Expiração"
                            withAsterisk
                            placeholder="MM/AA"
                          />
                          <TextInput
                            label="Codigo de segurança"
                            withAsterisk
                            placeholder="CVV"
                          />
                        </SimpleGrid>
                      </Flex>
                    ) : (
                      <Center>Finalize a etapa anterior</Center>
                    )}
                  </SectionsForm>

                  <PaymentSummary isLoading={isLoading} />
                </Flex>
              </Flex>
            </Center>
          </form>
        </FormProvider>
      </MantineModal>
    </>
  );
};
export default Purchase;

import { Box, Center, Image, ImageProps } from "@mantine/core";
import { LogoTraderEvolution } from "../../../../assets";
import CategorieBadge from "./CategorieBadge";

interface IProps extends ImageProps {
  title: string;
  image?: string;
  badgetext: string;
  brokers?: boolean;
}

const ImageHighlightCard: React.FC<IProps> = (props) => {
  return (
    <Box pos="relative" maw="539px" style={{ overflow: "hidden" }}>
      <Image
        {...props}
        src={props?.image}
        alt={props.title}
        withPlaceholder
        width={"100%"}
        bg="#000000"
        fit={props.brokers ? "contain" : "cover"}
        placeholder={
          <Center
            h="100%"
            bg="#000000"
            w="100%"
            sx={{
              img: { height: "67px" },
            }}
          >
            <img src={LogoTraderEvolution} alt={props.title} />
          </Center>
        }
      />
      {!!props.badgetext ? (
        <CategorieBadge badgetext={props.badgetext} />
      ) : null}
    </Box>
  );
};

export default ImageHighlightCard;

import {
  Avatar,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  Tooltip,
} from "@mantine/core";
import { nameInitials } from "../../../utils/nameInitials";
import { Edit } from "iconsax-react";
import { TagCapsules, TagStatus } from "../../../components";
import { useDisclosure } from "@mantine/hooks";
import ModalEditProfile from "./ModalEditProfile";
import {
  convertCep,
  convertCpf,
  convertPhone,
} from "../../../utils/convertDataUser";

const objetoFake = {
  active: true,
  name: "Lorenzo Fonseca",
  create: "14/02/2024 - 10:56:14",
  cpf: "12345678901",
  email: "lorenzofonseca@gmail.com",
  cell: "51999999999",
  type: "Inquieto",
  whatsapp: "51123456789",
  messenger: null,
  facebook: null,
  instagram: null,
  adress: {
    cep: "12345789",
    adress: "av loren ipsum, 123",
    neighborhood: "Bairro Loren",
    complement: "Loren Loren Loren Loren",
    city: "São Paulo",
    state: "São Paulo",
  },
};

const ProfileInformation: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const adress = [
    {
      name: "CEP",
      data: convertCep(objetoFake?.adress?.cep),
      widthFlex: 103,
    },
    {
      name: "Endereço",
      data: objetoFake?.adress?.adress,
      widthFlex: 208,
    },
    {
      name: "Bairro",
      data: objetoFake?.adress?.neighborhood,
      widthFlex: 142,
    },
    {
      name: "Complemento",
      data: objetoFake?.adress?.complement,
      widthFlex: 266,
    },
    {
      name: "Estado/Cidade",
      data: `${objetoFake?.adress?.state}/${objetoFake?.adress?.city}`,
    },
  ];

  const user = [
    {
      name: "CPF",
      data: convertCpf(objetoFake?.cpf),
    },
    {
      name: "E-mail",
      data: objetoFake?.email,
    },
    {
      name: "Celular",
      data: convertPhone(objetoFake?.cell),
    },
    {
      name: "Tipo de Perfil",
      data: <TagCapsules colorTag="babyBlue">{objetoFake?.type}</TagCapsules>,
    },
    {
      name: "Whatsapp",
      data: convertPhone(objetoFake?.whatsapp),
    },
    {
      name: "Messenger",
      data: objetoFake?.messenger,
    },
    {
      name: "Facebook",
      data: objetoFake?.facebook,
    },
    {
      name: "Instagram",
      data: objetoFake?.instagram,
    },
  ];

  return (
    <>
      <ModalEditProfile opened={opened} close={close} />
      <Flex
        py={33}
        direction="column"
        justify="center"
        align="start"
        w="100%"
        mb="auto"
        gap={7}
      >
        <Flex
          id="editDataProfile"
          direction="column"
          bg={"basic.0"}
          p={{ base: 48 }}
          w="100%"
          sx={{
            borderRadius: "12px",
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Flex
            id="firstFlexEdit"
            direction={{ base: "column-reverse", 1414: "row" }}
            gap={{ base: 24 }}
            align="center"
            w="100%"
          >
            <Flex gap={{ base: 24 }} mr="auto">
              <Avatar
                radius="md"
                size={80}
                src={
                  "dsa"
                  // "https://cdn-icons-png.flaticon.com/512/3135/3135768.png"
                }
                alt="avatar"
                color="red"
              >
                <Text
                  fw={300}
                  h={80}
                  miw={80}
                  bg="primary.1"
                  c="basic.0"
                  align="center"
                  pt={16}
                >
                  {nameInitials(objetoFake?.name)}
                </Text>
              </Avatar>
              <Flex direction="column">
                <Text
                  tt="uppercase"
                  fw={{ base: 700 }}
                  fz={{ base: 32 }}
                  lh={{ base: "125%" }}
                  c="basic.5"
                >
                  {objetoFake?.name}
                </Text>
                <Text
                  fw={{ base: 500 }}
                  lh={{ base: "155%" }}
                  fz={{ base: 16 }}
                  c="basic.4"
                >
                  (criado em {objetoFake?.create})
                </Text>
              </Flex>
              <TagStatus
                miw={65}
                title={objetoFake?.active ? "ATIVO" : "INATIVO"}
                border={true}
                status={objetoFake?.active ? "success" : "disapproved"}
              />
            </Flex>
            <Button
              onClick={open}
              fullWidth
              maw={{ base: 260 }}
              variant="primary"
              ml="auto"
              leftIcon={<Edit size="24" />}
            >
              Editar Perfil
            </Button>
          </Flex>

          <SimpleGrid
            cols={4}
            mt={{ base: 48 }}
            sx={{ gridTemplateColumns: "repeat(4,auto )", gap: "2rem" }}
          >
            {user.map((x: any) => (
              <Box key={x.name}>
                <Text fz={{ base: 14 }} lh={{ base: "155%" }} c="basic.5">
                  {x.name}
                </Text>

                <Tooltip
                  events={{ hover: true, focus: true, touch: true }}
                  withArrow
                  label={x?.data}
                  display={x?.name === "E-mail" ? "block" : "none"}
                >
                  <Text
                    truncate
                    mt={4}
                    fw={{ base: 600 }}
                    fz={{ base: 14 }}
                    lh={{ base: "155%" }}
                    c="basic.5"
                  >
                    {x.data === null ? "---" : x.data}
                  </Text>
                </Tooltip>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>

        <Flex
          mb={108}
          id="editDataProfile"
          direction="column"
          bg={"basic.0"}
          p={{ base: 48 }}
          w="100%"
          sx={{
            borderRadius: "12px",
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Text
            mb={{ base: 24 }}
            fw={{ base: 600 }}
            fz={{ base: 16 }}
            lh={{ base: "155%" }}
            c="basic.5"
          >
            Endereço
          </Text>
          <Flex gap={{ base: 32 }} wrap="wrap">
            {adress.map((x: any) => (
              <Box key={x.name} w={x?.widthFlex}>
                <Text fz={{ base: 14 }} lh={{ base: "155%" }} c="basic.5">
                  {x.name}
                </Text>
                <Text
                  mt={4}
                  fw={{ base: 600 }}
                  fz={{ base: 14 }}
                  lh={{ base: "155%" }}
                  c="basic.5"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {x.data === null ? "---" : x.data}
                </Text>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ProfileInformation;

import React from "react";
import { Box, Flex, Text } from "@mantine/core";
import { BsCheckCircle } from "react-icons/bs";
import { CloseCircle, InfoCircle } from "iconsax-react";

interface passwordIProps {
  passwordOne: string;
  passwordTwo?: string;
}

const PasswordRequirements: React.FC<passwordIProps> = ({
  passwordOne,
  passwordTwo,
}) => {
  const isNumeric = (text: string): boolean => /\d+/.test(text);
  const hasLowerCase = (text: string): boolean => /[a-z]/.test(text);
  const hasUpperCase = (text: string): boolean => /[A-Z]/.test(text);
  const isLong = (text: string): boolean => text.length >= 8;
  const charSpecial = (text: string): boolean => /[^a-zA-Z 0-9]+/g.test(text);

  return (
    <Box>
      <Text color="#6B6B6B" fs="14px" mb={14}>
        A senha deve possuir:
      </Text>
      {passwordOne ? (
        <Flex
          direction="row"
          gap={"10px 26px"}
          wrap="wrap"
          sx={{
            whiteSpace: "nowrap",
            fontSize: "14px",
          }}
        >
          <Flex direction="row" align="center" gap={6}>
            {hasUpperCase(passwordOne) ? (
              <BsCheckCircle size="20" color="green" />
            ) : (
              <CloseCircle size="20" color="#DF0000" />
            )}
            <Text color="#161616">Letra maiúscula</Text>
          </Flex>

          <Flex direction="row" align="center" gap={6}>
            {hasLowerCase(passwordOne) ? (
              <BsCheckCircle size="20" color="green" />
            ) : (
              <CloseCircle size="20" color="#DF0000" />
            )}
            <Text color="#161616">Letra minúscula</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            {isNumeric(passwordOne) ? (
              <BsCheckCircle size="20" color="green" />
            ) : (
              <CloseCircle size="20" color="#DF0000" />
            )}
            <Text color="#161616">Número</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            {charSpecial(passwordOne) ? (
              <BsCheckCircle size="20" color="green" />
            ) : (
              <CloseCircle size="20" color="#DF0000" />
            )}
            <Text color="#161616"> Caractere especial</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            {isLong(passwordOne) ? (
              <BsCheckCircle size="20" color="green" />
            ) : (
              <CloseCircle size="20" color="#DF0000" />
            )}
            <Text color="#161616">Mínimo 8 caracteres</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            {passwordOne === passwordTwo ? (
              <BsCheckCircle size="20" color="green" />
            ) : (
              <CloseCircle size="20" color="#DF0000" />
            )}
            <Text color="#161616">Senha iguais</Text>
          </Flex>
        </Flex>
      ) : (
        <Flex
          direction="row"
          gap={26}
          wrap="wrap"
          sx={{
            whiteSpace: "nowrap",
            fontSize: "14px",
          }}
        >
          <Flex direction="row" align="center" gap={6}>
            <InfoCircle size="20" color="#868686" />
            <Text color="#161616">Letra maiúscula</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            <InfoCircle size="20" color="#868686" />
            <Text color="#161616">Letra minúscula</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            <InfoCircle size="20" color="#868686" />
            <Text color="#161616">Número</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            <InfoCircle size="20" color="#868686" />
            <Text color="#161616"> Caractere especial</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            <InfoCircle size="20" color="#868686" />
            <Text color="#161616">Mínimo 8 caracteres</Text>
          </Flex>
          <Flex direction="row" align="center" gap={6}>
            <InfoCircle size="20" color="#868686" />
            <Text color="#161616">Senha iguais</Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default PasswordRequirements;

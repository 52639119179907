import moment from "moment";
import "moment/locale/pt-br";

export const convertToDate = (date?: string | number | Date) => {
  if (!date || date === null) return;
  return moment(date).utc(false).format("DD/MM/YYYY");
};

export const convertToDateYearAbreviation = (date?: string | number | Date) => {
  if (!date || date === null) return;
  return moment(date).utc(false).format("DD/MM/YY");
};

export function convertToDateAndHour(date?: string | number | Date) {
  if (!date || date === null) return;
  return moment(date).utc(false).format("DD/MM/YYYY - HH:mm");
}

export const dateDiffToTime = (init: number, end: number) => {
  let seconds = moment(end).diff(init, "s") % 60;
  let minutes = moment(end).diff(init, "m") % 60;
  let hours = moment(end).diff(init, "h") % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const timeToSeconds = (time: string) => {
  if (!time) return 0;

  const timeArr = time.split(":");
  const hour = Number(timeArr[0]);
  const minute = Number(timeArr[1]);
  const second = Number(timeArr[2]);

  return second + minute * 60 + hour * 60 * 60;
};

export const secondsToTime = (seconds: number) => {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
};

export function convertToHour(date: string | number | Date) {
  if (!date || date === null) return;
  return moment(date).utc(false).format("HH:mm");
}

export const convertDayWeek = (dates: string | number | Date) => {
  let daysWeek = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];
  const day = new Date(dates);

  return daysWeek[day.getDay()];
};

import { Table, TagCapsules } from "../../../../../components";
import { DATA_ORDER_ANALIST } from "./DATA_ORDER_ANALIST";

const rows = DATA_ORDER_ANALIST.map((element: any, index: number) => (
  <tr key={index}>
    <td width={130}>{element?.create}</td>
    <td>{element?.position}</td>
    <td>{element?.order}</td>
    <td>{element?.company}</td>
    <td>
      <TagCapsules
        mr={12}
        border
        colorTag={element?.activity === "COMPRA" ? "blue" : "green"}
      >
        {element?.activity}
      </TagCapsules>
    </td>
    <td>{element?.quantity}</td>
    <td>{element?.price}</td>
    <td>{element?.totalPrice}</td>
  </tr>
));

const colsTable = (
  <tr>
    <th>Criado em </th>
    <th>Posição</th>
    <th>Ordem</th>
    <th>Ativo</th>
    <th>Atividade</th>
    <th>Qntd</th>
    <th>Preço</th>
    <th>Preço Total</th>
  </tr>
);

const TableOrderAnalist = () => {
  return <Table cols={colsTable} rows={rows} />;
};
export default TableOrderAnalist;

import {
  Avatar,
  Flex,
  FlexProps,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { Profile } from "iconsax-react";
import TagStatus from "../../../Tags/TagStatus";
import { useMediaQuery } from "@mantine/hooks";
import { IProductItem } from "../../../../interfaces/IListProducts";

interface IProps extends FlexProps {
  select?: boolean;
  badge?: boolean;
  item?: IProductItem;
}

const HeaderCommonCard: React.FC<IProps> = ({
  select,
  badge,
  item,
  ...props
}) => {
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  const theme = useMantineTheme();
  return (
    <Flex
      {...props}
      gap="8px"
      w="100%"
      justify="space-between"
      align="center"
      h={{ base: 44, 991: 54 }}
    >
      <Flex gap={{ base: 8, 991: 16 }} align="center">
        <Avatar
          variant="outline"
          size={select ? 55.8 : 48}
          color="basic.2"
          radius={8}
          src={
            item?.organization?.icon?.Location
              ? item?.organization.icon.Location
              : null
          }
          alt={item?.product.title ? item?.product.title : "não existe imagem"}
          style={{ transition: "all 0.5s linear" }}
          styles={{
            root: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D7D7D7",
            },
            image: {
              height: select ? "45.8px" : "38px",
              width: select ? "45.8px" : "38px",
            },
          }}
        >
          <Profile color={theme.colors.basic[5]} />
        </Avatar>
        <Flex gap="2px" justify="space-between" direction="column" h="100%">
          <Text
            transform="uppercase"
            fw={500}
            size={select ? 13.9 : 12}
            lh={select ? "21.6px" : "19px"}
            color="basic.4"
            ff="Barlow"
            lineClamp={1}
            style={{
              wordBreak: "break-all",
              transition: "all 0.5s linear",
            }}
          >
            {!!item?.organization?.name
              ? item.organization.name
              : "nome do parceiro ausente na api"}
          </Text>
          <Title
            transform="uppercase"
            fw={700}
            size={select ? 18.6 : 16}
            lh={select ? "28.8px" : "24px"}
            color="basic.5"
            lineClamp={1}
            style={{
              wordBreak: "break-all",
              transition: "all 0.5s linear",
            }}
          >
            {!!item?.product?.title
              ? item.product.title
              : "nome do produto ausente na api"}
          </Title>
        </Flex>
      </Flex>
      {badge ? (
        <Flex gap="12px">
          {item?.product?.is_demo ? (
            <TagStatus
              style={{ border: "1px solid", borderRadius: "4px" }}
              status={"orange"}
              title={
                isLargerThan991 && !item?.product?.is_real
                  ? "versão demo"
                  : "demo"
              }
            />
          ) : null}
          {item?.product?.is_real ? (
            <TagStatus
              style={{ border: "1px solid", borderRadius: "4px" }}
              status={"success"}
              title={
                isLargerThan991 && !item?.product?.is_demo
                  ? "versão real"
                  : "real"
              }
            />
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default HeaderCommonCard;

import { Card, Center, Divider, Text } from "@mantine/core";
import {
  ComponentCommonCard,
  ImageCommonCard,
  HeaderCommonCard,
  FooterCard,
  ContentCampCard,
} from "../../ComponentsCards";
import CategorieBadge from "../../ComponentsCards/ImageCommonCard/CategorieBadge";
import { CardCampProps } from "../../ComponentsCards/typesCards";
import { useNavigate } from "react-router-dom";

type IProps = {
  item: CardCampProps;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardCamp: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ComponentCommonCard
      onClick={() =>
        navigate(
          !!props?.preSlug
            ? `${props?.preSlug}${props?.item?.slug}`
            : props?.item?.slug
        )
      }
      // maw={"540px"}
      p={24}
    >
      <Card.Section
        pos={"relative"}
        style={{
          marginInline: "-24px",
          marginTop: "-24px",
        }}
      >
        {!!props?.item.image ? (
          <ImageCommonCard
            image={props?.item.image}
            title={props?.item.title}
            height={145}
            w={540}
            full={true}
            badgeName={props?.item.badgeName}
            badgeColor={props?.item.badgeColor}
          />
        ) : (
          <Center h={145} bg="primary.0">
            <Text
              w={540}
              fw={700}
              size={16}
              align="center"
              transform="uppercase"
              color="white"
            >
              {props?.item?.title}
              <CategorieBadge
                badgeName={props?.item?.badgeName}
                badgeColor={props?.item.badgeColor}
              />
            </Text>
          </Center>
        )}
      </Card.Section>

      <HeaderCommonCard
        // item={props.item}
        mt={20}
        mb={20}
      />

      <ContentCampCard
        initialDate={props?.item.initialDate}
        finalDate={props?.item.finalDate}
        awarded={props?.item.awarded}
        participant={props?.item.participant}
        participantTotal={props?.item.participantTotal}
        contract={props?.item.contract}
        award={props?.item.award}
        price={props?.item.price}
      />

      <Card.Section style={{ marginInline: "-24px" }}>
        <Divider my={20} />
      </Card.Section>

      <FooterCard
        price={props?.item.price}
        activeInit={props?.item.activeInit}
        activeFinal={props?.item.activeFinal}
      />
    </ComponentCommonCard>
  );
};

export default CardCamp;

import { Center, Tooltip } from "@mantine/core";
import { Table } from "../../../../../components";
import { CloseCircle, InfoCircle, TickCircle } from "iconsax-react";
import { DATA_ORDER_ANALIST } from "../TableOrderAnalist/DATA_ORDER_ANALIST";

const rows = DATA_ORDER_ANALIST.map((element: any, index: number) => (
  <tr key={index}>
    <td width={130}>{!!element?.copy ? element?.copy : "---"}</td>
    <td>{!!element?.quantityCopy ? element?.quantityCopy : "---"}</td>
    <td>{!!element?.priceCopy ? element?.priceCopy : "---"}</td>
    <td
      style={{
        background: element?.price > element?.priceCopy ? "#FFEEDE" : "",
        color: element?.price > element?.priceCopy ? "#FB6815" : "",
      }}
    >
      {!!element?.totalPriceCopy ? element?.totalPriceCopy : "---"}
    </td>
    <td>
      <Center>
        <Tooltip
          label={
            element?.info === "yes"
              ? "Ordem copiada com sucesso"
              : element?.info === "no"
              ? "O status da EA está desligado"
              : "Volume permitido pelo plano foi excedido"
          }
          color="dark"
          withArrow
        >
          {element?.info === "yes" ? (
            <TickCircle size="24" color="#23bd32" variant="Bold" />
          ) : element?.info === "no" ? (
            <CloseCircle size="24" color="#f43333" variant="Bold" />
          ) : (
            <InfoCircle size="24" color="#faab36" variant="Bold" />
          )}
        </Tooltip>
      </Center>
    </td>
  </tr>
));

const colsTable = (
  <tr>
    <th>Copiado ás </th>
    <th>Qtnd</th>
    <th>Preço</th>
    <th>Preço Total</th>
    <th>
      <Center>
        <Tooltip label={"Status da ordem"} color="dark" withArrow>
          <InfoCircle size="24" color="#2f2f2f" variant="Bold" />
        </Tooltip>
      </Center>
    </th>
  </tr>
);

export const TableCopy: React.FC = () => {
  return <Table w="100%" cols={colsTable} rows={rows} />;
};

export const DATA_TABLE = [
  {
    date: "14/02/2024 10:56:14",
    moving: "COMPRA",
    typePoduct: "Copytrading",
    product: "Copy EVO",
    pay: "Carteira",
    value: 184.0,
    point: 148,
    status: null,
  },
  {
    date: "14/02/2024 10:56:14",
    moving: "SAQUE",
    typePoduct: null,
    product: null,
    pay: "Carteira",
    value: 200.0,
    point: null,
    status: "declined",
  },
  {
    date: "14/02/2024 10:56:14",
    moving: "SAQUE",
    typePoduct: null,
    product: null,
    pay: "Carteira",
    value: 700.0,
    point: null,
    status: "approved",
  },
  {
    date: "14/02/2024 10:56:14",
    moving: "DEPÓSITO",
    typePoduct: "Campeonato",
    product: "Trader Mensal",
    pay: "Carteira",
    value: null,
    point: 1000,
    status: null,
  },
  {
    date: "14/02/2024 10:56:14",
    moving: "COMPRA",
    typePoduct: "Copytrading",
    product: "Copy EVO",
    pay: "Carteira",
    value: 184.0,
    point: null,
    status: null,
  },
  {
    date: "14/02/2024 10:56:14",
    moving: "SAQUE",
    typePoduct: null,
    product: null,
    pay: "Carteira",
    value: 200.0,
    point: null,
    status: "waiting",
  },
];

export const DATA_ORDER_ANALIST = [
  {
    create: "30/07/2024 10:56:14",
    position: "999999",
    order: "00000090",
    company: "PETR4",
    activity: "COMPRA",
    quantity: "1.000",
    price: 26.29,
    totalPrice: 26.29,
    copy: "30/07/2024 10:56:14",
    quantityCopy: "1.000",
    priceCopy: 26.29,
    totalPriceCopy: 26.29,
    info: "yes",
  },
  {
    create: "30/07/2024 10:56:14",
    position: "999999",
    order: "00000090",
    company: "PETR4",
    activity: "VENDA",
    quantity: "1.000",
    price: 26.29,
    totalPrice: 26.29,
    copy: "30/07/2024 10:56:14",
    quantityCopy: "1.000",
    priceCopy: 6.28,
    totalPriceCopy: 26.29,
    info: "no",
  },
  {
    create: "30/07/2024 10:56:14",
    position: "999999",
    order: "00000090",
    company: "PETR4",
    activity: "VENDA",
    quantity: "1.000",
    price: 26.29,
    totalPrice: 26.29,
    copy: "30/07/2024 10:56:14",
    quantityCopy: "1.000",
    priceCopy: 26.29,
    totalPriceCopy: 26.29,
    info: "waiting",
  },
  {
    create: "30/07/2024 10:56:14",
    position: "999999",
    order: "00000090",
    company: "PETR4",
    activity: "VENDA",
    quantity: "1.000",
    price: 26.29,
    totalPrice: 26.29,
    quantityCopy: null,
    priceCopy: null,
    totalPriceCopy: null,
    info: "no",
  },
];

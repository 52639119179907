import { Center, Flex, Table, Text, useMantineTheme } from "@mantine/core";
import React from "react";

const elements = [
  { plan: 1, contract: 5, value: 184, points: 370 },
  { plan: 2, contract: 10, value: 350, points: 700 },
  { plan: 3, contract: 15, value: 480, points: 960 },
  { plan: 4, contract: 30, value: 840, points: 1680 },
];

const TableProduct: React.FC = () => {
  const theme = useMantineTheme();

  const rows = elements.map((element) => (
    <tr key={element.plan}>
      <td>
        <Center w={35} fw={600} color={theme.colors.basic[5]}>
          {element.plan}
        </Center>
      </td>
      <td>
        <Text color={theme.colors.basic[5]}>Até {element.contract}</Text>
      </td>
      <td>
        <Text fw={600} color={theme.colors.primary[1]}>
          R${element.value}/{element.points} pts
        </Text>
      </td>
    </tr>
  ));

  return (
    <Flex
      sx={{
        borderRadius: "12px",
        borderColor: `${theme.colors.basic[1]} !important`,
        border: "1px solid",
        overflow: "hidden",

        table: {
          borderColor: "unset",
          border: "unset",
        },
        thead: {
          background: theme.colors.other[0],

          tr: {
            th: {
              borderBottom: "1px solid",
              borderColor: `${theme.colors.basic[1]} `,
              w: "fit-content",
              fontWeight: 400,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingRight: "4px",
              color: `${theme.colors.basic[4]}`,
            },
          },
        },
        tbody: {
          border: "unset",
          tr: {
            border: "unset",
            ":nth-of-type(odd)": {
              background: theme.colors.primaryOpacity[1],
            },
            td: {
              border: "unset",
              paddingRight: "4px",
            },
          },
        },
      }}
    >
      <Table fontSize={14} lh="22px" horizontalSpacing={20} verticalSpacing={8}>
        <thead>
          <tr>
            <th>Planos</th>
            <th>Contratos*</th>
            <th>Valor ao mês</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Flex>
  );
};

export default TableProduct;

import {
  Box,
  Button,
  Flex,
  NumberInput,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Modal, Select } from "../../../../components";
import { InfoCircle } from "iconsax-react";
import { convertCurrency } from "../../../../utils/currency";
import { useState } from "react";

const dataWithdraw = {
  money: 13000,
  keyPix: [
    {
      id: 1,
      label: "lorenzofonseca@gmail.com",
      value: "lorenzofonseca@gmail.com",
    },
    {
      id: 2,
      label: "999.999.999-99",
      value: "999.999.999-99",
    },
    {
      id: 3,
      label: "51 99999-9999",
      value: "51 99999-9999",
    },
  ],
};

interface IProps {
  close?: any;
}

const ModalWithdraw: React.FC<IProps> = ({ close }) => {
  const theme = useMantineTheme();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [money, setMoney] = useState<number | "">();

  return (
    <>
      <Modal
        type="success"
        titleSeccondButton="Fechar"
        description="Solicitação de saque enviada.</br></br>
        Você receberá a confirmação de sua solicitação por e-mail e na aba “notificações”."
        open={openModal}
        setClose={() => setOpenModal(!openModal)}
        firstClick={() => {}}
        secondClick={() => {
          close();
          setOpenModal(!openModal);
        }}
      />
      <Flex
        w={{ base: 686 }}
        direction="column"
        gap={24}
        bg="basic.0"
        pt={32}
        p={48}
        sx={{
          borderRadius: "12px",
          boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Flex direction="column" gap={{ base: 32 }}>
          <Text
            mx={"auto"}
            fz={{ base: 24 }}
            fw={{ base: 600 }}
            lh={{ base: "37.2px" }}
            c={"basic.5"}
          >
            Solicitar saque
          </Text>
          <Flex
            w="100%"
            bg="alertsOpacity.1"
            align="center"
            p={{ base: 16 }}
            gap={12}
            sx={{
              borderRadius: "8px",
            }}
          >
            <InfoCircle size="24" color="#FAAB36" variant="Bold" />
            <Flex
              w="100%"
              justify="space-between"
              sx={{
                color: theme.colors.basic[5],
                fontSize: "16px",
                b: {
                  fontWeight: 600,
                },
              }}
            >
              <Text>
                Valor disponível para <b>Saque:</b>
              </Text>
              <Text>
                R$ <b> {convertCurrency(dataWithdraw?.money)}</b>
              </Text>
            </Flex>
          </Flex>
          <Select
            label="Chave PIX"
            placeholder="Selecione a chave que deseja utilizar"
            withAsterisk
            data={dataWithdraw?.keyPix}
          />

          <Box>
            <NumberInput
              left={"R$"}
              hideControls
              min={0}
              decimalSeparator=","
              formatter={(value) =>
                !Number.isNaN(parseFloat(value))
                  ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
              precision={2}
              label="Valor"
              placeholder="Digite valor que deseja sacar"
              withAsterisk
              value={money}
              onChange={setMoney}
            />
            {!!money ? (
              <Text fz={{ base: 13 }} fw={500} c={"basic.4"} mt={8}>
                {money > dataWithdraw?.money
                  ? "Saldo insuficiente"
                  : `*Seu saldo após a confirmação do saque R$
              ${convertCurrency(dataWithdraw?.money - money)}`}
              </Text>
            ) : null}
          </Box>
        </Flex>

        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          type="submit"
          variant="primary"
          fullWidth
          mt={26}
          disabled={!money}
        >
          Solicitar saque
        </Button>
        <Button onClick={close} type="submit" variant="dangerOutline" fullWidth>
          Cancelar
        </Button>
      </Flex>
    </>
  );
};

export default ModalWithdraw;

import React from "react";
import { Button, Input as InputSearch, InputProps } from "@mantine/core";
import { SearchNormal1 } from "iconsax-react";

interface IPropsSearch extends InputProps {
  onClick?: () => void;
}

const Search: React.FC<IPropsSearch> = ({ onClick, ...props }) => {
  return (
    <InputSearch
      h={56}
      mt={0}
      placeholder="Pesquisar"
      type="search"
      rightSection={
        <Button
          bg={"transparent"}
          p={0}
          sx={{
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          style={{ border: "none" }}
          type="button"
          onClick={onClick}
        >
          <SearchNormal1 size="24" color="#2f2f2f" />
        </Button>
      }
      {...props}
    />
  );
};

export default Search;

import { api } from "../api/api";

export type PostEmailProps = {
  email_to: string;
  subject: string;
  email_from: string;
  text: string;
  html: string;
  reply_to: string;
};

export const postEmail = async (value: PostEmailProps) => {
  const resp = await api.post("/emails", value);
  return resp.data;
};

export function convertPoints(points: number) {
  var currencyFormatado = points.toLocaleString();
  return currencyFormatado;
}

export function convertCurrency(currency: number) {
  var currencyFormatado = currency.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  });
  return currencyFormatado;
}

export function convertCurrencyBR(currency: number) {
  var currencyFormatado = currency.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return currencyFormatado;
}

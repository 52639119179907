import React, { createContext, useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../constants/queryClient";
import { api } from "../api/api";
import { useLocalStorage } from "@mantine/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { CardGraficProps } from "../components/Cards/ComponentsCards/typesCards";
import { number } from "yup";

type FormData = {
  username: string;
  password: string;
};

interface IUser {
  error: string;
  product: string;
  status: string;
  token: string;
  username: string;
  roleNav: string;
}

interface IProps {
  children: React.ReactNode;
}

export interface InterestContext extends CardGraficProps {
  name: string;
  slug: string;
  selected?: boolean;
}

interface IContext {
  signed: boolean;
  postAuthorize: any;
  user: IUser | null;
  logout: any;
  isLoading: boolean;
  userMVP: IUserMVP;
  loginMVP: () => void;
  returnPath: (path: string) => void;
  logoutMVP: () => void;
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  interest: InterestContext[];
  setInterest: React.Dispatch<React.SetStateAction<InterestContext[]>>;
}
export interface IUserMVP {
  active: boolean;
  username: string;
  document: string;
  email: string;
  phone: string;
  password: string;
  secondPassword?: string;
  coins?: number;
  message?: boolean;
  notification?: boolean;
  wallet?: number;
  roles: string[];
  create: string;
  type: string;
  whatsapp?: string;
  messenger?: string;
  facebook?: string;
  instagram?: string;
  term_of_contract?: boolean;
  saveLogin?: boolean;
  adress?: {
    cep: string;
    adress: string;
    neighborhood: string;
    number: string;
    complement: string;
    city: string;
    state: string;
  };
}
const Context = createContext<IContext>({} as IContext);

export const MOCK_USER_MVP: IUserMVP = {
  active: true,
  username: "MVP Spider Trader",
  document: "00000000099",
  email: "mvp_spider@spidertrader.com",
  phone: "(51) 99999-9999",
  create: "14/02/2024 - 10:56:14",
  password: "Abcd123$",
  secondPassword: "Abcd123$",
  saveLogin: false,
  term_of_contract: false,
  type: "Inquieto",
  whatsapp: "51123456789",
  messenger: "",
  facebook: "",
  instagram: "",
  roles: ["Cliente", "Analista", "Admin"],
  wallet: 0,
  coins: 0,
  message: false,
  notification: false,
  adress: {
    cep: "12345789",
    adress: "av loren ipsum",
    number: "123",
    neighborhood: "Bairro Loren",
    complement: "Loren Loren Loren Loren",
    city: "São Paulo",
    state: "São Paulo",
  },
};

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [userMVP, setUserMVP] = useState<IUserMVP>({} as IUserMVP);
  const [role, setRole] = useState<string>("");
  const [interest, setInterest] = useState<InterestContext[]>([]);
  const [user, setUser, removeUser] = useLocalStorage<IUser | null>({
    key: "@App:user",
    getInitialValueInEffect: true,
  });
  const [token, setToken, removeToken] = useLocalStorage<string>({
    key: "@App:token",
    getInitialValueInEffect: true,
  });

  const useAuthorize = async (data: FormData) => {
    const response = await api.post("/login", data);

    return response.data;
  };

  const logout = () => {
    setUser(null);
    removeToken();
    removeUser();
    window.location.replace("/");
  };

  const { mutate: postAuthorize } = useMutation(useAuthorize, {
    onSuccess: (resp: any) => {
      setUser(resp);
      setToken(resp.token);
      queryClient.invalidateQueries(["user"]);
      navigate("/dashboard");
    },
  });

  const returnPath = (path: string) => {
    const from = location.state?.from;

    if (!!from && !from.includes(path)) {
      navigate(from);
    } else {
      navigate("/marketplace");
    }
  };

  const loginMVP = () => {
    setUserMVP((prev) => ({
      ...prev,
      ...MOCK_USER_MVP,
    }));
    setUser({
      error: "",
      product: "",
      status: "",
      token: "TA ON",
      username: MOCK_USER_MVP.username,
      roleNav: "Cliente",
    });
    setRole("Cliente");
    returnPath("/autenticacao");
  };

  const logoutMVP = () => {
    setUserMVP({} as IUserMVP);
    setUser({} as IUser);
    setRole("");
    returnPath("/marketplace");
  };

  return (
    <Context.Provider
      value={{
        signed: Boolean(token),
        user,
        postAuthorize,
        logout,
        isLoading,
        userMVP,
        loginMVP,
        returnPath,
        logoutMVP,
        role,
        setRole,
        interest,
        setInterest,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useAuth() {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export default Context;

import React, { useState } from "react";
import { Button, Modal as ModalMantine } from "@mantine/core";
import TypeRecover from "./OneStepRecover";
import SendRecover from "./SendRecover";
import PinCode from "./PinCode";
import ChangePassword from "./ChangePassword";
import { Modal } from "../../../components";

const RecoverPassword: React.FC = () => {
  const [successChanged, setSuccessChanged] = useState<string>("");
  const [openModal, SetOpenModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [alreadyPassword, setAlreadyPassword] = useState<boolean>(false);
  const [stepModal, setStepModal] = useState<number>(0);
  const nextStep = () =>
    setStepModal((current) => (current < 5 ? current + 1 : current));
  const destroyStep = () => {
    setStepModal(0);
  };

  return (
    <>
      <Modal
        type={successChanged === "success" ? "success" : "warning"}
        titleSeccondButton="Acessar conta"
        description="Senha alterada com sucesso."
        open={openModal}
        setClose={() => {
          SetOpenModal(!openModal);
        }}
        firstClick={() => {}}
        secondClick={() => {
          SetOpenModal(!openModal);
        }}
      />
      <Button h="auto" onClick={nextStep} variant="link">
        Esqueci minha senha
      </Button>
      <ModalMantine
        opened={stepModal > 0}
        onClose={destroyStep}
        title={
          stepModal < 3
            ? "Esqueci minha senha"
            : stepModal === 3
            ? "Recuperar senha"
            : "Alterar senha"
        }
        centered
        size="auto"
        transitionProps={{
          transition: "slide-up",
          duration: 300,
          timingFunction: "linear",
          exitDuration: 0,
        }}
        styles={{
          content: {
            borderRadius: "12px",
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03);",
          },
          header: {
            paddingTop: "10px",
          },
          inner: {
            background: "rgba(0, 0, 0, 0.6)",
          },
          title: {
            fontSize: "24px",
            fontWeight: 600,
            width: "100%",
            textAlign: "center",
            marginLeft: "22px",
            marginTop: "36px",
          },
          body: {
            padding: "48px",
            color: "#2F2F2F",
          },
          close: {
            position: "absolute",
            top: 24,
            right: 24,
            height: 40,
            width: 40,
            svg: {
              width: 24,
              height: 24,
            },
          },
        }}
      >
        {stepModal === 1 && (
          <TypeRecover setType={setType} nextStep={nextStep} />
        )}
        {stepModal === 2 && (
          <SendRecover
            nextStep={nextStep}
            type={type}
            setAlreadyPassword={setAlreadyPassword}
          />
        )}
        {stepModal === 3 && (
          <PinCode
            nextStep={nextStep}
            type={type}
            alreadyPassword={alreadyPassword}
          />
        )}
        {stepModal === 4 && (
          <ChangePassword
            nextStep={destroyStep}
            setSuccessChanged={setSuccessChanged}
            SetOpenModal={SetOpenModal}
          />
        )}
      </ModalMantine>
    </>
  );
};

export default RecoverPassword;

import { Center, Flex, Text } from "@mantine/core";
import React, { useRef } from "react";
import { Swiper } from "swiper/react";
import { Swiper as SwiperType, Navigation, Autoplay } from "swiper";
import ButtonsCarousel from "../ButtonsCarousel";

type Iprops = {
  title: string;
  children: React.ReactNode;
  activeLoop?: boolean;
  slidesPerView?: number;
  height?: number;
};

const CarouselWithTitleDefault: React.FC<Iprops> = (props) => {
  const swiperRef = useRef<SwiperType>();

  return (
    <Center>
      <Flex
        p="unset"
        align="center"
        direction="column"
        h="fit-content"
        style={{
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <Flex align="center" justify="space-between" w="100%">
          <Text
            fw={700}
            fz={"60px"}
            lh={"100px"}
            sx={{
              background:
                "linear-gradient(180deg, #0D2541 0%, rgba(13, 37, 65, 0) 100%)",
              backgroundClip: "text",
              textFillColor: "transparent",
              opacity: 0.8,
            }}
          >
            {!!props.title ? props.title : "Produtos que possam interessar"}
          </Text>

          <ButtonsCarousel
            next={() => swiperRef.current?.slideNext()}
            previous={() => swiperRef.current?.slidePrev()}
          />
        </Flex>
        <Flex
          maw="1680px"
          w="100%"
          h={!!props.height ? props.height : 580}
          sx={{ overflow: "hidden" }}
          justify="center"
          align="center"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={32}
            centeredSlides={true}
            loop={!!props.activeLoop ? props.activeLoop : true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay]}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={
              !!props.slidesPerView && props.slidesPerView === 3
                ? {
                    1365: {
                      slidesPerView: 3,
                      centeredSlides: false,
                    },
                    991: {
                      slidesPerView: 2,
                      centeredSlides: false,
                    },
                  }
                : {
                    1500: {
                      slidesPerView: !!props.slidesPerView
                        ? props.slidesPerView
                        : 4,
                      centeredSlides: false,
                    },
                    1100: {
                      slidesPerView: !!props.slidesPerView
                        ? props.slidesPerView - 1
                        : 3,
                      centeredSlides: false,
                    },
                    900: {
                      slidesPerView: 2,
                      centeredSlides: false,
                    },
                  }
            }
            className="mySwiper"
          >
            {props.children}
          </Swiper>
        </Flex>
      </Flex>
    </Center>
  );
};

export default CarouselWithTitleDefault;

import axios from "axios";

const isProd = process.env.REACT_APP_IS_PROD;
const API_BASE_URL = isProd
  ? process.env.REACT_APP_BASE_URL
  : process.env.REACT_APP_HML_BASE_URL;
const API_USER = isProd
  ? process.env.REACT_APP_USER
  : process.env.REACT_APP_HML_USER;
const API_PASSWORD = isProd
  ? process.env.REACT_APP_PASSWORD
  : process.env.REACT_APP_HML_PASSWORD;

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
});

api.interceptors.request.use(async (config: any) => {
  const token = await window.localStorage.getItem("@trader:token");

  let regexToken = token?.replaceAll('"', "");

  if (!!token) {
    config.headers["Authorization"] = regexToken;
  }
  return config;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error: any) => {
    const token = await window.localStorage.getItem("@trader:token");

    if (error.response?.status === 401 && !!token) {
      return api.request(error.config);
    }

    console.log("intercept error unhandled", error);
    return Promise.reject(error);
  }
);

const apiIBGE = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api",
});

export { api, apiIBGE };

import {
  Button,
  Divider,
  Flex,
  Highlight,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import {
  AddCircle,
  Calendar,
  DocumentText,
  DollarSquare,
  InfoCircle,
  Lock,
  MinusCirlce,
  Wallet,
} from "iconsax-react";
import { useState } from "react";
import { useNotificationControl } from "../../../../context/notificationContext";
import IconTitleText from "./IconTitleText";
import TableProduct from "./TableProduct";
import { CardGraficProps } from "../../../../components/Cards/ComponentsCards/typesCards";
import { useAuth } from "../../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  selectedProduct: CardGraficProps | undefined;
}

const BuyArea = ({ selectedProduct }: IProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [addCart, setAddCart] = useState<boolean>(false);
  const { notificationControl } = useNotificationControl();
  const { interest, setInterest, userMVP } = useAuth();
  const hasInterest =
    !!interest.length && !!selectedProduct
      ? interest.find((product) => product.slug === selectedProduct.slug)
      : false;
  const product = !!selectedProduct ? selectedProduct : ({} as CardGraficProps);

  const handleBuyProduct = () => {
    const from = location.pathname;
    navigate("/comprar-produtos", { state: { from } });
    setInterest([product]);
  };
  return (
    <Flex
      gap="32px"
      direction="column"
      w="100%"
      h="fit-content"
      maw="442px"
      p="32px"
      style={{
        border: "1px solid",
        borderColor: theme.colors.basic[2],
        borderRadius: "12px",
      }}
    >
      <Flex gap="16px" direction="column">
        <Title fw={600} size={16} lh="25px" color="basic.5">
          Planos disponíveis
        </Title>

        <TableProduct />

        <Highlight
          highlight={["R$1000,00", "WIN%"]}
          size={12}
          lh="19px"
          color="basic.5"
          fw={400}
          sx={{
            "& [data-highlight]": {
              backgroundColor: "unset",
              fontWeight: 600,
            },
          }}
        >
          *Capital mínimo recomendado: R$1000,00 por contrato WIN%
        </Highlight>
      </Flex>

      <Divider />

      {/* botões de compra */}
      <Flex gap="16px" direction="column">
        {hasInterest ? (
          <Button
            leftIcon={<MinusCirlce size={24} />}
            variant="dangerOutline"
            onClick={() => {
              setInterest(
                interest.filter(
                  (interestProduct) => interestProduct.slug !== product.slug
                )
              );
              setAddCart(!addCart);
              notificationControl({
                id: "checkRemovido",
                title: "Sucesso!",
                message: "Produto Removido da sua lista de interesse!",
                type: "check",
              });
            }}
          >
            Remover da lista de interesse
          </Button>
        ) : (
          <Button
            leftIcon={
              !!userMVP.email ? <AddCircle size={24} /> : <Lock size={24} />
            }
            variant="primary"
            onClick={() => {
              setInterest([...interest, product]);
              setAddCart(!addCart);
              notificationControl({
                id: "checkAdicionado",
                title: "Sucesso!",
                message: "Produto adicionado a sua lista de interesse!",
                type: "check",
              });
            }}
            disabled={!userMVP.email}
          >
            {!!userMVP.email
              ? "Adicionar a lista de interesse"
              : "Acesse sua conta para adicionar"}
          </Button>
        )}
        <Button
          leftIcon={!!userMVP.email ? <Wallet size={24} /> : <Lock size={24} />}
          disabled={!userMVP.email}
          variant="primaryOutline"
          onClick={handleBuyProduct}
        >
          {!!userMVP.email ? "Comprar agora" : "Acesse sua conta para comprar"}
        </Button>

        <Flex
          gap="10px"
          bg="complementaryOpacity.4"
          p="10.5px 0"
          w="100%"
          align="center"
          justify="center"
          sx={{ borderRadius: "8px" }}
        >
          <Highlight
            highlight={["840 pontos"]}
            size={12}
            lh="19px"
            color="complementary.4"
            fw={500}
            sx={{
              "& [data-highlight]": {
                backgroundColor: "unset",
                fontWeight: 700,
              },
            }}
          >
            Está compra vai lhe render até 840 pontos
          </Highlight>
          <Tooltip
            multiline
            w={236}
            label="O valor desta compra equivale a 1 ponto por real gasto."
            withArrow
            transitionProps={{ duration: 200 }}
          >
            <InfoCircle size={24} color={theme.colors.complementary[4]} />
          </Tooltip>
        </Flex>
      </Flex>
      {/* infos de cobranças */}
      <Flex gap="16px" direction="column">
        <IconTitleText
          title="Formas de pagamento aceitas"
          text="Cartão de créditos, PIX ou Pontos"
          leftIcon={<DollarSquare size={24} />}
        />
        <IconTitleText
          title="Periodicidade de cobrança"
          text="Mensal, semestral ou anual"
          leftIcon={<Calendar size={24} />}
        />
      </Flex>

      <Divider />

      {/* termos */}
      <Button
        h={48}
        leftIcon={<DocumentText size={24} />}
        variant="primaryTransparent"
      >
        Termos de contratação
      </Button>
    </Flex>
  );
};

export default BuyArea;

import * as Yup from "yup";

export const ValidationRecovery = Yup.object().shape({
  password: Yup.string()
    .required("Campo obrigatório")
    .min(8, "Mínimo 8 caracteres"),
  recoveryPassword: Yup.string()
    .required("Campo obrigatório")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z 0-9])(?=.{8,})/,
      "A senha deve possuir todos os requisitos"
    ),
  secondRecoveryPassword: Yup.string().required("Campo obrigatório"),
  //.oneOf([Yup.ref("recoveryPassword")], "Senhas diferentes"),
});

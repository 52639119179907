import { Button, Drawer, Flex, Tooltip, useMantineTheme } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Filter } from "iconsax-react";

const FilterDrawer: React.FC = () => {
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const isLargerThan1020 = useMediaQuery("(min-width: 1020px)");
  const isLargerThan500 = useMediaQuery("(min-width: 500px)");
  const Filters = [
    /*  {
      filtro: 1,
    },
    {
      filtro: 2,
    },
    {
      filtro: 3,
    }, */
  ];

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title="Filtro"
        position="right"
        size={659}
      >
        {/* Drawer content */}
      </Drawer>

      <Tooltip
        label="Filtros"
        position="bottom"
        disabled={isLargerThan500 && !isLargerThan1020 ? false : true}
        withArrow
      >
        <Button
          w="fit-content"
          variant={
            isLargerThan1020 ? "secondaryTransparent" : "transparentEspecial"
          }
          onClick={open}
          h={{ base: 48, 1020: 56 }}
          p={{ base: 8, 500: 12 }}
          fz={{ base: 14, 1020: 16 }}
          lh={{ base: "22px", 1020: "25px" }}
          fw={400}
          styles={{
            leftIcon: {
              marginRight: !!Filters.length
                ? "12px"
                : isLargerThan500 && !isLargerThan1020
                ? 0
                : "12px",
            },
            rightIcon: {
              marginLeft: isLargerThan500 && !isLargerThan1020 ? 0 : "12px",
            },
          }}
          leftIcon={<Filter size={24} variant="Outline" />}
          rightIcon={
            !!Filters.length ? (
              <Flex
                bg={theme.colors.primary[1]}
                p="4px 8px"
                align="center"
                justify="center"
                style={{
                  borderRadius: "20px",
                  color: theme.colors.basic[0],
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "22px",
                }}
              >
                {Filters.length > 9 ? "+9" : `0${Filters.length}`}
              </Flex>
            ) : null
          }
        >
          {isLargerThan500 && !isLargerThan1020 ? null : "Filtros"}
        </Button>
      </Tooltip>
    </>
  );
};

export default FilterDrawer;

import { Button, Divider, Flex, Modal, Text } from "@mantine/core";
import { Coin1, Filter, MoneyRecive, MoneySend, Wallet } from "iconsax-react";
import { convertCurrency, convertPoints } from "../../../utils/currency";
import TableEdit from "./TableEdit";
import { Search } from "../../../components";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import ModalWithdraw from "./ModalWithdraw";
import ModalDeposit from "./ModalDeposit";

const profile = {
  valorTotal: 20000,
  valorSaque: 13000,
  valorDisponivel: 7000,
  pontos: 100000,
};

const Financial: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [payment, setPayment] = useState<boolean>(false);

  return (
    <>
      <Modal
        centered
        opened={opened}
        onClose={close}
        size="auto"
        styles={{
          header: { padding: 0 },
          close: {
            position: "absolute",
            top: "24px",
            right: "24px",
          },
          body: {
            padding: 0,
          },
        }}
      >
        {payment ? (
          <ModalWithdraw close={close} />
        ) : (
          <ModalDeposit close={close} />
        )}
      </Modal>
      <Flex direction="column" justify="center" align="start" w="100%">
        <Flex
          direction={{ base: "column", 1500: "row" }}
          mt={33}
          align="center"
          gap={{ base: 24 }}
          id="editDataProfile"
          bg={"primary.0"}
          p={{ base: 24 }}
          w="100%"
          sx={{
            borderRadius: "12px 12px 0 0",
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Flex w="100%" justify="space-between" align="center" miw={484}>
            <Text
              c={"basic.0"}
              lh={{ base: "30px" }}
              fz={{ base: 24 }}
              sx={{
                svg: {
                  marginBottom: "-3px",
                  marginRight: "14px",
                },
              }}
            >
              <Wallet size="24" />
              Valor de
              <Text component="span" fw={600} pl={8}>
                Carteira
              </Text>
            </Text>
            <Text c={"basic.0"} lh={{ base: "37.2px" }} fz={{ base: 24 }}>
              R$
              <Text
                c={"basic.0"}
                lh={{ base: "49.6px" }}
                fz={{ base: 32 }}
                fw={{ base: 700 }}
                component="span"
                ml={8}
              >
                {convertCurrency(profile.valorTotal)}
              </Text>
            </Text>
          </Flex>
          <Flex
            ml="auto"
            gap={28}
            id="pointsBoxs"
            bg="basic.0"
            justify="center"
            align="center"
            c="primary.0"
            w="100%"
            maw={{ base: 414 }}
            py={{ base: 14 }}
            px={{ base: 16 }}
            sx={{ borderRadius: "12px" }}
          >
            <Text
              sx={{
                svg: {
                  marginBottom: "-5px",
                  marginRight: "12px",
                },
              }}
            >
              <Coin1 size="24" />
              Valor em
              <Text component="b" fw={600}>
                {" "}
                pontos
              </Text>
            </Text>
            <Text fz={{ base: 24 }} fw={{ base: 700 }} lh={{ base: "37.2px" }}>
              {convertPoints(profile.pontos)}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", 1500: "row" }}
          p={{ base: 24 }}
          w="100%"
          bg={"basic.0"}
          gap={{ base: 32 }}
          sx={{
            borderRadius: "0 0 12px 12px",
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Flex
            w="100%"
            gap={{ base: 18 }}
            direction="column"
            c="other.2"
            fz={{ base: 14 }}
          >
            <Flex justify="space-between" h={18}>
              <Text>
                Disponível para{" "}
                <Text component="span" fw={{ base: 600 }}>
                  saque:
                </Text>
              </Text>
              <Text>
                R$
                <Text component="span" fw={{ base: 700 }} fz={{ base: 18 }}>
                  {" "}
                  {convertCurrency(profile.valorSaque)}
                </Text>
              </Text>
            </Flex>
            <Flex justify="space-between" h={18}>
              <Text>
                Disponível para uso na{" "}
                <Text component="span" fw={{ base: 600 }}>
                  plataforma:
                </Text>
              </Text>
              <Text>
                R$
                <Text component="span" fw={{ base: 700 }} fz={{ base: 18 }}>
                  {" "}
                  {convertCurrency(profile.valorDisponivel)}
                </Text>
              </Text>
            </Flex>
          </Flex>
          <Flex
            ml="auto"
            id="flexBtnEdit"
            w="100%"
            align="center"
            maw={{ base: 414 }}
            gap={{ base: 16 }}
          >
            <Button
              onClick={() => {
                open();
                setPayment(true);
              }}
              fullWidth
              variant="primaryOutline"
              leftIcon={<MoneySend size="24" />}
            >
              Sacar
            </Button>
            <Button
              onClick={() => {
                open();
                setPayment(false);
              }}
              fullWidth
              variant="primary"
              leftIcon={<MoneyRecive size="24" />}
            >
              Depositar
            </Button>
          </Flex>
        </Flex>
        <Divider
          size={2}
          mt={{ base: 48 }}
          color="other.5"
          w={"100%"}
          variant="dashed"
        />
        <Flex
          mb={32}
          mt={{ base: 48 }}
          id="filtersEdit"
          w="100%"
          justify="center"
          align="center"
          gap={{ base: 24 }}
        >
          <Text fz={{ base: 24 }} fw={700} lh={{ base: "32px" }} mr="auto">
            Histórico de compras
          </Text>
          <Search my="auto" maw={400} w="100%" />
          <Button variant="primaryOutline" leftIcon={<Filter size="24" />}>
            Filtros
          </Button>
        </Flex>

        <TableEdit />
      </Flex>
    </>
  );
};

export default Financial;

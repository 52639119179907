import { Flex, Text, Title, Badge, Grid } from "@mantine/core";
import { Cup, People } from "iconsax-react";

interface IProps {
  registrations: string;
  championship: number;
  actives: string[];
}

type InfosProps = {
  children: React.ReactNode;
  title: string;
  result?: string;
};

type BadgeSpecialProps = {
  text: string;
};

const Infos = (props: InfosProps) => {
  return (
    <Flex
      gap="4px"
      justify="space-between"
      direction="column"
      miw="96px"
      w={{ base: props.title === "Ativos" ? "100%" : "auto" }}
      style={{ flex: props.title === "Ativos" ? 2 : 1 }}
    >
      <Text
        fw={500}
        size={14}
        lh="21.7px"
        color="basic.3"
        ff="Barlow"
        lineClamp={1}
      >
        {props.title}
      </Text>
      <Flex gap="8px" align="center">
        {props.children}

        {!!props.result && (
          <Title
            fw={500}
            size={14}
            lh="21.7px"
            color="basic.4"
            style={{ wordBreak: "keep-all" }}
          >
            {props.result}
          </Title>
        )}
      </Flex>
    </Flex>
  );
};

const BadgeSpecial = ({ text }: BadgeSpecialProps) => {
  return (
    <Badge
      styles={{
        root: {
          width: "fit-content",
          height: "27px",
          padding: "4px 8px",
          backgroundColor: "#ECF3FE",
          border: "unset",
          borderRadius: "20px",
        },
        inner: {
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "21.7px",
          color: "#1B4FDF",
        },
      }}
    >
      {text}
    </Badge>
  );
};

const ContentMainCard: React.FC<IProps> = (props) => {
  return (
    <Grid>
      <Grid.Col span="content">
        <Infos result={props.registrations} title="Inscrições">
          <People size="24" color="#3F87F4" />
        </Infos>
      </Grid.Col>
      <Grid.Col span="content">
        <Infos result={props.championship.toString()} title="Campeonatos">
          <Cup size="24" color="#3F87F4" />
        </Infos>
      </Grid.Col>
      <Grid.Col span={6} w="100%">
        <Infos title="Ativos">
          <Flex gap="8px" align="center" h="27px">
            {props?.actives?.slice(0, 3)?.map((active, index) => (
              <BadgeSpecial key={`${index}+${active}`} text={active} />
            ))}
            {props?.actives?.length > 3 && (
              <BadgeSpecial
                text={`+${(props?.actives?.length - 3).toString()}`}
              />
            )}
          </Flex>
        </Infos>
      </Grid.Col>
    </Grid>
  );
};

export default ContentMainCard;

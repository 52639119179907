import { Flex, FlexProps, Text } from "@mantine/core";

interface IProps extends FlexProps {
  title: string;
  content?: string;
}

const BoxShop: React.FC<IProps> = ({ title, content, ...props }) => {
  return (
    <Flex direction="column" justify="center" {...props}>
      <Text ff="Barlow" fz={{ base: 14 }} lh={{ base: "155%" }} c="basic.4">
        {title}
      </Text>
      <Text
        ff="Barlow"
        fw={{ base: 600 }}
        fz={{ base: 14 }}
        lh={{ base: "155%" }}
        c="basic.5"
      >
        {content}
      </Text>
    </Flex>
  );
};

export default BoxShop;

import React, { useEffect, useState } from "react";
import { Badge as BadgeMantine, BadgeProps } from "@mantine/core";

interface IPropsTagStatus extends BadgeProps {
  status?:
    | "disapproved"
    | "loading"
    | "orange"
    | "success"
    | "waiting"
    | undefined;
  title?: string;
  select?: boolean;
  border?: boolean;
}

const TagStatus: React.FC<IPropsTagStatus> = ({
  status,
  title,
  select,
  border,
  ...props
}) => {
  const [content, setContent] = useState<string | any>({});

  useEffect(() => {
    switch (status) {
      case "loading":
        return setContent({
          text: "em processamento",
          backgroundColor: "#FEF7EB",
          color: "#FAAB36",
          border: "#f29100",
        });
      case "disapproved":
        return setContent({
          text: "reprovado",
          backgroundColor: "#FEEBEB",
          color: "#F43333",
          border: "#f80000",
        });
      case "orange":
        return setContent({
          backgroundColor: " #FFEEDE",
          color: "#FB6815",
          border: "#bd4500",
        });
      case "waiting":
        return setContent({
          backgroundColor: "#F0F0F0",
          color: "#B2B1B1",
          border: "#B2B1B1",
        });

      case "success":
        return setContent({
          backgroundColor: "#E9F8EB",
          color: "#23BD32",
          border: "#169C3C",
        });

      default:
        return setContent({
          text: "Aprovado",
          backgroundColor: "#E9F8EB",
          color: "#23BD32",
          border: "#169C3C",
        });
    }
  }, [status]);

  return (
    <BadgeMantine
      styles={{
        root: {
          color: content.color,
          background: content.backgroundColor,
          padding: select ? "5px 10px" : "4px 8px",
          height: select ? "40px" : "30px",
          fontSize: select ? "16px" : "14px",
          border: "1px solid",
          width: "fit-content",
          borderColor: border ? content.border : "transparent",
        },
      }}
      radius="sm"
      {...props}
    >
      {title ? title : content.text}
    </BadgeMantine>
  );
};

export default TagStatus;

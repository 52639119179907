import { Flex } from "@mantine/core";
import { Footer } from "../components";
import { Outlet } from "react-router-dom";
import NavBar from "../Layout";

const Root: React.FC = () => {
  return (
    <Flex h="100%" direction="column" justify="space-between" mih="100vh">
      <Flex direction="column">
        <NavBar />
        <Outlet />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Root;

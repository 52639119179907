import React from "react";
import { Flex, useMantineTheme } from "@mantine/core";
import { SwiperSlide } from "swiper/react";

import {
  CardCommonAlgorithm,
  CardCommonCamp,
  CardCommonCurse,
  CardCommonGrafic,
  CardCommonProduct,
  CarouselWithLogo,
  CarouselWithSide,
  CarouselWithTitle,
  HeaderCards,
} from "../../../components";

import {
  DATA_CARD_CAMP,
  DATA_CARD_CURSE,
  DATA_CARD_GRAFIC,
  DATA_CARD_PRODUCT,
} from "../../../constants/cards";

import LeagueAndCamps from "./LeagueAndCamps";
import { DATA_CARD_ALGORITHMS } from "../../../constants/cardsAPI";

const TodosMarketplace: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Flex bg={theme.colors.other[0]} direction="column">
      <HeaderCards />

      {/*CopyOrder*/}
      {/* <CarouselWithSide
        subtitle={"Conheça nossos"}
        title={"CopyOrder"}
        titleButton={"Acessar CopyOrder"}
        linkButton={"/CopyOrder"}
        activeLoop={DATA_CARD_GRAFIC.length >= 8}
        maxWidth={460}
      >
        {DATA_CARD_GRAFIC.map((item, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => (
              <Flex justify="center" align="center" mih="580px">
                <CardCommonGrafic
                  item={item}
                  select={isActive}
                  preSlug="CopyOrder/"
                />
              </Flex>
            )}
          </SwiperSlide>
        ))}
      </CarouselWithSide> */}

      <LeagueAndCamps />

      {/*Robôs*/}
      {/* <CarouselWithSide
        subtitle={"Conheça nossos"}
        title={"Algoritmos & Indicadores"}
        titleButton={"Acessar"}
        linkButton={"/solucoes/trader-evolution/algoritmos-indicadores"}
        activeLoop={DATA_CARD_PRODUCT.length >= 8}
        maxWidth={460}
      >
        {DATA_CARD_PRODUCT.map((item, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => (
              <Flex justify="center" align="center" mih="580px">
                <CardCommonProduct item={item} select={isActive} />
              </Flex>
            )}
          </SwiperSlide>
        ))}
      </CarouselWithSide> */}

      {/*Cursos*/}
      {/* <CarouselWithTitle
        subtitle={"Conheça nossos"}
        title={"Cursos & Treinamentos"}
        titleButton={"Acessar cursos e treinamentos"}
        linkButton={"/cursos"}
        activeLoop={DATA_CARD_CURSE.length >= 8}
      >
        {DATA_CARD_CURSE.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "560px",
            }}
          >
            <Flex justify="center" align="center" mih="580px">
              <CardCommonCurse item={item} />
            </Flex>
          </SwiperSlide>
        ))}
      </CarouselWithTitle> */}

      {/*Campeonatos*/}
      {/* <CarouselWithLogo
        subtitle={"Conheça nossos"}
        title={"Campeonatos"}
        titleButton={"Acessar campeonatos"}
        linkButton={"/campeonatos"}
        activeLoop={DATA_CARD_CAMP.length >= 6}
      >
        {DATA_CARD_CAMP.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "595px",
            }}
          >
            <Flex
              justify="center"
              align="center"
              mih={{ base: 665, 500: 595 }}
              w="100%"
            >
              <CardCommonCamp item={item} />
            </Flex>
          </SwiperSlide>
        ))}
      </CarouselWithLogo> */}

      {/*Plataformas*/}
      {/* <CarouselWithSide
        subtitle={"Conheça nossas"}
        title={"Plataformas"}
        titleButton={"Acessar plataformas"}
        linkButton={"/solucoes/trader-evolution/plataformas"}
        activeLoop={DATA_CARD_ALGORITHMS.length >= 8}
        maxWidth={610}
      >
        {DATA_CARD_ALGORITHMS.map((item, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => (
              <Flex justify="center" align="center" mih="580px">
                <CardCommonAlgorithm item={item} select={isActive} />
              </Flex>
            )}
          </SwiperSlide>
        ))}
      </CarouselWithSide> */}

      {/*Soluções*/}
      {/* <CarouselWithTitle
        subtitle={"Conheça nossas"}
        title={"Soluções"}
        titleButton={"Acessar soluções"}
        linkButton={"/Solucoes"}
        activeLoop={DATA_CARD_ALGORITHMS.length >= 8}
      >
        {DATA_CARD_ALGORITHMS.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CardCommonAlgorithm item={item} />
          </SwiperSlide>
        ))}
      </CarouselWithTitle> */}
    </Flex>
  );
};

export default TodosMarketplace;

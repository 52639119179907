import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Flex,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Add, HambergerMenu } from "iconsax-react";
import NotificationsNavbar from "../NotificationsNavbar";
import AccordionMenu from "./AccordionMenu";
import FooterLinks from "./FooterLinks";
import { Logo } from "../../components";
import { useAuth } from "../../context/auth";

const DrawerMenu = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();
  const { userMVP } = useAuth();

  return (
    <>
      <ActionIcon size={44} variant="transparent" onClick={open} ml={-8}>
        <HambergerMenu color={theme.colors.basic[0]} size={28} />
      </ActionIcon>
      <Drawer.Root opened={opened} onClose={close}>
        <Drawer.Overlay />
        <Drawer.Content bg={theme.colors.primary[0]} w="100%" h="100%">
          <Flex direction="column" p="48px 0 56px">
            {/* Header */}
            <Flex p="0 24px 0" justify="space-between" align="center" mb="32px">
              <Flex gap="16px" align="center">
                <Button
                  leftIcon={
                    <Add
                      color={theme.colors.basic[0]}
                      size={32}
                      style={{ transform: "rotate(45deg)" }}
                    />
                  }
                  variant="transparentEspecial"
                  onClick={close}
                  h={32}
                  w={32}
                  styles={{
                    root: {
                      fontWeight: 400,
                    },
                    leftIcon: {
                      "@media (max-width: 1200px)": {
                        marginRight: "0",
                      },
                    },
                  }}
                />
                <Logo height={34} />
              </Flex>
              {!!userMVP.username ? null : <NotificationsNavbar />}
            </Flex>

            {/* menu de navegação */}
            <AccordionMenu close={close} />
            <Divider m="26px 0" />

            <FooterLinks close={close} />
            {/* autenticação */}
            {/* <Flex mt={32} w="100%">
              {props.auth === "notLogin" ? (
                <Authentication drawer={true} />
              ) : (
                <Button variant="danger" h={48} m="0 24px" w="100%">
                  Sair
                </Button>
              )}
            </Flex> */}
          </Flex>
        </Drawer.Content>
      </Drawer.Root>
    </>
  );
};

export default DrawerMenu;

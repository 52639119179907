import { Button, Flex, Text } from "@mantine/core";
import {
  Modal,
  PasswordInput,
  PasswordRequirements,
} from "../../../components";
import { Controller, useForm } from "react-hook-form";
import { IFormRecoveryPassword } from "./type";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationRecovery } from "./schema-validation";
import { useState } from "react";

const Password: React.FC = () => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormRecoveryPassword>({
    mode: "onChange",
    resolver: yupResolver(ValidationRecovery),
  });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onSubmit = (data: IFormRecoveryPassword) => {};

  return (
    <>
      <Modal
        type="success"
        titleSeccondButton="Fechar"
        description="Senha alterada com sucesso!"
        open={openModal}
        setClose={() => setOpenModal(!openModal)}
        firstClick={() => {}}
        secondClick={() => {
          setOpenModal(!openModal);
        }}
      />
      <Flex
        py={33}
        direction="column"
        justify="center"
        align="start"
        w="100%"
        mb="auto"
        gap={7}
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Flex
            id="editDataProfile"
            direction="column"
            bg={"basic.0"}
            p={{ base: 48 }}
            gap={{ base: 24 }}
            w="100%"
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
            }}
          >
            <Text fw={{ base: 600 }} fz={{ base: 24 }} lh={{ base: "37.2px" }}>
              Alterar senha
            </Text>

            <Controller
              name="password"
              control={control}
              render={({ field: { onBlur, onChange, value } }) => (
                <PasswordInput
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label="Senha atual"
                  placeholder="Digite sua senha atual"
                  error={errors.password?.message}
                />
              )}
            />
            <Flex
              gap={{ base: 24 }}
              direction={{ base: "column", 1504: "row" }}
            >
              <Controller
                name="recoveryPassword"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <PasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    w="100%"
                    label="Nova senha"
                    placeholder="Digite sua nova senha"
                    error={errors.recoveryPassword?.message}
                  />
                )}
              />
              <Controller
                name="secondRecoveryPassword"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <PasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    w="100%"
                    label="Confirmar nova senha"
                    placeholder="Confirme sua nova senha"
                    error={errors.secondRecoveryPassword?.message}
                  />
                )}
              />
            </Flex>
            <PasswordRequirements
              passwordOne={watch("recoveryPassword")}
              passwordTwo={watch("secondRecoveryPassword")}
            />
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || !isValid}
              onClick={() => setOpenModal(!openModal)}
            >
              Alterar senha
            </Button>
          </Flex>
        </form>
      </Flex>
    </>
  );
};

export default Password;

import { Button } from "@mantine/core";
import { Text } from "@mantine/core";
import { Flex } from "@mantine/core";
import { InfoCircle } from "iconsax-react";

interface IProps {
  close: any;
}

const ModalDeposit: React.FC<IProps> = ({ close }) => {
  return (
    <Flex
      direction="column"
      gap={24}
      bg="basic.0"
      pt={32}
      p={48}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
      }}
    >
      <Text
        mx={"auto"}
        fz={{ base: 24 }}
        fw={{ base: 600 }}
        lh={{ base: "37.2px" }}
        c={"basic.5"}
      >
        Realizar depósito
      </Text>

      <Flex
        gap={18}
        direction="column"
        justify="center"
        align="center"
        w={{ base: 590 }}
        h={{ base: 296 }}
        bg="basic.1"
        sx={{ borderRadius: "12px" }}
      >
        <InfoCircle size="60" color="#6B6B6B" variant="Bold" />
        <Text
          maw={{ base: 242 }}
          ta="center"
          fz={{ base: 16 }}
          lh={{ base: "25px" }}
          fw={600}
          c={"basic.4"}
        >
          Aqui aparecerão informações da plataforma da VINDI.
        </Text>
      </Flex>

      <Button
        onClick={close}
        type="submit"
        variant="primaryOutline"
        fullWidth
        mt={26}
        //disabled={!isDirty || !isValid || !accept}
      >
        Fechar
      </Button>
    </Flex>
  );
};

export default ModalDeposit;

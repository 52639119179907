import { Center, Flex, Highlight, Title, useMantineTheme } from "@mantine/core";
import React from "react";
import { BackgroundLeagueAndCamps } from "../../../../assets";
import { CardLeague } from "../../../../components";

const LeagueAndCamps: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Center>
      <Flex
        p="0"
        direction="column"
        maw={1680}
        w="100%"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BackgroundLeagueAndCamps})`,
          backgroundSize: "cover",
          backgroundPosition: "right bottom",
          borderRadius: "20px",
        }}
      >
        <Flex p="80px" direction={"column"} align="start" gap={56}>
          <Flex direction={"column"} maw="550px">
            <Title
              order={1}
              id="Title"
              fw={400}
              fz="48px"
              lh="68px"
              color={theme.colors.basic[0]}
            >
              Conheça nossas
            </Title>
            <Title
              order={1}
              id="Title"
              fw={400}
              fz="48px"
              lh="68px"
              color={theme.colors.basic[0]}
            >
              <Highlight
                highlight={["Ligas", "Campeonatos"]}
                highlightStyles={() => ({
                  fontWeight: 700,
                  background: "unset",
                })}
              >
                Ligas e Campeonatos!
              </Highlight>
            </Title>
            <Title
              order={3}
              id="SubTitle Carrousel"
              fw={400}
              fz="24px"
              lh="37.2px"
              mt="16px"
              color={theme.colors.basic[0]}
            >
              Acesse agora e participe!
            </Title>
          </Flex>
          <Flex w="100%" gap="40px" h={195}>
            <CardLeague type="trader" />
            <CardLeague type="partners" />
          </Flex>
        </Flex>
      </Flex>
    </Center>
  );
};

export default LeagueAndCamps;

import { Flex, SegmentedControl, Text } from "@mantine/core";

interface IProps {
  data: any;
  radius?: string;
  label?: string;
  disabled?: boolean;
}

const SegmentControlRounded: React.FC<IProps> = ({
  data,
  radius,
  label,
  disabled = false,
}) => {
  return (
    <Flex direction={"column"} gap={8}>
      {!!label ? (
        <Text fw={600} fz="14px" lh="155%">
          {label}
        </Text>
      ) : null}
      <SegmentedControl
        radius={16}
        size="56px"
        data={data}
        disabled={disabled}
        styles={{
          root: {
            width: "auto",
            borderRadius: !!radius ? radius : "50px",
            background: "rgba(63, 135, 244, 0.2)",
            borderRight: "1.5px solid #E7E9EC",
            padding: "9px",
            paddingRight: "2",
          },
          label: {
            fontFamily: "Barlow",
            color: "#122447",
            fontWeight: 500,
            lineHeight: "155%",
            fontSize: "14px",
            padding: "8px 11px",
            "&[data-active]": {
              color: "#FAFBFE",
            },
            "&[data-active]:hover": {
              color: "#FAFBFE",
            },
          },
          control: {
            paddingLeft: "4px",
            border: "none !important",
          },
          indicator: {
            top: "4.5px",
            left: "8px",
            height: "48px",
            borderRadius: "50px",
            background: "#3F87F4",
          },
        }}
      />
    </Flex>
  );
};
export default SegmentControlRounded;

import { useCallback, useEffect } from "react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { ArrowRight2 } from "iconsax-react";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { LogoSpiderColor } from "../../assets";

type IProps = {
  drawer?: boolean;
};

const Authentication = (props: IProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { returnPath } = useAuth();
  const isLargerThan550 = useMediaQuery("(min-width: 550px)");
  const [opened, { open, close }] = useDisclosure(false);

  const isAuthPath = location.pathname.includes("/autenticacao");
  const createAccount = location.pathname.includes("/criar-conta");

  useEffect(() => {
    if (isAuthPath) {
      open();
    } else {
      close();
    }
  }, [isAuthPath]);

  const handleRedirect = useCallback(
    (path: string) => {
      open();
      const from = location.pathname;
      navigate(path, { state: { from } });
    },
    [location.pathname, navigate, open]
  );

  const handleOnClose = () => {
    returnPath("/autenticacao");
    // close();
  };

  const AuthButton = ({
    label,
    path,
    isActive,
  }: {
    label: string;
    path: string;
    isActive: boolean;
  }) => (
    <Button
      fullWidth
      onClick={() => handleRedirect(path)}
      bg={isActive ? "primaryOpacity.2" : ""}
      sx={{
        border: isActive ? "1px solid #1B4FDF" : "",
      }}
      rightIcon={<ArrowRight2 size={28} />}
      variant="login"
      mb={16}
      h={88}
    >
      {label}
      <span>
        {label === "Acessar Conta"
          ? "Acesse todas as funcionalidades"
          : "Cadastre-se em poucos minutos"}
      </span>
    </Button>
  );
  return (
    <>
      <Modal
        onClose={handleOnClose}
        transitionProps={{
          exitDuration: 0,
          duration: 0,
        }}
        opened={opened}
        fullScreen
        withCloseButton={false}
        styles={{
          content: {
            display: "flex",
            justifyContent: "center",
            background:
              "linear-gradient(270deg, rgba(250,251,254,1) 45%, rgba(255,255,255,1) 45%)",
          },
          body: {
            background: "#FAFBFE",
            overflow: "hidden",
            position: "relative",
            height: "100vh",
            width: "100%",
            maxWidth: "1920px",
            display: "flex",
            padding: "0",
          },
        }}
      >
        <Flex
          bg="#fff"
          direction="column"
          miw={{ base: "auto", lg: 556 }}
          p={80}
          justify="center"
          sx={{
            boxShadow: "2px 0px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Image
            src={LogoSpiderColor}
            height={45}
            width="auto"
            fit="contain"
            alt="logo-trader"
          />
          <Text
            w={270}
            mt={48}
            fw={500}
            color="#2F2F2F"
            size={28}
            lh="155%"
            align="start"
          >
            Encontre na
            <Text
              fw={900}
              sx={{
                background: theme.colors.primary[0],
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              SpiderTrader
            </Text>
            tudo para o seu negócio!
          </Text>
          <Text color="#6B6B6B" size={20} mt={12} mb={48}>
            Comece agora!
          </Text>

          <AuthButton
            label="Acessar Conta"
            path="/autenticacao/acessar-conta"
            isActive={!createAccount}
          />
          <AuthButton
            label="Cadastrar Conta"
            path="/autenticacao/criar-conta"
            isActive={createAccount}
          />
        </Flex>
        <ScrollArea
          w="100%"
          scrollHideDelay={500}
          styles={{
            viewport: {
              ">div": {
                height: "100%",
              },
            },
          }}
        >
          <Center
            w="100%"
            h="100%"
            style={{
              display: "table-cell",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            {createAccount ? <SignUp /> : <SignIn />}
          </Center>
        </ScrollArea>
        <Modal.CloseButton
          title="Fechar Modal"
          size={28}
          bg={"transparent"}
          p="4px"
          style={{
            position: "absolute",
            borderRadius: "4px",
            top: 24,
            right: 24,
          }}
        />
      </Modal>

      <Flex
        gap={16}
        w={{
          base: props.drawer ? "100%" : 132,
          550: props.drawer ? "100%" : 250,
          1400: 336,
        }}
        align="center"
        p={props.drawer ? "0 24px" : "unset"}
        direction={props.drawer ? "column-reverse" : "row"}
      >
        {props.drawer || (!props.drawer && isLargerThan550) ? (
          <Button
            onClick={() => handleRedirect("/autenticacao/criar-conta")}
            fullWidth
            w="100%"
            variant="secondaryTransparent"
            h={{ base: 48, 991: 38, 1400: 56 }}
            styles={{
              root: {
                "@media (max-width: 1400px)": {
                  lineHeight: "24.8px",
                },
              },
            }}
          >
            Cadastrar
          </Button>
        ) : null}

        <Button
          onClick={() => handleRedirect("/autenticacao/acessar-conta")}
          fullWidth
          w="100%"
          variant="secondary"
          h={{ base: 48, 991: 38, 1400: 56 }}
          styles={{
            root: {
              "@media (max-width: 1400px)": {
                lineHeight: "24.8px",
              },
            },
          }}
        >
          Acessar
        </Button>
      </Flex>
    </>
  );
};
export default Authentication;

import { Accordion, Flex, useMantineTheme } from "@mantine/core";
import { ArrowDown2 } from "iconsax-react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NAV_LINKS } from "../../constants/links";
import { useAuth } from "../../context/auth";

type IProps = {
  close: () => void;
};

const AccordionMenu: React.FC<IProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [value, setValue] = useState<string | null>(null);
  const { user } = useAuth();

  return (
    <Accordion
      value={value}
      onChange={setValue}
      variant="filled"
      bg={theme.colors.primary[0]}
      styles={{
        chevron: {
          svg: {
            height: "24px",
            width: "24px",
          },
        },
        item: {
          border: "none",
          borderRadius: "unset",
          background: "unset",
          fontWeight: 600,
          fontFamily: "Barlow",
          lineHeight: "25px",
          color: theme.colors.basic[0],
          marginTop: "8px",
          ":nth-of-type(1)": {
            marginTop: "unset",
          },
          "&[data-active]": {
            background: "rgba(255, 255, 255, 0.05)",
          },
        },
        control: {
          color: theme.colors.basic[0],
          padding: "16px 24px",
          transition: "all 0.5s ease",
          ":hover": {
            background: "rgba(255, 255, 255, 0.05)",
          },
        },
        label: {
          fontWeight: 600,
          fontFamily: "Barlow",
          lineHeight: "25px",
        },
        content: {
          fontWeight: 600,
          fontFamily: "Barlow",
          lineHeight: "25px",
          padding: "unset",
        },
      }}
    >
      {NAV_LINKS.find(
        (navLinks) => navLinks.authType === user?.roleNav
      )?.links?.map((navLink, index) => (
        <Accordion.Item
          key={`${navLink.label}${index}`}
          value={navLink.href}
          onClick={() => {
            if (!!!navLink.links) {
              navigate(navLink.href);
              props.close();
            }
          }}
        >
          <>
            <Accordion.Control
              chevron={
                <ArrowDown2
                  size="1rem"
                  style={{ opacity: !!navLink.links ? 1 : 0 }}
                />
              }
              sx={{
                backgroundColor:
                  !navLink.links && location.pathname === navLink.href
                    ? theme.colors.basic[0]
                    : "unset",
                color:
                  !navLink.links && location.pathname === navLink.href
                    ? theme.colors.primary[0]
                    : theme.colors.basic[0],
                ":hover": {
                  backgroundColor:
                    location.pathname === navLink.href
                      ? theme.colors.basic[0]
                      : "rgba(255, 255, 255, 0.05)",
                  opacity: location.pathname === navLink.href ? 0.9 : "unset",
                },
              }}
            >
              {navLink.label}
            </Accordion.Control>
            {!!navLink.links ? (
              <Accordion.Panel>
                {navLink.links.map((item, index) => (
                  <Flex key={`${item}${index}`} direction="column">
                    {!!item.links ? (
                      <>
                        <Flex
                          p="16px 24px 16px 48px"
                          bg="rgba(255, 255, 255, 0.05)"
                          style={{
                            borderTop: "2px solid",
                            borderColor: theme.colors.primary[0],
                          }}
                        >
                          {item.name}
                        </Flex>
                        {item.links.map((subItem, index) => (
                          <Link
                            key={`${subItem.name}${index}`}
                            to={!!subItem?.href ? subItem?.href : "/"}
                            style={{
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              props.close();
                            }}
                          >
                            <Flex
                              direction="column"
                              p="16px 24px 16px 6px"
                              sx={{
                                borderTop: "2px solid",
                                borderColor: theme.colors.primary[0],
                                backgroundColor:
                                  location.pathname === subItem.href
                                    ? theme.colors.basic[0]
                                    : "unset",
                                color:
                                  location.pathname === subItem.href
                                    ? theme.colors.primary[0]
                                    : theme.colors.basic[0],
                                transition: "all 0.5s ease",

                                ":hover": {
                                  backgroundColor:
                                    location.pathname === subItem.href
                                      ? theme.colors.basic[0]
                                      : "rgba(255, 255, 255, 0.05)",
                                  opacity:
                                    location.pathname === subItem.href
                                      ? 0.9
                                      : "unset",
                                },
                              }}
                            >
                              <li>{subItem.name}</li>
                            </Flex>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <Link
                        to={!!item?.href ? item?.href : "/"}
                        style={{
                          textDecoration: "unset",
                        }}
                        onClick={() => {
                          props.close();
                        }}
                      >
                        <Flex
                          p="16px 24px 16px 48px"
                          sx={{
                            borderTop: "2px solid",
                            borderColor: theme.colors.primary[0],
                            backgroundColor:
                              location.pathname === item.href
                                ? theme.colors.basic[0]
                                : "unset",
                            color:
                              location.pathname === item.href
                                ? theme.colors.primary[0]
                                : theme.colors.basic[0],
                            transition: "all 0.5s ease",
                            ":hover": {
                              backgroundColor:
                                location.pathname === item.href
                                  ? theme.colors.basic[0]
                                  : "rgba(255, 255, 255, 0.05)",
                              opacity:
                                location.pathname === item.href ? 0.9 : "unset",
                            },
                          }}
                        >
                          {item.name}
                        </Flex>
                      </Link>
                    )}
                  </Flex>
                ))}
              </Accordion.Panel>
            ) : null}
          </>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default AccordionMenu;

import { Button, Drawer, Flex, Text, useMantineTheme } from "@mantine/core";

interface IProps {
  opened: boolean;
  close: any;
  selected: string;
}

const PurchaseDetails: React.FC<IProps> = ({ opened, close, selected }) => {
  const theme = useMantineTheme();

  return (
    <Flex w="100%" direction="column">
      <Drawer
        opened={opened}
        onClose={close}
        title={`Detalhes ${
          selected === "COMPRA" ? "da" : "do"
        } ${selected.toLowerCase()}`}
        position="right"
        size={659}
        overlayProps={{ opacity: 0.5 }}
        styles={{
          content: {
            background: theme.colors.other[0],
          },
          header: {
            padding: "48px 48px 32px",
            gap: "24px",
            justifyContent: "start",
            flexDirection: "row-reverse",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
          },
          close: {
            height: "40px",
            width: "40px",
            ":hover": {
              background: "#F0F0F0",
            },
            svg: {
              height: "20px",
              width: "20px",
            },
          },
          title: {
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "155%",
          },
          body: {
            margin: "32px 48px 48px",
            padding: 0,
          },
        }}
      >
        <Flex id="flexCardsDrawerEdit" direction={"column"} gap={{ base: 2 }}>
          <Flex
            gap={{ base: 10 }}
            direction={"column"}
            bg="basic.0"
            px={{ base: 32 }}
            py={{ base: 30 }}
            sx={{
              borderRadius: "12px",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Flex justify="space-between">
              <Text color="basic.4" fz={{ base: 16 }} lh={{ base: "155%" }}>
                Código {selected === "COMPRA" ? "da " : "do "}
                {selected.toLowerCase()}
              </Text>
              <Text
                fz={{ base: 16 }}
                fw={{ base: 700 }}
                lh={{ base: "155%" }}
                c={"basic.5"}
              >
                95682648521599325
              </Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="basic.4" fz={{ base: 16 }} lh={{ base: "155%" }}>
                Data da{selected === "COMPRA" ? "solicitação" : "compra"}
              </Text>
              <Text
                fz={{ base: 16 }}
                fw={{ base: 700 }}
                lh={{ base: "155%" }}
                c={"basic.5"}
              >
                13/02/2024 - 11:36
              </Text>
            </Flex>
          </Flex>

          <Flex
            gap={{ base: 10 }}
            direction={"column"}
            bg="basic.0"
            px={{ base: 32 }}
            py={{ base: 30 }}
            sx={{
              borderRadius: "12px",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Flex justify="space-between">
              <Text color="basic.4" fz={{ base: 16 }} lh={{ base: "155%" }}>
                Código {selected === "COMPRA" ? "da " : "do "}
                {selected.toLowerCase()}
              </Text>
              <Text
                fz={{ base: 16 }}
                fw={{ base: 700 }}
                lh={{ base: "155%" }}
                c={"basic.5"}
              >
                95682648521599325
              </Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="basic.4" fz={{ base: 16 }} lh={{ base: "155%" }}>
                Data da{selected === "COMPRA" ? "solicitação" : "compra"}
              </Text>
              <Text
                fz={{ base: 16 }}
                fw={{ base: 700 }}
                lh={{ base: "155%" }}
                c={"basic.5"}
              >
                13/02/2024 - 11:36
              </Text>
            </Flex>
          </Flex>

          <Button mt={{ base: 32 }} variant="danger">
            {selected === "COMPRA"
              ? "Solicitar cancelamento da compra "
              : "Cancelar solicitação"}
          </Button>
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default PurchaseDetails;

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ICity } from "../interfaces/ICity";
import { apiIBGE } from "../api/api";

const getCity = async (ufID: string): Promise<ICity[]> => {
  const resp = await apiIBGE.get(
    `/v1/localidades/estados/${ufID}/municipios?orderBy=nome`
  );
  return resp.data;
};

export const useCity = (ufID: string): UseQueryResult<ICity[]> => {
  return useQuery(["city", ufID], () => getCity(ufID), { enabled: !!ufID });
};

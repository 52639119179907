import {
  Drawer as DrawerMantine,
  Flex,
  Text,
  useMantineTheme,
} from "@mantine/core";

interface IProps {
  opened: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  childrenFooter?: React.ReactNode;
  heightBody: string;
}

const Drawer = ({
  opened,
  onClose,
  title,
  children,
  childrenFooter,
  heightBody,
}: IProps) => {
  const theme = useMantineTheme();

  return (
    <DrawerMantine.Root
      opened={opened}
      onClose={onClose}
      position="right"
      size={659}
    >
      <DrawerMantine.Overlay />
      <DrawerMantine.Content style={{ background: "#FAFBFE" }}>
        <DrawerMantine.Header
          p="48px"
          style={{
            gap: "24px",
            alignItems: "center",
            justifyContent: "start",
            boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            background: "white",
          }}
        >
          <DrawerMantine.CloseButton
            style={{ margin: "0", width: "40px", height: "40px" }}
          />
          <Text fs={"24px"} fw={600} color={theme.colors.basic[5]}>
            {title}
          </Text>
        </DrawerMantine.Header>
        <DrawerMantine.Body
          style={{
            background: "#FAFBFE",
            minHeight: heightBody,
          }}
        >
          {children}
        </DrawerMantine.Body>
        {childrenFooter ? (
          <Flex
            p={48}
            pos={"sticky"}
            bottom={0}
            style={{
              height: "fit-content",

              gap: "24px",
              alignItems: "center",
              justifyContent: "start",
              background: "transparent",
            }}
          >
            {childrenFooter}
          </Flex>
        ) : null}
      </DrawerMantine.Content>
    </DrawerMantine.Root>
  );
};

export default Drawer;

import React from "react";
import {
  PasswordInput as PasswordInputMantine,
  PasswordInputProps,
} from "@mantine/core";
import { Lock } from "iconsax-react";

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  return (
    <PasswordInputMantine
      withAsterisk
      icon={<Lock size="17" color="#2F2F2F" />}
      visibilityToggleIcon={({ reveal }) =>
        reveal ? <p>Ocultar senha</p> : <p>Mostrar senha</p>
      }
      {...props}
    />
  );
};
export default PasswordInput;

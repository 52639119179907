import { Flex, FlexProps, Title, useMantineTheme } from "@mantine/core";

interface IProps extends FlexProps {
  subtitle: string;
  title: string;
  alingTitle?: string | undefined;
}

const DescriptionCarousel: React.FC<IProps> = ({
  title,
  subtitle,
  alingTitle,
  ...props
}) => {
  const theme = useMantineTheme();

  return (
    <Flex p="0" direction={"column"} {...props}>
      <Title
        order={2}
        id="SubTitle Carrousel"
        fw={400}
        fz={{ base: 16, 991: 24 }}
        lh={{ base: "24px", 991: "50px" }}
        color={theme.colors.basic[4]}
      >
        {subtitle}
      </Title>
      <Title
        order={1}
        id="Title Carrousel"
        fw={700}
        fz={{ base: 24, 991: 48 }}
        lh={{ base: "40px", 991: "64px" }}
        color={theme.colors.basic[5]}
      >
        {title}
      </Title>
    </Flex>
  );
};

export default DescriptionCarousel;

import { Box, Flex, Image, Text } from "@mantine/core";
import BoxItens from "./BoxItens";
import { convertCurrency } from "../../../../utils/currency";

const dataGraphicLeft = [
  {
    name: "PETR4",
    ordens: 4000,
  },
  {
    name: "WING23",
    ordens: 3000,
  },
];

const GraphicDonutsDahsboardRigth: React.FC = () => {
  return (
    <Flex
      id="29568"
      w="100%"
      maw={{ base: 396 }}
      direction="column"
      p={{ base: 32 }}
      gap={{ base: 32 }}
      bg="basic.0"
      sx={{
        boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
        borderRadius: "12px",
      }}
    >
      <Text
        fz={{ base: 20 }}
        fw={{ base: 600 }}
        lh={{ base: "32px" }}
        color="basic.5"
      >
        Registros copiados
      </Text>
      <Box w={"100%"} h={220}>
        <Image src="https://www.amcharts.com/wp-content/uploads/2020/08/demo_15817_none-2-1024x690.png" />
      </Box>
      {dataGraphicLeft.map((item: any, index: number) => (
        <BoxItens
          text={convertCurrency(item?.ordens)}
          ordens={item?.ordens * 0.1}
          textBox={item?.name}
          key={index}
        />
      ))}
    </Flex>
  );
};

export default GraphicDonutsDahsboardRigth;

import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { useMediaQuery } from "@mantine/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Modal as ModalMantine,
  Button,
  Flex,
  TextInput,
  Input,
  InputBase,
  Anchor,
  Grid,
  Text,
} from "@mantine/core";
import HeaderModal from "./HeaderModal";
import InputWrapper from "../../Form/Input";
import { IFormModalBrokers } from "./type";
import { IProductItem } from "../../../interfaces/IListProducts";
import Modal from "../ModalResponse";
import CreateMessage from "./CreateMessage";
import { postEmail } from "../../../hooks/useEmail";
import { Modal as ModalType } from "../../../interfaces/CommonInterfces";
import { validationModalRegister } from "../../../utils/validation";
import Checkbox from "../../Form/Checkbox";
import Select from "../../Form/Select";

interface IProps {
  item: IProductItem;
  open: boolean;
  setClose: () => void;
}

const ModalRegister: React.FC<IProps> = (props) => {
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const contentModal: ModalType = !!props?.item?.product?.modal
    ? props.item.product.modal
    : {
        name: true,
        phone: true,
        email: true,
        cpf: false,
        account: false,
        broker_list: [],
        term_of_contract: "/termo-de-uso",
        commercial_conditions: false,
        habilit_account: false,
      };

  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 2,
  }).format;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormModalBrokers>({
    mode: "onChange",
    resolver: yupResolver(validationModalRegister(contentModal)),
  });

  const { mutate: mutateEmail } = useMutation(postEmail, {
    onSuccess: (success) => {
      const redirect = () => {
        window.open(props?.item?.product?.email?.redirect_url);
      };

      setModalSuccess(true);

      if (!!props?.item?.plan.price && props?.item?.plan.price > 0) {
        reset();
        props.setClose();
        redirect();
      } else {
        reset();
        props.setClose();
        setOpenModal(!openModal);
      }
    },
    onError: (error) => {
      setSendLoading(false);
      setModalSuccess(false);
      setOpenModal(!openModal);
      console.error(error);
    },
  });

  const onSubmit = async (data: IFormModalBrokers) => {
    try {
      setSendLoading(true);
      const dataMensage = await CreateMessage({
        dataProduct: props.item,
        dataForm: data,
      });

      mutateEmail(dataMensage);
    } catch (err) {
      setSendLoading(false);
      setModalSuccess(false);
      setOpenModal(!openModal);
    }
  };

  const htmlResponseModal = `<div>
      <p>A liberação dos acessos dependem da validação e confirmação dos dados.</p>
      <br /> 
      <p>Os acessos poderão ser liberados em até 24 horas.</p>
      <br /> 
      <p>Restou alguma dúvida? <a href="https://chat.movidesk.com/ChatWidget/Landing/7A6E5709B4C1431997F4F6B3CB10619E" target="_blank">Clique aqui</a> e fale conosco no chat.</p>
    </div> `;

  return (
    <>
      <Modal
        type={modalSuccess ? "successSpecial" : "danger"}
        titleSeccondButton={"Fechar"}
        description={modalSuccess ? htmlResponseModal : "Erro ao enviar!"}
        open={openModal}
        setClose={() => {
          setOpenModal(!openModal);
          setSendLoading(false);
        }}
        secondClick={() => {
          setOpenModal(!openModal);
          setSendLoading(false);
        }}
      />
      <ModalMantine
        opened={props.open}
        onClose={props.setClose}
        // closeOnClickOutside={false}
        transitionProps={{
          exitDuration: 600,
          transition: "slide-up",
          duration: 600,
          timingFunction: "ease",
        }}
        centered
        size="auto"
        padding={0}
        radius={"12px"}
        withCloseButton={false}
      >
        <Flex
          p={{ base: 24, 991: 48 }}
          maw={{ base: 480, 991: 900 }}
          w="100%"
          style={{ zIndex: -1, borderRadius: "50px" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <HeaderModal
              item={props?.item}
              sendLoading={sendLoading}
              setClose={() => {
                reset();
                setSendLoading(false);
                props.setClose();
              }}
            />

            <Flex
              mt={{ base: 24, 991: 32 }}
              mb={{ base: 32, 991: 48 }}
              p={0}
              justify={{ base: "center", 991: "space-between" }}
              w="100%"
              direction="column"
              gap={{ base: 32, 991: 24 }}
            >
              <Grid grow gutter={24}>
                {contentModal.name ? (
                  <Grid.Col span={isLargerThan991 ? 6 : 12}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <TextInput
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          label="Nome"
                          withAsterisk
                          placeholder="Digite seu nome"
                          error={errors?.name?.message}
                          maw={{ base: "100%", 991: 390 }}
                          w="100%"
                        />
                      )}
                    />
                  </Grid.Col>
                ) : null}
                {contentModal.phone ? (
                  <Grid.Col span={isLargerThan991 ? 6 : 12}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <InputWrapper
                          label="Telefone"
                          withAsterisk
                          error={errors.phone?.message}
                          maw={{ base: "100%", 991: 390 }}
                          w="100%"
                        >
                          <Input<any>
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            component={IMaskInput}
                            mask="(00) 00000-0000"
                            placeholder="(00) 00000-0000"
                            error={errors.phone?.message}
                          />
                        </InputWrapper>
                      )}
                    />
                  </Grid.Col>
                ) : null}
                {contentModal.email ? (
                  <Grid.Col span={isLargerThan991 ? 6 : 12}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <InputWrapper label="E-mail" withAsterisk w="100%">
                          <InputBase
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            placeholder="Digite seu email"
                            error={errors.email?.message}
                          />
                        </InputWrapper>
                      )}
                    />
                  </Grid.Col>
                ) : null}
                {contentModal.cpf ? (
                  <Grid.Col span={isLargerThan991 ? 6 : 12}>
                    <Controller
                      name="cpf"
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <InputWrapper
                          label="CPF"
                          withAsterisk
                          error={errors?.cpf?.message}
                          onChange={onChange}
                          onBlur={onBlur}
                          maw={{ base: "100%", 991: 390 }}
                          w="100%"
                        >
                          <Input<any>
                            value={value}
                            component={IMaskInput}
                            mask="000.000.000-00"
                            placeholder="Digite o CPF"
                            error={errors?.cpf?.message}
                          />
                        </InputWrapper>
                      )}
                    />
                  </Grid.Col>
                ) : null}
                {contentModal.account ? (
                  <Grid.Col span={isLargerThan991 ? 6 : 12}>
                    <Controller
                      name="account"
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <TextInput
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          label="Login TraderEvolution"
                          withAsterisk
                          placeholder="Digite seu login TraderEvolution"
                          error={errors?.account?.message}
                          maw={{ base: "100%", 991: 390 }}
                          w="100%"
                        />
                      )}
                    />
                  </Grid.Col>
                ) : null}
                {!!contentModal.broker_list &&
                contentModal.broker_list.length ? (
                  <Grid.Col span={isLargerThan991 ? 6 : 12}>
                    <Controller
                      name="broker_list"
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <InputWrapper
                          label="Corretora"
                          withAsterisk
                          onBlur={onBlur}
                          error={errors?.broker_list?.message}
                          maw={{ base: "100%", 991: 390 }}
                          w="100%"
                        >
                          <Select
                            value={value}
                            onChange={onChange}
                            error={errors?.broker_list?.message}
                            placeholder="Escolha sua corretora"
                            data={contentModal.broker_list}
                          />
                        </InputWrapper>
                      )}
                    />
                  </Grid.Col>
                ) : null}
              </Grid>
              <Flex direction="column" gap={{ base: 16, 991: 24 }}>
                {!!contentModal.term_of_contract ? (
                  <Controller
                    name="term_of_contract"
                    control={control}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Checkbox
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        error={errors?.term_of_contract?.message}
                        label={
                          <>
                            {isLargerThan991 ? (
                              <Text>
                                Confirmo que li e aceito todos os
                                <Anchor
                                  href={contentModal.term_of_contract}
                                  target="_blank"
                                >
                                  Termos de Contratação
                                </Anchor>
                              </Text>
                            ) : (
                              <Text>
                                Li e aceito todos os
                                <Anchor
                                  href={contentModal.term_of_contract}
                                  target="_blank"
                                >
                                  Termos de Contratação
                                </Anchor>
                              </Text>
                            )}
                          </>
                        }
                      />
                    )}
                  />
                ) : null}
                {contentModal.commercial_conditions ? (
                  <Controller
                    name="commercial_conditions"
                    control={control}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Checkbox
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        error={errors?.commercial_conditions?.message}
                        label={
                          <Text>
                            Estou ciente que as condições comerciais sobre o uso
                            do indicador podem mudar a qualquer tempo sem prévio
                            aviso (gerando apenas o bloqueio do uso)
                          </Text>
                        }
                      />
                    )}
                  />
                ) : null}
                {contentModal.habilit_account ? (
                  <Controller
                    name="habilit_account"
                    control={control}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Checkbox
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        error={errors?.habilit_account?.message}
                        label={
                          <Text>
                            Estou ciente que é necessário ter a
                            <Text component="b" color="primary.1">
                              {" "}
                              TraderEvolution (versão desktop) conta real ou
                              demo{" "}
                            </Text>
                            habilitada para acessar o indicador
                          </Text>
                        }
                      />
                    )}
                  />
                ) : null}
              </Flex>
            </Flex>

            <Flex justify={{ base: "center", 991: "space-between" }} w="100%">
              {isLargerThan991 ? (
                <Button
                  variant="primaryOutline"
                  fullWidth
                  maw={{ base: 348, 991: 276 }}
                  disabled={sendLoading}
                  onClick={() => {
                    reset();
                    setSendLoading(false);
                    props.setClose();
                  }}
                >
                  Fechar
                </Button>
              ) : null}

              <Button
                type="submit"
                variant="primary"
                fullWidth
                maw={{ base: 348, 991: 276 }}
                loading={sendLoading}
                disabled={!isDirty || !isValid}
              >
                {sendLoading
                  ? "Aguarde"
                  : `Prosseguir (${
                      props?.item?.plan?.price === 0
                        ? "Gratuito"
                        : currencyFormatter(
                            !!props?.item?.plan?.price
                              ? props?.item?.plan?.price
                              : 0
                          )
                    })`}
              </Button>
            </Flex>
          </form>
        </Flex>
      </ModalMantine>
    </>
  );
};

export default ModalRegister;

import { Center, Flex, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import DrawerMenu from "./DrawerMenu";
import NavLinks from "./NavLinks";
import { Logo } from "../components";
import { Authentication } from "../pages";
import { useAuth } from "../context/auth";
import AccessInfos from "./AccessInfos";

const NavBar = () => {
  const theme = useMantineTheme();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const isLargerThan1100 = useMediaQuery("(min-width: 1100px)");
  const { userMVP } = useAuth();
  return (
    <Center px={{ base: 24, 630: 60 }} bg={theme.colors.primary[0]}>
      <Flex h="fit-content" direction="column" maw={1680} w="100%">
        <Flex
          w="100%"
          gap={{ base: 16, 1700: 32 }}
          h="fit-content"
          mih={{ base: 44, 991: 38, 1400: 56 }}
          p={{ base: "48px 0 16px", 991: "40px 0 32px" }}
          justify="space-between"
          align="center"
        >
          <Flex
            id="btnNavbar"
            gap={{ base: 16, 1100: 32, 1700: 60 }}
            w="auto"
            align="center"
            style={{ zIndex: 2 }}
          >
            {isLargerThan991 ? (
              <>
                <Logo height={isLargerThan1100 ? 66 : 28} />
                <NavLinks />
              </>
            ) : (
              <>
                <DrawerMenu />
                <Logo height={28} />
              </>
            )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            gap="16px"
            w="fit-content"
          >
            {!!userMVP.email ? <AccessInfos /> : <Authentication />}
          </Flex>
        </Flex>
      </Flex>
    </Center>
  );
};

export default NavBar;

import { SimpleGrid } from "@mantine/core";
import {
  ArrowDown,
  DollarSquare,
  Graph,
  PercentageSquare,
  TrendUp,
} from "iconsax-react";
import ParametersCard from "../../../../components/Cards/ParametersCard";

interface IProps {
  return: string;
  profit: string;
  loss: string;
  position: string;
  order: string;
  tradesProfit: string;
}

const ParametersProduct: React.FC<IProps> = (props) => {
  return (
    <SimpleGrid cols={5} w="100%" spacing={24}>
      <ParametersCard
        title="Retorno no período"
        value1={props.return}
        full={false}
        icon={<DollarSquare size={32} />}
        colorIcon="green"
      />
      <ParametersCard
        title="Fator de lucro"
        value1={props.profit}
        full={false}
        icon={<PercentageSquare size={32} />}
        colorIcon="purple"
      />
      <ParametersCard
        title="Perda máxima"
        value1={props.loss}
        full={false}
        icon={<ArrowDown size={32} />}
        colorIcon="red"
      />
      <ParametersCard
        title="Posições / Ordens"
        value1={props.position}
        value2={props.order}
        full={false}
        icon={<TrendUp size={32} />}
        colorIcon="pink"
      />
      <ParametersCard
        title="Trades com lucro"
        value1={props.tradesProfit}
        full={false}
        icon={<Graph size={32} />}
        colorIcon="blue"
      />
    </SimpleGrid>
  );
};

export default ParametersProduct;

import { Divider } from "@mantine/core";
import {
  ComponentCommonCard,
  ImageCommonCard,
  HeaderCommonCard,
  FooterCard,
} from "../../ComponentsCards";
import { CardCurseProps } from "../../ComponentsCards/typesCards";
import { useNavigate } from "react-router-dom";

type IProps = {
  item: CardCurseProps;
  select?: boolean;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardCurse: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ComponentCommonCard
      onClick={() =>
        navigate(
          !!props?.preSlug
            ? `${props?.preSlug}${props?.item?.slug}`
            : props?.item?.slug
        )
      }
      w={props?.select ? "460px" : "396px"}
      p={props?.select ? 27.8 : 22}
      select={props?.select}
      withBorder
    >
      <ImageCommonCard
        image={props?.item.image}
        title={props?.item.title}
        height={props?.select ? 182 : 157}
        radius={12}
      />

      <HeaderCommonCard
        // item={props.item}
        select={props?.select}
        mt={20}
        mb={20}
      />

      <Divider my={20} />

      <FooterCard text={props?.item.text} />
    </ComponentCommonCard>
  );
};

export default CardCurse;

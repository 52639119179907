import React from "react";
import {
  Button,
  Center,
  Flex,
  Image,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { PhotoPageNotFound } from "../../assets";
import { Link } from "react-router-dom";

const PageNotFound: React.FC = () => {
  const theme = useMantineTheme();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <Flex
      justify="center"
      pos="relative"
      sx={{
        ":before": {
          right: 0,
          top: 0,
          position: "absolute",
          background: theme.colors.primary[0],
          content: "''",
          width: "100vw",
          height: isLargerThan991 ? "220px" : "74px",
          zIndex: -1,
        },
      }}
      px={{ base: 24, 991: 20 }}
      mb={80}
    >
      <Flex
        w="100%"
        maw={1680}
        bg="basic.0"
        align="center"
        justify="center"
        direction={{ base: "column-reverse", 991: "row" }}
        gap={{ base: 32, 991: 48 }}
        mt={{ base: 32, 991: 48 }}
        style={{
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px",
          overflow: "hidden",
        }}
        p={{ base: "40px 16px", 991: "0px 32px", 1300: "0px 80px" }}
      >
        <Flex
          direction="column"
          gap={48}
          align="center"
          justify="center"
          my={{ base: 0, 991: 40 }}
        >
          <Flex direction="column" gap={16}>
            <Flex
              direction="column"
              gap={8}
              miw={{ base: 206, 991: 340 }}
              maw={{ base: 440, 991: 785 }}
            >
              <Text
                data-aos="fade-top"
                data-aos-easing="linear"
                data-aos-duration="500"
                fw={500}
                fz={{ base: 14, 991: 16 }}
                lh={{ base: "22px", 991: "25px" }}
                color="complementary.2"
                ff="Barlow"
              >
                ERRO 404
              </Text>
              <Title
                data-aos="fade-top"
                data-aos-easing="linear"
                data-aos-duration="300"
                w="100%"
                fw={700}
                fz={{ base: 24, 991: 40 }}
                lh={{ base: "40px", 991: "56px" }}
                color="basic.5"
              >
                A página que você está tentando acessar não foi encontrada!
              </Title>
            </Flex>
            <Text
              data-aos="fade-top"
              data-aos-easing="linear"
              data-aos-duration="500"
              fw={400}
              fz={{ base: 16, 991: 20 }}
              lh={{ base: "32px", 991: "40px" }}
              color="basic.4"
            >
              Verifique a URL em questão e tente novamente.
            </Text>
          </Flex>
          <Flex
            w="100%"
            align="center"
            gap="24px"
            direction={{ base: "column-reverse", 991: "row" }}
          >
            <Button
              component={Link}
              to="/marketplace"
              variant="primaryOutline"
              maw={{ base: 348, 991: 276 }}
              w="100%"
            >
              Voltar para Marketplace
            </Button>
            <Button
              component={Link}
              to="/"
              variant="primary"
              maw={{ base: 348, 991: 276 }}
              w="100%"
            >
              Voltar para Home
            </Button>
          </Flex>
        </Flex>
        <Center
          h={{ base: "100%", 991: "100%" }}
          p={{ base: "10px 30px", 991: "10px 30px" }}
        >
          <Image src={PhotoPageNotFound} alt="Assinatura" fit="cover" />
        </Center>
      </Flex>
    </Flex>
  );
};

export default PageNotFound;

import { Button, Center, Flex } from "@mantine/core";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper } from "swiper/react";
import { Swiper as SwiperType, Navigation, Autoplay } from "swiper";
import ButtonsCarousel from "../ButtonsCarousel";
import DescriptionCarousel from "../DescriptionCarousel";
import { useMediaQuery } from "@mantine/hooks";

type Iprops = {
  subtitle: string;
  title: string;
  titleButton: string;
  linkButton: string;
  children: React.ReactNode;
  bg?: string;
  centerCarousel?: boolean;
  h?: number;
  maxWidth: number;
  margin?: string | number;
  marginLeft?: string;
  isLoading?: boolean;
};

const CarouselWithSide: React.FC<Iprops> = (props) => {
  const swiperRef = useRef<SwiperType>();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const isLargerThan1200 = useMediaQuery("(min-width: 1200px)");

  return (
    <Center
      id="CarouselWithSide"
      w="100%"
      h={{ base: "unset", 991: 580 }}
      p={{ base: "48px 0", 991: "45px 0" }}
      bg={!!props.bg ? props.bg : "basic.0"}
    >
      <Flex
        align="center"
        h="fit-content"
        justify="end"
        w="100%"
        maw={1920}
        direction={{ base: "column", 991: "row" }}
        style={{
          position: "relative",
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <Flex
          direction={{ base: "row-reverse", 991: "column" }}
          align={{ base: "start", 991: "end" }}
          justify={{ base: "space-between", 991: "center" }}
          gap="24px"
          p={{
            base: "0 24px",
            630: "0 100px",
            991: "0 20px 0 0px",
            1200: "0 60px",
            1600: "0 120px",
          }}
          miw={{
            base: "100%",
            991: "fit-content",
          }}
          h={{ base: "fit-content", 991: 580 }}
          pos={{
            base: "unset",
            991: props.centerCarousel ? "absolute" : "unset",
          }}
          bg={!!props.bg ? props.bg : "basic.0"}
          style={{
            left: 0,
            zIndex: 2,
            overflow: "hidden",
          }}
        >
          <ButtonsCarousel
            next={() => swiperRef.current?.slideNext()}
            previous={() => swiperRef.current?.slidePrev()}
          />
          <DescriptionCarousel
            subtitle={props.subtitle}
            title={props.title}
            w={{ base: 210, 991: 404 }}
            align={{ base: "start", 991: "end" }}
            ta={{ base: "start", 991: "end" }}
            mt={{ base: 0, 991: 8 }}
          />
          {isLargerThan991 ? (
            <Button
              id="Botão Carrousel"
              variant="primary"
              w={253}
              component={Link}
              to={props.linkButton}
            >
              {props.titleButton}
            </Button>
          ) : null}
        </Flex>
        <Flex
          sx={{
            zIndex: 1,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            ".swiper-slide-active": isLargerThan1200
              ? {
                  maxWidth: `${props.maxWidth}px !important`,
                  width: `${props.maxWidth}px !important`,
                  minWidth: `${props.maxWidth}px !important`,
                }
              : {
                  maxWidth: "fit-content",
                  width: "fit-content",
                  minWidth: "fit-content",
                },
          }}
          ml={
            props.centerCarousel
              ? { base: "20px", 991: 250, 1200: 200, 1600: 20 }
              : { base: 0 }
          }
          w={{ base: "100%", 991: "75vw", 1000: "90vw", 1921: 1744 }}
          align="start"
          mih={{ base: 430, 991: 560 }}
        >
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay]}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={
              props.centerCarousel
                ? {
                    1600: {
                      slidesPerView: 4,
                      spaceBetween: 32,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 32,
                    },
                    991: {
                      slidesPerView: 2,
                      spaceBetween: 32,
                    },
                  }
                : {
                    1920: {
                      slidesPerView: 2.3,
                      spaceBetween: 32,
                      centeredSlides: false,
                    },
                    1720: {
                      slidesPerView: 2,
                      spaceBetween: 32,
                      centeredSlides: false,
                    },
                    1600: {
                      slidesPerView: 1.8,
                      spaceBetween: 32,
                      centeredSlides: false,
                    },
                    1200: {
                      slidesPerView: 1.4,
                      spaceBetween: 32,
                      centeredSlides: false,
                    },
                    991: {
                      slidesPerView: 1.2,
                      spaceBetween: 32,
                      centeredSlides: false,
                    },
                  }
            }
            className="mySwiper"
          >
            {props.children}
          </Swiper>
        </Flex>

        {isLargerThan991 ? null : (
          <Button
            id="Botão Carrousel"
            variant="primary"
            maw={380}
            w="100%"
            component={Link}
            to={props.linkButton}
          >
            {props.titleButton}
          </Button>
        )}
      </Flex>
    </Center>
  );
};

export default CarouselWithSide;

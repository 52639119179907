import { Divider, Flex, Text, Title } from "@mantine/core";
import {
  ComponentCommonCard,
  ImageCommonCard,
  HeaderCommonCard,
  FooterCard,
} from "../../ComponentsCards";
import { CardGraficProps } from "../../ComponentsCards/typesCards";
import { useNavigate } from "react-router-dom";

type IProps = {
  item: CardGraficProps;
  select?: boolean;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardGrafic: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ComponentCommonCard
      onClick={() =>
        navigate(
          !!props?.preSlug
            ? `${props?.preSlug}${props?.item?.slug}`
            : props?.item?.slug,
          { preventScrollReset: false }
        )
      }
      maw={props?.select ? 460 : 396}
      h={props?.select ? 520 : 448}
      p={props?.select ? 27.8 : 22}
      select={props?.select}
      withBorder
    >
      <ImageCommonCard
        image={props?.item.image}
        title={props?.item.title}
        height={props?.select ? 182 : 157}
        radius={12}
        select={props?.select}
      />

      <HeaderCommonCard
        // item={props.item}
        select={props?.select}
        mt={props?.select ? 25 : 20}
        mb={props?.select ? 25 : 20}
      />

      <Flex
        gap={props?.select ? "19.8px" : "17px"}
        h={props?.select ? "72.6px" : "63px"}
        style={{
          transition: "all 0.5s linear",
        }}
      >
        <Flex justify="center" direction="column" w="144px">
          <Text
            fw={700}
            size={props?.select ? 18.59 : 16}
            lh={props?.select ? "28.8px" : "24.8px"}
            style={{
              transition: "all 0.5s linear",
            }}
            color="basic.4"
          >
            {props?.item.return}
          </Text>
          <Title
            ff="Barlow"
            fw={500}
            size={props?.select ? 16.26 : 14}
            lh={props?.select ? "25.2px" : "21.7px"}
            style={{
              transition: "all 0.5s linear",
            }}
            color="basic.3"
          >
            Retorno no período
          </Title>
        </Flex>
        <Flex justify="center" direction="column" w="144px">
          <Text
            fw={700}
            size={props?.select ? 18.59 : 16}
            lh={props?.select ? "28.8px" : "24.8px"}
            style={{
              transition: "all 0.5s linear",
            }}
            color="basic.4"
          >
            {props?.item.market}
          </Text>
          <Title
            ff="Barlow"
            fw={500}
            size={props?.select ? 16.26 : 14}
            lh={props?.select ? "25.2px" : "21.7px"}
            style={{
              transition: "opacity 0.5s linear",
            }}
            color="basic.3"
          >
            Mercado
          </Title>
        </Flex>
      </Flex>

      <Divider
        my={props?.select ? 25 : 20}
        style={{
          transition: "all 0.5s linear",
        }}
      />

      <FooterCard
        price={props?.item.price}
        points={props?.item.points}
        select={props?.select}
      />
    </ComponentCommonCard>
  );
};

export default CardGrafic;

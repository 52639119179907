import { Button, Center, Flex, Text } from "@mantine/core";
import ProfileInformation from "./ProfileInformation/Index";
import Financial from "./Financial/Index";
import Password from "./Password/Index";
import { useCallback, useEffect, useState } from "react";
import { ArrowRight2 } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";

const contentButtons = [
  {
    id: "profile",
    component: <ProfileInformation />,
    title: "Perfil",
    text: "Visualize e edite informações de perfil",
    path: "/meu-perfil/configuracoes",
  },
  {
    id: "financial",
    component: <Financial />,
    title: "Financeiro",
    text: "Acesse carteira e histórico de transações",
    path: "/meu-perfil/financeiro",
  },
  {
    id: "password",
    component: <Password />,
    title: "Senha",
    text: "Altere informações de segurança",
    path: "/meu-perfil/senha",
  },
];

const Profile: React.FC = () => {
  const [comp, setComp] = useState<any>(<ProfileInformation />);
  const [type, setType] = useState<any>("profile");
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const handleRedirect = useCallback(
    (btn: any) => {
      setType(btn?.id);
      setComp(btn?.component);
      navigate(btn?.path);
    },
    [navigate]
  );

  useEffect(() => {
    if (path.includes("meu-perfil/configuracoes")) {
      setComp(<ProfileInformation />);
      setType("profile");
    } else if (path.includes("meu-perfil/financeiro")) {
      setComp(<Financial />);
      setType("financial");
    }
  }, [location]);

  return (
    <Center h="100%" bg={"other.0"}>
      <Flex w="100%" maw={1680} mx={{ base: 24, 630: 60 }}>
        <Flex
          // direction={{ base: "column", 992: "row" }}
          justify="center"
          align="center"
          w="100%"
          gap={74}
        >
          <Flex
            direction="column"
            maw={396}
            //miw={{ base: 396 }}
            h="100%"
            id="options"
          >
            <Text
              mt={33}
              c="primary.2"
              lh={{ base: "155%" }}
              fz={{ base: 28 }}
              fw={{ base: 700 }}
            >
              Configurações
            </Text>
            <Text
              mt={12}
              mb={48}
              c="basic.4"
              lh={{ base: "155%" }}
              fz={{ base: 20 }}
            >
              Informações de cadastro da sua conta Trader evolution.
            </Text>

            <Flex
              id="flexButtonsEditProfile"
              mb="auto"
              gap={16}
              direction="column"
            >
              {contentButtons?.map((btn: any) => (
                <Button
                  key={btn?.id}
                  onClick={() => {
                    handleRedirect(btn);
                  }}
                  fullWidth
                  bg={btn.id === type ? "primaryOpacity.2" : ""}
                  sx={{
                    border: btn.id === type ? "1px solid #1B4FDF" : "",
                  }}
                  rightIcon={<ArrowRight2 size={28} />}
                  variant="login"
                  h={88}
                >
                  {btn?.title}
                  <Text
                    display={{ base: "none", 445: "block" }}
                    component="span"
                  >
                    {btn?.text}
                  </Text>
                </Button>
              ))}
              <Button fullWidth variant="danger" mt={32} mb={40}>
                Excluir conta
              </Button>
            </Flex>
          </Flex>
          {comp}
        </Flex>
      </Flex>
    </Center>
  );
};

export default Profile;

import {
  Avatar,
  Flex,
  NavLink,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { Profile } from "iconsax-react";
import { Link } from "react-router-dom";

interface IProps {
  avatar?: string;
  name: string;
  link?: string;
  title: string;
  subtitle?: string;
  transparent?: boolean;
  backgroundName?: string;
  colorWhite?: boolean | false;
}

const HeaderItem: React.FC<IProps> = (props) => {
  const theme = useMantineTheme();

  return (
    <Flex gap="24px" align="center" mih="54px">
      {/* <Avatar
        component="span"
        variant="outline"
        size={80}
        radius={8}
        src={props.avatar}
        alt="Nome do parceiro"
        bg={props.colorWhite ? theme.colors.basic[0] : ""}
        sx={{
          border: "1px solid",
          borderColor: theme.colors.basic[2],
          padding: "18px",
          img: {
            objectFit: "contain",
          },
        }}
      >
        <Profile color={theme.colors.basic[5]} size={50} />
      </Avatar> */}
      <Flex
        justify="space-between"
        direction="column"
        h="fit-content"
        gap={props.link ? "unset" : "4px"}
      >
        {props.link ? (
          <NavLink
            tt="uppercase"
            label={props.name}
            component={Link}
            to={props.link}
            styles={{
              root: {
                background: theme.colors.basic[1],
                width: "fit-content",
                padding: "4px 8px",
                borderRadius: "8px",
                color: theme.colors.basic[4],
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18.6px",
                transition: "all 0.3s ease",
                span: {
                  fontSize: "12px",
                  lineHeight: "18.6px",
                },
                "&:hover": {
                  background: theme.colors.basic[2],
                  color: theme.colors.basic[5],
                  opacity: 0.8,
                },
              },
            }}
          />
        ) : (
          <Text
            bg={props?.backgroundName}
            tt="uppercase"
            fw={700}
            size={12}
            lh="25px"
            color="basic.4"
            style={{
              padding: "4px 8px",
              width: "fit-content",
              borderRadius: "8px",
            }}
          >
            {props.name}
          </Text>
        )}
        <Flex align={"start"} wrap="wrap" direction={"column"} mt="6px">
          <Title
            tt="uppercase"
            fw={700}
            fz={32}
            lh="32px  "
            color={
              props.colorWhite ? theme.colors.basic[0] : theme.colors.basic[5]
            }
            mr="5px"
          >
            {props.title}
          </Title>
          {!!props.subtitle ? (
            <Text
              span
              fz={16}
              lh="22px"
              color={
                props.colorWhite ? theme.colors.basic[0] : theme.colors.basic[5]
              }
              fw={400}
              w="fit-content"
              style={{ whiteSpace: "nowrap" }}
            >
              {props.subtitle}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HeaderItem;

import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Flex } from "@mantine/core";
import { PasswordInput, PasswordRequirements } from "../../../../components";
import { IFormChangePassword } from "./type";
import { ValidationChangePassword } from "./schema-validation";

interface IPropsChangePassord {
  nextStep: () => void;
  setSuccessChanged: Function;
  SetOpenModal: Function;
}

const ChangePassword: React.FC<IPropsChangePassord> = ({
  nextStep,
  setSuccessChanged,
  SetOpenModal,
}) => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormChangePassword>({
    mode: "onBlur",
    resolver: yupResolver(ValidationChangePassword),
  });

  const onSubmit = (data: IFormChangePassword) => {
    nextStep();
    setSuccessChanged("success");
    SetOpenModal(true);
  };

  return (
    <>
      <Flex gap={24} mb={24} direction={{ base: "column", md: "row" }}>
        <Controller
          name="password"
          control={control}
          render={({ field: { onBlur, onChange, value } }) => (
            <PasswordInput
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              w="100%"
              label="Senha"
              placeholder="Digite sua senha"
              error={errors.password?.message}
            />
          )}
        />
        <Controller
          name="secondPassword"
          control={control}
          render={({ field: { onBlur, onChange, value } }) => (
            <PasswordInput
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              w="100%"
              label="Senha"
              placeholder="Confirme sua senha"
              error={errors.secondPassword?.message}
            />
          )}
        />
      </Flex>
      <PasswordRequirements
        passwordOne={watch("password")}
        passwordTwo={watch("secondPassword")}
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        variant="primary"
        fullWidth
        mt={26}
        disabled={!isDirty || !isValid}
      >
        Validar código
      </Button>
    </>
  );
};

export default ChangePassword;

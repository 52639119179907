import React from "react";
import { Flex, NavLink, useMantineTheme } from "@mantine/core";
import { Location, Sms } from "iconsax-react";
import { useMediaQuery } from "@mantine/hooks";

const ContactFooter: React.FC = () => {
  const theme = useMantineTheme();
  const isLargerThan400 = useMediaQuery("(min-width: 400px)");

  const Dot = () => {
    return (
      <Flex
        w={6}
        h={6}
        bg="basic.0"
        sx={{ borderRadius: "100%", content: "''" }}
      />
    );
  };

  return (
    <Flex
      id="contactFooter"
      w="100%"
      align="start"
      maw={{ base: 560, 991: 320, 1152: 400 }}
      direction="column"
      gap={12}
      sx={{
        fontFamily: "Barlow",
        fontWeight: 500,
        lineHeight: "28px",
        whiteSpace: "nowrap",
        color: theme.colors.basic[0],
      }}
    >
      <Flex align="center" gap={16}>
        <Location size={24} style={{ minWidth: "24px" }} />
        <Flex
          sx={{ fontFamily: "Barlow", fontWeight: 500, lineHeight: "28px" }}
          columnGap={12}
          align="center"
          wrap={"wrap"}
          miw={180}
        >
          <Flex align="center" gap={12}>
            Av. Cidade Jardim, 400
            <Dot />
          </Flex>
          <Flex align="center" gap={12}>
            4º andar
            <Dot />
          </Flex>
          <Flex align="center" gap={12}>
            Jardim Europa
            <Dot />
          </Flex>
          São Paulo/SP
        </Flex>
      </Flex>
      <Flex
        gap={24}
        direction={{ base: "row", 796: "column", 1260: "row" }}
        wrap={{ base: "wrap" }}
        justify={{ base: "start", 670: "start" }}
      >
        <NavLink
          label="atendimento@traderevolution.com.br"
          c="#fff"
          icon={<Sms size={24} />}
          component="a"
          href="https://atendimento.traderevolution.com.br/form/3983/"
          target="_blank"
          p="0"
          sx={{
            span: {
              marginRight: "16px",
              whiteSpace: isLargerThan400 ? "nowrap" : "pre-wrap",
              wordBreak: isLargerThan400 ? "unset" : "break-all",
            },
          }}
        />
        <Flex align="center" gap={16}>
          {/*  <Call size={24} />
          (+55) 11 99999-999 */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContactFooter;

import React, { useEffect } from "react";
import MantineProvider from "./styles/MantineProvider";
import { AuthProvider } from "./context/auth";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./constants/queryClient";
import { NotificationControlProvider } from "./context/notificationContext";
import "swiper/css";
import Aos from "aos";
import Router from "./router/Router";

const App: React.FC = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <MantineProvider>
            <NotificationControlProvider>
              <Router />
            </NotificationControlProvider>
          </MantineProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;

export const DATA_DASHBOARD = {
  desktop: true,
  mobile: true,
  web: true,
  windows: true,
  apple: true,
  android: true,
  appleStore: true,
  chrome: false,
  safari: false,
  opera: false,
  edge: false,
  firefox: false,
};

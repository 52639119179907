import { Skeleton as SkeletonMantine, SkeletonProps } from "@mantine/core";

interface IProps extends SkeletonProps {
  repeat: number;
}

const Skeleton: React.FC<IProps> = ({ repeat, ...props }) => {
  const lista = [];
  for (let i = 0; i < repeat; i++) {
    lista.push(i);
  }

  return (
    <>
      {lista?.map((index: number) => (
        <SkeletonMantine
          id="skeleton"
          key={index}
          {...props}
          visible={true}
          radius="md"
        />
      ))}
    </>
  );
};

export default Skeleton;

import * as Yup from "yup";
import { validatePHONE } from "./PHONEvalidation";
import { validateCPF } from "./CPFvalidation";

const name = Yup.string().required("Informe seu nome");
const phone = Yup.string()
  .min(15, "Informe o telefone corretamente")
  .required("Informe seu telefone")
  .test("phone", "Telefone inválido", (value) => validatePHONE(value ?? ""));
const email = Yup.string()
  .email("Informe e-mail válido")
  .required("Informe o e-mail");
const cpf = Yup.string()
  .required("CPF inválido")
  .test("cpf", "CPF inválido", (value) => validateCPF(value ?? ""));
const account = Yup.string().required("Informe seu login TraderEvolution");
const broker_list = Yup.string().required("Informe sua Corretora");
const term_of_contract = Yup.boolean().oneOf([true], "Aceite para continuar");
const commercial_conditions = Yup.boolean().oneOf(
  [true],
  "Aceite para continuar"
);
const habilit_account = Yup.boolean().oneOf([true], "Aceite para continuar");
const required = Yup.string().required("Campo obrigatório");
const requiredNumber = Yup.number()
  .required("Campo obrigatório")
  .moreThan(0, "Campo obrigatório");
const notRequired = Yup.string().notRequired();
const notRequiredBoolean = Yup.boolean().notRequired();
const commonPassword = Yup.string()
  .required("Campo obrigatório")
  .min(8, "Senha deve possuir 8 digitos");
const password = Yup.string()
  .required("Campo obrigatório")
  .min(8, "Senha deve possuir 8 digitos")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z 0-9])(?=.{8,})/,
    "A senha deve possuir todos os requisitos"
  );
const secondPassword = Yup.string()
  .required("Campo obrigatório")
  .oneOf([Yup.ref("password")], "Senhas diferentes");

const arrayOfObject = (value: Yup.ObjectShape) =>
  Yup.array().of(Yup.object().shape(value));

type IsRequiredIfProps = {
  nameValidation: string | string[];
  resultValidation: boolean;
};

const stringIsRequiredIf = ({
  nameValidation,
  resultValidation,
}: IsRequiredIfProps) =>
  Yup.string().when(nameValidation, {
    is: resultValidation,
    then: (value) => value.required("Campo obrigatório"),
    otherwise: (value) => value.notRequired(),
  });

const numberIsRequiredIf = ({
  nameValidation,
  resultValidation,
}: IsRequiredIfProps) =>
  Yup.number().when(nameValidation, {
    is: resultValidation,
    then: (value) =>
      value.moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
    otherwise: (value) => value.notRequired(),
  });

const booleanIsRequiredIf = ({
  nameValidation,
  resultValidation,
}: IsRequiredIfProps) =>
  Yup.boolean().when(nameValidation, {
    is: resultValidation,
    then: (value) => value.required("Campo obrigatório"),
    otherwise: (value) => value.notRequired(),
  });

const dateIsRequiredIf = ({
  nameValidation,
  resultValidation,
}: IsRequiredIfProps) =>
  Yup.date().when(nameValidation, {
    is: resultValidation,
    then: (value) => value.required("Campo obrigatório"),
    otherwise: (value) => value.notRequired(),
  });

export {
  name,
  phone,
  email,
  cpf,
  account,
  broker_list,
  term_of_contract,
  commercial_conditions,
  habilit_account,
  required,
  requiredNumber,
  notRequired,
  notRequiredBoolean,
  password,
  commonPassword,
  secondPassword,
  arrayOfObject,
  stringIsRequiredIf,
  numberIsRequiredIf,
  booleanIsRequiredIf,
  dateIsRequiredIf,
};

import { Flex, useMantineTheme } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { FOOTER_LINKS } from "../../constants/links";

type IProps = {
  close: () => void;
};

const FooterLinks: React.FC<IProps> = (props) => {
  const location = useLocation();
  const theme = useMantineTheme();

  return (
    <Flex gap="8px" direction="column">
      {FOOTER_LINKS.map((item, index) => (
        <Link
          key={index}
          to={!!item?.href ? item.href : "/"}
          style={{
            textDecoration: "none",
          }}
          onClick={() => {
            props.close();
          }}
        >
          <Flex
            key={`${item.label}${index}`}
            direction="column"
            p="16px 24px"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              fontFamily: "Barlow",
              backgroundColor:
                location.pathname === item.href
                  ? theme.colors.basic[0]
                  : "unset",
              color:
                location.pathname === item.href
                  ? theme.colors.primary[0]
                  : theme.colors.basic[0],
              transition: "all 0.5s ease",

              ":hover": {
                backgroundColor:
                  location.pathname === item.href
                    ? theme.colors.basic[0]
                    : "rgba(255, 255, 255, 0.05)",
                opacity: location.pathname === item.href ? 0.9 : "unset",
              },
            }}
          >
            {item.label}
          </Flex>
        </Link>
      ))}
    </Flex>
  );
};

export default FooterLinks;

import { Flex, FlexProps, Text } from "@mantine/core";

interface IProps extends FlexProps {
  children: React.ReactNode;
  title: string;
  number: string;
}

const SectionsForm: React.FC<IProps> = ({
  children,
  title,
  number,
  ...props
}) => {
  return (
    <Flex gap="32px" direction={{ base: "column", 991: "row" }} w={"100%"}>
      <Flex
        direction={{ base: "row", 991: "column" }}
        gap={{ base: "24px", 991: "4px" }}
        maw="253px"
        w="100%"
      >
        <Text fw={400} c="basic.5" fz={20} lh="31px">
          {number}
        </Text>
        <Text
          fw={600}
          c="basic.5"
          fz={20}
          lh="31px"
          maw={{ base: "100%", 991: "152px" }}
        >
          {title}
        </Text>
      </Flex>
      <Flex
        w="100%"
        gap="32px"
        p="48px"
        bg="basic.0"
        direction="column"
        style={{
          borderRadius: "12px",
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
        }}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default SectionsForm;

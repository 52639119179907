import React from "react";
import { Flex } from "@mantine/core";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import NavLinkMatrix from "../NavLinkMatrix";
import { FOOTER_LINKS, FOOTER_LINKS_TERMS } from "../../constants/links";

const NavlinksFooter: React.FC = () => {
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const { scrollIntoView } = useScrollIntoView({ duration: 0 });

  return (
    <Flex
      direction={{ base: "column" }}
      rowGap={{ base: 24, 450: 12, 991: 12 }}
      columnGap={{
        base: "unset",
        450: 20,
        1120: "50px !important",
      }}
      w="100%"
      miw={{ base: 190, 450: 380, 796: 190, 991: "380px !important" }}
      maw={{
        base: "unset",
        450: 560,
        796: "fit-content",
        991: "550px !important ",
      }}
      h={{ base: "unset", 450: 130, 796: "unset", 991: "130px !important" }}
      wrap={{
        base: "nowrap",
        450: "wrap",
        //670: "revert",
        991: "wrap !important",
      }}
      sx={{ alignContent: "space-between" }}
    >
      {FOOTER_LINKS_TERMS.map((item, index) => (
        <Flex
          key={`navlinkFooter${index}`}
          w={"100%"}
          maw={{ base: "fit-content", 450: 190 }}
          mb={{
            base: "unset",
            991: index === 1 ? 50 : "unset",
          }}
          sx={{
            flex: index < 2 ? 1 : 2,
            order: isLargerThan991 ? "unset" : index < 2 ? 2 : 1,
          }}
        >
          <NavLinkMatrix
            onClick={() => scrollIntoView({ alignment: "start" })}
            label={item.label}
            to={item.href ? item.href : "/"}
            h={{ base: 44, 450: 34 }}
          />
        </Flex>
      ))}

      {FOOTER_LINKS.map((item, index) => (
        <Flex
          key={`navlinkFooter${index}`}
          w={"100%"}
          maw={{ base: "fit-content", 450: 190 }}
        >
          <NavLinkMatrix
            target="_blank"
            label={item.label}
            to={item.href ? item.href : "/"}
            h={{ base: 44, 450: 34 }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default NavlinksFooter;

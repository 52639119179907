import React from "react";
import { Text, Loader, Modal as ModalMantine } from "@mantine/core";

interface IProps {
  open: boolean;
}

const ModalLoading: React.FC<IProps> = ({ open }) => {
  return (
    <ModalMantine
      onClose={() => {}}
      opened={open}
      transitionProps={{
        exitDuration: 600,
        transition: "slide-up",
        duration: 600,
        timingFunction: "ease",
      }}
      closeOnClickOutside={false}
      centered={true}
      styles={{
        content: {
          background: "#ECF3FE",
          borderRadius: " 12px",
        },
        header: {
          marginBottom: "0",
          background: "transparent",
          height: "129px",
        },
        close: {
          display: "none",
        },
        body: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "50px 50px 12px 12px",
          padding: "0 40px 0 40px",
        },
      }}
    >
      <Loader size={60} mt="-100px" />
      <Text fw={600} fz="24px" lh="48xp" mt="60px">
        Validando compra
      </Text>
      <Text fw={600} fz="16px" lh="48px" color="basic.4" mb="32px">
        Aguarde alguns instantes...
      </Text>
    </ModalMantine>
  );
};

export default ModalLoading;

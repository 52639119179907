import { useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { Pagination, Table, TagCapsules } from "../../../../components";
import { Clock, CloseCircle, InfoCircle, TickCircle } from "iconsax-react";
import { convertCurrencyBR } from "../../../../utils/currency";
import { DATA_TABLE } from "./DATA";
import { useDisclosure } from "@mantine/hooks";
import PurchaseDatails from "../PurchaseDatails";

const TableEdit: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [idcol, setIdCol] = useState("");
  const theme = useMantineTheme();
  const qtd = 6;
  const total = 12021;
  const [activePage, setPage] = useState(1);

  const rows = DATA_TABLE.map((element: any, index: number) => (
    <tr key={index}>
      <td>{element?.date}</td>
      <td>
        <Flex align="center">
          <TagCapsules
            mr={12}
            border
            colorTag={
              element?.moving === "COMPRA"
                ? "orange"
                : element?.moving === "SAQUE"
                ? "purple"
                : "blue"
            }
          >
            {element?.moving}
          </TagCapsules>
          {!!element.status ? (
            <Tooltip
              label={
                element?.status === "declined"
                  ? "Saque recusado"
                  : element?.status === "approved"
                  ? "Saque aprovado"
                  : "Aguardando aprovação"
              }
              color="dark"
              position="right"
              withArrow
            >
              {element?.status === "declined" ? (
                <CloseCircle size="24" color="#F43333" variant="Bold" />
              ) : element?.status === "approved" ? (
                <TickCircle size="24" color="#23BD32ed" variant="Bold" />
              ) : (
                <Clock size="24" color="#FB6815" variant="Bold" />
              )}
            </Tooltip>
          ) : null}
        </Flex>
      </td>
      <td>
        {!!element?.typePoduct ? (
          <Box>
            <Text
              tt="uppercase"
              fz={{ base: 12 }}
              fw={{ base: 500 }}
              lh={{ base: "155%" }}
              c={"basic.4"}
            >
              {element?.typePoduct}
            </Text>
            <Text
              fz={{ base: 14 }}
              fw={{ base: 500 }}
              lh={{ base: "32px" }}
              c={"basic.5"}
            >
              {element?.product}
            </Text>
          </Box>
        ) : (
          "---"
        )}
      </td>
      <td>{element?.pay}</td>
      <td>
        {element?.value === null ? "---" : convertCurrencyBR(element?.value)}
      </td>
      <td>
        {element?.point}
        {element?.point !== null ? " pts" : "---"}
      </td>
      <td
        style={{
          height: "100%",
          padding: 0,
        }}
      >
        <Button
          variant="transparentEspecial"
          fullWidth
          onClick={() => {
            setIdCol(element?.moving);
            open();
          }}
        >
          <InfoCircle size="24" color="#B3B1B1" variant="Bold" />
        </Button>
      </td>
    </tr>
  ));

  const colsTable = (
    <tr>
      <th style={{ width: "32px" }}>Data</th>
      <th>Movimentação</th>
      <th>Produto</th>
      <th>Pagamento</th>
      <th>Valor</th>
      <th>Pontuação</th>
      <th>
        <Center>
          <Tooltip label="Detalhes" color="dark" withArrow>
            <InfoCircle size="24" color="#2f2f2f" variant="Bold" />
          </Tooltip>
        </Center>
      </th>
    </tr>
  );

  return (
    <Flex w="100%" direction="column">
      <PurchaseDatails opened={opened} close={close} selected={idcol} />

      <Table cols={colsTable} rows={rows} sx={{ borderRadius: "15px" }} />

      <Flex
        bg={theme.colors.basic[0]}
        justify="space-between"
        align="center"
        mb={32}
        py={{ base: 34 }}
        px={{ base: 32 }}
        sx={{
          borderRadius: "0 0 12px 12px",
          border: "1.5px solid",
          borderTop: "none",
          borderColor: theme.colors.basic[2],
        }}
      >
        <Text>
          Mostrando
          <Text component="b">
            {" "}
            {!!qtd ? qtd : "0"} {!total ? "" : "de"}{" "}
            {total?.toLocaleString("pt-BR")}
          </Text>{" "}
          registros
        </Text>
        <Pagination
          total={15}
          value={activePage}
          onChange={setPage}
          siblings={0}
        />
      </Flex>
    </Flex>
  );
};

export default TableEdit;

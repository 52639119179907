import { Icon } from "iconsax-react";
import {
  IconBTG,
  IconCLEAR,
  IconGENIAL,
  IconRICO,
  IconXP,
  LogoBTG,
  LogoCLEAR,
  LogoGENIAL,
  LogoGUIDE,
  LogoMODAL,
  LogoMYCAP,
  LogoRICO,
  LogoTERRA,
  LogoTORO,
  LogoXP,
} from "../assets";

export const DataChampionshioClients = [
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Nome do campeonato",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Campeonato do Trader",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Nome do campeonato",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Campeonato do Trader",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Nome do campeonato",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Campeonato do Trader",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Nome do campeonato",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Campeonato do Trader",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Nome do campeonato",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Campeonato do Trader",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Nome do campeonato",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
  {
    image: "",
    avatar: null,
    name: "Nome do parceiro",
    title: "Campeonato do Trader",
    link: "/cursos",
    initialDate: new Date().toISOString(),
    finalDate: new Date().toISOString(),
    awarded: "Top 3",
    participant: 100,
    participantTotal: 1000,
    contract: 10,
    award: "1.000,00",
    price: 15,
    activeInit: "win",
    activeFinal: "wdo",
    badgeColor: "green",
  },
];

export const DATA_BROKERS = [
  {
    name: "Genial Investimentos",
    logo: { Location: LogoGENIAL },
    icon: { Location: IconGENIAL },
  },
  {
    name: "BTG Investimentos",
    logo: { Location: LogoBTG },
    icon: { Location: IconBTG },
  },
  {
    name: "Clear Corretora",
    logo: { Location: LogoCLEAR },
    icon: { Location: IconCLEAR },
  },
  {
    name: "XP Investimentos",
    logo: { Location: LogoXP },
    icon: { Location: IconXP },
  },
  {
    name: "Rico",
    logo: { Location: LogoRICO },
    icon: { Location: IconRICO },
  },
];

export const Todos = {
  name: "Todos",
  logo: { Location: "" },
  icon: { Location: "" },
};

export const DATA_SWIPER_CHAMPIONSHIP = [
  {
    name: "Todos",
    logo: { Location: "" },
    icon: { Location: "" },
  },
  {
    name: "Genial Investimentos",
    logo: { Location: LogoGENIAL },
    icon: { Location: IconGENIAL },
  },
  {
    name: "BTG Investimentos",
    logo: { Location: LogoBTG },
    icon: { Location: IconBTG },
  },
  {
    name: "Clear Corretora",
    logo: { Location: LogoCLEAR },
    icon: { Location: IconCLEAR },
  },
  {
    name: "XP Investimentos",
    logo: { Location: LogoXP },
    icon: { Location: IconXP },
  },
  {
    name: "Rico",
    logo: { Location: LogoRICO },
    icon: { Location: IconRICO },
  },
];

export const DATA_SWIPER_GENIAL = [
  {
    name: "Todos",
    logo: { Location: "" },
    icon: { Location: "" },
  },
  {
    name: "Genial Investimentos",
    logo: { Location: LogoGENIAL },
    icon: { Location: IconGENIAL },
  },
];

import React, { useEffect, useState } from "react";
import { Center, Flex, Text, useMantineTheme } from "@mantine/core";
import { SwiperSlide } from "swiper/react";
import {
  CarouselWithSide,
  HeaderCards,
  CardHighlightAlgorithm,
  CardCommonPlatform,
  CardCommonAlgorithm,
  SkeletonCarousel,
  CarouselPartner,
  CardHighlightGrafic,
  CardCommonGrafic,
} from "../../components";
import { useMediaQuery } from "@mantine/hooks";
import {
  useAlgorithms,
  useAlgorithmsEmphasis,
  usePlatforms,
} from "../../hooks";
import { IProductItem } from "../../interfaces/IListProducts";
import BigHeader from "../../Layout/BigHeader";
import { DATA_SWIPER_CHAMPIONSHIP } from "../../constants/dataChampionship";
import { useAuth } from "../../context/auth";
import { DATA_CARD_GRAFIC } from "../../constants/cards";
import CardHighlightGraficMVP from "../../components/Cards/HighlightCards/CardHighlightGraficMVP";
import CardCommonGraficMVP from "../../components/Cards/CommonCards/CardCommonGraficMVP";

const Marketplace = () => {
  const { role } = useAuth();
  const [partner, setPartner] = useState<string>("Todos");

  const { data: algorithms, isLoading: isLoadingAlgorithms } =
    useAlgorithms(true);
  const [algorithmsSlides, setAlgorithmsSlides] = useState<
    IProductItem[] | undefined
  >(algorithms);

  const { data: algorithmsEmphasis, isLoading: isLoadingEmphasis } =
    useAlgorithmsEmphasis(true, true);

  const { data: platforms, isLoading: isLoadingPlatforms } = usePlatforms(true);
  const [platformsSlides, setPlatformsSlides] = useState<
    IProductItem[] | undefined
  >(platforms);

  const theme = useMantineTheme();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  useEffect(() => {
    if (!!algorithms && algorithms?.length < 8) {
      const newArray = [
        ...algorithms,
        ...algorithms,
        ...algorithms,
        ...algorithms,
        ...algorithms,
        ...algorithms,
        ...algorithms,
        ...algorithms,
      ];

      setAlgorithmsSlides(newArray);
    } else {
      setAlgorithmsSlides(algorithms);
    }

    if (!!platforms && platforms?.length < 8) {
      const newArray = [
        ...platforms,
        ...platforms,
        ...platforms,
        ...platforms,
        ...platforms,
        ...platforms,
      ];

      setPlatformsSlides(newArray);
    } else {
      setPlatformsSlides(platforms);
    }
  }, [algorithms, platforms]);

  return (
    <BigHeader title={"Destaques"} hasOverlay={true}>
      {/* <CarouselPartner
        title="Organização"
        data={DATA_SWIPER_CHAMPIONSHIP}
        setPartner={setPartner}
        partner={partner}
        isLoading={false}
      /> */}
      <HeaderCards isLoading={false}>
        {DATA_CARD_GRAFIC?.map((item, index: number) =>
          index < 3 ? (
            <Center w="100%" key={index}>
              <CardHighlightGraficMVP
                item={item}
                preSlug="/marketplace/copyorder/"
              />
            </Center>
          ) : null
        )}
      </HeaderCards>

      <Flex py="80px" gap="80px" direction={"column"} w="100%">
        {false ? (
          <SkeletonCarousel />
        ) : !!DATA_CARD_GRAFIC ? (
          <CarouselWithSide
            subtitle={"Conheça nossos"}
            title={"CopyOrder"}
            titleButton={"Acessar CopyOrder"}
            linkButton={"/marketplace/copyorder"}
            bg={theme.colors.other[0]}
            maxWidth={460}
            centerCarousel={true}
          >
            {DATA_CARD_GRAFIC?.map((item, index: number) => (
              <SwiperSlide
                key={index}
                style={{
                  maxWidth: isLargerThan991 ? "unset" : "fit-content",
                  width: isLargerThan991 ? "unset" : "fit-content",
                  minWidth: isLargerThan991 ? "unset" : "fit-content",
                  display: isLargerThan991 ? "unset" : "flex",
                  alignItems: isLargerThan991 ? "unset" : "center",
                  justifyContent: isLargerThan991 ? "unset" : "center",
                  height: isLargerThan991 ? "unset" : "560px",
                }}
              >
                {({ isActive }) => (
                  <Center
                    mx={{ base: 8, 991: 0 }}
                    maw={{ base: 313, 991: "unset" }}
                    mah={{
                      base: 560,
                      991: 580,
                    }}
                    h="100%"
                  >
                    <CardCommonGraficMVP
                      item={item}
                      select={
                        !!isLoadingAlgorithms && !!isActive ? true : false
                      }
                      isLoading={isLoadingAlgorithms}
                      preSlug="/marketplace/copyorder/"
                    />
                  </Center>
                )}
              </SwiperSlide>
            ))}
          </CarouselWithSide>
        ) : null}

        {false ? (
          <SkeletonCarousel />
        ) : !!role && role === "Analista" ? (
          <CarouselWithSide
            subtitle={"Conheça nossas"}
            title={"SmartCopy"}
            titleButton={"Acessar SmartCopy"}
            linkButton={"/marketplace/smartcopy"}
            bg={theme.colors.basic[0]}
            maxWidth={460}
            centerCarousel={true}
          >
            {DATA_CARD_GRAFIC?.map((item, index: number) => (
              <SwiperSlide
                key={index}
                style={{
                  maxWidth: isLargerThan991 ? "unset" : "fit-content",
                  width: isLargerThan991 ? "unset" : "fit-content",
                  minWidth: isLargerThan991 ? "unset" : "fit-content",
                  display: isLargerThan991 ? "unset" : "flex",
                  alignItems: isLargerThan991 ? "unset" : "center",
                  justifyContent: isLargerThan991 ? "unset" : "center",
                  height: isLargerThan991 ? "unset" : "560px",
                }}
              >
                {({ isActive }) => (
                  <Center
                    mx={{ base: 8, 991: 0 }}
                    maw={{ base: 313, 991: "unset" }}
                    mah={{
                      base: 560,
                      991: 580,
                    }}
                    h="100%"
                  >
                    <CardCommonGraficMVP
                      item={item}
                      select={!!isLoadingPlatforms && !!isActive ? true : false}
                      isLoading={isLoadingPlatforms}
                      carrousel={true}
                      preSlug="/marketplace/smartcopy/"
                    />
                  </Center>
                )}
              </SwiperSlide>
            ))}
          </CarouselWithSide>
        ) : null}
      </Flex>
    </BigHeader>
  );
};

export default Marketplace;

import axios from "axios";

export const getPublicIPAdrress = async () => {
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    // console.log(res.data.IPv4, "preparou");
    return res.data.IPv4;
  };

  return await getData().then((data) => {
    // console.log(data, "return");
    return data;
  });
};

import React, { useState } from "react";
import {
  Accordion as AccordionMantine,
  Divider,
  Flex,
  Title,
} from "@mantine/core";
import { AddCircle, Minus } from "iconsax-react";

type Data = {
  id: string;
  title: string;
  content: string;
};

interface IPropsAccordion {
  data: Data[];
  firstOpen?: boolean;
  title?: string;
}

const Accordion: React.FC<IPropsAccordion> = (props) => {
  const [value, setValue] = useState<string | null>(
    props.firstOpen ? props.data[0].id : null
  );

  return (
    <Flex direction="column" gap="32px">
      {props.title ? (
        <Title fw={700} size={24} lh={"32px"} color="basic.5">
          {props.title}
        </Title>
      ) : null}
      <AccordionMantine
        variant="separated"
        defaultValue={props.firstOpen ? props.data[0].id : null}
        // transitionDuration={500}
        onChange={setValue}
        value={value}
        w="100%"
        styles={() => ({
          chevron: {
            display: "none",
          },
          item: {
            border: "1px solid #FAFBFE",
            background: "#FFFFFF",
            "&[data-active]": {
              color: "#6B6B6B",
              background: "#FAFBFE",
            },
          },
          control: {
            fontSize: "16px",
            padding: "14px 12px",
          },
          label: {
            fontWeight: 500,
            marginLeft: "6px",
          },
          content: {
            padding: "0 12px 0 23px ",
          },
        })}
      >
        {props.data.map((id: Data, index: number) => (
          <AccordionMantine.Item key={index} value={id.id}>
            <AccordionMantine.Control
              icon={
                value === id.id ? (
                  <Minus
                    size="24"
                    variant="Outline"
                    color="#1b4fdf"
                    style={{
                      border: "2px solid #1B4FDF",
                      borderRadius: "50px",
                    }}
                  />
                ) : (
                  <AddCircle
                    size="24"
                    variant="Bold"
                    color="#1B4FDF"
                    style={{
                      border: "2px solid #1B4FDF",
                      borderRadius: "50px",
                    }}
                  />
                )
              }
            >
              {id.title}
            </AccordionMantine.Control>
            <AccordionMantine.Panel>
              <Flex mb="24px">
                <Divider
                  orientation="vertical"
                  size="md"
                  mr="24px"
                  color="#1B4FDF"
                />
                {id.content}
              </Flex>
            </AccordionMantine.Panel>
          </AccordionMantine.Item>
        ))}
      </AccordionMantine>
    </Flex>
  );
};

export default Accordion;

import * as Yup from "yup";
import { Modal } from "../../../interfaces/CommonInterfces";
import {
  account,
  broker_list,
  commercial_conditions,
  cpf,
  email,
  habilit_account,
  name,
  notRequired,
  phone,
  term_of_contract,
} from "../YupSchema";

export const validationModalRegister = (modal: Modal) => {
  return Yup.object().shape({
    name: !!modal?.name ? name : notRequired,
    phone: !!modal?.phone ? phone : notRequired,
    email: !!modal?.email ? email : notRequired,
    cpf: !!modal?.cpf ? cpf : notRequired,
    account: !!modal?.account ? account : notRequired,
    broker_list:
      !!modal?.broker_list && !!modal?.broker_list.length
        ? broker_list
        : notRequired,
    term_of_contract: !!modal?.term_of_contract
      ? term_of_contract
      : notRequired,
    commercial_conditions: !!modal?.commercial_conditions
      ? commercial_conditions
      : notRequired,
    habilit_account: !!modal?.habilit_account ? habilit_account : notRequired,
  });
};

import React from "react";
import {
  NavLink as NavLinkMantine,
  useMantineTheme,
  NavLinkProps,
} from "@mantine/core";
import { NavLink as Link, useLocation } from "react-router-dom";

interface IProps extends NavLinkProps {
  to: string;
  target?: string;
  onClick?: any;
}

const NavLinkMatrix: React.FC<IProps> = ({ onClick, to, target, ...props }) => {
  const theme = useMantineTheme();
  const location = useLocation();
  return (
    <NavLinkMantine
      {...props}
      onClick={onClick}
      component={Link}
      to={to}
      target={target}
      active={to === location.pathname}
      styles={{
        root: {
          width: "max-content",
          height: "22px",
          padding: "0",
          margin: "0",
          display: "inline-block",
          fontFamily: "Barlow",
          fontWeight: 400,
          whiteSpace: "nowrap",
          backgroundColor: "unset",
          color: theme.colors.basic[0],
          transition: "0.5s",
          borderBottom: "2px solid transparent",
          cursor: "pointer",

          span: {
            fontSize: "18px",
            lineHeight: "28px",
            fontFamily: "Barlow",
            whiteSpace: "pre-wrap",
          },

          ":after": {
            display: "block",
            content: "''",
            borderBottom: "2.5px solid",
            borderColor: theme.colors.primary[2],
            transform: "scaleX(0)",
            transition: "transform 500ms ease-in-out",
            transformOrigin: "0% 10%",
          },

          ":hover": {
            fontWeight: 700,

            "&:after": {
              transform: "scaleX(1)",
            },
          },

          "&[data-active]": {
            backgroundColor: "unset",
            color: theme.colors.basic[0],
            transition: "0.5s",
            fontWeight: 700,

            ":after": {
              display: "block",
              content: "''",
              borderBottom: "2.5px solid",
              borderColor: theme.colors.primary[2],
              transform: "scaleX(1)",
              transition: "transform 500ms ease-in-out",
              transformOrigin: "0% 10%",
            },

            ":hover": {
              backgroundColor: "unset",
              color: theme.colors.basic[0],
              opacity: 0.95,
              fontWeight: 700,
            },
          },
        },
      }}
    />
  );
};

export default NavLinkMatrix;

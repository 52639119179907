import * as Yup from "yup";

export const ValidationChangePassword = Yup.object().shape({
  password: Yup.string()
    .required("Campo obrigatório")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z 0-9])(?=.{8,})/,
      "A senha deve possuir todos os requisitos"
    ),
  secondPassword: Yup.string()
    .required("Campo obrigatório")
    .oneOf([Yup.ref("password")], "Senhas diferentes"),
});

import React from "react";
import {
  Divider,
  Flex,
  Image,
  NavLink,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import { ArrowUp2 } from "iconsax-react";
import { LogoCria } from "../../assets";

const Footer: React.FC = () => {
  const theme = useMantineTheme();
  const { scrollIntoView } = useScrollIntoView();
  const isLargerThan550 = useMediaQuery("(min-width: 550px)");

  const BackToTop = () => {
    return (
      <NavLink
        onClick={() => scrollIntoView({ alignment: "start" })}
        h={34}
        styles={{
          root: {
            width: "max-content",
            height: "22px",
            padding: "0",
            margin: "0",
            display: "inline-block",
            fontFamily: "Barlow",
            fontWeight: 400,
            whiteSpace: "nowrap",
            backgroundColor: "unset",
            color: theme.colors.basic[0],
            transition: "0.5s",
            borderBottom: "2px solid transparent",
            cursor: "pointer",

            span: {
              fontSize: "18px",
              lineHeight: "28px",
              fontFamily: "Barlow",
              whiteSpace: "nowrap",
            },

            ":after": {
              display: "block",
              content: "''",
              borderBottom: "2.5px solid",
              borderColor: theme.colors.primary[2],
              transform: "scaleX(0)",
              transition: "transform 500ms ease-in-out",
              transformOrigin: "0% 10%",
            },

            ":hover": {
              fontWeight: 700,
              "&:after": {
                transform: "scaleX(1)",
              },
            },
          },
        }}
        rightSection={
          <>
            <Text pr={16}>Voltar ao topo</Text>
            <ArrowUp2 size={32} />
          </>
        }
      />
    );
  };

  const DevelopCria = () => {
    return (
      <Flex
        align="center"
        direction={{ base: "column", 991: "row" }}
        sx={{
          whiteSpace: "nowrap",
        }}
        gap={{ base: 4, 991: 12 }}
      >
        Idealizado por:
        <NavLink
          component="a"
          href="https://cria.digital/"
          target="_blank"
          p="0"
          rightSection={
            <Image
              src={LogoCria}
              alt="logo cria"
              sx={{
                root: {
                  cursor: "pointer",
                  transition: "0.3s",
                  ":hover": {
                    opacity: "0.9",
                    transition: "0.3s",
                  },
                },
              }}
            />
          }
        />
      </Flex>
    );
  };

  return (
    <Flex
      pb={{ base: 14, 991: 48 }}
      align="center"
      justify="space-between"
      direction="column"
      gap={{ base: "16px", 991: "32px" }}
      maw={1680}
      mah="fit-content"
      w="100%"
      sx={{
        fontFamily: "Barlow",
        fontWeight: 400,
        lineHeight: "28px",
        color: theme.colors.basic[0],
      }}
    >
      {isLargerThan550 ? (
        <>
          <Divider color={theme.colors.other[1]} w="100%" />
          <Flex w="100%" align="center" justify="space-between">
            <Flex w={{ base: "148px", 991: "auto" }}>
              Todos os direitos reservados.
            </Flex>
            <BackToTop />
            {/* <DevelopCria /> */}
          </Flex>
        </>
      ) : (
        <>
          <Flex w="100%" align="end" justify="space-between" gap="32px">
            <Flex w="148px">Todos os direitos reservados.</Flex>
            {/* <DevelopCria /> */}
          </Flex>
          <Divider color={theme.colors.other[1]} w="100%" />
          <BackToTop />
        </>
      )}
    </Flex>
  );
};

export default Footer;

import React from "react";
import { Badge as BadgeSelection, BadgeProps, Button } from "@mantine/core";
import { CloseCircle } from "iconsax-react";

interface IPropsSelection extends BadgeProps {
  contain?: string;
  textDisabled?: string;
  onClick?: () => void;
}

const TagSelection: React.FC<IPropsSelection> = ({
  contain,
  textDisabled,
  onClick,
  ...props
}) => {
  return (
    <BadgeSelection
      {...props}
      styles={{
        root: {
          width: "fit-content",
          height: "36px",
          borderRadius: "20px",
          padding: "8px 12px",
          background: !!contain ? "#ECF3FE" : "#F0F0F0",
          color: !!contain ? "#1B4FDF" : "#B3B1B1",
          fontWeight: !!contain ? 600 : 500,
        },
        inner: {
          width: "fit-content",
        },
        rightSection: {
          display: !!contain ? "block" : "none",
        },
      }}
      rightSection={
        <Button
          bg={"transparent"}
          p={0}
          sx={{
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          style={{ border: "none" }}
          type="button"
          onClick={onClick}
        >
          <CloseCircle size="16" color="#f43333" variant="Bold" />
        </Button>
      }
    >
      {contain ? contain : textDisabled}
    </BadgeSelection>
  );
};

export default TagSelection;

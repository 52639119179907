import { Box, Center, Flex, Text } from "@mantine/core";
import { StatusUp, TrendDown } from "iconsax-react";

interface IProps {
  data: any;
}

const CardsDashboard: React.FC<IProps> = ({ data }) => {
  return (
    <Flex gap={{ base: 33 }} mt={{ base: 38 }}>
      {data.map((card: any, index: number) => (
        <Box
          w="100%"
          key={index}
          bg="basic.0"
          p={{ base: 32 }}
          sx={{
            borderRadius: "12px",
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Flex w="100%" justify="space-between" align="center">
            <Box>
              <Text
                fz={{ base: 18 }}
                fw={{ base: 700 }}
                lh={{ base: "155%" }}
                c="basic.5"
              >
                {card?.info}
              </Text>
              <Text
                mt={{ base: 4 }}
                ff="Barlow"
                fz={{ base: 16 }}
                fw={{ base: 500 }}
                lh={{ base: "155%" }}
                c="other.5"
              >
                {card?.text}
              </Text>
            </Box>
            <Center
              p={{ base: 8 }}
              bg={card?.bgColor}
              sx={{ borderRadius: "8px" }}
            >
              {card?.icon}
            </Center>
          </Flex>
          {!!card?.textFooter || !!card?.incomes ? (
            <Flex
              mt={15}
              pt={15}
              align={"center"}
              justify={"space-between"}
              sx={{ borderTop: "1px solid #D7D7D7" }}
            >
              {!!card?.incomes ? (
                <Flex align={"center"} gap={10}>
                  <Center>
                    {card?.incomes > 0 ? (
                      <StatusUp size="20" color="#23BD32" />
                    ) : (
                      <TrendDown size="20" color="#F43333" />
                    )}
                  </Center>
                  <Text
                    ff={"Barlow"}
                    fz={{ base: 16 }}
                    fw={{ base: 500 }}
                    lh={{ base: "155%" }}
                    color={card?.incomes > 0 ? "alerts.2" : "alerts.0"}
                  >
                    {`${card?.incomes > 0 ? "+" : ""} 
                 ${(card?.incomes).toFixed(2)} %`}
                  </Text>
                </Flex>
              ) : null}

              {!!card?.textFooter ? (
                <Text
                  mt={{ base: 4 }}
                  ff="Barlow"
                  fz={{ base: 16 }}
                  fw={{ base: 500 }}
                  lh={{ base: "155%" }}
                  c="other.5"
                >
                  {card?.textFooter}
                </Text>
              ) : null}
            </Flex>
          ) : null}
        </Box>
      ))}
    </Flex>
  );
};

export default CardsDashboard;

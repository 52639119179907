import {
  Avatar,
  Flex,
  Modal,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { Profile } from "iconsax-react";
import TagStatus from "../../Tags/TagStatus";
import { useMediaQuery } from "@mantine/hooks";
import DeskMobWeb from "../../DeskMobWeb";
import { IProductItem } from "../../../interfaces/IListProducts";

type IProps = {
  item: IProductItem;
  setClose: () => void;
  sendLoading: boolean;
};

const HeaderModal: React.FC<IProps> = (props) => {
  const theme = useMantineTheme();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <Flex
      bg={theme.colors.primaryOpacity[1]}
      style={{
        borderRadius: "12px",
      }}
      p={{ base: 12, 991: 16 }}
    >
      <Flex direction="column" gap={12} w="100%">
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          h={{ base: 44, 991: 54 }}
        >
          <Flex gap={{ base: 8, 991: 16 }} align="center">
            <Avatar
              variant="outline"
              size={48}
              color="basic.2"
              radius={8}
              src={props?.item?.organization?.icon?.Location}
              alt="Nome do parceiro"
              style={{ transition: "all 0.5s linear" }}
              styles={{
                root: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #D7D7D7",
                  background: theme.colors.basic[0],
                },
                image: { height: "38px", width: "38px" },
              }}
            >
              <Profile color={theme.colors.basic[5]} />
            </Avatar>
            <Flex gap="2px" justify="space-between" direction="column" h="100%">
              <Text
                transform="uppercase"
                fw={500}
                size={12}
                lh={"19px"}
                color="basic.4"
                ff="Barlow"
                lineClamp={1}
                style={{
                  wordBreak: "break-all",
                  transition: "all 0.5s linear",
                }}
              >
                {!!props?.item?.organization?.name
                  ? props?.item?.organization?.name
                  : null}
              </Text>
              <Title
                transform="uppercase"
                fw={700}
                size={16}
                lh={"25px"}
                color="basic.5"
                lineClamp={1}
                style={{
                  wordBreak: "break-all",
                  transition: "all 0.5s linear",
                }}
              >
                {!!props?.item?.product?.title
                  ? props?.item?.product?.title
                  : null}
              </Title>
            </Flex>
          </Flex>

          <Flex w="fit-content" gap={8} h="100%" miw="fit-content">
            {props?.item?.product?.is_demo ? (
              <TagStatus
                style={{ border: "1px solid", borderRadius: "4px" }}
                status={"orange"}
                title={
                  isLargerThan991 && !props?.item?.product?.is_real
                    ? "versão demo"
                    : "demo"
                }
              />
            ) : null}
            {props?.item?.product?.is_real ? (
              <TagStatus
                style={{ border: "1px solid", borderRadius: "4px" }}
                status={"success"}
                title={
                  isLargerThan991 && !props?.item?.product?.is_demo
                    ? "versão real"
                    : "real"
                }
              />
            ) : null}
            {isLargerThan991 ? null : (
              <Modal.CloseButton
                title="Fechar Modal"
                onClick={props.setClose}
                size={28}
                bg={theme.colors.basic[0]}
                p="4px"
                styles={{ root: { borderRadius: "4px" } }}
                disabled={props.sendLoading}
              />
            )}
          </Flex>
        </Flex>
        {props?.item?.product?.category === "Platforms" &&
        (props?.item?.product?.desktop ||
          props?.item?.product?.mobile ||
          props?.item?.product?.web) ? (
          <DeskMobWeb
            desktop={props?.item?.product?.desktop}
            mobile={props?.item?.product?.mobile}
            web={props?.item?.product?.web}
            select={false}
          />
        ) : null}
      </Flex>
    </Flex>
  );
};

export default HeaderModal;

import React from "react";
import { Flex } from "@mantine/core";
import Skeleton from "../Skeleton";

type IProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
};

const HeaderCards: React.FC<IProps> = (props) => {
  return (
    <Flex
      w="100%"
      h="100%"
      gap="32px"
      pb={{ base: 30, 991: 48 }}
      sx={{
        zIndex: 1,
        overflowY: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {props.isLoading ? (
        <Flex w="100%" gap={32}>
          <Skeleton repeat={3} w="100%" maw={524} miw={313} h={524} />
        </Flex>
      ) : !!props.children ? (
        props.children
      ) : null}
    </Flex>
  );
};

export default HeaderCards;

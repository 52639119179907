import { Flex, Text, Title } from "@mantine/core";

import {
  CardCommonAlgorithm,
  CardCommonBroker,
  CardCommonCamp,
  CardCommonCurse,
  CardCommonGrafic,
  CardCommonPlatform,
  CardCommonProduct,
  CardHighlightAlgorithm,
  CardHighlightCamp,
  CardHighlightGrafic,
  CardLeague,
  CardMainLeague,
} from "../../components";
import {
  DATA_CARD_CAMP,
  DATA_CARD_CURSE,
  DATA_CARD_GRAFIC,
  DATA_CARD_LEAGUES,
  DATA_CARD_PRODUCT,
} from "../../constants/cards";
import {
  DATA_CARD_ALGORITHMS,
  DATA_CARD_PLATFORM,
} from "../../constants/cardsAPI";
import { DATA_CARD_BROKERS } from "../../constants/cardsAPI";
import { DATA_BROKERS } from "../../constants/dataChampionship";

const CardsTeste: React.FC = () => {
  return (
    <Flex direction={"column"} align="center">
      <Title>CARDS COMUNS</Title>
      <Flex direction="column" bg="white" gap="20px" align="center">
        <Text>cardCommon de graficos</Text>
        <Flex gap="20px" wrap="wrap" justify="center">
          <CardCommonGrafic item={DATA_CARD_GRAFIC[0]} />
          <CardCommonGrafic item={DATA_CARD_GRAFIC[1]} />
        </Flex>

        <Text>cardCommon de cursos</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonCurse item={DATA_CARD_CURSE[0]} />
          <CardCommonCurse item={DATA_CARD_CURSE[1]} />
        </Flex>

        <Text>cardCommon de Algorithm</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonAlgorithm item={DATA_CARD_ALGORITHMS[0]} />
          <CardCommonAlgorithm item={DATA_CARD_ALGORITHMS[1]} />
        </Flex>

        <Text>cardCommon de plataformas</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonPlatform item={DATA_CARD_PLATFORM[0]} />
          <CardCommonPlatform item={DATA_CARD_PLATFORM[1]} />
        </Flex>
        <Text>cardCommon de brokers</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonBroker item={DATA_CARD_BROKERS[0]} />
          <CardCommonBroker item={DATA_CARD_BROKERS[1]} />
        </Flex>

        <Text>cardCommon de campeonato</Text>
        <Flex gap="20px" wrap="wrap" justify="center">
          <CardCommonCamp item={DATA_CARD_CAMP[0]} />
          <CardCommonCamp item={DATA_CARD_CAMP[1]} />
        </Flex>

        <Text>cardCommon de produtos</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonProduct item={DATA_CARD_PRODUCT[0]} />
          <CardCommonProduct item={DATA_CARD_PRODUCT[1]} />
        </Flex>
      </Flex>

      <Title>CARDS SELECIONADOS</Title>
      <Flex direction="column" bg="white" gap="20px" justify="center">
        <Text>cardCommon de graficos</Text>
        <Flex gap="20px" wrap="wrap">
          <CardCommonGrafic select={true} item={DATA_CARD_GRAFIC[0]} />
          <CardCommonGrafic select={true} item={DATA_CARD_GRAFIC[1]} />
        </Flex>

        <Text>cardCommon de plataforma</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonAlgorithm select={true} item={DATA_CARD_ALGORITHMS[0]} />
          <CardCommonAlgorithm select={true} item={DATA_CARD_ALGORITHMS[0]} />
        </Flex>

        <Text>cardCommon de produtos</Text>
        <Flex gap="20px" wrap="wrap" align="center">
          <CardCommonProduct select={true} item={DATA_CARD_PRODUCT[0]} />
          <CardCommonProduct select={true} item={DATA_CARD_PRODUCT[1]} />
        </Flex>
      </Flex>

      <Title>CARDS highLigts</Title>
      <Flex direction="column" bg="white" gap="20px" align="center">
        <Text>cardHighlight de róbos</Text>
        <Flex gap="20px" wrap="wrap">
          <CardHighlightAlgorithm item={DATA_CARD_PLATFORM[0]} slug={true} />
        </Flex>
        <Text>cardHighlight de plataforma</Text>
        <Flex gap="20px" wrap="wrap">
          <CardHighlightAlgorithm item={DATA_CARD_PLATFORM[0]} slug={true} />
        </Flex>
        <Text>cardHighlight de copytrading</Text>
        <Flex gap="20px" wrap="wrap">
          <CardHighlightGrafic
            item={{
              image:
                "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
              avatar:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80",
              name: "Nome do parceiro",
              title: "Nome do copytrading",
              return: "101,33%",
              market: "BMF",
              price: 0,
              points: "400",
              slug: "titulo-1",
              badgetext: "copytrading",
              brokers: DATA_BROKERS,
              plan: [
                {
                  name: "Plano 1",
                  contractsInfo: "Até 5 contratos",
                  price: 184,
                  isSelected: false,
                },
                {
                  name: "Plano 2",
                  contractsInfo: "Até 10 contratos",
                  price: 350,
                  isSelected: false,
                },
                {
                  name: "Plano 3",
                  contractsInfo: "Até 15 contratos",
                  price: 480,
                  isSelected: false,
                },
                {
                  name: "Plano 4",
                  contractsInfo: "Até 30 contratos",
                  price: 840,
                  isSelected: false,
                },
              ],
            }}
          />
        </Flex>

        <Text>cardHighlight de campeonato</Text>
        <Flex gap="20px" wrap="wrap">
          <CardHighlightCamp
            item={{
              image:
                "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80",
              avatar:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80",
              name: "Nome do parceiro",
              title: "Nome do campeonato",
              initialDate: new Date().toISOString(),
              finalDate: new Date().toISOString(),
              awarded: "Top 3",
              participant: 100,
              participantTotal: 1000,
              contract: 10,
              award: "1.000,00",
              price: 0,
              activeInit: "win",
              activeFinal: "wdo",
              badgetext: "nome da liga",
              slug: "/cursos",
            }}
          />
        </Flex>
      </Flex>

      <Title>CARDS PRINCIPAIS - LIGAS</Title>
      <Flex direction="column" bg="white" gap="20px" align="center">
        <CardMainLeague item={DATA_CARD_LEAGUES[0]} />
      </Flex>

      <Title>CARDS DAS LIGAS</Title>
      <Flex direction="column" bg="white" gap="20px" align="center">
        <CardLeague type="partners" />
        <CardLeague type="trader" />
      </Flex>
    </Flex>
  );
};

export default CardsTeste;

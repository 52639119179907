import {
  Center,
  Divider,
  Flex,
  Image,
  Text,
  TypographyStylesProvider,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import {
  LogoTraderEvolutionNoTextBlue,
  TEMP_GraficProduct,
} from "../../../../assets";
import {
  Accordion,
  Breadcrumb,
  CardCommonGrafic,
  CarouselWithTitleDefault,
  HeaderItem,
} from "../../../../components";
import { DATA_ACCORDION } from "../../../../constants/accordion";
import { DATA_CARD_GRAFIC } from "../../../../constants/cards";
import ActivesTags from "../../../../components/Tags/TagActive";
import BuyArea from "./BuyArea";
import ParametersProduct from "./ParametersProduct";

const navlinks = [
  { title: "CopyOrder", href: "/marketplace/smartcopy" },
  { title: "Detalhes", href: "#" },
];

const SmartCopyProduct: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Center
      px={{ base: 60, xl: 20 }}
      pos="relative"
      sx={{
        ":before": {
          position: "absolute",
          zIndex: -1,
          top: "0",
          right: "0",
          background: theme.colors.primary[0],
          content: "''",
          height: "300px",
          width: "100vw",
        },
      }}
    >
      <Flex pt="32px" direction="column" maw={1680} w="100%" gap="48px">
        <Breadcrumb navlinks={navlinks} />

        <Flex
          p="48px"
          gap="48px"
          direction="column"
          w="100%"
          h="fit-content"
          bg={theme.colors.basic[0]}
          style={{
            boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
            borderRadius: "12px",
          }}
        >
          <Flex gap="48px">
            <Flex direction="column" gap="24px">
              <Flex gap="24px" align="center">
                <HeaderItem
                  avatar={LogoTraderEvolutionNoTextBlue}
                  title={"COPY EVO"}
                  name={"TraderEvolution"}
                  link="/smartcopy/item"
                />
                <ActivesTags actives={["PETR4", "WING23"]} />
              </Flex>
              <ParametersProduct
                return="137%"
                profit=""
                loss="0%"
                position="76"
                order="100"
                tradesProfit="100%"
              />
              <Flex direction="column" gap="32px">
                <Image alt={"grafico"} src={TEMP_GraficProduct} />
                <TypographyStylesProvider style={{}}>
                  <Text
                    ff="barlow"
                    lh="32px"
                    color="basic.4"
                    dangerouslySetInnerHTML={{
                      __html:
                        "<p>Praesent ut felis et erat venenatis lacinia. Nam ultrices luctus nisi, et aliquet erat sodales eget. Maecenas rutrum, eros sit amet iaculis blandit, urna nibh venenatis purus, vel volutpat ante sapien et purus.</br></br>Nulla facilisi. Aenean quam ex, viverra in elementum ut, porttitor eget quam. Quisque et felis id massa maximus auctor ut non arcu. Integer vitae varius ex. Praesent ut felis et erat venenatis lacinia. Nam ultrices luctus nisi, et aliquet erat sodales eget. Maecenas rutrum, eros sit amet iaculis blandit, urna nibh venenatis purus, vel volutpat ante sapien et purus. Nulla facilisi. Aenean quam ex, viverra in elementum ut, porttitor eget quam. Quisque et felis id massa maximus auctor ut non arcu. Integer vitae varius ex.</p>",
                    }}
                  />
                </TypographyStylesProvider>
              </Flex>
            </Flex>
            <BuyArea />
          </Flex>

          <Divider />

          <Accordion
            data={DATA_ACCORDION}
            firstOpen={true}
            title="Dúvidas frequentes"
          />
        </Flex>

        <CarouselWithTitleDefault
          title={"smartcopy"}
          activeLoop={DATA_CARD_GRAFIC.length >= 8}
        >
          {DATA_CARD_GRAFIC.map((item, index) => (
            <SwiperSlide key={index}>
              <Flex justify="center" align="center" mih="580px">
                <CardCommonGrafic
                  item={item}
                  preSlug="/marketplace/smartcopy/"
                />
              </Flex>
            </SwiperSlide>
          ))}
        </CarouselWithTitleDefault>
      </Flex>
    </Center>
  );
};

export default SmartCopyProduct;

import {
  Table as TableMantine,
  TableProps,
  useMantineTheme,
} from "@mantine/core";

interface IProps extends TableProps {
  rows: React.ReactNode;
  cols: React.ReactNode;
}

const Table: React.FC<IProps> = ({ cols, rows, ...props }) => {
  const theme = useMantineTheme();
  return (
    <TableMantine
      {...props}
      horizontalSpacing="xl"
      highlightOnHover
      sx={{
        background: theme.colors.basic[2],
        fontWeight: 500,
        th: {
          height: "56px",
        },
        button: {
          color: "#2F2F2F",
          fontSize: "0.875rem",
        },
        tbody: {
          tr: {
            height: "56px",
            border: "1px solid",
            borderColor: theme.colors.basic[2],
            background: theme.colors.basic[0],
          },
          td: {
            color: "#2F2F2F",
          },
        },
      }}
    >
      <thead>{cols}</thead>
      <tbody>{rows}</tbody>
    </TableMantine>
  );
};

export default Table;

import { useState } from "react";
import { CarouselPartner, HeaderCards } from "../../../../components";
import { Button, Center, Flex, SimpleGrid } from "@mantine/core";
import {
  DATA_CARD_GENIAL,
  DATA_CARD_GRAFIC,
} from "../../../../constants/cards";
import BigHeader from "../../../../Layout/BigHeader";
import { DATA_SWIPER_GENIAL } from "../../../../constants/dataChampionship";
import CardHighlightGraficMVP from "../../../../components/Cards/HighlightCards/CardHighlightGraficMVP";
import CardCommonGraficMVP from "../../../../components/Cards/CommonCards/CardCommonGraficMVP";

const Genial = () => {
  const [partner, setPartner] = useState<string>("Genial Investimentos");

  return (
    <BigHeader title={"Genial Invest."} hasOverlay={true}>
      <CarouselPartner
        title="Organização"
        data={DATA_SWIPER_GENIAL}
        setPartner={setPartner}
        partner={partner}
        isLoading={false}
      />
      <HeaderCards isLoading={false}>
        {DATA_CARD_GENIAL?.map((item, index: number) =>
          index < 3 ? (
            <Center w="100%" key={index}>
              <CardHighlightGraficMVP item={item} isBroker={true} />
            </Center>
          ) : null
        )}
      </HeaderCards>
      <Flex align="center" direction={"column"} mb={108}>
        <SimpleGrid
          maw={1680}
          w="100%"
          cols={4}
          verticalSpacing="48px"
          spacing="32px"
          breakpoints={[
            { maxWidth: 1692, cols: 3 },
            { maxWidth: 1365, cols: 2 },
          ]}
        >
          {DATA_CARD_GRAFIC?.map((item: any, index: number) => {
            return (
              <Center key={index}>
                <CardCommonGraficMVP
                  item={item}
                  preSlug="/marketplace/copyorder/genial/"
                />
              </Center>
            );
          })}
        </SimpleGrid>
        <Button w={254} variant="primaryTransparent" mt={48}>
          Carregar mais
        </Button>
      </Flex>
    </BigHeader>
  );
};

export default Genial;

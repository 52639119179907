import { Card, CardProps } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface IProps extends CardProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const ComponentHighlightCard: React.FC<IProps> = ({
  onClick,
  children,
  ...props
}) => {
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <Card
      {...props}
      onClick={onClick}
      bg="unset"
      p={0}
      shadow="sm"
      radius="md"
      w="100%"
      sx={(theme) => ({
        height: isLargerThan991 ? 524 : "100%",
        position: isLargerThan991 ? "relative" : "unset",
        overflow: "hidden",
        textDecoration: "none",
        cursor: "pointer",
        boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.06)",
        transition: "all 0.5s ease-in-out",
        figure: {
          maxWidth: "539px",
          div: {
            transition: "all 0.5s ease-in-out",

            img: {
              transition: "all 0.5s ease-in-out",
            },
          },
        },

        button: {
          borderColor: theme.colors.basic[2],
          color: theme.colors.basic[4],
          background: theme.colors.basic[0],
          transition: "all 0.3s ease-in-out",
        },

        ":hover": {
          boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.09)",
          figure: {
            div: {
              img: {
                filter: "brightness(80%)",
              },
              div: {
                filter: "brightness(90%)",
              },
            },
          },

          button: {
            color: theme.colors.basic[0],
            borderColor: theme.colors.primary[1],
            background: theme.colors.primary[1],
          },
        },
      })}
    >
      {children}
    </Card>
  );
};

export default ComponentHighlightCard;

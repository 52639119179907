import { Center, Flex, Text, useMantineTheme } from "@mantine/core";

interface IProps {
  title: string;
  hasOverlay?: boolean;
  children: React.ReactNode;
}
const BigHeader = ({ title, hasOverlay = false, children }: IProps) => {
  const theme = useMantineTheme();

  return (
    <Flex
      h="fit-content"
      direction="column"
      w="100%"
      justify={"center"}
      align={"center"}
    >
      <Flex
        h="fit-content"
        w="100%"
        justify={"center"}
        bg={theme.colors.primary[0]}
      >
        <Center
          h={{ base: 62, 991: 220 }}
          mb={{ base: 12, 991: "unset" }}
          maw={1680}
          w="100%"
        >
          <Text
            fw={700}
            fz={{ base: "40px", 991: "9.5vw", 1920: 180 }}
            lh={"100%"}
            sx={{
              whiteSpace: "nowrap",
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 5.97%, rgba(231, 233, 236, 0) 82.74%)",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            {title}
          </Text>
        </Center>
      </Flex>
      <Flex
        direction="column"
        maw={1680}
        w="100%"
        mt={hasOverlay ? { base: -32, 991: -60 } : "unset"}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default BigHeader;

import { Avatar, Button, Flex, Text } from "@mantine/core";
import { nameInitials } from "../../../../utils/nameInitials";
import { SegmentControlRounded } from "../../../../components";
import { Maximize4 } from "iconsax-react";
import { FiMinimize2 } from "react-icons/fi";

const optionsOne = [
  { label: "TUDO", value: "1" },
  { label: "PETR4", value: "7" },
  { label: "WING23", value: "30" },
];

const optionsTwo = [
  { label: "ABERTAS", value: "1" },
  { label: "FECHADAS", value: "7" },
];

const optionsTree = [
  { label: "TUDO", value: "1" },
  { label: "COMPRA", value: "7" },
  { label: "VENDA", value: "30" },
];

export interface IProps {
  openModal: boolean;
  setOpenModal: () => void;
}

const InfoUserDashboard: React.FC<IProps> = ({ openModal, setOpenModal }) => {
  const nameUser = "Fabiano silveira";
  return (
    <Flex
      justify="space-between"
      align="center"
      bg="basic.0"
      p={32}
      sx={{ borderRadius: "12px 12px 0 0" }}
    >
      <Flex direction="column" id="29618" gap={8}>
        <Flex id="29940" align="center" gap={12}>
          <Avatar
            radius="md"
            size={48}
            src={
              //"dsa"
              "https://marketplace.canva.com/EAFEits4-uw/1/0/1600w/canva-boy-cartoon-gamer-animated-twitch-profile-photo-oEqs2yqaL8s.jpg"
            }
            alt="avatar"
            color="red"
          >
            <Text
              fw={300}
              h={48}
              miw={48}
              bg="primary.1"
              c="basic.0"
              align="center"
              pt={10}
            >
              {nameInitials(nameUser)}
            </Text>
          </Avatar>
          <Flex id="29875" direction="column">
            <Text
              tt={"capitalize"}
              color="complementary.3"
              fz={{ base: 12 }}
              lh={{ base: "155%" }}
            >
              analista
            </Text>
            <Text
              tt={"capitalize"}
              color="basic.5"
              fz={{ base: 18 }}
              fw={{ base: 700 }}
              lh={{ base: "155%" }}
            >
              {nameUser}
            </Text>
          </Flex>
        </Flex>
        <Flex id="29617" gap={16}>
          <Text fz={{ base: 16 }} lh={{ base: "32px" }} color="basic.4">
            Atualizado ás 13:21 - 14/02/2024
          </Text>
          <Text
            component="a"
            href=""
            td="underline"
            fz={{ base: 16 }}
            fw={{ base: 500 }}
            lh={{ base: "32px" }}
            color="primary.1"
          >
            Atualizar agora
          </Text>
        </Flex>
      </Flex>
      <Flex gap={24}>
        <SegmentControlRounded data={optionsOne} />

        <SegmentControlRounded data={optionsTwo} />

        <SegmentControlRounded data={optionsTree} />
        {!openModal ? (
          <Button variant="segments" onClick={setOpenModal}>
            <Maximize4 size="24" color="#122447" />
          </Button>
        ) : (
          <Button variant="segments" onClick={setOpenModal}>
            <FiMinimize2 size="36" />
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
export default InfoUserDashboard;

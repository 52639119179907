import {
  Avatar,
  Flex,
  Menu,
  Overlay,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  ArrowDown2,
  DollarSquare,
  LoginCurve,
  Profile,
  Setting3,
  TickCircle,
} from "iconsax-react";
import { useAuth } from "../../context/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const MenuUser = () => {
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [openedProfile, { toggle: toggleProfile }] = useDisclosure(false);

  const { userMVP, logoutMVP, role: roleSelected, setRole } = useAuth();
  const navigate = useNavigate();

  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const isLargerThan1600 = useMediaQuery("(min-width: 1600px)");

  const handleRedirect = useCallback(
    (path: string) => {
      close();
      navigate(path);
    },
    [navigate]
  );

  const handleSelectProfile = (role: string) => {
    setRole(role);
    toggleProfile();
    close();
  };

  return (
    <>
      {opened && (
        <Overlay onClick={() => close()} opacity={0.25} color="#000" />
      )}
      <Menu
        shadow="md"
        width={280}
        zIndex={opened ? 300 : "unset"}
        offset={16}
        position="bottom-end"
        onOpen={open}
        onClose={close}
        closeOnItemClick={false}
        opened={opened}
        transitionProps={{
          transition: "scale-y",
          duration: 300,
          keepMounted: opened,
        }}
        styles={{
          dropdown: {
            width: 280,
            bg: theme.colors.other[0],
            borderRadius: "8px",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            padding: "12px !important",
            gap: "8px !important",
          },
          item: {
            transition: "0.5s",
            padding: "8px",
            margin: "4px 0",
          },
          itemIcon: {
            marginRight: "16px",
          },
        }}
      >
        <Menu.Target>
          <Flex
            w={{ base: 76, 630: 280, 991: 92, 1400: 280 }}
            h={{ base: 48, 991: 56 }}
            align="center"
            justify="center"
            style={{
              position: "relative",
              content: "''",
              zIndex: "200",
            }}
          >
            <Flex
              w={{
                base: opened ? 280 : 76,
                630: opened ? 280 : 280,
                991: opened ? 280 : 92,
                1400: 280,
              }}
              h={{
                base: 48,
                991: 56,
                1600: 56,
              }}
              p={{ base: 8, 630: "8px 12px" }}
              bg={theme.colors.other[0]}
              gap={{ base: 8, 630: 12 }}
              direction="column"
              align="center"
              style={{
                overflow: "hidden",
                position: "absolute",
                top: 0,
                right: 0,
                borderRadius: "8px",
                transition: "all 0.3s linear",
              }}
            >
              <Flex
                gap={{
                  base: opened ? 8 : 4,
                  630: 8,
                  991: opened ? 12 : 4,
                  1400: 12,
                }}
                align="center"
                justify="space-between"
                w="fit-content"
                style={{
                  transition: "width 0.3s linear, height 0.3s linear",
                }}
              >
                <Avatar
                  component="span"
                  variant="outline"
                  size={isLargerThan991 ? 40 : 32}
                  color="basic.2"
                  radius={8}
                  src={"props.avatar"}
                  alt="Nome do parceiro"
                  styles={{
                    root: {
                      background: theme.colors.primary[1],
                      transition: "all 0.5s linear, width 0.5s linear",
                    },
                  }}
                >
                  <Profile color={theme.colors.basic[0]} />
                </Avatar>
                <Flex
                  w={{
                    base: opened ? 184 : 0,
                    630: 184,
                    991: opened ? 168 : 0,
                    1400: 168,
                  }}
                  h={{ base: 32, 991: 40 }}
                  direction="column"
                  pos="relative"
                  justify="center"
                  style={{
                    transition: "width 0.3s linear",
                  }}
                >
                  <Text
                    lh={{ base: "22px", 991: "25px" }}
                    fz={{ base: "14px", 991: "16px" }}
                    fw={600}
                    truncate
                  >
                    {userMVP.username}
                  </Text>
                </Flex>
                <ArrowDown2
                  size={24}
                  color={theme.colors.basic[5]}
                  style={{
                    transform: opened ? "rotatex(180deg)" : "unset",
                    transition: "0.5s",
                  }}
                />
              </Flex>

              {/* <Transition
                mounted={opened}
                transition="scale-y"
                duration={400}
                timingFunction="ease"
              >
                {(styles) => (
                  <Flex
                    w={{
                      base: 264,
                      991: 256,
                    }}
                    h={56}
                    style={styles}
                    align="end"
                    justify={"end"}
                  >
                    <ValuesAndPoints menuUser={true} />
                  </Flex>
                )}
              </Transition> */}
            </Flex>
          </Flex>
        </Menu.Target>
        {isLargerThan991 ? (
          <Menu.Dropdown>
            <Menu.Item
              icon={<Setting3 size={24} color={theme.colors.basic[5]} />}
              onClick={() => handleRedirect("/meu-perfil/configuracoes")}
            >
              Configurações
            </Menu.Item>
            <Menu.Item
              icon={<DollarSquare size={24} color={theme.colors.basic[5]} />}
              onClick={() => handleRedirect("/meu-perfil/financeiro")}
            >
              Financeiro
            </Menu.Item>
            <Menu.Item
              icon={<Profile size={24} color={theme.colors.basic[5]} />}
              onClick={toggleProfile}
            >
              <Flex w="100%" justify="space-between">
                <Text w={"fit-content"}>Alterar perfil</Text>
                <ArrowDown2
                  size={24}
                  color={theme.colors.basic[5]}
                  style={{
                    transform: openedProfile ? "rotatex(180deg)" : "unset",
                    transition: "0.5s",
                  }}
                />
              </Flex>
            </Menu.Item>
            {openedProfile
              ? userMVP.roles.map((role, index) => {
                  return (
                    <Menu.Item
                      key={`role${index}`}
                      onClick={() => handleSelectProfile(role)}
                      icon={
                        <Profile
                          size={24}
                          color={
                            role.includes(roleSelected)
                              ? "white"
                              : "theme.colors.basic[5]"
                          }
                        />
                      }
                      bg={
                        role.includes(roleSelected)
                          ? theme.colors.primary[0]
                          : "unset"
                      }
                      color={role.includes(roleSelected) ? "white" : "unset"}
                      sx={(theme) => ({
                        "&:hover": {
                          backgroundColor: role.includes(roleSelected)
                            ? theme.colors.primary[1]
                            : theme.colors.primaryOpacity[4],
                          color: role.includes(roleSelected)
                            ? "white"
                            : theme.colors.basic[6],
                        },
                      })}
                    >
                      <Flex w="100%" justify="space-between" align={"center"}>
                        {role}
                        {role.includes(roleSelected) ? (
                          <TickCircle size="18" color="white" />
                        ) : null}
                      </Flex>
                    </Menu.Item>
                  );
                })
              : null}

            <Menu.Divider />

            <Menu.Item
              color="red"
              onClick={logoutMVP}
              icon={<LoginCurve size={24} color={theme.colors.alerts[0]} />}
            >
              Sair da conta
            </Menu.Item>
          </Menu.Dropdown>
        ) : null}
      </Menu>
    </>
  );
};

export default MenuUser;

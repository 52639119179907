import { Box, Divider, Flex, Text, Title } from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import {
  FooterCard,
  ImageHighlightCard,
  ComponentHighlightCard,
} from "../../ComponentsCards";
import { useNavigate } from "react-router-dom";
import { CardGraficProps } from "../../ComponentsCards/typesCards";
import HeaderHighlightCardMVP from "./HeaderHighlightCardMVP";

interface IProps {
  item: CardGraficProps;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
  isBroker?: boolean;
}

const CardHighlightGraficMVP: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const { hovered, ref } = useHover();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <Box ref={ref} sx={{ width: "100%", maxWidth: "539px" }}>
      <ComponentHighlightCard
        onClick={() => {
          if (!!props?.item.slug) return;
          navigate(
            !!props?.preSlug
              ? `${props?.preSlug}${props?.item.slug}`
              : props?.item.slug
          );
        }}
      >
        <ImageHighlightCard
          image={props?.item.image}
          title={props?.item.title}
          badgetext={props?.item.badgetext}
          radius={0}
          brokers={props.isBroker}
          height={
            isLargerThan991 ? (hovered && !props.isBroker ? 224 : 406) : 215
          }
        />
        <Flex
          w="100%"
          direction="column"
          h={isLargerThan991 ? 300 : "fit-content"}
          p={32}
          bg="white"
          pos={isLargerThan991 ? "absolute" : "unset"}
        >
          <HeaderHighlightCardMVP
            item={props.item}
            mb={isLargerThan991 ? (hovered && !props.isBroker ? 16 : 32) : 16}
            style={{
              transition: "0.5s",
            }}
          />

          <Flex
            gap={isLargerThan991 ? "17px" : "8px"}
            h={isLargerThan991 ? "69px" : "unset"}
            wrap="wrap"
            w="100%"
            style={{
              transition: "0.5s",
            }}
          >
            <Flex
              justify="center"
              direction="column"
              // miw="144px"
              p="8px"
              h="100%"
            >
              <Text fw={700} size={18} lh="27.9px" color="basic.4">
                {props?.item.return}
              </Text>
              <Title ff="Barlow" fw={500} size={16} lh="24.8px" color="basic.3">
                Retorno no período
              </Title>
            </Flex>
            <Flex
              justify="center"
              direction="column"
              // miw="144px"
              h="100%"
              p="8px"
            >
              <Text fw={700} size={18} lh="27.9px" color="basic.4">
                {props?.item.market}
              </Text>
              <Title ff="Barlow" fw={500} size={16} lh="24.8px" color="basic.3">
                Mercado
              </Title>
            </Flex>
          </Flex>

          <Divider my={24} w="100%" />

          <FooterCard price={props?.item.plan[0].price} />
        </Flex>
      </ComponentHighlightCard>
    </Box>
  );
};

export default CardHighlightGraficMVP;

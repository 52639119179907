import { Button, Flex } from "@mantine/core";
import { useNotificationControl } from "../../context/notificationContext";

const NotificationTeste: React.FC = () => {
  const { notificationControl, updateNotificationControl } =
    useNotificationControl();

  return (
    <Flex direction="column" gap="20px">
      NOTIFICAÇÕES TESTE
      <Button
        onClick={() =>
          notificationControl({
            id: "default",
            title: "Notificação padrão",
            message: "Informação sobre a notificação",
            type: "default",
          })
        }
      >
        Notificação padrão
      </Button>
      <Button
        onClick={() =>
          notificationControl({
            id: "check",
            title: "Sucesso!",
            message: "Produto adicionado a sua lista de interesse!",
            type: "check",
          })
        }
      >
        Sucesso!
      </Button>
      <Button
        onClick={() =>
          notificationControl({
            id: "danger",
            title: "Erro!",
            message: "Informação sobre o erro",
            type: "danger",
          })
        }
      >
        Erro!
      </Button>{" "}
      <Button
        onClick={() =>
          notificationControl({
            id: "warning",
            title: "Atenção!",
            message: "Informação sobre a atenção",
            type: "warning",
          })
        }
      >
        Atenção!
      </Button>
      <Button
        onClick={() =>
          notificationControl({
            id: "success",
            title: "Sucesso!",
            message: "Informação sobre a sucesso",
            type: "success",
          })
        }
      >
        Sucesso!
      </Button>
      <Button
        onClick={() =>
          notificationControl({
            type: "loading",
            id: "loading-error",
            title: "Carregando...",
            message: "Informação sobre o carregamento",
          })
        }
      >
        loading-error!
      </Button>
      <Button
        onClick={() =>
          notificationControl({
            type: "loading",
            id: "loading-success",
            title: "Carregando...",
            message: "Informação sobre o carregamento",
          })
        }
      >
        loading-success!
      </Button>
      <Button
        onClick={() =>
          updateNotificationControl({
            isSuccess: false,
            id: "loading-error",
          })
        }
      >
        mude o estado do loading-error
      </Button>
      <Button
        onClick={() =>
          updateNotificationControl({
            isSuccess: true,
            id: "loading-success",
          })
        }
      >
        mude o estado do loading-success
      </Button>
    </Flex>
  );
};

export default NotificationTeste;

import {
  Pagination as PaginationMantine,
  PaginationProps,
  useMantineTheme,
} from "@mantine/core";

const Pagination: React.FC<PaginationProps> = ({ ...props }) => {
  const theme = useMantineTheme();

  return (
    <PaginationMantine
      sx={{
        gap: "16px",
        button: {
          width: "40px",
          height: "52px",
          fontSize: "14px",
          lineHeight: "32px",
          fontWeight: 500,
          border: "none",
          color: theme.colors.basic[4],
          "&[data-active]": {
            background: theme.colors.primary[1],
          },
          svg: {
            height: "30px",
            width: "30px",
          },
        },
      }}
      {...props}
      radius="xl"
    />
  );
};

export default Pagination;

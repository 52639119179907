import * as Yup from "yup";

export const ValidationSendRecoverPhone = Yup.object().shape({
  phone: Yup.string()
    .min(15, "Informe o telefone cooretamente")
    .required("Informe seu whatsapp"),
});

export const ValidationSendRecoverEmail = Yup.object().shape({
  email: Yup.string()
    .email("Informe seu e-mail válido")
    .required("Informe seu e-mail"),
});

import { Divider, Skeleton } from "@mantine/core";
import {
  ComponentCommonCard,
  HeaderCommonCard,
  FooterCard,
} from "../../ComponentsCards";
import ContentBrokerCard from "../../ComponentsCards/ContentBrokerCard";
import { IProductItem } from "../../../../interfaces/IListProducts";
import { useNavigate } from "react-router-dom";
import ModalRegister from "../../../Modals/ModalRegister";
import { useState } from "react";

type IProps = {
  item: IProductItem;
  select?: boolean;
  preSlug?: string;
  isLoading?: boolean;
  carrousel?: boolean;
  slug?: boolean;
};

const CardCommonPlatform: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [modalRegister, setModalRegister] = useState<boolean>(false);

  return (
    <>
      <ModalRegister
        item={props.item}
        open={modalRegister}
        setClose={() => setModalRegister(!modalRegister)}
      />
      {props.isLoading ? (
        <Skeleton
          w={
            props.carrousel
              ? { base: 600, 991: 600 }
              : { base: "100%", 991: "100%" }
          }
          maw={
            props.carrousel ? { base: 313, 991: 600 } : { base: 313, 991: 540 }
          }
          radius="xl"
          h={{ base: 215, 991: 400 }}
        />
      ) : (
        <ComponentCommonCard
          onClick={() =>
            props.slug
              ? navigate(
                  !!props?.preSlug
                    ? `${props?.preSlug}${props?.item?.product?.title}`
                    : props?.item?.product?.title
                )
              : setModalRegister(!modalRegister)
          }
          maw={{
            base: props?.carrousel ? 313 : 540,
            991: 540,
            1100: props?.select ? 612 : 540,
          }}
          w="100%"
          ml={{ base: 0, 991: props?.select ? 16 : 0 }}
          p={{ base: 16, 991: props?.select ? 36 : 32 }}
        >
          <HeaderCommonCard
            select={props?.select}
            badge={true}
            item={props.item}
          />

          <ContentBrokerCard
            item={props?.item?.product}
            select={props?.select}
          />

          <Divider my={{ base: "16px", 991: 16 }} />

          <FooterCard price={props?.item?.plan?.price} select={props?.select} />
        </ComponentCommonCard>
      )}
    </>
  );
};

export default CardCommonPlatform;

import { ActionIcon, Flex } from "@mantine/core";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import React from "react";

type IProps = {
  next: () => void | undefined;
  previous: () => void | undefined;
  positionAbsolute?: boolean;
};

const ButtonsCarousel: React.FC<IProps> = (props) => {
  return (
    <Flex
      gap="16px"
      style={{
        position: props.positionAbsolute ? "absolute" : "unset",
        bottom: "0",
        right: "0",
      }}
    >
      <ActionIcon variant="secondary" onClick={() => props.previous()}>
        <ArrowLeft size={28} />
      </ActionIcon>
      <ActionIcon variant="secondary" onClick={() => props.next()}>
        <ArrowRight size={28} />
      </ActionIcon>
    </Flex>
  );
};

export default ButtonsCarousel;

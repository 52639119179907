import React from "react";
import { Badge, Box, BoxProps, Flex, Text } from "@mantine/core";

interface IProps {
  text: string;
  ordens: string | number;
  textBox: string;
}

const BoxItens: React.FC<IProps> = ({ ordens, text, textBox }) => {
  return (
    <Flex justify="space-between">
      <Box>
        <Text
          fz={{ base: 16 }}
          fw={{ base: 600 }}
          lh={{ base: "155%" }}
          color="basic.5"
        >
          {text} ordens
        </Text>
        <Text
          fz={{ base: 14 }}
          fw={{ base: 500 }}
          lh={{ base: "155%" }}
          color="basic.3"
        >
          {ordens} posições
        </Text>
      </Box>
      <Badge
        h={48}
        w={76}
        p={8}
        radius={8}
        bg={
          textBox === "WING23"
            ? "complementaryOpacity.3"
            : `complementaryOpacity.4`
        }
        color={textBox === "WING23" ? "complementary.3" : `complementary.4`}
        styles={{
          inner: {
            fontFamily: "Barlow",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "32px",
          },
        }}
      >
        {textBox}
      </Badge>
    </Flex>
  );
};
export default BoxItens;

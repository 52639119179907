import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowRight2 } from "iconsax-react";
import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IBrokers } from "../../../interfaces/IBrokers";
import { useMediaQuery } from "@mantine/hooks";
import Skeleton from "../../Skeleton";
import { useNavigate, useParams } from "react-router-dom";

interface IPropsPartners {
  data: any;
  setPartner: any;
  partner: any;
  title: string;
  isLoading: boolean;
}

const CarouselPartner: React.FC<IPropsPartners> = ({
  data,
  partner,
  setPartner,
  title,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");
  const theme = useMantineTheme();

  const handleClick = (item: any) => {
    setPartner(item?.name);
    if (item?.name.includes("Genial"))
      navigate(`/marketplace/copyorder/genial`);
    if (item?.name.includes("Todos")) navigate(`/marketplace/copyorder`);
  };

  return (
    <Box
      p={{ base: 0, 991: 24 }}
      mb={{ base: 30, 991: 48 }}
      bg={theme.colors.basic[0]}
      h={{ base: 125, 991: 176 }}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Flex
        direction={{ base: "column", 991: "row" }}
        sx={{
          border: "1px solid",
          borderColor: theme.colors.basic[1],
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Flex
          align="center"
          ml={{ base: 12, 991: 0 }}
          justify={{ base: "start", 991: "space-around" }}
          bg="basic.0"
          miw={257}
          h={{ base: 44, 991: 128 }}
        >
          <Flex
            align={{ base: "center" }}
            direction={{ base: "row", 991: "column" }}
          >
            <Text color="basic.4" fz={{ base: 14, 991: 16 }} pr={12}>
              Navegue por
              {!isLargerThan991 ? " : " : null}
            </Text>
            <Text color="basic.5" fz={{ base: 16, 991: 24 }} weight={700}>
              {title}
            </Text>
          </Flex>
          {isLargerThan991 ? (
            <Center>
              <ArrowRight2
                size="32"
                color={theme.colors.basic[5]}
                variant="Outline"
              />
            </Center>
          ) : null}
        </Flex>
        <Divider
          w={{ base: "100%", 991: "auto" }}
          orientation={isLargerThan991 ? "vertical" : "horizontal"}
          size={1}
          color="#F0F0F0"
        />
        {isLoading ? (
          <Flex
            gap={40}
            align="center"
            justify="center"
            pl={30}
            pr={{ base: 20, 991: 0 }}
            py={{ base: 20, 991: 0 }}
          >
            <Skeleton repeat={7} h={isLargerThan991 ? 98 : 38} w={194} />
          </Flex>
        ) : (
          <Swiper
            style={{
              margin: 0,
            }}
            centeredSlides={false}
            slidesPerView={"auto"}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data?.map((item: any, index: number) => (
              <SwiperSlide
                key={index}
                style={{
                  width: "fit-content",
                  display: isLargerThan991 ? "unset" : "flex",
                  alignItems: isLargerThan991 ? "unset" : "center",
                  justifyContent: isLargerThan991 ? "unset" : "center",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                onClick={() => {
                  handleClick(item);
                }}
              >
                <Center
                  p={{ base: 5, 991: 10 }}
                  my={{ base: 16, 991: 24 }}
                  h={{ base: 48, 991: 80 }}
                  w={{ base: 48, 991: 216 }}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "12px",
                    border:
                      item?.name === partner
                        ? "1.5px solid #3F87F4"
                        : isLargerThan991
                        ? "1px solid transparent"
                        : "1px solid #D7D7D7",
                    transition: "0.3s",
                  }}
                >
                  {item.name !== "Todos" ? (
                    <Image
                      withPlaceholder
                      fit="contain"
                      height={isLargerThan991 ? 60 : 38}
                      alt={item?.name}
                      src={
                        isLargerThan991
                          ? item?.logo?.Location
                          : item?.icon?.Location
                      }
                      styles={{
                        placeholder: {
                          background: "white",
                          height: isLargerThan991 ? "100%" : "40px",
                          div: {
                            height: "40px",
                            div: {
                              height: "40px",
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <Text color={"#154BB6"} fz="28px" p={"5px 50px"} fw={600}>
                      {item.name}
                    </Text>
                  )}
                </Center>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Flex>
    </Box>
  );
};

export default CarouselPartner;

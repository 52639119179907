import { useState } from "react";
import {
  Group,
  Button,
  Flex,
  Input,
  useMantineTheme,
  Box,
} from "@mantine/core";
import {
  InputWrapper,
  Select,
  PasswordInput,
  RadioGroup,
  Radio,
  Search,
  TagStatus,
  TagBoolean,
  TagCapsules,
  TagPay,
  TagSelection,
  Accordion,
  Modal,
  ModalLoading,
  NotificationTeste,
  CardsTeste,
  CarouselPartner,
} from "../../components";
import { CiUser } from "react-icons/ci";
import { AddCircle } from "iconsax-react";
import Home from "../SEMUSO/Todos-antiga";
import { DATA_SWIPER_CHAMPIONSHIP } from "../../constants/dataChampionship";

const ScreenComponents: React.FC = () => {
  const dataSlide = DATA_SWIPER_CHAMPIONSHIP;
  const [partner, setPartner] = useState<string>();

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const data = [
    {
      id: "qódjnvqpjnvqpinf23r1312v",
      title: "titulo 0",
      content:
        "Pellentesque vel mauris sit amet arcu ultricies varius eu ut felis. Fusce a ante ante. Vivamus non condimentum dui. Suspendisse justo risus, gravida id diam nec, pharetra luctus urna. Sed id justo augue. Maecenas pulvinar lorem at aliquam posuere. Morbi sit amet dolor sit amet odio euismod dapibus. Nunc gravida rutrum ante, vel pharetra mi aliquet non. Etiam egestas justo sit amet nisl viverra malesuada. Donec orci lorem, fringilla quis malesuada id, eleifend sed sem. Pellentesque condimentum nisi sit amet egestas vehicula. Vivamus a tincidunt lectus. Ut quis scelerisque lacus. Nunc venenatis eleifend ligula ac imperdiet. Duis in sodales lorem, iaculis ultrices ante.",
    },
    { id: "adkfáokdfós", title: "titulo 1", content: "content 1" },
    { id: "adljfovnqwpojefv-qóed", title: "titulo 2", content: "content 2" },
    { id: "wsdcqwdcqwe123ec3124", title: "titulo 3", content: "content 3" },
    { id: "3234r2345gb245tbwet", title: "titulo 4", content: "content 4" },
    { id: "34f2314gwerfsadbfd", title: "titulo 5", content: "content 5" },
  ];

  return (
    <>
      <div style={{ margin: "0 10px" }}>
        <div style={{ padding: "10px 0" }}>
          <div id="botões">
            <h5>Botões</h5>
            <div>
              <Flex>
                <Flex w="100%" gap={20}>
                  <Flex w="100%" gap={20} p={10}>
                    <Button fullWidth variant="primary">
                      primary
                    </Button>
                    <Button fullWidth variant="primaryOutline">
                      primaryOutline
                    </Button>
                    <Button fullWidth variant="primaryTransparent">
                      primaryTransparent
                    </Button>
                  </Flex>
                </Flex>
                <Flex w="100%" gap={20} bg={"primary.0"} p={10}>
                  <Button fullWidth variant="secondary">
                    secondary
                  </Button>
                  <Button fullWidth variant="secondaryOutline">
                    secondaryOutline
                  </Button>
                  <Button fullWidth variant="secondaryTransparent">
                    secondaryTransparent
                  </Button>
                </Flex>
              </Flex>
              <Flex>
                <Flex w="100%" gap={20}>
                  <Flex w="100%" gap={20} p={10}>
                    <Button
                      fullWidth
                      leftIcon={<AddCircle size="20" />}
                      variant="primary"
                    >
                      primary
                    </Button>
                    <Button
                      fullWidth
                      leftIcon={<AddCircle size="20" />}
                      variant="primaryOutline"
                    >
                      primaryOutline
                    </Button>
                    <Button
                      fullWidth
                      leftIcon={<AddCircle size="20" />}
                      variant="primaryTransparent"
                    >
                      primaryTransparent
                    </Button>
                  </Flex>
                </Flex>
                <Flex w="100%" gap={20} bg={"primary.0"} p={10}>
                  <Button
                    fullWidth
                    variant="secondary"
                    leftIcon={<AddCircle size="20" />}
                  >
                    secondary
                  </Button>
                  <Button
                    fullWidth
                    variant="secondaryOutline"
                    leftIcon={<AddCircle size="20" />}
                  >
                    secondaryOutline
                  </Button>
                  <Button
                    fullWidth
                    variant="secondaryTransparent"
                    leftIcon={<AddCircle size="20" />}
                  >
                    secondaryTransparent
                  </Button>
                </Flex>
              </Flex>
              <Flex gap={40}>
                <Flex w="100%" gap={20}>
                  <Flex w="50%" gap={20} p={10} mr={10}>
                    <Button fullWidth variant="primary" disabled>
                      primary
                    </Button>
                    <Button fullWidth variant="primaryOutline" disabled>
                      primaryOutline
                    </Button>
                    <Button fullWidth variant="primaryTransparent" disabled>
                      primaryTransparent
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={40}>
                <Flex w="100%" gap={20}>
                  <Flex w="50%" gap={20} p={10} mr={10}>
                    <Button
                      disabled
                      fullWidth
                      variant="primary"
                      leftIcon={<AddCircle size="20" />}
                    >
                      primary
                    </Button>
                    <Button
                      fullWidth
                      variant="primaryOutline"
                      leftIcon={<AddCircle size="20" />}
                      disabled
                    >
                      primaryOutline
                    </Button>
                    <Button
                      fullWidth
                      variant="primaryTransparent"
                      leftIcon={<AddCircle size="20" />}
                      disabled
                    >
                      primaryTransparent
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={40}>
                <Flex w="100%" gap={20}>
                  <Flex w="50%" gap={20} p={10} mr={10}>
                    <Button fullWidth variant="danger">
                      danger
                    </Button>
                    <Button fullWidth variant="dangerOutline">
                      dangerOutline
                    </Button>
                    <Button fullWidth variant="dangerTransparent">
                      dangerTransparent
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={40}>
                <Flex w="100%" gap={20}>
                  <Flex w="50%" gap={20} p={10} mr={10}>
                    <Button
                      fullWidth
                      variant="danger"
                      leftIcon={<AddCircle size="20" />}
                    >
                      danger
                    </Button>
                    <Button
                      fullWidth
                      variant="dangerOutline"
                      leftIcon={<AddCircle size="20" />}
                    >
                      dangerOutline
                    </Button>
                    <Button
                      fullWidth
                      variant="dangerTransparent"
                      leftIcon={<AddCircle size="20" />}
                    >
                      dangerTransparent
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </div>
          <div id="Accordion">
            <h4>Accordion</h4>
            <Accordion data={data} />
          </div>
          <div>
            <h4>tag Pay</h4>
            <TagPay paysucces={true} />
            <TagPay paysucces={false} ml="sm" />
          </div>
          <div>
            <h4>tag status</h4>
            <TagStatus />
            <TagStatus status="disapproved" ml="sm" />
            <TagStatus status="loading" ml="sm" />
          </div>
          <div>
            <h4>tag Boolean</h4>
            <TagBoolean statusYes />
            <TagBoolean ml="sm" />
          </div>
          <div>
            <h4>tag Capsulas</h4>
            <TagCapsules colorTag="green"> green</TagCapsules>
            <TagCapsules colorTag="orange" ml="sm">
              orange
            </TagCapsules>
            <TagCapsules colorTag="violet" ml="sm">
              gray
            </TagCapsules>
            <TagCapsules colorTag="red" ml="sm">
              red
            </TagCapsules>
            <TagCapsules colorTag="blue" ml="sm">
              blue
            </TagCapsules>
            <TagCapsules colorTag="pink" ml="sm">
              pink
            </TagCapsules>
            <TagCapsules colorTag="purple" ml="sm">
              purple
            </TagCapsules>
            <TagCapsules colorTag="babyBlue" ml="sm">
              babyBlue
            </TagCapsules>
          </div>
          <div>
            <h4>tag Capsulas</h4>
            <TagSelection />
            <TagSelection contain="Opção" ml="sm" onClick={() => {}} />
          </div>
        </div>

        <Search
          wrapperProps={{
            placeholder: "placeholder",
          }}
        />
        <h6>Radio</h6>
        <RadioGroup label="Label Radio" name="nome">
          <Group mt="xs">
            <Radio value="react" label="React" />
            <Radio value="svelte" label="Svelte" />
            <Radio value="ng" label="Angular" />
            <Radio value="vue" label="Vue" />
          </Group>
        </RadioGroup>

        <h6>Input wrappper</h6>
        <InputWrapper label="label input" withAsterisk>
          <Input placeholder="placesholder " />
        </InputWrapper>
        <h6>Input Erro</h6>
        <InputWrapper label="label Erro" error="Erro input" withAsterisk>
          <Input placeholder="placesholder " />
        </InputWrapper>

        <h6>Select</h6>
        <Select
          withAsterisk
          label={"select styled"}
          placeholder="placesholder Pick one "
          data={["um", "dois", "três", "quatro", "cinco"]}
        />
        <h6>Select Erro</h6>
        <Select
          error="Erro select"
          withAsterisk
          label={"select styled"}
          placeholder="placesholder Pick one "
          data={["um", "dois", "três", "quatro", "cinco"]}
        />
        <h5>Login</h5>
        <InputWrapper label="label" withAsterisk>
          <Input
            placeholder="placesholder "
            id="username"
            icon={<CiUser color="#2F2F2F" size={24} />}
          />
        </InputWrapper>

        <h6>password</h6>
        <PasswordInput label="Your password" placeholder="password" />
        <div id="notificações">
          <h5>Notificações</h5>
          <NotificationTeste />
        </div>

        <div id="modais">
          <h5>Modais</h5>
          <Flex gap={20}>
            <Button variant="primary" onClick={() => setOpen(!open)}>
              Modal Success
            </Button>
            <Button variant="primary" onClick={() => setOpen1(!open1)}>
              Modal Info
            </Button>
            <Button variant="primary" onClick={() => setOpen2(!open2)}>
              Modal Erro
            </Button>
            <Button variant="primary" onClick={() => setOpen3(!open3)}>
              Modal Warning
            </Button>
            <Button variant="primary" onClick={() => setOpen4(!open4)}>
              Modal Loading
            </Button>
          </Flex>
          <Modal
            titleFirstButton="Botão um"
            titleSeccondButton="Botão dois"
            type="success"
            description="Sucesso!"
            open={open}
            setClose={() => setOpen(!open)}
            firstClick={() => {}}
            secondClick={() => {}}
          />
          <Modal
            titleFirstButton="Botão um"
            titleSeccondButton="Botão dois"
            type="info"
            description="Texto de apoio"
            open={open1}
            setClose={() => setOpen1(!open1)}
            firstClick={() => {}}
            secondClick={() => {}}
          />
          <Modal
            titleFirstButton="Botão um"
            titleSeccondButton="Botão dois"
            type="danger"
            description="Texto de apoio"
            open={open2}
            setClose={() => setOpen2(!open2)}
            firstClick={() => {}}
            secondClick={() => {}}
          />
          <Modal
            titleFirstButton="Botão um"
            titleSeccondButton="Botão dois"
            type="warning"
            description="Texto de apoio"
            open={open3}
            setClose={() => setOpen3(!open3)}
            firstClick={() => {}}
            secondClick={() => {}}
          />
          <ModalLoading open={open4} />
        </div>
        <div id="cards" style={{ marginBottom: "20px" }}>
          <h5>Cards</h5>
          <CardsTeste />
        </div>

        {/* Carousel estão na tela home */}
        <div>
          <Home />
        </div>
        <Box pb={30}>
          <>descomentar</>

          <CarouselPartner
            title="Organização"
            data={dataSlide}
            setPartner={setPartner}
            partner={partner}
            isLoading={false}
          />
        </Box>
      </div>
    </>
  );
};

export default ScreenComponents;

import { ThemeIcon, ThemeIconProps, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";

interface IProps extends ThemeIconProps {
  children: React.ReactNode;
  colorIcon?:
    | "green"
    | "orange"
    | "violet"
    | "red"
    | "blue"
    | "pink"
    | "purple"
    | "babyBlue";
}

const IconColor: React.FC<IProps> = ({ children, colorIcon, ...props }) => {
  const theme = useMantineTheme();
  const [content, setContent] = useState<string | any>({
    backgroundColor: "#E9F8EB",
    colorText: "#23BD32",
  });

  useEffect(() => {
    switch (colorIcon) {
      case "green":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[0],
          colorText: theme.colors.complementary[0],
        });
      case "blue":
        return setContent({
          backgroundColor: theme.colors.primaryOpacity[1],
          colorText: theme.colors.primary[1],
        });
      case "red":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[2],
          colorText: theme.colors.complementary[2],
        });
      case "purple":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[3],
          colorText: theme.colors.complementary[3],
        });
      case "orange":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[4],
          colorText: theme.colors.complementary[4],
        });
      case "pink":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[5],
          colorText: theme.colors.complementary[5],
        });
      case "babyBlue":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[6],
          colorText: theme.colors.complementary[6],
        });
      case "violet":
        return setContent({
          backgroundColor: "#EAE8F1",
          colorText: "#2C1B73",
        });
    }
  }, [colorIcon]);

  return (
    <ThemeIcon
      variant="light"
      size={48}
      style={{
        padding: "8px",
        height: "27px",
        borderRadius: "8px",
        fontSize: "28px",
        background: content.backgroundColor,
        color: content.colorText,
      }}
      {...props}
    >
      {children}
    </ThemeIcon>
  );
};

export default IconColor;

import React, { useEffect, useState } from "react";
import {
  Badge as BadgeCapsules,
  BadgeProps,
  useMantineTheme,
} from "@mantine/core";

interface IPropsCapsules extends BadgeProps {
  colorTag?:
    | "green"
    | "orange"
    | "violet"
    | "red"
    | "blue"
    | "pink"
    | "purple"
    | "babyBlue";
  border?: boolean;
}

const TagCapsules: React.FC<IPropsCapsules> = ({
  colorTag,
  border,
  ...props
}) => {
  const theme = useMantineTheme();
  const [content, setContent] = useState<string | any>({
    backgroundColor: "#E9F8EB",
    colorText: "#23BD32",
  });

  useEffect(() => {
    switch (colorTag) {
      case "green":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[0],
          colorText: theme.colors.complementary[0],
          border: theme.colors.alerts[2],
        });
      case "blue":
        return setContent({
          backgroundColor: theme.colors.primaryOpacity[1],
          colorText: theme.colors.primary[1],
          border: theme.colors.primary[1],
        });
      case "red":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[2],
          colorText: theme.colors.complementary[2],
          border: theme.colors.complementary[2],
        });
      case "purple":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[3],
          colorText: theme.colors.complementary[3],
          border: theme.colors.complementary[3],
        });
      case "orange":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[4],
          colorText: theme.colors.complementary[4],
          border: theme.colors.complementary[4],
        });
      case "pink":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[5],
          colorText: theme.colors.complementary[5],
          border: theme.colors.complementary[5],
        });
      case "babyBlue":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[6],
          colorText: theme.colors.complementary[6],
          border: theme.colors.complementary[6],
        });
      case "violet":
        return setContent({
          backgroundColor: theme.colors.complementaryOpacity[1],
          colorText: theme.colors.complementary[1],
          border: theme.colors.complementary[1],
        });
    }
  }, [colorTag]);

  return (
    <BadgeCapsules
      styles={{
        root: {
          width: "fit-content",
          padding: "4px 8px",
          height: "27px",
          fontSize: "12px",
          background: content.backgroundColor,
          color: content.colorText,
          border: "1px solid",
          borderColor: border ? content.border : "transparent",
        },
        inner: {
          lineHeight: "14px",
          textAlign: "center",
        },
      }}
      radius={20}
      {...props}
    />
  );
};

export default TagCapsules;

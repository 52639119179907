import React from "react";
import { ActionIcon, Flex, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Youtube } from "iconsax-react";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import Logo from "../Logo";

type SocialLinksProps = {
  href: string;
  icon: React.ReactNode;
  name: string;
};

const SocialsFooter: React.FC = () => {
  const theme = useMantineTheme();
  const isLargerThan1120 = useMediaQuery("(min-width: 1120px)");

  const SocialLinks: SocialLinksProps[] = [
    {
      href: "https://www.instagram.com/traderevolutionbr/",
      icon: <AiFillInstagram size={32} color={theme.colors.basic[0]} />,
      name: "Instagram",
    },
    {
      href: "https://www.youtube.com/channel/UCXTirPWKQ0CLE9b59f2vWVQ",
      icon: <Youtube size={32} color={theme.colors.basic[0]} variant="Bold" />,
      name: "Youtube",
    },
    {
      href: "https://www.linkedin.com/company/traderevolution/",
      icon: <FaLinkedinIn size={32} color={theme.colors.basic[0]} />,
      name: "Linkedin",
    },
    {
      href: "https://www.facebook.com/TraderEvo",
      icon: <FaFacebookF size={32} color={theme.colors.basic[0]} />,
      name: "Facebook",
    },
    // {
    //   href: "https://t.me/tebrasiloficial",
    //   icon: <FaTelegramPlane size={32} color={theme.colors.basic[0]} />,
    //   name: "Telegram",
    // },
  ];

  return (
    <Flex
      direction="column"
      gap={{ base: 32, 550: 40 }}
      mih={118}
      h="100%"
      w="100%"
      align={{ base: "start", 450: "center", 796: "end" }}
      maw={{ base: 310, 450: 480, 796: 180, 1120: 310 }}
    >
      <Flex justify={{ base: "start", 670: "end" }}>
        <Logo height={isLargerThan1120 ? 45 : 53} />
      </Flex>
      <Flex justify="space-between" align="center" w="100%">
        {SocialLinks.map((item, index) => (
          <ActionIcon
            key={index}
            component="a"
            target="_blank"
            href={item.href}
            id={item.name}
            variant="transparent"
            sx={{
              height: "fit-content",
              width: "fit-content",
              transition: "0.3s",
              borderRadius: "0 !important",
              padding: "0",
              border: "unset",
              svg: {
                ":hover": {
                  filter: "drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.5))",
                  WebkitFilter:
                    "drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.5))",
                },
              },
            }}
          >
            {item.icon}
          </ActionIcon>
        ))}
      </Flex>
    </Flex>
  );
};

export default SocialsFooter;

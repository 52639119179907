import React from "react";
import { Button, Text } from "@mantine/core";
import { ArrowRight2, Mobile, Sms } from "iconsax-react";

interface IPropsTypeRecover {
  nextStep: () => void;
  setType: Function;
}

const TypeRecover: React.FC<IPropsTypeRecover> = ({ nextStep, setType }) => {
  return (
    <>
      <Text size={16} fw={400} mx={12} mb={24} maw={602} ta="center">
        Selecione como você deseja recuperar sua senha:
      </Text>
      <Button
        onClick={() => {
          nextStep();
          setType("email");
        }}
        fullWidth
        rightIcon={<ArrowRight2 size={28} />}
        leftIcon={<Sms size={28} color="#1B4FDF" />}
        variant="recoverPassword"
        mb={16}
        h={88}
      >
        Recuperar via e-mail
      </Button>
      {/* <Button
        onClick={() => {
          setType("phone");
          nextStep();
        }}
        fullWidth
        rightIcon={<ArrowRight2 size={28} />}
        leftIcon={<Mobile size={28} color="#1B4FDF" />}
        variant="recoverPassword"
        h={88}
        styles={{
          leftIcon: {
            svg: {
              marginLeft: "8px",
            },
          },
        }}
      >
        Recuperar via telefone
      </Button> */}
    </>
  );
};

export default TypeRecover;

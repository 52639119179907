import { Flex, Text, Title, useMantineTheme } from "@mantine/core";

interface IProps {
  text: string;
  title: string;
  leftIcon?: React.ReactNode;
}

const IconTitleText: React.FC<IProps> = (props) => {
  const theme = useMantineTheme();

  return (
    <Flex gap="8px" align="center" h="fit-contant" mih="48px" w="100%">
      <Flex
        w={24}
        h={24}
        sx={{
          svg: { color: theme.colors.basic[4] },
        }}
      >
        {props.leftIcon}
      </Flex>
      <Flex justify="space-between" direction="column" h="fit-content" w="100%">
        <Title
          tt="uppercase"
          fw={400}
          size={14}
          lh="22px"
          color="basic.4"
          ff="barlow"
          lineClamp={1}
          style={{
            wordBreak: "break-all",
          }}
        >
          {props.title}
        </Title>
        <Text
          fw={600}
          size={16}
          lh="25px"
          color="basic.5"
          ff="barlow"
          style={{
            width: "fit-content",
          }}
        >
          {props.text}
        </Text>
      </Flex>
    </Flex>
  );
};

export default IconTitleText;

import { Card, CardProps } from "@mantine/core";

interface IProps extends CardProps {
  children: React.ReactNode;
  select?: boolean;
  onClick?: () => void;
}

const ComponentBrokerCard: React.FC<IProps> = ({
  onClick,
  children,
  select,
  ...props
}) => {
  return (
    <Card
      {...props}
      onClick={onClick}
      sx={(theme) => ({
        width: "100%",
        textDecoration: "none",
        cursor: "pointer",
        transition: "all 0.5s ease-in-out",
        borderRadius: "12px",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",

        div: {
          figure: {
            overflow: "hidden",
            div: {
              overflow: "hidden",
              img: {
                transition: "all 0.5s ease-in-out",
              },
            },
          },
        },

        button: {
          borderColor: theme.colors.basic[2],
          color: theme.colors.basic[4],
          background: theme.colors.basic[0],
          transition: "all 0.3s ease-in-out",
        },

        ":hover": {
          boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.09)",
          transition: "0.5s",

          figure: {
            overflow: "hidden",
            div: {
              overflow: "hidden",
              img: {
                transition: "all 0.5s ease-in-out",
                scale: "1.1",
              },
            },
          },

          button: {
            color: theme.colors.basic[0],
            borderColor: theme.colors.primary[1],
            background: theme.colors.primary[1],
          },
        },
      })}
    >
      {children}
    </Card>
  );
};

export default ComponentBrokerCard;

import { Box, Center, Image, ImageProps } from "@mantine/core";
import { LogoTraderEvolution } from "../../../../assets";
import CategorieBadge from "./CategorieBadge";

interface IProps extends ImageProps {
  title: string;
  image?: string;
  select?: boolean | false;
  badgeName?: string;
  badgeColor?:
    | string
    | "green"
    | "orange"
    | "violet"
    | "red"
    | "blue"
    | "pink"
    | "purple"
    | "babyBlue";
  full?: boolean | false;
}

const ImageHighligthCard: React.FC<IProps> = ({
  title,
  image,
  select,
  badgeName,
  badgeColor,
  full,
  ...props
}) => {
  return (
    <Box
      sx={{
        div: {
          overflow: "hidden",
          borderRadius: full ? "unset" : "12px",
        },
      }}
      pos="relative"
    >
      <Image
        {...props}
        src={image}
        alt={title}
        withPlaceholder
        placeholder={
          <Center
            h="100%"
            bg="primary.0"
            w="100%"
            sx={{
              img: {
                height: select ? "51px" : "35px",
                transition: "all 0.5s ease-in-out",
              },
            }}
          >
            <img src={LogoTraderEvolution} alt={title} />
            {
              <CategorieBadge
                select={select}
                badgeName={badgeName}
                badgeColor={badgeColor}
              />
            }
          </Center>
        }
      />
      {
        <CategorieBadge
          select={select}
          badgeName={badgeName}
          badgeColor={badgeColor}
        />
      }
    </Box>
  );
};

export default ImageHighligthCard;

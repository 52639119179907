import * as Yup from "yup";

export const Validation = Yup.object().shape({
  username: Yup.string().required("Informe seu nome"),
  document: Yup.string().required("CPF inválido"),
  email: Yup.string()
    .email("Informe seu e-mail válido")
    .required("Informe seu e-mail"),
  phone: Yup.string()
    .min(15, "Informe o telefone cooretamente")
    .required("Informe seu telefone"),
  password: Yup.string()
    .required("Campo obrigatório")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z 0-9])(?=.{8,})/,
      "A senha deve possuir todos os requisitos"
    ),
  secondPassword: Yup.string()
    .required("Campo obrigatório")
    .oneOf([Yup.ref("password")], "Senhas diferentes"),
  term_of_contract: Yup.boolean().oneOf(
    [true],
    "Você precisa aceitar os termos"
  ),
});

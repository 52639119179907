import * as Yup from "yup";
import {
  booleanIsRequiredIf,
  dateIsRequiredIf,
  notRequired,
  notRequiredBoolean,
  requiredNumber,
  required,
  stringIsRequiredIf,
  email,
  numberIsRequiredIf,
} from "../YupSchema";
import { arrayOfObject } from "../YupSchema";
export type TypeString = string | null;
export type TypeAwardType = "money" | "points" | "others" | string;
export type PropsPaymentChamps = {
  valueVar: number;
  pointsVar: number;
  dateInitial: Date;
  dateFinal: Date;
};
export type PropsPaymentMethods = {
  creditVindi: boolean;
  pixVindi: boolean;
  boletoVindi: boolean;
  wallet: boolean;
  points: boolean;
};
export type IStep1 = {
  isChampionshipTrader: boolean;
  organization?: TypeString;
  analist?: TypeString;
  isIndividual: boolean;
  nameChampionship: string;
  selectLeague: TypeString;
  actives: { name: TypeString }[];
  allowedContracts: number;
  limitLoss: number;
  image: string;
  descriptionChampionship: string;
  leagueAwardType: TypeAwardType;
  leagueAwardValue: number;
  leagueAwardInfo: string;
  leagueAwardTop: number;
  leagueParticipants: number;
  podium: {
    valueAward: number;
  }[];
  podiumOthers: {
    othersAward: string;
  }[];
  isEnabledChat: boolean;
};

export type IStep2 = {
  isAwardFixed: boolean;
  awardType: TypeAwardType;
  awardValue: number;
  awardInfo: string;
  awardTop: number;
  participantsChamps: number;
  podium: {
    valueAward: number;
  }[];
  podiumPercent: {
    percentAward: number;
  }[];
  podiumOthers: {
    othersAward: string;
  }[];
};

export type IStep3 = {
  isEqualPeriod: boolean;
  subscribeDateInitial: Date;
  subscribeDateEnds: Date;
  dateInitial: Date;
  dateEnds: Date;
};

export type IStep4 = {
  isPayChamp: boolean;
  linkVindi: string;
  isSingleFee: boolean;
  valueParticipationFee: number;
  pointsParticipationFee: number;
  paymentMethods: PropsPaymentMethods;
  paymentChamps: PropsPaymentChamps[];
};

export type IStep5 = {
  isOpenChamp: boolean;
  participantChamps: {
    name: string;
    email: string;
  }[];
};

export type IStep6 = { termChamp: string };

export interface IFormData {
  step1: IStep1;
  step2: IStep2;
  step3: IStep3;
  step4: IStep4;
  step5: IStep5;
  step6: IStep6;
}
export const validationRegisterChampionship = Yup.object<IFormData>().shape({
  step1: Yup.object({
    isChampionshipTrader: notRequiredBoolean,
    organization: required,
    analist: required,
    isIndividual: notRequiredBoolean,
    nameChampionship: required,
    selectLeague: stringIsRequiredIf({
      nameValidation: "isIndividual",
      resultValidation: false,
    }),
    actives: arrayOfObject({ name: required }),
    allowedContracts: requiredNumber,
    limitLoss: requiredNumber,
    image: notRequired,
    descriptionChampionship: required,
    leagueAwardType: notRequired,
    leagueAwardValue: Yup.number().when(["isIndividual", "leagueAwardType"], {
      is: (isIndividual: boolean, leagueAwardType: string) =>
        !isIndividual &&
        (leagueAwardType === "money" || leagueAwardType === "points"),
      then: (value) =>
        value.required("Campo obrigatório").moreThan(0, "Campo obrigatório"),
      otherwise: (value) => value.optional(),
    }),
    leagueAwardInfo: Yup.string().when(["isIndividual", "leagueAwardType"], {
      is: (isIndividual: boolean, leagueAwardType: string) =>
        !isIndividual && leagueAwardType === "others",
      then: (value) => value.required("Campo obrigatório"),
      otherwise: (value) => value.optional(),
    }),
    leagueAwardTop: numberIsRequiredIf({
      nameValidation: "isIndividual",
      resultValidation: false,
    }),
    leagueParticipants: numberIsRequiredIf({
      nameValidation: "isIndividual",
      resultValidation: false,
    }),
    podium: Yup.array().when(["isIndividual", "leagueAwardType"], {
      is: (isIndividual: boolean, leagueAwardType: string) =>
        !isIndividual && leagueAwardType !== "others",
      then: (value) =>
        value
          .of(
            Yup.object().shape({
              valueAward: requiredNumber,
            })
          )
          .min(1, "Pelo menos um pagamento é obrigatório"),
      otherwise: (value) => value.notRequired(),
    }),
    podiumOthers: Yup.array().when(["isIndividual", "leagueAwardType"], {
      is: (isIndividual: boolean, leagueAwardType: string) =>
        !isIndividual && leagueAwardType === "others",
      then: (value) =>
        value
          .of(
            Yup.object().shape({
              othersAward: required,
            })
          )
          .min(1, "Pelo menos um pagamento é obrigatório"),
      otherwise: (value) => value.notRequired(),
    }),
    isEnabledChat: notRequiredBoolean,
  }),

  step2: Yup.object({
    isAwardFixed: notRequiredBoolean,
    awardType: notRequired,
    awardValue: Yup.number().when(["isAwardFixed", "awardType"], {
      is: (isAwardFixed: boolean, awardType: string) =>
        isAwardFixed && (awardType === "money" || awardType === "points"),
      then: (value) =>
        value.required("Campo obrigatório").moreThan(0, "Campo obrigatório"),
      otherwise: (value) => value.optional(),
    }),
    awardInfo: Yup.string().when(["isAwardFixed", "awardType"], {
      is: (isAwardFixed: boolean, awardType: string) =>
        isAwardFixed && awardType === "others",
      then: (value) => value.required("Campo obrigatório"),
      otherwise: (value) => value.optional(),
    }),
    awardTop: requiredNumber,
    participantsChamps: Yup.number()
      .required("Campo obrigatório")
      .moreThan(0, "Campo obrigatório"),
    podium: Yup.array().when(["isAwardFixed", "awardType"], {
      is: (isAwardFixed: boolean, awardType: string) =>
        isAwardFixed && awardType !== "others",
      then: (value) =>
        value
          .of(
            Yup.object().shape({
              valueAward: requiredNumber,
            })
          )
          .min(1, "Pelo menos um pagamento é obrigatório"),
      otherwise: (value) => value.notRequired(),
    }),
    podiumOthers: Yup.array().when(["isAwardFixed", "awardType"], {
      is: (isAwardFixed: boolean, awardType: string) =>
        isAwardFixed && awardType === "others",
      then: (value) =>
        value
          .of(
            Yup.object().shape({
              othersAward: required,
            })
          )
          .min(1, "Pelo menos um pagamento é obrigatório"),
      otherwise: (value) => value.notRequired(),
    }),
    podiumPercent: Yup.array().when("isAwardFixed", {
      is: false,
      then: (value) =>
        value
          .of(
            Yup.object().shape({
              percentAward: requiredNumber,
            })
          )
          .min(1, "Pelo menos um pagamento é obrigatório"),
      otherwise: (value) => value.notRequired(),
    }),
  }),
  step3: Yup.object({
    isEqualPeriod: notRequiredBoolean,
    subscribeDateInitial: dateIsRequiredIf({
      nameValidation: "isEqualPeriod",
      resultValidation: true,
    }),
    subscribeDateEnds: dateIsRequiredIf({
      nameValidation: "isEqualPeriod",
      resultValidation: true,
    }),
    dateInitial: Yup.date().required(),
    dateEnds: Yup.date().required(),
  }),
  step4: Yup.object({
    isPayChamp: notRequiredBoolean,
    linkVindi: stringIsRequiredIf({
      nameValidation: "isPayChamp",
      resultValidation: true,
    }),
    isSingleFee: booleanIsRequiredIf({
      nameValidation: "isPayChamp",
      resultValidation: true,
    }),
    paymentMethods: Yup.object().when("isPayChamp", {
      is: true,
      then: (value) =>
        value
          .shape({
            creditVindi: Yup.boolean().required(),
            pixVindi: Yup.boolean().required(),
            boletoVindi: Yup.boolean().required(),
            wallet: Yup.boolean().required(),
            points: Yup.boolean().required(),
          })
          .test(
            "at-least-one-method",
            "Selecione pelo menos um método de pagamento",
            (value) => {
              return Object.values(value).some((val) => val === true);
            }
          ),
      otherwise: (value) => value.optional(),
    }),
    valueParticipationFee: Yup.number().when(
      ["isPayChamp", "isSingleFee", "paymentMethods"],
      {
        is: (
          isPayChamp: boolean,
          isSingleFee: boolean,
          paymentMethods: PropsPaymentMethods
        ) =>
          isPayChamp &&
          isSingleFee &&
          (paymentMethods.creditVindi ||
            paymentMethods.pixVindi ||
            paymentMethods.boletoVindi ||
            paymentMethods.wallet),
        then: (value) =>
          value.required("Campo obrigatório").moreThan(0, "Campo obrigatório"),
        otherwise: (value) => value.optional(),
      }
    ),
    pointsParticipationFee: Yup.number().when(
      ["isPayChamp", "isSingleFee", "paymentMethods"],
      {
        is: (
          isPayChamp: boolean,
          isSingleFee: boolean,
          paymentMethods: PropsPaymentMethods
        ) => isPayChamp && isSingleFee && paymentMethods.points,
        then: (value) => value.moreThan(0, "Campo obrigatório").required(),
        otherwise: (value) => value.optional(),
      }
    ),
    paymentChamps: Yup.array().when(
      ["isPayChamp", "isSingleFee", "paymentMethods"],
      {
        is: (
          isPayChamp: boolean,
          isSingleFee: boolean,
          paymentMethods: PropsPaymentMethods
        ) =>
          isPayChamp &&
          !isSingleFee &&
          Object.values(paymentMethods).some((val) => val === true),
        then: (value) =>
          value
            .of(
              Yup.object().shape({
                valueVar: Yup.number().test(
                  "valueVar-required",
                  "Campo obrigatório",
                  async (value, context) => {
                    const { from } = context;
                    const paymentMethods = await from?.[1].value.paymentMethods;
                    const isSingleFee = await from?.[1].value.isSingleFee;
                    const isPayChamp = await from?.[1].value.isPayChamp;
                    if (
                      isPayChamp &&
                      !isSingleFee &&
                      (paymentMethods.creditVindi ||
                        paymentMethods.pixVindi ||
                        paymentMethods.boletoVindi ||
                        paymentMethods.wallet)
                    ) {
                      if (!!value && value > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }
                ),
                pointsVar: Yup.number().test(
                  "pointsVar-required",
                  "Campo obrigatório",
                  async (value, context) => {
                    const { from } = context;
                    const paymentMethods = await from?.[1].value.paymentMethods;
                    const isSingleFee = await from?.[1].value.isSingleFee;
                    const isPayChamp = await from?.[1].value.isPayChamp;
                    if (isPayChamp && !isSingleFee && paymentMethods.points) {
                      if (!!value && value > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }
                ),
                dateInitial: Yup.date().required(),
                dateFinal: Yup.date().required(),
              })
            )
            .min(1, "Pelo menos um pagamento é obrigatório"),
        otherwise: (value) => value.notRequired(),
      }
    ),
  }),
  step5: Yup.object({
    isOpenChamp: notRequiredBoolean,
    participantChamps: Yup.array().when("isOpenChamp", {
      is: false,
      then: (value) =>
        value
          .of(
            Yup.object().shape({
              name: required,
              email: email,
            })
          )
          .min(1, "Pelo menos um pagamento é obrigatório"),
      otherwise: (value) => value.notRequired(),
    }),
  }),
  // step6: Yup.object({ termChamp: required }),
});

export type LinksProps = {
  href?: string;
  label: string;
  name: string;
  nameSubLink?: string;
  links?: LinksProps[];
};

type SUB_NAV_LINKS_PROPS = {
  href: string;
  label: string;
  name: string;
  links?: LinksProps[];
};

type NAV_LINKS_PROPS = {
  authType: string;
  role: string;
  name: string;
  links: SUB_NAV_LINKS_PROPS[];
};
export interface IPropsLinkItem {
  name: string;
  path: string;
  blank?: boolean;
}

export interface ILinkItem extends IPropsLinkItem {
  subLinks?: IPropsLinkItem[];
}

const navbarLinksDefault: ILinkItem[] = [
  {
    name: "Home",
    path: "https://spidertrader.com.br/home/",
    blank: true,
  },
  {
    name: "Produtos",
    path: "https://spidertrader.com.br/produtos/",
    blank: true,
  },
  {
    name: "Marketplace",
    path: "/marketplace",
    subLinks: [
      {
        name: "Destaques",
        path: "/marketplace",
      },
      {
        name: "CopyOrder",
        path: "/marketplace/copyorder",
      },
    ],
  },
  {
    name: "Corretoras",
    path: "https://spidertrader.com.br/corretoras/",
    blank: true,
  },
  {
    name: "Central de Ajuda",
    path: "https://spidertrader.com.br/central-de-ajuda/",
    blank: true,
  },
];

const navbarLinksAnalista: ILinkItem[] = [
  {
    name: "Home",
    path: "https://spidertrader.com.br/home/",
    blank: true,
  },
  {
    name: "Produtos",
    path: "https://spidertrader.com.br/produtos/",
    blank: true,
  },
  {
    name: "Marketplace",
    path: "/marketplace",
    subLinks: [
      {
        name: "Destaques",
        path: "/marketplace",
      },
      {
        name: "CopyOrder",
        path: "/marketplace/copyorder",
      },
      {
        name: "SmartCopy",
        path: "/marketplace/smartcopy",
      },
    ],
  },
  {
    name: "Corretoras",
    path: "https://spidertrader.com.br/corretoras/",
    blank: true,
  },
  {
    name: "Central de Ajuda",
    path: "https://spidertrader.com.br/central-de-ajuda/",
    blank: true,
  },
  {
    name: "Meus produtos",
    path: "/meus-produtos",
  },
  {
    name: "Minhas compras",
    path: "/minhas-compras",
  },
];

const navbarLinksCliente: ILinkItem[] = [
  {
    name: "Home",
    path: "https://spidertrader.com.br/home/",
    blank: true,
  },
  {
    name: "Produtos",
    path: "https://spidertrader.com.br/produtos/",
    blank: true,
  },
  {
    name: "Marketplace",
    path: "/marketplace",
    subLinks: [
      {
        name: "Destaques",
        path: "/marketplace",
      },
      {
        name: "CopyOrder",
        path: "/marketplace/copyorder",
      },
    ],
  },
  {
    name: "Corretoras",
    path: "https://spidertrader.com.br/corretoras/",
    blank: true,
  },
  {
    name: "Central de Ajuda",
    path: "https://spidertrader.com.br/central-de-ajuda/",
    blank: true,
  },
  {
    name: "Minhas compras",
    path: "/minhas-compras",
  },
];

export const navbarLinks = (role: string | undefined): ILinkItem[] => {
  if (role?.includes("Analista")) {
    return navbarLinksAnalista;
  } else if (role?.includes("Cliente")) {
    return navbarLinksCliente;
  } else {
    return navbarLinksDefault;
  }
};

const SUB_NAV_LINKS_MARKETPLACE: LinksProps[] = [
  {
    href: "/",
    label: "Todos",
    name: "Destaques",
    nameSubLink: "Todos",
  },
  {
    href: "/marketplace/CopyOrder",
    label: "CopyOrder",
    name: "CopyOrder",
    nameSubLink: "CopyOrder",
  },
  // {
  //   href: "/marketplace/campeonatos",
  //   label: "Campeonatos",
  //   name: "Campeonatos",
  //   nameSubLink: "Campeonatos",
  // },
  // {
  //   href: "/marketplace/algoritmos-indicadores",
  //   label: "Algoritmos & Indicadores",
  //   name: "Algoritmos & Ind.",
  //   nameSubLink: "Algoritmos & Indicadores",
  // },
  // {
  //   href: "/marketplace/cursos",
  //   label: "Cursos",
  //   name: "Cursos",
  //   nameSubLink: "Cursos",
  // },
  // {
  //   href: "/marketplace/plataformas",
  //   label: "Plataformas",
  //   name: "Plataformas",
  //   nameSubLink: "Plataformas",
  // },
  // {
  //   href: "/solucoes/trader-evolution/work-spaces",
  //   label: "Work Spaces",
  //   name: "Work Spaces",
  //   nameSubLink: "Work Spaces",
  // },
];

const SUB_NAV_LINKS_SOLUCOES_TRADE: LinksProps[] = [
  {
    href: "/solucoes/trader-evolution",
    label: "Todos",
    name: "Soluções da Trader",
    nameSubLink: "Todos",
  },
  // {
  //   href: "/solucoes/trader-evolution/ideas",
  //   label: "Trading Ideas",
  //   name: "Trading Ideas",
  //   nameSubLink: "Trading Ideas",
  // },
  {
    href: "/solucoes/trader-evolution/algoritmos-indicadores",
    label: "Algoritmos & Indicadores",
    name: "Algoritmos & Ind.",
    nameSubLink: "Algoritmos & Indicadores",
  },
  {
    href: "/solucoes/trader-evolution/plataformas",
    label: "Plataformas",
    name: "Plataformas",
    nameSubLink: "Plataformas",
  },
  {
    href: "/solucoes/trader-evolution/workspaces",
    label: "Workspaces",
    name: "Workspaces",
    nameSubLink: "Workspaces",
  },
];

const SUB_NAV_LINKS_SOLUCOES_PRODUTOS: LinksProps[] = [
  {
    href: "/solucoes/produtos/desktop",
    label: "Pro (Desktop)",
    name: "Pro (Desktop)",
    nameSubLink: "Pro (Desktop)",
  },
  {
    href: "/solucoes/produtos/mac",
    label: "Mac",
    name: "Mac",
    nameSubLink: "Mac",
  },
  {
    href: "/solucoes/produtos/web",
    label: "Web",
    name: "Web",
    nameSubLink: "Web",
  },
  {
    href: "/solucoes/produtos/mobile",
    label: "Mobile",
    name: "Mobile",
    nameSubLink: "Mobile",
  },
  {
    href: "/solucoes/produtos/simulador",
    label: "Simulador",
    name: "Simulador",
    nameSubLink: "Simulador",
  },
  {
    href: "/solucoes/produtos/ideas",
    label: "Trading Ideas",
    name: "Trading Ideas",
    nameSubLink: "Trading Ideas",
  },
];

const SUB_NAV_LINKS_SOLUCOES_CORRETORAS: LinksProps[] = [
  {
    href: "/solucoes/corretoras/homologadas",
    label: "Homologadas",
    name: "Homologadas",
    nameSubLink: "Corretoras homologadas",
  },
  {
    href: "/solucoes/corretoras/seja-parceira",
    label: "Seja uma parceira",
    name: "Seja uma parceira",
    nameSubLink: "Seja uma corretora parceira",
  },
];

const SUB_NAV_LINKS_SOLUCOES: LinksProps[] = [
  {
    label: "corretoras",
    name: "Corretoras",
    links: SUB_NAV_LINKS_SOLUCOES_CORRETORAS,
  },
  {
    label: "produtos",
    name: "Produtos",
    links: SUB_NAV_LINKS_SOLUCOES_PRODUTOS,
  },
  {
    label: "solucoes_trade",
    name: "Soluções TraderEvolution",
    links: SUB_NAV_LINKS_SOLUCOES_TRADE,
  },
];

const DATA_TABS_CONTENT_LIST = [
  {
    value: "corretoras",
    data: SUB_NAV_LINKS_SOLUCOES_CORRETORAS,
    width: 211,
    widthFlex: 211,
  },
  {
    value: "produtos",
    data: SUB_NAV_LINKS_SOLUCOES_PRODUTOS,
    width: 176,
    widthFlex: 350,
  },
  {
    value: "trader-evolution",
    data: SUB_NAV_LINKS_SOLUCOES_TRADE,
    width: 269,
    widthFlex: 440,
  },
];

const FOOTER_LINKS_TERMS: LinksProps[] = [
  {
    href: "/politica-de-privacidade",
    name: "Política de privacidade",
    label: "Política de privacidade",
  },
  {
    href: "/termo-de-uso",
    name: "Termos de uso",
    label: "Termos de uso",
  },
];

const FOOTER_LINKS: LinksProps[] = [
  /* {
    href: "/quem-nos-somos",
    name: "Quem nós somos?",
    label: "Quem nós somos?",
  }, */
  {
    href: "https://traderevolution.com.br/blog/",
    name: "Blog",
    label: "Blog",
  },
  {
    href: "https://atendimento.traderevolution.com.br/kb",
    name: "Ajuda",
    label: "Ajuda",
  },
];

const NAV_LINKS: NAV_LINKS_PROPS[] = [
  {
    authType: "",
    role: "",
    name: "",
    links: [
      {
        href: "/",
        label: "Marketplace",
        name: "Marketplace",
        links: SUB_NAV_LINKS_MARKETPLACE,
      },
      // {
      //   href: "/solucoes",
      //   label: "Soluções",
      //   name: "Soluções",
      //   links: SUB_NAV_LINKS_SOLUCOES,
      // },
    ],
  },
  {
    authType: "cliente",
    role: "Analista",
    name: "meu nome de analista",
    links: [
      {
        href: "/",
        label: "Marketplace",
        name: "Marketplace",
        links: SUB_NAV_LINKS_MARKETPLACE,
      },
      // {
      //   href: "/solucoes",
      //   label: "Soluções",
      //   name: "Soluções",
      //   links: SUB_NAV_LINKS_SOLUCOES,
      // },
      // {
      //   href: "/meus-campeonatos",
      //   label: "Meus campeonatos",
      //   name: "Meus campeonatos",
      // },
      {
        href: "/minhas-compras",
        label: "Minhas compras",
        name: "Minhas compras",
      },
      {
        href: "/meus-produtos",
        label: "Minhas compras",
        name: "Minhas compras",
      },
    ],
  },
  {
    authType: "Analista",
    role: "Cliente",
    name: "meu nome de cliente",
    links: [
      {
        href: "/",
        label: "Marketplace",
        name: "Marketplace",
        links: SUB_NAV_LINKS_MARKETPLACE,
      },
      // {
      //   href: "/meus-campeonatos",
      //   label: "Meus campeonatos",
      //   name: "Meus campeonatos",
      // },
      {
        href: "/minhas-compras",
        label: "Minhas compras",
        name: "Minhas compras",
      },
      // {
      //   href: "/analistas",
      //   label: "Analistas",
      //   name: "Analistas",
      // },
    ],
  },
  // {
  //   authType: "login3",
  //   role: "Master",
  //   name: "Fernando Barreto",
  //   links: [
  //     {
  //       href: "/Produtos",
  //       label: "Produtos",
  //       name: "Produtos",
  //     },
  //     {
  //       href: "/usuarios",
  //       label: "Usuários",
  //       name: "Usuários",
  //     },
  //     {
  //       href: "/aprovacoes",
  //       label: "Aprovações",
  //       name: "Aprovações",
  //     },
  //   ],
  // },
  // {
  //   authType: "login4",
  //   role: "Financeiro",
  //   name: "Sarah Oliveira",
  //   links: [
  //     {
  //       href: "/usuarios",
  //       label: "Usuários",
  //       name: "Usuários",
  //     },
  //     {
  //       href: "/aprovacoes",
  //       label: "Aprovações",
  //       name: "Aprovações",
  //     },
  //   ],
  // },
  // {
  //   authType: "login5",
  //   role: "Comercial",
  //   name: "Adriano Flores",
  //   links: [
  //     {
  //       href: "/marketplace",
  //       label: "Marketplace",
  //       name: "Marketplace",
  //       links: SUB_NAV_LINKS_MARKETPLACE,
  //     },
  //     {
  //       href: "/solucoes",
  //       label: "Soluções",
  //       name: "Soluções",
  //       links: SUB_NAV_LINKS_SOLUCOES,
  //     },
  //     {
  //       href: "/usuarios",
  //       label: "Usuários",
  //       name: "Usuários",
  //     },
  //   ],
  // },
  // {
  //   authType: "login6",
  //   role: "Gestor",
  //   name: "Diego Filho",
  //   links: [
  //     {
  //       href: "/dados",
  //       label: "Dados",
  //       name: "Dados",
  //     },
  //     {
  //       href: "/analistas",
  //       label: "Analistas",
  //       name: "Analistas",
  //     },
  //   ],
  // },
  // {
  //   authType: "login7",
  //   role: "Analista",
  //   name: "Fabiano Silveira",
  //   links: [
  //     {
  //       href: "/dados",
  //       label: "Dados",
  //       name: "Dados",
  //     },
  //   ],
  // },
];
export {
  FOOTER_LINKS_TERMS,
  NAV_LINKS,
  SUB_NAV_LINKS_MARKETPLACE,
  SUB_NAV_LINKS_SOLUCOES,
  SUB_NAV_LINKS_SOLUCOES_TRADE,
  SUB_NAV_LINKS_SOLUCOES_PRODUTOS,
  SUB_NAV_LINKS_SOLUCOES_CORRETORAS,
  FOOTER_LINKS,
  DATA_TABS_CONTENT_LIST,
};

import React, { useState } from "react";
import { Box, Text, Flex, Image } from "@mantine/core";
import { IPlan } from "../../components/Cards/ComponentsCards/typesCards";
import { InterestContext, useAuth } from "../../context/auth";
import { convertCurrencyBR } from "../../utils/currency";
interface IPlanSelectorProps {
  plan: IPlan;
  onSelect: () => void; // Função para selecionar o plano
}

const PlanSelector = ({ plan, onSelect }: IPlanSelectorProps) => {
  return (
    <Flex
      onClick={onSelect}
      sx={(theme) => ({
        backgroundColor: plan.isSelected ? theme.colors.blue[0] : "#fff",
        border: plan.isSelected
          ? `1.5px solid ${theme.colors.blue[6]}`
          : `1px solid ${theme.colors.gray[3]}`,
        borderRadius: "8px",
        padding: "12px",
        gap: "8px",
        alignItems: "center",
        flex: 1,
        cursor: "pointer", // Adicionado cursor pointer para indicar que é clicável
      })}
    >
      <Flex>
        <Box
          sx={{
            flexShrink: 0,
            width: "28px",
            height: "28px",
            position: "relative",
          }}
        >
          <Image
            src={
              plan.isSelected
                ? "vuesax-linear-arrow-circle-left-selected.svg"
                : "vuesax-linear-arrow-circle-left.svg"
            }
            alt=""
          />
        </Box>
        <Box>
          <Text
            sx={{
              color: "#2f2f2f",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "155%",
            }}
          >
            {plan.name}
          </Text>
          <Text
            sx={{
              color: "#6b6b6b",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "155%",
            }}
          >
            {plan.contractsInfo}
          </Text>
        </Box>
      </Flex>
      <Text
        sx={{
          color: "#2f2f2f",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "155%",
        }}
      >
        {convertCurrencyBR(plan.price)}
      </Text>
    </Flex>
  );
};

interface IProps {
  product: InterestContext;
}

const ProductPlans = ({ product }: IProps) => {
  const { setInterest } = useAuth();

  const handlePlanSelect = (index: number) => {
    const updatedPlans = product.plan.map((plan, i) => ({
      ...plan,
      isSelected: i === index ? !plan.isSelected : false, // Alterna a seleção do plano clicado
    }));

    setInterest((prev) =>
      prev.map((p) =>
        p.slug === product.slug
          ? { ...p, plan: updatedPlans } // Atualiza os planos do produto selecionado
          : p
      )
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        alignSelf: "stretch",
        flexShrink: 0,
        position: "relative",
      }}
    >
      <Flex gap="8px" w="100%" justify="space-between">
        <Text
          sx={{
            color: "#2f2f2f",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "155%",
          }}
        >
          Planos
        </Text>
        <Text
          sx={{
            color: "#f43333",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "155%",
          }}
        >
          *
        </Text>
      </Flex>

      <Flex gap="8px" w="100%">
        {product.plan.map((plan, index) => (
          <PlanSelector
            key={index}
            plan={plan}
            onSelect={() => handlePlanSelect(index)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default ProductPlans;

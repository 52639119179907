import React from "react";
import { Text, TypographyStylesProvider, useMantineTheme } from "@mantine/core";

const Term: React.FC = () => {
  const theme = useMantineTheme();
  return (
    <TypographyStylesProvider>
      <Text
        p={{ base: 24, 630: 60 }}
        mx="auto"
        w="100%"
        c={theme.colors.basic[5]}
        maw={1680}
        dangerouslySetInnerHTML={{
          __html: `
            <h1>Termo de contratação</H1>
            <H2>TERMO DE CONTRATAÇÃO DE SOFTWARE E OUTRAS AVENÇAS</H2>
            <p>EVO TECNOLOGIA DA INFORMAÇÃO LTDA.. sociedade empresária de natureza limitada com sede na Avenida Brigadeiro Faria Lima, n. 23.144, 39 andar, Jardim Paulistano, na cidade de São Paulo, Estado de São Paulo, CEP 01.451 000, inscrita no CNPJ/ME sob o n. 204.435.724/0001 19. neste ato representada na forma de seu Contrato Social, doravante designada EVO e</p>
            <p>CLIENTE qualificado na ficha de cadastro, disposta no site da EVO ( quando referido em conjunto com EVO, "e. isoladamente. "Parte")</p>
            <H2>CONSIDERANDO QUE:</H2>
            <p><b> (a)</b>	A EVO è proprietária e distribuidora de licenças de uso do software denominado que consiste em uma tecnologia de negociação de multi-ativos, fornece aos usuários acesso a informações sobre cotações de ativos e demais dados que auxiliam na análise do mercado financeiro, além des automatizar o envio de ordens para negociação de valores mobiliários na B3 S/A ("Bolsa") nos segmentos Bovespa e BMF, mediante parâmetros definidos e inseridos no Software pelo próprio usuário;</p><p>
            <b>(b)</b> O CLIENTE, ao aderir a este contrato aceita todos os termos nele inseridos, declara ter conhecimento de uso do software, reconhece e aceita as condições de risco financeiros das operações realizadas na Bolsa.</p>
            <p>RESOLVEM as Partes celebrar o presente Contrato, o qual se reger á em conformidade com os seguintes termos e condições:</p>
            <h3>1 - O presente Contrato tem por objetivo conceder acesso ao Cliente, bem como regular o uso do software TraderEvolution</H3>
            <p>1.1 O software TraderEvolution será instalado pelo Cliente através de download e, a partir da inserção de dados de login e senha, possibilitando ao Cliente acessar as funcionalidades da ferramenta, bem como serà possível o envio de ordens para negociação na Bolsa, nos segmentos Bovespa e BMF, mediante parâmetros definidos pelo Cliente.</p>
            <p>1.2 A EVO não possui qualquer interferència sobre a estratégia, parámetro ou algoritmo utilizado pelo Cliente para a execução da operação, motivo pelo qual não se responsabiliza por qualquer perda experimentada pelo Cliente em razão do uso do software TraderEvolution.</p>
            <h3>2 - O Cliente declara se ciente:</H3>
            <p>(I) Da complexidade e da forma de utilização do software TraderEvolution:</p>
            <p>(II) Que, para a utilização do software TraderEvolution deverá possuir conexão e equipamentos tecnológicos compatíveis e que possuam a configuração mínima exigida pela EVO, tal como informado descritivo do software, disponibilizado ao cliente no momento da contratação:</p>
            <p>(III) A EVO tem a prerrogativa de imediatamente e sem a necessidade de aviso prévio, interromper o acesso do Cliente, caso o uso ou acesso, por qualquer razão, coloque em risco a estrutura tecnológica da EVO;</p>
            <p>2.1 O presente contrato se destina aos Investidores não profissionais, assim entendidos como os clientes Pessoa Fisica e Pessoas Juridicas não Institucionals.</p>
            <h3>3 - Pela utilização do software TradeEvolution, o Cliente pagará à EVO, mensalmente, o valor referente ao plano contratado, acrescido dos tributos incidentes sobre o serviço.</H3>
            <p>3.1 Caso seja a primeira vez que o cliente contrate a ferramenta no e-commerce EVO, será oferecido um período de degustação de 7 dias corridos do produto, periodo no qual o cliente poderá cancelar a ferramenta sem nenhum custo. Caso o cliente não cancele a plataforma dentro do periodo, a renovação da assinatura será feita de maneira automática, sendo que ocorrerá a efetivação de um ponto de utilização junto ao software TraderEvolution e o valor da mensalidade será cobrado no més seguinte.</p>
            <p>3.1.1 O periodo de degustação oferecido sem custo, somente poderá ser utilizado somente uma única vez por CPF portanto, após utilizar o período de degustação, não será permitido ao Cliente um novo periodo, mesmo que utilize em outra corretora, ou em outras datas (novas contratações), Neste caso a cobrança será automática no més subsequente a uso da plataforma.</p>
            <p>3.2 As cobranças serão realizadas até o quinto dia útil do més seguinte ao uso, ou seja, at e o quinto dia útil após o término do período de degustação o valor referente ao primeiro mês sera cobrado. A partir desta data, a cada quinto dia útil do mês, o cliente serà debitado do valor correspondente ao pagamento mensal do més anterior.</p>
            <p>3.3 No ato da contratação o cliente poderá optar em realizar o pagamento através de <span class="underline"> Cartão de Crédito.<span></p>
            <p>3.3.1 Se por qualquer motivo, o pagamento não for localizado pela EVO, a contratação será suspensa até regularização por parte do cliente.</p>
            <p>3.4 A EVO pode alterar o valor cobrado pela utilização do software TraderEvolution previsto na cláusula 3, a qualquer tempo. mediante aviso prévio.</p>
            <p>3.5 0 presente contrato é celebrado por prazo indeterminado e o Cliente poderá rescindi-lo e cancelar o uso a qualquer tempo, a seu exclusivo criterio, mediante solicitação dirigida a Area de Atendimento da EVO.</p>
            <p>3.6 0 sistema ficará disponível ao cliente até o final do mês em que fol feito o pedido de cancelamento. O software ficará disponivel para utilização a é o final do mês, mas, para utilizá-lo. o Cliente deve reativar, a plataforma pelo sistema de E-commerce e cancelar novamente até o ultimo dia útil do mês corrente.</p>
            <p>	Se o Cliente não cancelar novamente até o último dia útil do mês, será cobrado normalmente pela utilização do software, de acordo com a cláusula 3.
            <h3>4 - Após a contratação, o Cliente receberá da EVO, em até 48 horas, no e mail cadastrado, as instruções de instalação além de "log in de usuário e de "para acesso ao software.</H3>
            <p>4.10 log in de usuario e a para acesso ao software são pessoais e intransferíveis devendo o Cliente, por segurança, alterar a senha no primeiro acesso.</p>
            <p>4.2 As ordens inseridas no sistema, a partir da aposição de "log in usuário e de "quando da inicialização do Software, s à o entendidas como ordens próprias e manifestação inequivoca de vontade do Cliente. A EVO desconhece e não se responsabiliza pela estratégia escolhida que pode, eventualmente, gerar perdas superiores ao património total do Cliente.</p>
            <h3>5 - A EVO não se responsabiliza por inconsistências da Bolsa e/ou da Corretora que possam causar prejuízos ao Cliente.</H3>
            <p>5.1 Na eventual impossibilidade de funcionamento do software TraderEvolution não poderá ser invocada pelo cliente para requerer a reparação de prejuízo ou eventual perda de oportunidade;</p>
            <h3>6 - O cliente desde já autoriza a EVO a ter acesso a todo e qualquer dad e/ou informação sobre o Cliente que esteja registrada e/ou armazenada na Corretora, incluindo aquelas que estejam protegidas por sigilo bancário e/ou fiscal, para que assim o Cliente possa enviar ordens e ter acesso às suas informações privadas nos Softwares.</H3>
            <h3>7 - O Cliente será o único e exclusivo responsável:</H3>
            <p>(I) pelo seu "login" e senha, bem como pelo manuselo operacional do sistema não podendo alegar o desconhecimento das funcionalidades para pleitear qualquer ressarcimento;</p>
            <p>(II) pela seleção, escolha ou criação das estrategias a serem executados pelo sistema, bem como pela parametrização das mesmas. As perdas decorrentes da execução das estrategias inseridas no sistema são de inteira responsabilidade do cliente;</p>
            <p>(I) pela adequação, ajuste e/ou alteração da estratégia, acompanhamento, reparametrização durante o dia, ao longo do pregão, se necessario ou se assim desejar, não cabendo à TraderEvolution qualquer responsabilidade decorrente das eventuals perdas experimentadas em decorrência de tais ajustes feitos pelo cliente;</p>
            <p>(IV) pela manutenção de ambiente e equipamentos hábeis a manter o correto funcionamento do software, tais como: conectividade, energia, atualizações do software, capacidade do terminal utilizado e etc.</p>
            <p>7.1 A EVO não poderá ser responsabilizada nos casos de:</p>
            <p>(I) Atos alhelos a vontade, tais como falhas verificadas na Bolsa e/ou Corretora, como queda/instabilidade de sistema, queda de links de internet, quedas de energia, casos fortuitos e/ou de força maior;</p>
            <p>(II) Falhas decorrentes do funcionamento do software TraderEvolution</p>
            <p>(III) perda sofrida pelo Cliente decorrente da execução da estratégia definida e inserida no software TraderEvolution ou por qualquer falha incorrecan inexatidão querro dessa estratégia</p>
            <p>(IV) perda decorrente da inobservância, pelo cliente, das disposições contidas nas cláusulas 2 e 7.</p>
            <h3>8 - As Partes elegem o Foro Central da Comarca de São Paulo para dirimir quaisquer controvérsias oriundas do presente Contrato, renunciando se a qualquer outro, por mais privilegiado que seja ou venha ser.</H3>
            <p>DOCUMENTO ASSINADO ELETRONICAMENTE</p>
          `,
        }}
        sx={{
          h1: {
            fontSize: "62px",
            "@media screen and (max-width: 630px)": {
              fontSize: "48px",
            },
          },
          h2: {
            marginTop: "45px",
            fontSize: "36px",
            fontWeight: 700,
            "@media screen and (max-width: 630px)": {
              fontSize: "24px",
            },
          },
          h3: {
            fontSize: "20px",
            "@media screen and (max-width: 630px)": {
              fontSize: "16px",
            },
          },
          p: {
            fontSize: "20px",
            color: "#6B6B6B",
            "@media screen and (max-width: 630px)": {
              fontSize: "16px",
            },
          },
          ".underline": { textDecoration: "underline" },
        }}
      />
    </TypographyStylesProvider>
  );
};

export default Term;

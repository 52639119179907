import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Receipt, Trash } from "iconsax-react";
import { useAuth } from "../../context/auth";
import { TagCapsules } from "../../components";
import Drawer from "../../components/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { convertCurrencyBR } from "../../utils/currency";

const InterestDrawer = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();
  const { interest, setInterest } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleToggle = (index: number) => {
    setInterest((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleDelete = (index: number) => {
    setInterest((prev) => prev.filter((_, i) => i !== index));
    if (interest.length === 0) close();
  };

  const handleRedirect = useCallback(() => {
    const from = location.pathname;
    navigate("/comprar-produtos", { state: { from } });
    close();
  }, [location.pathname, navigate]);

  return (
    <>
      {!!interest.length ? (
        <Drawer
          opened={opened}
          onClose={close}
          title="Selecione os itens que deseja comprar"
          childrenFooter={
            <Button onClick={handleRedirect} w="100%" variant="primary">
              Ir para pagamento
            </Button>
          }
          heightBody="calc(100vh - 150px - 150px)"
        >
          <Flex direction="column" gap={32} p="24px 48px">
            <Text fs={"20px"} fw={600} color={theme.colors.basic[4]}>
              Selecione todos os itens que deseja comprar
            </Text>
            {interest.map((item, index) => (
              <Flex gap={32} key={index} w="100%" direction="column">
                {index !== 0 && <Divider />}
                <Flex align="center" justify="space-between" w="100%">
                  <Flex align="center" gap={16} style={{ flex: 1 }}>
                    <Checkbox
                      checked={item.selected || false}
                      onChange={() => handleToggle(index)}
                    />
                    <Flex
                      w="100px"
                      h="100px"
                      sx={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "12px",
                      }}
                    />
                    <Flex direction="column" gap={8} style={{ flex: 1 }}>
                      <Flex direction="column" gap={4}>
                        <TagCapsules colorTag="orange" border={true}>
                          COPYTRADING
                        </TagCapsules>
                        <Text color="#2f2f2f" size="lg" weight={700}>
                          {item.title}
                        </Text>
                      </Flex>

                      <Flex direction="column" gap={4}>
                        <Flex align="center" gap={4}>
                          <Text color="#6b6b6b" size="sm">
                            Vendido por:
                          </Text>
                          <Text color="#2f2f2f" size="sm" weight={600}>
                            {item.name}
                          </Text>
                        </Flex>
                        <Flex align="center" gap={4}>
                          <Text color="#6b6b6b" size="sm">
                            Valor:
                          </Text>
                          <Text color="#2f2f2f" size="sm" weight={600}>
                            a partir de {convertCurrencyBR(item.plan[0].price)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Button
                    variant="subtle"
                    style={{ padding: 8, borderRadius: 8 }}
                    onClick={() => handleDelete(index)}
                  >
                    <Trash size="32" color="#6b6b6b" />
                  </Button>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Drawer>
      ) : null}

      <Flex h={{ base: 48, 991: 56 }} w="fit-content" align="center" gap={10}>
        <Tooltip
          label={`Lista de interesses ${!!interest.length ? null : "vazia"}`}
          color={theme.colors.basic[5]}
          position="bottom"
          withArrow
        >
          <Button
            variant="transparentEspecial"
            onClick={open}
            h={{ base: 48, 991: 56 }}
            p={{ base: 8, 991: 12 }}
            leftIcon={
              <Receipt
                size={24}
                variant="Outline"
                color={theme.colors.basic[0]}
              />
            }
            styles={{
              leftIcon: {
                marginRight: !!interest.length ? "10px" : 0,
                "@media (max-width: 991px)": {
                  marginRight: !!interest.length ? "4px" : 0,
                },
              },
            }}
          >
            {!!interest.length ? (
              <Flex
                bg={theme.colors.primary[1]}
                p="4px 8px"
                align="center"
                justify="center"
                style={{
                  borderRadius: "20px",
                  color: theme.colors.basic[0],
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "22px",
                }}
              >
                {interest.length > 9 ? "+9" : `0${interest.length}`}
              </Flex>
            ) : null}
          </Button>
        </Tooltip>
      </Flex>
    </>
  );
};

export default InterestDrawer;

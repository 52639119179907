import { ActionIcon, Flex, Text, Title } from "@mantine/core";
import { ArrowRight } from "iconsax-react";
import { convertCurrencyBR } from "../../../../utils/currency";

type IProps = {
  price?: number;
  points?: string;
  text?: string;
  select?: boolean;
  activeInit?: string;
  activeFinal?: string;
};

const FooterCard: React.FC<IProps> = (props) => {
  return (
    <Flex gap="20px" justify="space-between" align="center">
      {!!props?.text ? (
        <Text
          lineClamp={4}
          fw={400}
          size={14}
          lh="22px"
          color="basic.4"
          ff="Barlow"
        >
          {props.text}
        </Text>
      ) : null}

      <Flex gap="2px" justify="space-between" direction="column">
        <Text
          fw={400}
          fz={{
            base: props.select ? 16.3 : 14,
            991: props.select ? 18.4 : 16,
          }}
          lh={{
            base: props.select ? "25px" : "22px",
            991: props.select ? "29px" : "25px",
          }}
          color="basic.5"
          style={{
            transition: "all 0.5s linear",
          }}
        >
          Planos a partir de:
        </Text>

        <Title
          fw={600}
          fz={{
            base: props.select ? 21 : 18,
            991: props.select ? 24 : 20,
          }}
          lh={{
            base: props.select ? "32.5px" : "28px",
            991: props.select ? "37.2px" : "31px",
          }}
          color="primary.2"
          lineClamp={1}
          style={{
            transition: "all 0.5s linear",
          }}
        >
          {props?.price === 0
            ? "Gratuito"
            : convertCurrencyBR(!!props?.price ? props.price : 0)}
        </Title>
      </Flex>

      {/* {!!props.price && !!props.points ? (
        <Flex gap="2px" justify="space-between" direction="column">
          <Text
            fw={400}
            fz={{ base: 14, 991: props.select ? 16 : 14 }}
            lh={{ base: "22px", 991: props.select ? "25px" : "22px" }}
            color="basic.5"
            style={{
              transition: "all 0.5s linear",
            }}
          >
            Planos{" "}
            <Text span fw={600}>
              a partir{" "}
            </Text>
            de:
          </Text>

          <Title
            fw={600}
            fz={{ base: 18, 991: 20 }}
            lh={{ base: "28px", 991: "31px" }}
            color="primary.2"
            lineClamp={1}
            style={{
              transition: "all 0.5s linear",
            }}
          >
            {currencyFormatter(props?.price)}
            <Text span fw={400}>
              {" "}
              ou{" "}
            </Text>
            {props.points} pts
          </Title>
        </Flex>
      ) : null} */}

      {!!props?.activeInit && !!props?.activeFinal && !!props?.price ? (
        <Flex gap="16px" wrap={"wrap"}>
          <Flex
            p="7.5px 16px"
            bg="primaryOpacity.1"
            align="start"
            style={{ borderRadius: "8px" }}
            direction="column"
          >
            <Text fw={400} size={14} lh="16px" color="basic.5" ff="Barlow">
              inscrição:
            </Text>
            <Text fw={600} size={16} lh="25px" color="primary.1">
              {convertCurrencyBR(props?.price)}
            </Text>
          </Flex>
          <Flex
            p="7.5px 16px"
            bg="primaryOpacity.1"
            align="start"
            style={{ borderRadius: "8px" }}
            direction="column"
          >
            <Text fw={400} size={14} lh="16px" color="basic.5" ff="Barlow">
              Ativos
            </Text>
            <Text fw={600} size={16} lh="25px" color="primary.1" tt="uppercase">
              {props?.activeInit}/{props.activeFinal}
            </Text>
          </Flex>
        </Flex>
      ) : null}

      <ActionIcon
        w={{ base: 40, 991: props.select ? 51.1 : 44 }}
        h={{ base: 40, 991: props.select ? 51.1 : 44 }}
        radius="xl"
        variant="outline"
        style={{
          transition: "all 0.5s linear",
        }}
      >
        <ArrowRight
          size={props.select ? 32 : 28}
          style={{
            transition: "all 0.5s linear",
          }}
        />
      </ActionIcon>
    </Flex>
  );
};

export default FooterCard;

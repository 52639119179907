import { api } from "../api/api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IProductItem } from "../interfaces/IListProducts";

const getAlgorithms = async (
  is_marketplace?: boolean,
  emphasis?: boolean
): Promise<IProductItem[]> => {
  let params = new URLSearchParams();

  params.append("is_marketplace", `${is_marketplace}`);

  if (emphasis) params.append("emphasis", "true");

  const resp = await api.get<IProductItem[]>(
    "/list-products?category=AlgorithmsAndIndicators",
    {
      params: params,
    }
  );

  return resp.data;
};

export const useAlgorithms = (
  is_marketplace: boolean
): UseQueryResult<IProductItem[]> => {
  return useQuery(["AlgorithmsAndIndicators"], () =>
    getAlgorithms(is_marketplace)
  );
};

export const useAlgorithmsEmphasis = (
  is_marketplace: boolean,
  emphasis: boolean
): UseQueryResult<IProductItem[]> => {
  return useQuery(
    ["AlgorithmsAndIndicators-emphasis", is_marketplace, emphasis],
    () => getAlgorithms(is_marketplace, emphasis)
  );
};

// export const useAlgorithmsTrader = (
//   is_marketplace: boolean
// ): UseQueryResult<IProductItem[]> => {
//   return useQuery(["AlgorithmsAndIndicators"], () =>
//     getAlgorithms(is_marketplace)
//   );
// };

// export const useAlgorithmsTraderEmphasis = (
//   is_marketplace: boolean,
//   emphasis: boolean
// ): UseQueryResult<IProductItem[]> => {
//   return useQuery(
//     ["AlgorithmsAndIndicators-trader-emphasis", is_marketplace, emphasis],
//     () => getAlgorithms(is_marketplace, emphasis)
//   );
// };

import { Divider, Text } from "@mantine/core";
import {
  ComponentCommonCard,
  ImageCommonCard,
  HeaderCommonCard,
  FooterCard,
} from "../../ComponentsCards";
import { CardProductProps } from "../../ComponentsCards/typesCards";
import { useNavigate } from "react-router-dom";

type IProps = {
  item: CardProductProps;
  select?: boolean;
  preSlug?: string;
};

const CardProduct: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ComponentCommonCard
      onClick={() =>
        navigate(
          !!props?.preSlug
            ? `${props?.preSlug}${props?.item.slug}`
            : props?.item.slug
        )
      }
      w={props?.select ? "460px" : "396px"}
      p={props?.select ? 27.8 : 22}
      withBorder
      select={props?.select}
    >
      <ImageCommonCard
        image={props?.item.image}
        title={props?.item.title}
        height={props?.select ? 182 : 157}
        radius={12}
        select={props?.select}
      />

      <HeaderCommonCard
        // item={props.item}
        select={props?.select}
        mt={props?.select ? 25 : 20}
        mb={props?.select ? 25 : 20}
      />

      <Text
        lineClamp={2}
        fw={400}
        size={props?.select ? 16.26 : 14}
        lh={props?.select ? "25.2px" : "21.7px"}
        color="basic.4"
        ff="Barlow"
      >
        {props?.item.text}
      </Text>

      <Divider my={props?.select ? 25 : 20} />

      <FooterCard
        price={props?.item.price}
        points={props?.item.points}
        select={props?.select}
      />
    </ComponentCommonCard>
  );
};

export default CardProduct;

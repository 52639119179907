import { Flex, Text } from "@mantine/core";
import {
  ComponentMainCard,
  HeaderMainCard,
  ContentMainCard,
} from "../../ComponentsCards";
import { useNavigate } from "react-router-dom";

interface IProps {
  item: any;
  preSlug?: string;
}

const CardMainLeague: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ComponentMainCard
      onClick={() =>
        navigate(
          !!props?.preSlug
            ? `${props?.preSlug}${props?.item?.slug}`
            : props?.item?.slug
        )
      }
    >
      <HeaderMainCard
        avatar={props?.item?.avatar}
        title={props?.item?.title}
        name={props?.item?.name}
      />
      <Flex gap="16px" mt="16px" direction="column">
        <Text
          lineClamp={3}
          h={66}
          fw={400}
          fs="14px"
          lh={"21.7px"}
          color="basic.4"
          ff="Barlow"
        >
          {props?.item?.text}
        </Text>

        <ContentMainCard
          registrations={props?.item?.registrations}
          championship={props?.item?.championship}
          actives={props?.item?.actives}
        />
      </Flex>
    </ComponentMainCard>
  );
};

export default CardMainLeague;

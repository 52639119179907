import { Avatar, Flex, FlexProps, Text, Title } from "@mantine/core";
import { Profile } from "iconsax-react";
import { CardGraficProps } from "../../ComponentsCards/typesCards";

interface IProps extends FlexProps {
  item: CardGraficProps;
}

const HeaderHighlightCardMVP: React.FC<IProps> = ({ item, ...props }) => {
  return (
    <Flex {...props} gap="16px" align="center" className="" h="54px" mih="54px">
      <Avatar
        component="span"
        variant="outline"
        size={48}
        color="basic.2"
        radius={8}
        bg="dark"
        src={item?.avatar}
        alt={item?.title}
        style={{ transition: "all 0.5s linear" }}
        styles={{
          root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #D7D7D7",
          },
          image: {
            height: "38px",
            width: "38px",
          },
        }}
      >
        <Profile color="#2F2F2F" />
      </Avatar>
      <Flex gap="4px" justify="space-between" direction="column" h="100%">
        <Text
          tt="uppercase"
          fw={500}
          size={14}
          lh="21.7px"
          color="basic.4"
          ff="Barlow"
          lineClamp={1}
          style={{
            wordBreak: "break-all",
          }}
        >
          {item.name}
        </Text>
        <Title
          tt="uppercase"
          fw={700}
          size={18}
          lh={"27.9px"}
          color="basic.5"
          lineClamp={1}
          style={{
            wordBreak: "break-all",
          }}
        >
          {item.title}
        </Title>
      </Flex>
    </Flex>
  );
};

export default HeaderHighlightCardMVP;

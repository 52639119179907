import {
  Flex,
  Text,
  SimpleGrid,
  Input,
  Button,
  Modal as ModalMantine,
  Group,
  TextInput,
  InputBase,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  InputWrapper,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "../../../../components";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { nameInitials } from "../../../../utils/nameInitials";
import { CiUser } from "react-icons/ci";
import { IMaskInput } from "react-imask";
import { useCity, useUF } from "../../../../hooks";
import { useState } from "react";
import { IState } from "../../../../interfaces/IState";
import { validationSignIn } from "../../../../utils/validation";
import { IUserMVP, MOCK_USER_MVP } from "../../../../context/auth";

interface IProps {
  opened: boolean;
  close: any;
  obj?: any;
}

const ModalEditProfile = ({ close, opened, obj }: IProps) => {
  const [accept, setAccept] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IUserMVP>({
    mode: "onChange",
    resolver: yupResolver(validationSignIn),
    defaultValues: MOCK_USER_MVP,
  });

  const { data: state } = useUF(opened);

  const watchUF = watch("adress.state");
  const { data: listCity } = useCity(watchUF);

  const ufsOptions = state?.map((uf: IState, index: number) => {
    return {
      key: `${index}${uf?.sigla}`,
      value: uf?.sigla,
      label: uf?.nome,
    };
  });

  const cityOptions = listCity?.map((uf: any, index: number) => {
    return {
      key: `${index}${uf?.sigla}`,
      label: uf?.nome,
      value: uf?.nome,
    };
  });

  const onSubmit = (data: IUserMVP) => {
    console.log(data);
  };

  return (
    <>
      <Modal
        type="success"
        titleSeccondButton="Fechar"
        description="Informações alteradas com sucesso!"
        open={openModal}
        setClose={() => setOpenModal(!openModal)}
        firstClick={() => {}}
        secondClick={() => {
          setOpenModal(!openModal);
        }}
      />
      <ModalMantine
        centered
        opened={opened}
        onClose={close}
        size="auto"
        styles={{
          header: { padding: 0 },
          close: {
            position: "absolute",
            top: "24px",
            right: "24px",
          },
          body: {
            padding: 0,
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            direction="column"
            gap={24}
            bg="basic.0"
            pt={32}
            p={48}
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
            }}
          >
            <Text
              mx={"auto"}
              fz={{ base: 24 }}
              fw={{ base: 600 }}
              lh={{ base: "37.2px" }}
              c={"basic.5"}
            >
              Editar perfil
            </Text>
            <Flex
              align="center"
              gap={24}
              direction={{ base: "column", xl: "row" }}
            >
              <Dropzone
                onDrop={() => {}}
                accept={IMAGE_MIME_TYPE}
                bg="#FFFFFF"
                styles={(theme) => ({
                  root: {
                    padding: 0,
                    borderRadius: "6px",
                    border: "1.5px solid #D9D9D9",
                    "&[data-accept]": {
                      color: "#000",
                      backgroundColor: "#bdd4f9",
                    },

                    "&[data-reject]": {
                      color: theme.white,
                      backgroundColor: theme.colors.alerts[0],
                    },
                  },
                  inner: {
                    background: !watch("username")
                      ? ""
                      : theme.colors.primary[1],
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100px",
                    width: "100px",
                  },
                })}
              >
                {watch("username") ? (
                  <Text
                    align="center"
                    style={{
                      color: "#FFFFFF",
                      fontSize: "42px",
                      letterSpacing: "2px",
                    }}
                  >
                    {nameInitials(watch("username"))}
                  </Text>
                ) : (
                  <CiUser size="60" color="#2f2f2f" />
                )}
                <Text
                  size={11.6667}
                  bg="#ECF3FE"
                  w="100%"
                  h={25}
                  sx={{
                    border: "1.5px solid #ECF3FE",
                    borderRadius: "0 0 6px 6px",
                  }}
                  align="center"
                >
                  Alterar
                </Text>
              </Dropzone>
            </Flex>
            <SimpleGrid
              cols={3}
              /*   breakpoints={[
              { maxWidth: "97rem", cols: 2, spacing: "md" },
              { maxWidth: "86rem", cols: 1, spacing: "md" },
            ]} */
              spacing="32px"
              verticalSpacing="24px"
            >
              <Controller
                name="username"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    label="Nome"
                    withAsterisk
                    placeholder="Digite seu nome"
                    error={errors?.username?.message}
                  />
                )}
              />

              <Controller
                name="document"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper
                    label="CPF"
                    withAsterisk
                    error={errors?.document?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    <Input<any>
                      value={value}
                      component={IMaskInput}
                      mask="000.000.000-00"
                      placeholder="000.000.000-00"
                      error={errors?.document?.message}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper label="E-mail" withAsterisk>
                    <InputBase
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Digite seu email"
                      error={errors.email?.message}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper
                    label="Celular"
                    withAsterisk
                    error={errors.phone?.message}
                  >
                    <Input<any>
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      component={IMaskInput}
                      mask="(00) 00000-0000"
                      placeholder="(00) 00000-0000"
                      error={errors.phone?.message}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper
                    label="Whatsapp"
                    withAsterisk
                    error={errors.phone?.message}
                  >
                    <Input<any>
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      component={IMaskInput}
                      mask="(00) 00000-0000"
                      placeholder="(00) 00000-0000"
                      error={errors.phone?.message}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper label="Messenger">
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Digite perfil do seu messenger"
                    />
                  </InputWrapper>
                )}
              />
            </SimpleGrid>

            <SimpleGrid cols={2} spacing="32px" verticalSpacing="24px">
              <Controller
                name="phone"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper label="Facebook">
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Digite perfil do seu facebook"
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper label="Instagram">
                    <Input
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Digite perfil do seu instagram"
                    />
                  </InputWrapper>
                )}
              />
            </SimpleGrid>

            <Text fw={600} size={24} color="#2f2f2f">
              Endereço
            </Text>

            <SimpleGrid
              cols={2}
              id="gridAdressOne"
              spacing="32px"
              verticalSpacing="24px"
            >
              <Flex gap={{ base: 32 }}>
                <TextInput
                  maw={{ base: 150 }}
                  label="CEP"
                  withAsterisk
                  placeholder="CEP"
                />
                <TextInput
                  label="Endereço"
                  withAsterisk
                  placeholder="Digite seu logradouro"
                />
                <TextInput
                  maw={{ base: 90 }}
                  label="Número"
                  withAsterisk
                  placeholder="N°"
                />
              </Flex>
              <Flex gap={{ base: 32 }}>
                <Controller
                  name="adress.state"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Select
                      searchable
                      nothingFound="Estado inválido"
                      value={value}
                      name={name}
                      onChange={onChange}
                      withAsterisk
                      label={"Estado"}
                      placeholder="Selecione um estado"
                      data={
                        ufsOptions !== undefined
                          ? ufsOptions
                          : [{ value: "", label: "" }]
                      }
                    />
                  )}
                />

                <Controller
                  name="adress.city"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Select
                      searchable
                      nothingFound="Cidade inválida"
                      value={value}
                      name={name}
                      onChange={onChange}
                      withAsterisk
                      label={"Cidade"}
                      placeholder="Selecione uma cidade"
                      data={
                        cityOptions !== undefined
                          ? cityOptions
                          : [{ value: "", label: "" }]
                      }
                    />
                  )}
                />
              </Flex>
            </SimpleGrid>

            <SimpleGrid
              cols={2}
              id="gridAdressTwo"
              spacing="32px"
              verticalSpacing="24px"
            >
              <TextInput
                label="Bairro"
                withAsterisk
                placeholder="Digite seu bairro"
              />

              <TextInput
                label="Complemento"
                placeholder="Digite o complemento"
              />
            </SimpleGrid>

            <Button
              onClick={() => {
                setOpenModal(!openModal);
              }}
              type="submit"
              variant="primary"
              fullWidth
              mt={26}
              disabled={!isDirty || !isValid || !accept}
            >
              Salvar alterações
            </Button>
          </Flex>
        </form>
      </ModalMantine>
    </>
  );
};

export default ModalEditProfile;

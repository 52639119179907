import React from "react";
import { Badge as BadgeBoolean, BadgeProps } from "@mantine/core";

interface IPropsBoolean extends BadgeProps {
  statusYes?: boolean;
}

const TagBoolean: React.FC<IPropsBoolean> = ({ statusYes, ...props }) => {
  return (
    <BadgeBoolean
      {...props}
      styles={{
        root: {
          color: statusYes ? "#23BD32" : "#F43333",
          background: statusYes ? "#E9F8EB" : "#FEEBEB",
          padding: "4px 8px",
          height: "30px",
          fontSize: "14px",
          borderRadius: "20px",
        },
      }}
    >
      {statusYes ? "Sim" : "não"}
    </BadgeBoolean>
  );
};

export default TagBoolean;

import { Box, BoxProps, Text } from "@mantine/core";

interface IProps extends BoxProps {
  value: string;
  text: string;
}

const BoxFlex: React.FC<IProps> = ({ value, text, ...props }) => {
  return (
    <Box {...props}>
      <Text
        fz={{ base: 18 }}
        fw={{ base: 700 }}
        lh={{ base: "155%" }}
        color="basic.5"
      >
        {value}
      </Text>
      <Text
        fz={{ base: 16 }}
        fw={{ base: 500 }}
        lh={{ base: "155%" }}
        color="basic.3"
      >
        {text}
      </Text>
    </Box>
  );
};

export default BoxFlex;

import React from "react";
import { Text, TypographyStylesProvider, useMantineTheme } from "@mantine/core";

const Policy: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <TypographyStylesProvider>
      <Text
        p={{ base: 24, 630: 60 }}
        mx="auto"
        w="100%"
        c={theme.colors.basic[5]}
        maw={1680}
        dangerouslySetInnerHTML={{
          __html: `
            <h1>Política de privacidade</H1>
            <h2>QUEM SOMOS</h2>
            <p>A TraderEvolution atua há quase duas décadas com o objetivo de trazer inovação e tecnologia de ponta ao mercado financeiro. O endereço do nosso site é: https://traderevolution.com.br</p>
            <h2>SEÇÃO 1 - O QUE FAREMOS COM ESTA INFORMAÇÃO?</h2>
            <p>Quando você realiza algum cadastro ou compra em nosso site, como parte do processo de cadastramento, coletamos as informações pessoais que você nos dá tais como: nome, e-mail e endereço, CPF.</p>
            <p>Quando você acessa nosso site, também recebemos automaticamente o protocolo de internet do seu computador, endereço de IP, a fim de obter informações que nos ajudam a aprender sobre seu navegador e sistema operacional.</p>
            <p>Email Marketing será realizado apenas caso você permita. Nestes emails você poderá receber notícia sobre nossa a TraderEvolution, novos produtos e outras atualizações. </p>
            <h2>SEÇÃO 2 - CONSENTIMENTO</h2>
            <p>Como vocês obtêm meu consentimento?</p>
            <p>Quando você fornece informações pessoais como nome, telefone e endereço, para completar: uma transação, verificar seu cartão de crédito, fazer um pedido, providenciar uma entrega ou retornar uma compra. Após a realização de ações entendemos que você está de acordo com a coleta de dados para serem utilizados pela nossa empresa. </p>
            <p>Se pedimos por suas informações pessoais por uma razão secundária, como marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe fornecer a oportunidade de dizer não.  </p>
            <p>E caso você queira retirar seu consentimento, como proceder? </p>
            <p>Se após você nos fornecer seus dados, você mudar de ideia, você pode retirar o seu consentimento para que possamos entrar em contato, para a coleção de dados contínua, uso ou divulgação de suas informações, a qualquer momento, entrando em contato conosco em <b>atendimento@traderevolution.com.br </b>ou nos enviando uma correspondência em: <b>TraderEvolution - Av Brigadeiro Faria Lima, 1461, 14º andar - São Paulo - SP - CEP: 01452-001</b></p>
            <h2>SEÇÃO 3 - DIVULGAÇÃO</h2>
            <p>Podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de uso do serviço.</p>
            <h2>SEÇÃO 4 - SERVIÇOS DE TERCEIROS</h2>
            <p>No geral, os fornecedores terceirizados usados por nós irão apenas coletar, usar e divulgar suas informações na medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.</p>
            <p>Entretanto, certos fornecedores de serviços terceirizados, tais como gateways de pagamento e outros processadores de transação de pagamento, têm suas próprias politicas de privacidade com respeito à informação que somos obrigados a fornecer para eles de suas transações relacionadas com compras.</p>
            <p> Para esses fornecedores, recomendamos que você lela suas politicas de privacidade para que você possa entender a maneira na qual suas informações pessoals serão usadas por esses fornecedores.</p>
            <p> Em particular, lembre-se que certos fornecedores podem ser localizados em ou possuir instalações que são localizadas em jurisdições diferentes que você ou nos. Assim, se você quer continuar com uma transação que envolve os serviços de um fornecedor de serviço terceirizado. então suas informações podem tornar-se sujeitas as leis da(s)jurisdição(ões) nas quals o fornecedor de serviço ou suas instalações estão localizados.</p>
            <p> Como um exemplo, se você está localizado no Canadá e sua transação e processada por um gateway de pagamento localizado nos Estados Unidos, então suas informações pessoais usadas para completar aquela transação podem estar sujeitas a divulgação sob a legislação dos Estados Unidos, incluindo o Ato Patriota.</p>
            <p> Uma vez que você deixe o nosso site ou seja redirecionado para um aplicativo ou site de terceiros. você não será mais regido por essa Politica de Privacidade ou pelos Termos de Serviço do nosso site. </p>
            <h3>LINKS</h3>
            <p>Quando você clica em links na nosso site,<b> eles podem </b>the direcionar para fora do nosso site. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe Incentivamos a ler as declarações de privacidade deles. </p>
            <H2>SEÇÃO 5 - SEGURANÇA</H2>
            <p>Para proteger suas informações pessoals, tomamos precauções razoáveis e seguimos as melhores práticas da indústria para nos certificar que elas não serão perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas ou destruídas.</p>
            <p> Se você nos fornecer as suas informações de cartão de crédito, essa informação é criptografada usando tecnologia "secure socket layer (SSL)" e armazenada com uma criptografia AES-256. Embora nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos padrões adicionais geralmente aceitos pela indústria.</p>
            <H2>SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</H2>
            <p>Reservamos o direito de modificar essa política de privacidade a qualquer momento, então por favor, revise-a com frequência. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação no site. Se fizermos alterações de materiais para essa politica, iremos notificá-lo aqui que eles foram atualizados, para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se alguma, usamos e/ou divulgamos elas.</p>
            <p>Se nossa empresa for adquirida ou fundida com outra empresa, suas informações podem ser transferidas para os novos proprietários para que possamos continuar a vender produtos para você.</p>
          `,
        }}
        sx={{
          h1: {
            fontSize: "62px",
            "@media screen and (max-width: 630px)": {
              fontSize: "48px",
            },
          },
          h2: {
            marginTop: "45px",
            fontSize: "36px",
            "@media screen and (max-width: 630px)": {
              fontSize: "24px",
            },
          },
          h3: {
            marginTop: "45px",
            fontSize: "26px",
            "@media screen and (max-width: 630px)": {
              fontSize: "20px",
            },
          },
          p: {
            fontSize: "20px",
            color: "#6B6B6B",
            "@media screen and (max-width: 630px)": {
              fontSize: "16px",
            },
          },
        }}
      />
    </TypographyStylesProvider>
  );
};

export default Policy;

import { Button, Drawer, Flex, Tooltip } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { SearchNormal1 } from "iconsax-react";

const SearchDrawer: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const isLargerThan1020 = useMediaQuery("(min-width: 1020px)");
  const isLargerThan500 = useMediaQuery("(min-width: 500px)");

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title="Pesquisar"
        position="bottom"
        size="100%"
      >
        {/* Drawer content */}
      </Drawer>
      <Tooltip
        label="Pesquisar"
        disabled={isLargerThan500 && !isLargerThan1020 ? false : true}
        position="bottom"
        withArrow
      >
        <Button
          maw={{ base: "214px", 1020: "unset" }}
          w={{ base: "100%", 1020: "fit-content" }}
          variant={
            isLargerThan1020 ? "secondaryTransparent" : "transparentEspecial"
          }
          onClick={open}
          leftIcon={<SearchNormal1 size={24} variant="Outline" />}
          h={{ base: 48, 1020: 56 }}
          p={{ base: 8, 500: 12 }}
          fz={{ base: 14, 1020: 16 }}
          lh={{ base: "22px", 1020: "25px" }}
          fw={400}
          sx={{ div: { justifyContent: "start" } }}
          styles={{
            leftIcon: {
              marginRight: isLargerThan500 && !isLargerThan1020 ? 0 : "12px",
            },
            rightIcon: {
              marginLeft: isLargerThan500 && !isLargerThan1020 ? 0 : "12px",
            },
          }}
        >
          {isLargerThan500 && !isLargerThan1020 ? null : "Pesquisar"}
        </Button>
      </Tooltip>
    </>
  );
};

export default SearchDrawer;

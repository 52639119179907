import {
  Text,
  TextProps,
  TypographyStylesProvider,
  useMantineTheme,
} from "@mantine/core";
import React from "react";

interface Iprops extends TextProps {
  textHTML: string;
}

const TextHTML: React.FC<Iprops> = ({ textHTML, ...props }) => {
  const theme = useMantineTheme();
  return (
    <TypographyStylesProvider>
      <Text
        m={"0"}
        fz={{ base: 16, 1120: 20 }}
        lh={{ base: "32px", 1120: "36px" }}
        color="basic.4"
        dangerouslySetInnerHTML={{
          __html: textHTML,
        }}
        {...props}
        sx={{
          p: {
            margin: "0",
          },
          b: {
            fontWeight: 600,
          },
          strong: {
            fontWeight: 600,
          },
          h1: {
            fontSize: "42px",
            margin: 0,
            fontWeight: 400,
            strong: {
              fontWeight: 700,
            },
          },
          a: {
            fontWeight: 600,
            color: theme.colors.primary[1],
            textDecoration: "underline",
            lineHeight: "40px",
          },
        }}
      />
    </TypographyStylesProvider>
  );
};

export default TextHTML;

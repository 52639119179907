import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    primary: [
      "#154BB6", //0
      "#3F87F4", //1
      "#319CE6", //2
      "#1239A6", //3
      "#1B40A8", //4
    ],
    primaryOpacity: [
      "#E7E9EC", //0
      "#ECF3FE", //1
      "#EAF5FC", //2
      "#E8F5EC", //3
      "#154BB620", //4
    ],
    complementary: [
      "#169C3C", //0
      "#2C1B73", //1
      "#E3183B", //2
      "#8131E6", //3
      "#FB6815", //4
      "#EB49EE", //5
      "#319CE6", //6
    ],
    complementaryOpacity: [
      "#E8F5EC", //0
      "#EAE8F1", //1
      "#FCE8EB", //2
      "#F2EAFC", //3
      "#FFEEDE", //4
      "#FDEDFD", //5
      "#EAF5FC", //6
    ],
    basic: [
      "#FFFFFF", //0
      "#F0F0F0", //1
      "#D7D7D7", //2
      "#B2B1B1", //3
      "#6B6B6B", //4
      "#2F2F2F", //5
    ],
    other: [
      "#FAFBFE", //0
      "#B9B9B9", //1
      "#1D3461", //2
      "#172E59", //3
      "#DEDEDE", //4
      "#B3B1B1", //5
    ],
    alerts: [
      "#F43333", //0
      "#FAAB36", //1
      "#23BD32", //2
      "#EB4747", //3
    ],
    alertsOpacity: [
      "#FEEBEB", //0
      "#FEF7EB", //1
      "#E9F8EB", //2
    ],
  },
  fontFamily: "Montserrat",
  components: {
    Highlight: {
      styles: () => ({
        root: {
          "& [data-highlight]": {
            backgroundColor: "unset",
            fontWeight: 600,
          },
        },
      }),
    },
    InputWrapper: {
      styles: () => ({
        root: {
          position: "relative",
        },
        label: {
          fontWeight: 600,
          fontSize: "14px",
          color: "#2F2F2",
        },
        error: {
          position: "absolute",
          bottom: "-20px",
          fontSize: "16px",
          fontWeight: 600,
          color: "#F43333",
          paddingTop: "5px",
        },
      }),
    },
    NumberInput: {
      styles: () => ({
        rightSection: { marginRight: "-12px", overflow: "hidden" },
      }),
    },
    Input: {
      styles: () => ({
        rightSection: {
          right: "16px",
        },
        icon: {
          left: "8px",
        },
        wrapper: {
          marginBottom: "0px !important",
          boxSizing: "border-box",
          borderRadius: "8px",
          WebkitBorderRadius: "8px",
          outline: "1.5px solid #D7D7D7",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          marginTop: "8px ",
          Input: {
            padding: "12px 16px",
            backgroundColor: "#fff",
            height: "56px !important",
            fontSize: "16px",
            lineHeight: "155%",
            color: "#2F2F2F",
            outline: "1.5px solid #D7D7D7",
            borderRadius: "8px",
            WebkitBorderRadius: "8px",
            "::placeholder": {
              fontWeight: "lighter",
              color: "#6B6B6B",
            },
            ":hover": {
              borderColor: "#1B4FDF",
            },
            ":focus-visible": {
              fontWeight: "bolder",
              color: "#2F2F2F",
              background: "#F0F0F0",
              borderColor: "#1B4FDF",
            },
            ":disabled": {
              background: "#B9B9B9",
              opacity: "1",
              "::placeholder": {
                color: "#6B6B6B",
              },
            },
            "&#username": {
              paddingLeft: "42px",
            },
          },
        },
      }),
    },
    Select: {
      styles: (theme) => ({
        rightSection: {
          right: "6px",
          pointerEvents: "none",
          width: "45px",
        },
        dropdown: {
          borderRadius: "0 0 8px 8px",
          background: "#f2f2f2",
          border: "1.5px solid #1B4FDF",
          borderTop: "none",
          marginTop: "-15px",
        },
        item: {
          borderRadius: "0",
          paddingTop: "16px",
          height: "56px",
          background: "white",
          borderTop: "1px solid #B9B9B9",
          ":hover": {
            background: "#B9B9B9",
          },
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: "#B9B9B9",
              color: "#f2f2f2",
              fontWeight: "bold",
            },
          },
        },
      }),
    },
    PasswordInput: {
      styles: (theme) => ({
        input: {
          borderRadius: "8px",
          WebkitBorderRadius: "8px",
          height: "56px !important",
          ":hover": {
            borderColor: theme.colors.primary[1],
          },
          ":focus-within": {
            borderColor: "#1B4FDF",
          },
        },
        wrapper: {
          marginBottom: "0px !important",
          height: "56px !important",
          boxSizing: "border-box",
          borderRadius: "8px",
          WebkitBorderRadius: "8px",
          outline: "1.5px solid #D7D7D7",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          input: {
            paddingLeft: "44px !Important",
            border: "none",
            width: "100%",
            paddingRight: "150px",
            ":hover": {
              borderColor: "#1B4FDF",
            },
            ":focus-visible": {
              fontWeight: "bolder",
              color: "#2F2F2F",
              background: " #F0F0F0;",
              borderColor: "red",
            },
            ":disabled": {
              background: "#B9B9B9",
              opacity: "1",
              "::placeholder": {
                color: "red",
              },
            },
          },
        },
        rightSection: {
          whiteSpace: "nowrap",
          fontSize: "14px",
          width: "140px",
          button: {
            ":hover": {
              background: "transparent ",
            },
          },
          p: {
            fontSize: "14px",
            color: "#6B6B6B",
          },
        },
      }),
    },
    Checkbox: {
      styles: (theme) => ({
        body: { height: "28px", alignItems: "center" },
        input: {
          height: "20px",
          width: "20px",
          borderRadius: "8px",
          border: "2px solid",
          borderColor: theme.colors.basic[4],
        },
        icon: {
          height: "16px",
          width: "16px",
        },
        label: {
          fontSize: "16px",
          fontWeight: 500,
          color: "#2F2F2F",
          paddingLeft: "8px",

          a: {
            paddingLeft: "6px",
            color: "#1B4FDF",
            fontWeight: 600,
            textDecoration: "underline",
          },
        },
      }),
    },
    Radio: {
      styles: (theme) => ({
        body: { height: "28px", alignItems: "center" },
        radio: {
          height: "20px",
          width: "20px",
          border: "2px solid",
          borderColor: theme.colors.basic[4],
          ":checked": {
            background: "primary.0",
          },
        },
        inner: {
          alignSelf: "center",
        },
        icon: {
          height: "16px",
          width: "16px",
          margin: "-4px -4px",
          fill: "white",
        },
        description: {
          margin: "0",
          fontWeight: 500,
          fontSize: "12px",
        },
        label: {
          color: "#2F2F2F ",
          fontSize: "14px",
          fontWeight: 500,
          paddingLeft: "8px",
        },
      }),
    },
    RadioGroup: {
      styles: (theme) => ({
        label: {
          fontWeight: 600,
          span: {
            marginLeft: "8px",
            color: theme.colors.alerts[0],
            fontWeight: 600,
            fontSize: "16px",
          },
        },
      }),
    },
    Group: {
      styles: () => ({
        root: {
          gap: "44px",
        },
      }),
    },
    Tabs: {
      styles: () => ({
        tabsList: {
          borderBottom: "none",
        },
        tab: {
          fontSize: "16px",
          lineHeight: "155%",
          color: "#2f2f2f",
          padding: "8px 20px",
          borderBottom: "3px solid",
          borderColor: "transparent",
          borderRadius: "3px",
          "&[data-active]": {
            fontWeight: 600,
            borderColor: "#3F87F4",
          },
          ":hover": {
            background: "transparent",
            borderColor: "#84b0fd",
          },
        },
        tabRightSection: {
          marginLeft: "8px !important",
        },
      }),
    },
    Badge: {
      styles: () => ({
        root: {
          lineHeight: "155%",
          fontWeight: 500,
        },
      }),
    },
    NavLink: {
      styles: () => ({
        root: {
          width: "auto",
          fontSize: "16px",
          span: {
            margin: 0,
          },
          ":hover": {
            background: "transparent",
          },
        },
      }),
    },
    SegmentedControl: {
      styles: (theme) => ({
        root: {
          background: theme.colors.primaryOpacity[1],
          width: "fit-content",
          gap: "12px",
          borderRadius: "8px",
          padding: "12px",
          height: "fit-content",
        },
        label: {
          justifyContent: "space-between",
          width: "fit-content",
          borderRadius: "8px",
          padding: "12px 16px",
          lineHeight: "25px",
          fontSize: "16px",
          fontWeight: 600,
          color: theme.colors.basic[5],
        },
        indicator: {
          width: "fit-content",
          borderRadius: "8px",
        },
      }),
    },
    PinInput: {
      styles: () => ({
        root: {
          justifyContent: "space-between",
          gap: "16px",
        },
        input: { width: "80px", fontSize: "28px !important", fontWeight: 600 },
      }),
    },
    Tooltip: {
      styles: (theme) => ({
        tooltip: {
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 400,
          padding: "12px",
          borderRadius: "8px",
          color: theme.colors.basic[0],
          background: theme.colors.basic[5],
        },
      }),
    },
    Button: {
      styles: () => ({
        root: {
          height: "56px",
          borderRadius: "8px",
          transition: "0.3s linear",
          fontSize: "16px",
          lineHeight: "24.8px",
          fontWeight: 600,
          padding: "0 12px",

          "&[data-disabled]": {
            cursor: "not-allowed",
          },
        },
        leftIcon: {
          marginRight: "12px",
          // paddingBottom: "5px",
        },
        rightIcon: {
          marginLeft: "12px",
          // paddingBottom: "5px",
        },
        group: {
          borderRadius: "50px !important",
        },
      }),
      variants: {
        primary: (theme) => ({
          root: {
            background: theme.colors.primary[0],
            color: theme.colors.basic[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              border: "unset",
              color: "#FFFFFF",
              background: theme.colors.primary[1],
            }),

            "&[data-disabled]": {
              background: theme.colors.other[1],
              color: theme.colors.basic[0],
            },
          },
        }),
        primaryOutline: (theme) => ({
          root: {
            color: theme.colors.primary[0],
            border: "1px solid",
            borderColor: theme.colors.primary[0],
            ...theme.fn.hover({
              color: theme.colors.primary[1],
              borderColor: theme.colors.primary[1],
              background: theme.colors.primaryOpacity[2],
            }),
            "&[data-disabled]": {
              color: theme.colors.basic[4],
              borderColor: theme.colors.basic[4],
            },
          },
        }),
        primaryTransparent: (theme) => ({
          root: {
            color: theme.colors.primary[0],
            ...theme.fn.hover({
              color: theme.colors.primary[1],
              background: theme.colors.primaryOpacity[2],
            }),
            "&[data-disabled]": {
              color: theme.colors.basic[4],
            },
          },
        }),
        secondary: (theme) => ({
          root: {
            color: theme.colors.primary[0],
            background: theme.colors.basic[0],
            ...theme.fn.hover({
              background: theme.colors.primaryOpacity[2],
              color: theme.colors.primary[1],
            }),
            "&[data-disabled]": {
              background: theme.colors.basic[4],
              color: theme.colors.basic[1],
            },
          },
        }),
        secondaryOutline: (theme) => ({
          root: {
            color: theme.colors.basic[0],
            background: "transparent",
            border: "1px solid",
            borderColor: theme.colors.basic[0],
            ...theme.fn.hover({
              background: "rgba(255, 255, 255, 0.2)",
            }),
            "&[data-disabled]": {
              color: theme.colors.basic[4],
              borderColor: theme.colors.basic[4],
              background: "transparent",
            },
          },
        }),
        secondaryTransparent: (theme) => ({
          root: {
            color: theme.colors.basic[0],
            background: "transparent",
            ...theme.fn.hover({
              background: "rgba(255, 255, 255, 0.2)",
            }),
            "&[data-disabled]": {
              background: "transparent",
              color: theme.colors.basic[4],
            },
          },
        }),
        danger: (theme) => ({
          root: {
            color: theme.colors.basic[0],
            background: theme.colors.alerts[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",

            ...theme.fn.hover({
              border: "unset",
              color: "#FFFFFF",
              background: theme.colors.alerts[3],
              ":before": {
                opacity: "1",
              },
            }),
            ":before": {
              position: "absolute",
              content: "''",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              background: theme.colors.alerts[3],
              zIndex: -1,
              transition: "0.3s linear",
              opacity: "0",
            },

            "&[data-disabled]": {
              background: theme.colors.basic[4],
              color: theme.colors.basic[1],
            },
          },
        }),
        dangerOutline: (theme) => ({
          root: {
            color: theme.colors.alerts[0],
            border: "1px solid",
            borderColor: theme.colors.alerts[0],
            ...theme.fn.hover({
              background: theme.colors.alertsOpacity[0],
              color: theme.colors.alerts[0],
            }),
            "&[data-disabled]": {
              color: theme.colors.basic[4],
              borderColor: theme.colors.basic[4],
            },
          },
        }),
        dangerTransparent: (theme) => ({
          root: {
            color: theme.colors.alerts[0],
            ...theme.fn.hover({
              background: theme.colors.alertsOpacity[0],
              color: theme.colors.alerts[0],
            }),
            "&[data-disabled]": {
              color: theme.colors.basic[4],
            },
          },
        }),
        transparentEspecial: (theme) => ({
          root: {
            color: theme.colors.basic[0],
            background: "rgba(255, 255, 255, 0.1)",
            ...theme.fn.hover({
              background: "rgba(255, 255, 255, 0.15)",
              WebkitTextFillColor: "primary.0",
            }),
            "&[data-disabled]": {
              background: "transparent",
              color: theme.colors.basic[4],
            },
          },
        }),
        login: (theme) => ({
          root: {
            padding: "16px ",
            border: "1px solid",
            borderColor: theme.colors.basic[2],
            ...theme.fn.hover({
              background: theme.colors.primaryOpacity[2],
              borderColor: theme.colors.primary[1],
            }),
          },
          inner: {
            justifyContent: "space-between",
          },
          rightIcon: {
            color: theme.colors.basic[5],
          },
          label: {
            flexDirection: "column",
            height: "auto",
            alignItems: "start",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "31px",
            color: theme.colors.basic[5],
            span: {
              WebkitTextFillColor: theme.colors.basic[4],
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24.8px",
            },
          },
        }),
        recoverPassword: (theme) => ({
          root: {
            padding: "16px ",
            border: "1px solid",
            borderColor: theme.colors.basic[2],
            ...theme.fn.hover({
              background: theme.colors.primaryOpacity[2],
              borderColor: theme.colors.primary[1],
            }),
          },
          inner: {
            justifyContent: "left",
          },
          rightIcon: {
            marginLeft: "auto",
          },
          label: {
            fontWeight: 400,
            fontSize: "20px",
          },
          leftIcon: {
            marginRight: "16px",
            height: "48px",
            width: "48px",
            background: "#ECF3FE",
            borderRadius: "8px",
            WebkitBorderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }),
        link: () => ({
          root: {
            ":focus": {
              outline: "none",
            },
          },
          label: {
            color: "#1B4FDF",
            textDecoration: "underline",
            fontWeight: 600,
            fontSize: "14px",
            ":focus": {},
          },
        }),
        details: (theme) => ({
          root: {
            background: theme.colors.primaryOpacity[1],
            color: theme.colors.basic[5],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            borderRadius: "0 10px 10px 0 !important",
            ...theme.fn.hover({
              border: "unset",
              color: theme.colors.basic[0],
              background: theme.colors.primary[1],
            }),

            "&[data-disabled]": {
              background: theme.colors.other[1],
              color: theme.colors.basic[0],
            },
          },
        }),
        segments: (theme) => ({
          root: {
            width: "56px",
            outline: "none",
            background: "rgba(63, 135, 244, 0.2)",
            color: theme.colors.basic[5],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            borderRadius: "50px !important",
            ...theme.fn.hover({
              svg: {
                transition: "all 0.5s ease-in-out",
                height: "30px",
                width: "30px",
              },
            }),
          },
        }),
        table: (theme) => ({
          root: {
            padding: "0 !important",
            outline: "none",
            background: "inset",
            color: theme.colors.basic[5],
            zIndex: 1,
            overflow: "hidden",
          },
          inner: {
            justifyContent: "space-between",
          },
        }),
        modal: (theme) => ({
          root: {
            padding: "10px !important",
            outline: "none",
            background: "inset",
            color: theme.colors.basic[5],
            zIndex: 1,
            overflow: "hidden",
            ...theme.fn.hover({
              background: theme.colors.primaryOpacity[0],
            }),
          },
          inner: {
            justifyContent: "flex-start",
          },
        }),
        modalDanger: (theme) => ({
          root: {
            padding: "10px !important",
            outline: "none",
            background: "inset",
            zIndex: 1,
            overflow: "hidden",
            ...theme.fn.hover({
              background: theme.colors.complementaryOpacity[2],
            }),
          },
          inner: {
            color: theme.colors.alerts[0],
            justifyContent: "flex-start",
          },
        }),
      },
    },
    ActionIcon: {
      styles: () => ({
        root: {
          transition: "0.3s linear",
          "&[data-disabled]": {
            cursor: "not-allowed",
          },
        },
      }),
      variants: {
        secondary: (theme) => ({
          root: {
            padding: "8px",
            height: "44px",
            width: "44px",
            border: "1px solid",
            borderRadius: "50%",
            borderColor: theme.colors.basic[2],
            color: theme.colors.basic[5],
            background: theme.colors.basic[0],
            ...theme.fn.hover({
              borderColor: "transparent",
              color: theme.colors.primary[1],
              background: theme.colors.primaryOpacity[2],
            }),
            "&[data-disabled]": {
              background: theme.colors.basic[4],
              color: theme.colors.basic[1],
            },
          },
        }),
        transparentEspecial: (theme) => ({
          root: {
            padding: "8px",
            height: "44px",
            width: "44px",
            borderRadius: "50%",
            color: theme.colors.basic[0],
            background: "rgba(255, 255, 255, 0.1)",
            ...theme.fn.hover({
              background: "rgba(255, 255, 255, 0.15)",
              WebkitTextFillColor: "primary.0",
            }),
            "&[data-disabled]": {
              background: "transparent",
              color: theme.colors.basic[4],
            },
          },
        }),
        visualization: (theme) => ({
          root: {
            padding: "8px",
            height: "40px",
            width: "40px",
            borderRadius: "8px",
            color: theme.colors.basic[5],
            background: "transparent",
            ...theme.fn.hover({
              background: theme.colors.primaryOpacity[1],
            }),
            "&[data-disabled]": {
              background: "transparent",
              color: theme.colors.basic[4],
            },
          },
        }),
        remove: (theme) => ({
          root: {
            padding: "8px",
            height: "40px",
            width: "40px",
            borderRadius: "8px",
            color: theme.colors.basic[5],
            background: "transparent",
            ...theme.fn.hover({
              background: theme.colors.alertsOpacity[0],
              color: theme.colors.alerts[0],
            }),
            "&[data-disabled]": {
              background: "transparent",
              color: theme.colors.basic[4],
            },
          },
        }),
      },
    },
    modal: {
      styles: () => ({
        overlay: {
          background: "rgba(0, 0, 0, 0.6)",
        },
      }),
    },
  },
};

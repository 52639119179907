import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { apiIBGE } from "../api/api";
import { IState } from "../interfaces/IState";

const getState = async (): Promise<IState[]> => {
  const resp = await apiIBGE.get<IState[]>(
    "/v1/localidades/estados?orderBy=nome"
  );
  return resp.data;
};

export const useUF = (enablad?: boolean): UseQueryResult<IState[]> => {
  return useQuery({
    queryKey: ["uf", enablad],
    queryFn: () => getState(),
    enabled: enablad,
  });
};

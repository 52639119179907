import { Center } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";
import { CloseCircle, InfoCircle, Receipt21, TickCircle } from "iconsax-react";
import { createContext, ReactNode, useContext } from "react";
import useStyles from "../components/Notification/createStyles";

type NotificationProps = {
  id: string;
  title?: string;
  message?: string;
};

export interface updateNotificationControlProps extends NotificationProps {
  isSuccess: boolean;
}

export interface notificationControlProps extends NotificationProps {
  type: "success" | "danger" | "warning" | "check" | "default" | "loading";
}
interface NotificationControlContextProps {
  notificationControl: (notificationData: notificationControlProps) => void;
  updateNotificationControl: (
    updateNotificationData: updateNotificationControlProps
  ) => void;
}

type ProviderProps = {
  children: ReactNode;
};

export const NotificationControlContext =
  createContext<NotificationControlContextProps>(
    {} as NotificationControlContextProps
  );

export const NotificationControlProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const { classes } = useStyles();

  const classesNames = {
    root: classes.root,
    title: classes.title,
    description: classes.description,
    closeButton: classes.closeButton,
    icon: classes.icon,
    loader: classes.loader,
  };

  function notificationControl(notificationData: notificationControlProps) {
    switch (notificationData.type) {
      case "default":
        return notifications.show({
          id: notificationData.id,
          title: !!notificationData?.title
            ? notificationData.title
            : "Digite uma mensagem personalizada!",
          message: notificationData.message,
          color: "primary.2",
          style: { backgroundColor: "#ECF3FE" },
          classNames: classesNames,
        });
      case "check":
        return notifications.show({
          id: notificationData.id,
          title: !!notificationData?.title
            ? notificationData.title
            : "Sucesso!",
          message: notificationData.message,
          icon: (
            <Center pos="relative">
              <Center>
                <Receipt21 size="28" color="#3F87F4" />
              </Center>
              <Center
                pos="absolute"
                right="-1px"
                bottom="-1px"
                style={{ zIndex: 9 }}
              >
                <TickCircle size="13" color="white" />
              </Center>
              <Center
                pos="absolute"
                right="-1px"
                bottom="-1px"
                style={{ zIndex: 10 }}
              >
                <TickCircle size="13" color="#23BD32" variant="Bold" />
              </Center>
            </Center>
          ),
          color: "primaryOpacity.2",
          style: { backgroundColor: "#ECF3FE" },
          classNames: classesNames,
        });
      case "danger":
        return notifications.show({
          id: notificationData.id,
          title: !!notificationData?.title ? notificationData.title : "Erro!",
          message: notificationData.message,
          icon: <CloseCircle size="28" color="#F43333" variant="Bold" />,
          color: "alertsOpacity.0",
          style: { backgroundColor: "#FEEBEB" },
          classNames: classesNames,
        });
      case "warning":
        return notifications.show({
          id: notificationData.id,
          title: !!notificationData?.title
            ? notificationData.title
            : "Atenção!",
          message: notificationData.message,
          icon: <InfoCircle size="28" color="#FAAB36" variant="Bold" />,
          color: "alertsOpacity.1",
          style: { backgroundColor: "#FEF7EB" },
          classNames: classesNames,
        });
      case "success":
        return notifications.show({
          id: notificationData.id,
          title: !!notificationData?.title
            ? notificationData.title
            : "Sucesso!",
          message: notificationData.message,
          icon: <TickCircle size="28" color="#23BD32" variant="Bold" />,
          color: "alertsOpacity.2",
          style: { backgroundColor: "#E9F8EB" },
          classNames: classesNames,
        });
      case "loading":
        return notifications.show({
          id: notificationData.id,
          title: !!notificationData?.title
            ? notificationData.title
            : "Carregando...",
          message: notificationData.message,
          loading: true,
          style: { backgroundColor: "#ECF3FE" },
          classNames: classesNames,
          autoClose: false,
          // disallowClose: true,
        });
    }
  }

  function updateNotificationControl(
    updateNotificationData: updateNotificationControlProps
  ) {
    switch (updateNotificationData.isSuccess) {
      case true:
        return notifications.update({
          id: updateNotificationData.id,
          title: !!updateNotificationData?.title
            ? updateNotificationData.title
            : "Sucesso!",
          message: !!updateNotificationData?.message
            ? updateNotificationData.message
            : "Tarefa realizada com Sucesso!",
          icon: <TickCircle size="28" color="#23BD32" variant="Bold" />,
          color: "alertsOpacity.2",
          style: { backgroundColor: "#E9F8EB" },
          classNames: classesNames,
          autoClose: 2000,
        });
      case false:
        return notifications.update({
          id: updateNotificationData.id,
          title: !!updateNotificationData?.title
            ? updateNotificationData.title
            : "Erro!",
          message: !!updateNotificationData?.message
            ? updateNotificationData.message
            : "Erro ao realizar a tarefa!",
          icon: <CloseCircle size="28" color="#F43333" variant="Bold" />,
          color: "alertsOpacity.0",
          style: { backgroundColor: "#FEEBEB" },
          classNames: classesNames,
          autoClose: 2000,
        });
    }
  }

  const value = { notificationControl, updateNotificationControl };

  return (
    <NotificationControlContext.Provider value={value}>
      <Notifications />
      {children}
    </NotificationControlContext.Provider>
  );
};

export function useNotificationControl(): NotificationControlContextProps {
  const context = useContext(NotificationControlContext);

  return context;
}

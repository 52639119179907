import { Global } from "@emotion/react";
import { MantineProvider as Provider } from "@mantine/core";
import { theme } from "./theme";

interface IProps {
  children: React.ReactNode;
}

const MantineProvider: React.FC<IProps> = ({ children }) => {
  return (
    <Provider withGlobalStyles withNormalizeCSS theme={theme}>
      <Global
        styles={(theme) => ({
          "::-webkit-scrollbar-track-piece": {
            opacity: 0,
          },

          "::-webkit-scrollbar": {
            background: "transparent",
            opacity: 0,
            width: "15px",
          },
          "::-webkit-scrollbar-track": {
            //margin: "-5px",
            borderRadius: "150px",
            paddingTop: "10px",
            boxShadow: "inset 0 0 5px grey",
          },

          // obejto que rola
          "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#EAF5FC",
            border: "2px solid #122447",
            ":hover": {
              background: "#122447",
              border: "2px solid #Eaf5fc",
            },
          },

          body: {
            background: "#FAFBFE",
            margin: "0",
            border: "unset",

            ":before": {
              boxSizing: "unset",
            },
          },
          label: {
            color: "#2F2F2F ",
            fontWeight: "400",
            lineHeight: "155%",
            width: "100%",
            display: "flex !important",
            justifyContent: "space-between",

            span: {
              // fontSize: "16px",
              color: "#F43333 !important",
            },
          },
        })}
      />
      {children}
    </Provider>
  );
};

export default MantineProvider;

import { Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import NotificationsNavbar from "../NotificationsNavbar";
import MenuUser from "./MenuUser";
import InterestDrawer from "./InterestDrawer";

const AccessInfos = () => {
  const isLargerThan991 = useMediaQuery("(min-width: 991px)");

  return (
    <Flex
      gap={{ base: 12, 991: 16, 1700: 24 }}
      h={{ base: 48, 991: 56 }}
      justify="space-between"
      align="center"
      w="100%"
    >
      {isLargerThan991 ? <NotificationsNavbar /> : null}
      <MenuUser />
      <InterestDrawer />
    </Flex>
  );
};

export default AccessInfos;

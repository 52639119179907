import {
  Badge,
  Flex,
  Table,
  Text,
  Tooltip,
  Box,
  useMantineTheme,
  Image,
} from "@mantine/core";
import { Select } from "../../../../components";
import { convertCurrencyBR } from "../../../../utils/currency";
import BoxFlex from "./BoxFlex";
import { InfoCircle } from "iconsax-react";

const options = [
  { value: "Hoje", label: "Hoje" },
  { value: "Amanhã", label: "amanha" },
];

const dataGraphicLeft = [
  {
    name: "PETR4",
    acumulado: 100000,
    position: 10,
    ordens: 30,
    totalComprado: 4000,
    totalVendido: 4000,
  },
  {
    name: "WING23",
    acumulado: 60000,
    position: 10,
    ordens: 30,
    totalComprado: 5000,
    totalVendido: 6000,
  },
];

const colsTable = (
  <tr>
    <th> </th>
    <th>
      <p>
        Total <strong>comprado</strong> no dia
      </p>
    </th>
    <th>
      <p>
        Total <strong>vendido</strong> no dia
      </p>
    </th>
    <th>
      <p>Lucro/prejuízo</p>
    </th>
    <th>
      <p>
        <strong>Fechamento</strong> do dia
      </p>
    </th>
    <th>
      <p>Taxas</p>
    </th>
  </tr>
);

const GraphicDonutsDahsboardLeft: React.FC = () => {
  const theme = useMantineTheme();
  const rows = dataGraphicLeft.map((element: any, index: number) => (
    <tr key={index}>
      <td width={130}>
        <Flex gap={{ base: 8 }} align="center">
          <Box
            w={24}
            h={24}
            bg={
              element?.name === "WING23" ? "complementary.3" : "complementary.4"
            }
            sx={{ borderRadius: "8px" }}
          ></Box>
          {element?.name}
        </Flex>
      </td>
      <td>{convertCurrencyBR(element?.totalComprado)}</td>
      <td>{convertCurrencyBR(element?.totalVendido)}</td>
      <td
        style={{
          color:
            element?.totalVendido - element?.totalComprado === 0
              ? theme.colors.primary[1]
              : element?.totalVendido - element?.totalComprado > 0
              ? theme.colors.alerts[2]
              : theme.colors.complementary[2],
        }}
      >
        {element?.totalVendido - element?.totalComprado === 0
          ? "="
          : element?.totalVendido - element?.totalComprado > 0
          ? "+"
          : null}
        {convertCurrencyBR(element?.totalVendido - element?.totalComprado)}
      </td>
      <td>{convertCurrencyBR(element?.totalVendido)}</td>
      <td>
        <Flex gap={{ base: 18 }}>
          {convertCurrencyBR(element?.totalVendido * 0.1)}
          <Tooltip
            label={
              <Box>
                <Text
                  fz={{ base: 14 }}
                  fw={{ base: 700 }}
                  lh={{ base: "21px" }}
                >
                  Taxa total:{convertCurrencyBR(element?.totalVendido * 0.1)}
                </Text>
                <Text>-</Text>
                <Text>
                  Emolumento:
                  {convertCurrencyBR(element?.totalVendido * 0.05)}
                </Text>
                <Text>
                  Registro:
                  {convertCurrencyBR(element?.totalVendido * 0.05)}
                </Text>
              </Box>
            }
            color="dark"
            withArrow
          >
            <InfoCircle size="24" color="#6b6b6b" />
          </Tooltip>
        </Flex>
      </td>
    </tr>
  ));

  return (
    <Flex
      id="29643"
      w="100%"
      direction="column"
      p={{ base: 32 }}
      gap={{ base: 24 }}
      bg="basic.0"
      sx={{
        boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
        borderRadius: "12px",
      }}
    >
      <Flex direction="column" id="29642">
        <Flex justify="space-between" align="center" id="29639">
          <Text
            fz={{ base: 20 }}
            fw={{ base: 600 }}
            lh={{ base: "32px" }}
            color="basic.5"
          >
            Ativos
          </Text>
          <Select placeholder="Escolha qual dia" data={options} />
        </Flex>
        <Flex id="29641" align="center" gap={{ base: 44 }}>
          <Image
            width={443}
            height={229}
            fit="contain"
            src="https://www.amcharts.com/wp-content/uploads/2016/08/demo_8418_none-1-1024x690.png"
          />
          <Flex
            id="29640"
            w="100%"
            align="flex-end"
            justify="center"
            direction="column"
            gap={{ base: 16 }}
          >
            {dataGraphicLeft.map((item: any, index: number) => (
              <Flex
                key={index}
                align="center"
                p={{ base: 16 }}
                gap={20}
                sx={{ borderRadius: "12px", border: "1px solid #D7D7D7" }}
              >
                <BoxFlex
                  miw={{ base: 140 }}
                  text="Acumulado"
                  value={convertCurrencyBR(item?.acumulado)}
                />
                <BoxFlex
                  miw={{ base: 78 }}
                  text="Posições"
                  value={item?.position}
                />
                <BoxFlex
                  miw={{ base: 90 }}
                  text="Ordens"
                  value={item?.ordens}
                />
                <Badge
                  h={48}
                  w={76}
                  p={8}
                  radius={8}
                  bg={
                    item?.name === "WING23"
                      ? "complementaryOpacity.3"
                      : `complementaryOpacity.4`
                  }
                  color={
                    item?.name === "WING23"
                      ? "complementary.3"
                      : `complementary.4`
                  }
                  styles={{
                    inner: {
                      fontFamily: "Barlow",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "32px",
                    },
                  }}
                >
                  {item?.name}
                </Badge>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex
          id="29722"
          mt={24}
          pt={24}
          mb={9}
          sx={{
            borderTop: "1px solid #D7D7D7",
            ".t-row tr td": {
              fontSize: "16px",
              fontWeight: 600,
              border: "none !important",
            },
            ".t-col tr th": {
              border: "none !important",
            },
            th: {
              p: {
                margin: 0,
                fontSize: "16px !important",
                lineHeight: "32px",
                fontWeight: 400,
              },
              strong: {
                fontWeight: 600,
              },
            },
            td: {
              border: "1px solid #0000000",
            },
          }}
        >
          <Table verticalSpacing="sm">
            <thead className="t-col">{colsTable}</thead>
            <tbody className="t-row">{rows}</tbody>
          </Table>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GraphicDonutsDahsboardLeft;

import {
  Flex,
  Overlay,
  useMantineTheme,
  Menu,
  NavLink as NavLinkMantine,
  Anchor,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link, useLocation } from "react-router-dom";

import { ILinkItem } from "../../constants/links";
import { NavLinkMatrix } from "../../components";
interface IProps {
  navLink: ILinkItem;
}
const NavLink = ({ navLink }: IProps) => {
  const theme = useMantineTheme();
  const location = useLocation();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      {opened && (
        <Overlay onClick={() => close()} opacity={0.25} color="#000" />
      )}
      {!!navLink.subLinks?.length ? (
        <Menu
          shadow="md"
          zIndex={opened ? 300 : "unset"}
          offset={12}
          position="bottom-start"
          onOpen={open}
          onClose={close}
          // trigger="hover"
          opened={opened}
          closeOnItemClick={false}
          styles={{
            dropdown: {
              backgroundColor: theme.colors.primary[0],
              borderRadius: "0 0 8px 0",
              left: "-12px",
              padding: "0 !important",
              zIndex: 1000,
              border: "unset",

              ":before": {
                left: "-12px",
                top: "-0",
                position: "absolute",
                backgroundColor: theme.colors.primary[0],
                borderRadius: "0 0 0 8px",
                content: "''",
                width: "15px",
                height: "100%",
              },
            },
            item: {
              padding: "unset",
              border: "unset",
              "&[data-hovered]": {
                backgroundColor: "unset",
                color: "unset",
                border: "unset",
              },
            },
            itemIcon: {
              marginRight: "16px",
            },
          }}
        >
          <Menu.Target>
            <NavLinkMantine
              label={navLink.name}
              active={location.pathname.includes(navLink.path) || opened}
              style={{ zIndex: opened ? 300 : "unset" }}
              pos="relative"
              styles={{
                root: {
                  width: "100%",
                  whiteSpace: "nowrap",
                  padding: "8px",
                  borderRadius: "8px",
                  backgroundColor: "unset",
                  color: theme.colors.basic[0],
                  fontWeight: 400,
                  zIndex: opened ? 300 : "unset",

                  ":after": {
                    left: "-12px",
                    top: "-12px",
                    position: "absolute",
                    backgroundColor: theme.colors.primary[0],
                    borderRadius: "8px 8px 0 0",
                    content: "''",
                    width: "127px",
                    height: "65px",
                    zIndex: -2,
                    opacity: "0",
                  },
                  ":before": {
                    left: "0",
                    top: "0",
                    position: "absolute",
                    backgroundColor: theme.colors.primaryOpacity[1],
                    borderRadius: "8px",
                    content: "''",
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    opacity: "0",
                  },
                  "&:hover": {
                    backgroundColor: theme.colors.primaryOpacity[1],
                    color: theme.colors.primary[3],
                    fontWeight: 600,
                    opacity: 0.95,
                    fontSize: "16px !important",
                    ":before": {
                      opacity: "0",
                    },
                  },

                  span: {
                    fontSize: "16px",
                    lineHeight: "24.8px",
                    fontFamily: "Barlow",
                  },

                  "&[data-active]": {
                    backgroundColor: theme.colors.primaryOpacity[1],
                    color: theme.colors.primary[3],
                    fontWeight: 600,
                    transition: "all 0.3s linear",
                    zIndex: opened ? 300 : "unset",

                    ":before": {
                      opacity: "1",
                    },
                    ":after": {
                      opacity: "1",
                    },
                    "&:hover": {
                      backgroundColor: theme.colors.primaryOpacity[1],
                      color: theme.colors.primary[3],
                      fontWeight: 600,
                      opacity: 0.95,
                      ":before": {
                        opacity: "unset",
                      },
                    },
                  },
                },
              }}
            />
          </Menu.Target>

          <Menu.Dropdown>
            <Flex
              direction="column"
              p="12px 8px"
              rowGap={16}
              wrap={"wrap"}
              w="115px"
            >
              {navLink.subLinks?.map((subLink, index) => {
                return (
                  <NavLinkMatrix
                    key={`${subLink.name}${index}`}
                    label={subLink.name}
                    to={subLink.path}
                    onClick={close}
                    sx={{
                      height: "34px",
                    }}
                  />
                );
              })}
            </Flex>
          </Menu.Dropdown>
        </Menu>
      ) : !!navLink?.blank ? (
        <NavLinkMantine
          label={navLink.name}
          component={"a"}
          href={navLink.path}
          target="_blank"
          active={location.pathname.includes(navLink.path)}
          styles={() => ({
            root: {
              width: "100%",
              whiteSpace: "nowrap",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: "unset",
              color: theme.colors.basic[0],
              transition: "all 0.3s",
              fontWeight: 400,
              "&:hover": {
                backgroundColor: theme.colors.primaryOpacity[1],
                color: theme.colors.primary[3],
                fontWeight: 600,
                opacity: 0.95,
                fontSize: "16px !important",
              },

              span: {
                fontSize: "16px",
                lineHeight: "24.8px",
                fontFamily: "Barlow",
              },

              "&[data-active]": {
                backgroundColor: theme.colors.primaryOpacity[1],
                color: theme.colors.primary[3],
                fontWeight: 600,
                transition: "all 0.3s",
                "&:hover": {
                  backgroundColor: theme.colors.primaryOpacity[1],
                  color: theme.colors.primary[3],
                  fontWeight: 600,
                  opacity: 0.95,
                },
              },
            },
          })}
        />
      ) : (
        <NavLinkMantine
          label={navLink.name}
          component={Link}
          to={navLink.path}
          active={location.pathname.includes(navLink.path)}
          styles={() => ({
            root: {
              width: "100%",
              whiteSpace: "nowrap",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: "unset",
              color: theme.colors.basic[0],
              transition: "all 0.3s",
              fontWeight: 400,
              "&:hover": {
                backgroundColor: theme.colors.primaryOpacity[1],
                color: theme.colors.primary[3],
                fontWeight: 600,
                opacity: 0.95,
                fontSize: "16px !important",
              },

              span: {
                fontSize: "16px",
                lineHeight: "24.8px",
                fontFamily: "Barlow",
              },

              "&[data-active]": {
                backgroundColor: theme.colors.primaryOpacity[1],
                color: theme.colors.primary[3],
                fontWeight: 600,
                transition: "all 0.3s",
                "&:hover": {
                  backgroundColor: theme.colors.primaryOpacity[1],
                  color: theme.colors.primary[3],
                  fontWeight: 600,
                  opacity: 0.95,
                },
              },
            },
          })}
        />
      )}
    </>
  );
};

export default NavLink;

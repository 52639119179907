import React from "react";
import {
  Flex,
  Text,
  SimpleGrid,
  Input,
  Checkbox,
  Button,
  Tooltip,
  Center,
} from "@mantine/core";
import { InputWrapper, PasswordInput } from "../../../components";
import { HiCheck } from "react-icons/hi";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSignIn } from "./schema-validation";
import RecoverPassword from "../RecoverPassword";
import { InfoCircle } from "iconsax-react";
import { IUserMVP, MOCK_USER_MVP, useAuth } from "../../../context/auth";

const SignIn: React.FC = () => {
  const { loginMVP } = useAuth();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IUserMVP>({
    mode: "onSubmit",
    resolver: yupResolver(ValidationSignIn),
    defaultValues: MOCK_USER_MVP,
  });

  const acceptTerm = watch("saveLogin");
  const handleAcceptSave = () => {
    setValue("saveLogin", !acceptTerm);
  };
  const onSubmit = (data: IUserMVP) => {
    console.log(data);
    loginMVP();
  };

  const MOCK_REQUIRED_TOOLTIP_TEXT = [
    "Letra maiúscula",
    "Letra minúscula",
    "Número",
    "Caractere especial",
    "Mínimo 8 caracteres",
  ];

  return (
    <Center w="100%" h="100%">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        style={{ width: "100%", height: "100%" }}
      >
        <Flex
          w="100%"
          h="100%"
          p={{ base: "50px", 1600: "77px" }}
          direction="column"
          justify="center"
          gap="8px"
        >
          <Flex
            w="100%"
            direction="column"
            gap={24}
            bg="basic.0"
            p={48}
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.03)",
            }}
          >
            <Text fw={600} size={24} color="#2f2f2f">
              Acessar conta
            </Text>
            <SimpleGrid
              cols={2}
              breakpoints={[{ maxWidth: "97rem", cols: 1, spacing: "md" }]}
              spacing="32px"
              verticalSpacing="24px"
            >
              <Controller
                name="email"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputWrapper
                    label="E-mail"
                    withAsterisk
                    error={errors.email?.message}
                  >
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Digite seu email ou telefone"
                      error={errors.email?.message}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <Input.Wrapper dir="column" withAsterisk>
                    <Input.Label required w={"100%"}>
                      <Flex
                        justify={"space-between"}
                        align={"center"}
                        w={"100%"}
                      >
                        Senha{" "}
                        <Tooltip
                          maw={"448px"}
                          w="100%"
                          label={
                            <Flex mr="8px" direction={"column"} gap="8px">
                              <Text size="sm" color="white">
                                A senha deve possuir:
                              </Text>
                              <Flex
                                wrap={"wrap"}
                                columnGap={"24px"}
                                rowGap={"8px"}
                              >
                                {MOCK_REQUIRED_TOOLTIP_TEXT.map(
                                  (text, index) => (
                                    <RequiredTooltipText
                                      value={text}
                                      key={`${text}-${index}`}
                                    />
                                  )
                                )}
                              </Flex>
                            </Flex>
                          }
                        >
                          <Flex mr="8px">
                            <InfoCircle size="22" color="#6B6B6B" />
                          </Flex>
                        </Tooltip>
                      </Flex>
                    </Input.Label>
                    <PasswordInput
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Digite sua senha"
                      error={errors.password?.message}
                    />
                  </Input.Wrapper>
                )}
              />
            </SimpleGrid>
            <Flex justify="space-between">
              <Checkbox
                onClick={handleAcceptSave}
                color="primary.1"
                size="md"
                icon={HiCheck}
                label="Permanecer logado"
              />
              <RecoverPassword />
            </Flex>
            <Button type="submit" variant="primary" fullWidth mt={26}>
              Acessar conta
            </Button>
          </Flex>
        </Flex>
      </form>
    </Center>
  );
};

export default SignIn;

const RequiredTooltipText = ({ value }: { value: string }) => {
  return (
    <Flex gap="4px" align={"center"} maw="fit-content">
      <InfoCircle size="24" color="#6B6B6B" />
      <Text size="sm" color="white" maw="fit-content">
        {value}
      </Text>
    </Flex>
  );
};
